import React from 'react';
import { object, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';

import { EndpointSignal } from '..';
import { getEndpointLabel, getEndpointIcon, getEndpointSignalLabel, getEndpointSignalType } from '../../../../../../utils/endpointDisplayMap';
import getValueAndUnits from '../../../../utils/convertEndpointUnits';

const SignalSet = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const EndpointSignalGroup = ({
  endpoint, endpointSignals, unitSystem,
}) => {
  const theme = useTheme();
  const heading = getEndpointLabel(endpoint);
  const signals = Object.keys(endpointSignals);

  // If the value is a boolean, convert that boolean to string
  const getEndpointSignalValue = (data, key) => {
    let value = data[key];
    if (typeof value === 'boolean') {
      value = value ? 'TRUE' : 'FALSE';
    }
    return value;
  };

  return (
    <SignalSet>
      <Box display="flex" justifyContent="flex-start" alignItems="center" marginBottom={theme.spacing(1)}>
        {endpoint && <img src={getEndpointIcon(endpoint)} alt={heading} />}
        <Typography variant="bold" sx={{ marginLeft: theme.spacing(2) }}>{heading}</Typography>
      </Box>
      <Box marginLeft={theme.spacing(5)}>
        {signals.map(signal => (
          <EndpointSignal
            key={signal}
            label={getEndpointSignalLabel(endpoint, signal)}
            value={getValueAndUnits(
              getEndpointSignalValue(endpointSignals, signal),
              getEndpointSignalType(endpoint, signal),
              unitSystem === 'Imperial',
            )}
          />
        ))}
      </Box>
    </SignalSet>
  );
};

export default EndpointSignalGroup;

EndpointSignalGroup.propTypes = {
  endpoint: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  endpointSignals: object.isRequired,
  unitSystem: string.isRequired,
};
