import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DeleteWebhookModal from './DeleteWebhookModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state) => {
  const { currentWebhook } = state.webhooks;
  return {
    webhook: state.webhooks.webhooks[currentWebhook],
  };
};

const mapDispatchToProps = (dispatch) => {
  const { deleteWebhookRequest, toggleWebhookModal } = webhooksActions;

  return {
    actions: bindActionCreators({
      deleteWebhookRequest,
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteWebhookModal);
