import React from 'react';
import { bool, objectOf, string, shape } from 'prop-types';
import { Box, Typography } from '@mui/material';

import CopyButton from '../CopyButton';
import Feedback from '../Feedback';
import { Secret } from './components';
import staticText from '../../localization/Application/configuration';

const Credentials = ({
  applications,
  hasHeading,
  selectedApplication,
  secret,
}) => {
  const application = applications[selectedApplication];
  const {
    id: clientId,
    name,
  } = application;
  const secretWarning = <Feedback message={staticText.oauth.clientSecretInfo} type="info" additionalClassNames="m-t-none" />;

  return (
    <div>
      {hasHeading && (
        <React.Fragment>
          {secret && secretWarning}
          <Typography variant="h2" mb={2}>{staticText.oauth.heading}</Typography>
        </React.Fragment>
      )}
      <Typography variant="h3" mb={2}>{staticText.oauth.clientId}</Typography>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Typography variant="copyable">{clientId}</Typography>
        <CopyButton content={clientId} />
      </Box>
      <Secret
        applicationId={clientId}
        applicationName={name}
        secretWarning={!hasHeading && secretWarning}
      />
    </div>
  );
};

Credentials.propTypes = {
  applications: objectOf(shape({
    name: string.isRequired,
    id: string.isRequired,
  })).isRequired,
  hasHeading: bool,
  selectedApplication: string.isRequired,
  secret: string,
};

Credentials.defaultProps = {
  secret: '',
  hasHeading: true,
};

export default Credentials;
