import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import loadingShimmerStyles from '../../../../../../global-styles/animations/loadingShimmer';

export const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'isErrored',
})(({ theme, isErrored }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: '360px',
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isErrored ? 'center' : 'normal',
  '& canvas': {
    width: '100% !important',
  },
}));

const chartLabelProps = theme => ({
  textTransform: 'none',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
});

export const LoadingLabel = styled('div')(({ theme }) => ({
  ...chartLabelProps(theme),
  width: '150px',
  height: '20px',
  ...loadingShimmerStyles,
}));

export const ChartLabel = styled(Typography)(({ theme }) => ({
  ...chartLabelProps(theme),
  marginRight: theme.spacing(1),
}),
);

export const ChartLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
});
