import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../../../services/auth/reducers';
import MfaLogin from './MfaLogin';

function mapStateToProps(state) {
  const {
    mfaEnabled,
    mfaInProgress,
    mfaObj,
    mfaRecoveryEmailSent,
    mfaError,
  } = state.auth;

  return {
    mfaEnabled,
    mfaInProgress,
    mfaObj,
    mfaRecoveryEmailSent,
    mfaError,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    mfaLoginVerificationRequest, mfaRecoveryRequest, mfaResetLogin,
  } = actions;

  return {
    actions: bindActionCreators({
      mfaLoginVerificationRequest,
      mfaRecoveryRequest,
      mfaResetLogin,
    }, dispatch),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MfaLogin);
