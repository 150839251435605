import FEATURES from '../../services/featureGate/features';

const staticText = {
  [FEATURES.APPLICATIONS]: {
    body: 'You have reached the limit for the number of apps you can configure. Upgrade your plan to register more apps.',
    items: 'applications',
  },
  [`${FEATURES.APPLICATIONS}-locked`]: {
    body: "Your access to this application has been restricted due to your plan's features. Upgrade your plan to gain access to this application.",
    items: 'applications',
  },
  [FEATURES.LOG_EXPORT]: {
    body: 'Upgrade your plan or contact us to learn more about how you can export vehicle logs.',
  },
  [FEATURES.EVENT_WEBHOOKS]: {
    body: 'Upgrade your plan or contact us to learn more about how you can get access to Event based webhooks.',
  },
  [FEATURES.CONNECT_CONFIG]: {
    body: 'Your plan does not support customizing Connect. Upgrade your plan to customize your customer experience & more.',
  },
  [FEATURES.CONNECT_FUNNEL]: {
    body: 'Upgrade your plan to unlock conversion rates and drop-off metrics.',
  },
  [FEATURES.SCHEDULED_WEBHOOKS]: {
    body: 'Your plan does not support adding webhooks. Upgrade your plan to add webhooks & more.',
  },
  title: 'Upgrade your plan',
  title2: 'Upgrade to unlock',
  upgrade: 'Upgrade now',
  decline: 'No thanks',
  reachedLimit: {
    title: "You've reached a limit for your account",
    body: feature => (staticText[feature].items ?
      `You've reached the maximum number of ${staticText[feature].items} included in your account. You may delete unused ${staticText[feature].items} or get in touch with your account representative if you have a use case that requires a limit increase.`
      : "You've reached a limit for this feature. You may reduce current usage or get in touch with your account representative if you have a use case that requires a limit increase."),
    primaryCta: 'Get in touch',
    secondaryCta: 'Close',
  },
};

export default staticText;

