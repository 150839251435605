
const staticText = {
  title: 'User profile',
  developerId: 'Developer ID',
  supportNote: 'Please use this Developer ID when contacting support.',
  btnSubmit: 'Save',
  resetPassword: 'Reset password',
  fields: [
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'First name',
      errorMessage: null,
      width: '49%',
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Last name',
      errorMessage: null,
      width: '49%',
    },
    {
      name: 'username',
      type: 'email',
      placeholder: 'Email',
      errorMessage: null,
      width: '100%',
    },
  ],
  emailVerificationRequired: 'Verify your email now to prevent any future interruptions of your dashboard usage.',
  emailVerificationSent: 'Verification email sent! Please check your inbox to complete the verification.',
  sendVerificationButton: 'Send verification email',
  resendVerificationButton: 'Resend verification email',
};

export default staticText;

