import React from 'react';
import { bool, shape, string, object } from 'prop-types';
import { InputLabel, FormControl, Tooltip, Typography, styled, useTheme } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { PermissionGate } from '../../..';

export const Label = styled(InputLabel)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  transform: 'none',
  position: 'relative',
  overflow: 'visible',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  maxWidth: 'unset',
  pointerEvents: 'auto',
  zIndex: 'auto',

  svg: {
    marginLeft: theme.spacing(0.5),
    marginTop: '-2px',
  },
}));

const FormControlWrapper = ({
  displayName, tooltip, children, permission, currentInputState,
}) => {
  const theme = useTheme();

  return (
    <div>
      <FormControl>
        <Label htmlFor={displayName}>
          <Typography variant="h3">{displayName}</Typography>
          {tooltip && (
          <Tooltip title={tooltip} arrow placement="top">
            <InfoOutlined
              htmlColor={theme.palette.text.disabled}
              fontSize="inherit"
            />
          </Tooltip>
          )}
        </Label>
        {permission ? (
          <PermissionGate dashboardPermission={permission} disabled>
            {children}
          </PermissionGate>
        ) : children}
      </FormControl>
      {currentInputState.errorMessage && currentInputState.visited && (
        <Typography variant="caption" color="error">{currentInputState.errorMessage}</Typography>
      )}
    </div>
  );
};

export default FormControlWrapper;

FormControlWrapper.propTypes = {
  displayName: string.isRequired,
  tooltip: string,
  children: object.isRequired,
  permission: string,
  currentInputState: shape({
    visited: bool.isRequired,
    errorMessage: string,
  }).isRequired,
};

FormControlWrapper.defaultProps = {
  tooltip: '',
  permission: '',
};
