import React from 'react';
import { any, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Signal = styled('div', {
  shouldForwardProp: prop => prop !== 'iconSrc',
})(({ theme, iconSrc }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  borderBottom: iconSrc ? 'none' : `1px solid ${theme.palette.grey[100]}`,
}));

const EndpointSignal = ({
  iconSrc, label, value,
}) => {
  return (
    <Signal iconSrc={iconSrc}>
      {iconSrc ? (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <img src={iconSrc} alt={label} />
          <Typography variant="bold" marginLeft={2}>{label}</Typography>
        </Box>
      ) : (
        <Typography>{label}</Typography>
      )}
      <Typography>{value}</Typography>
    </Signal>
  );
};

export default EndpointSignal;

EndpointSignal.propTypes = {
  iconSrc: string,
  label: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any.isRequired,
};

EndpointSignal.defaultProps = {
  iconSrc: null,
};
