import React from 'react';
import { Link } from 'react-router-dom';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { bool, string } from 'prop-types';

import './InlineLinks.scss';
import ArrowLink from '../ArrowLink';

const InlineLinks = ({ text, icon, externalLink }) => {
  const splitOnLinks = text.split('|+|');
  // If there is no link, return the text
  if (splitOnLinks.length === 1) {
    return <span>{text}</span>;
  }

  return splitOnLinks.map((section) => {
    if (!section) return null;
    if (section.includes('@@')) {
      const [linkText, linkUrl] = section.split('@@');
      return (
        externalLink ? (
          <ArrowLink
            key={linkText}
            link={linkUrl}
            text={linkText}
            icon={icon}
          />
        ) : (
          <Link
            key={linkText}
            to={linkUrl}
            className="inline-link"
            onClick={() => {
              reportToSegment(types.TRACK, eventNames.linkClicked, {
                text: linkText,
                path: linkUrl,
                style: 'inline',
                section: 'article',
              });
            }}
          >
            {linkText}
          </Link>
        )
      );
    }
    return <span key={section}>{section}</span>;
  });
};

InlineLinks.propTypes = {
  text: string.isRequired,
  icon: string,
  externalLink: bool,
};

InlineLinks.defaultProps = {
  icon: null,
  externalLink: false,
};

export default InlineLinks;
