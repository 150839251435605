import _ from 'lodash';

const desiredFields = [
  'trackedAt',
  'requestRoute',
  'responseStatusCode',
  'responseErrorType',
  'responseErrorCode',
  'requestId',
  'moreActions',
];

const formatOverrides = {
  trackedAt: {
    minWidth: 200,
    resizable: true,
    flex: 0.8,
  },
  requestRoute: {
    minWidth: 150,
    flex: 0.6,
  },
  responseStatusCode: {
    minWidth: 170,
    flex: 0.6,
  },
  responseErrorType: {
    minWidth: 160,
    flex: 0.6,
  },
  responseErrorCode: {
    minWidth: 200,
    flex: 0.6,
  },
  requestId: {
    minWidth: 275,
    flex: 1.2,
  },
};

const formatVehicleLogs = (logs, offset) => {
  const updatedColumns = logs.cols
    .filter(col => desiredFields.includes(col.field))
    .map((col) => {
      return {
        ...col,
        ...formatOverrides[col.field],
        hide: false,
      };
    })
    .sort((a, b) => desiredFields.indexOf(a.field) - desiredFields.indexOf(b.field));

  const updatedRows = logs.rows.map((row) => {
    const updatedRow = _.pick(row, desiredFields);
    updatedRow.id = `${row.id}-${offset}`;
    return updatedRow;
  });

  return {
    rows: updatedRows,
    columns: updatedColumns,
    rowCount: Number(logs.totalCount),
  };
};

export default formatVehicleLogs;
