import { styled } from '@mui/material/styles';
import { Alert, Link } from '@mui/material';

export const ConnectOptionsContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'hideBorder',
})(({ theme, hideBorder }) => ({
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(1.5, 0, 1, 0),

  ...(hideBorder && {
    borderBottom: '0px',
  }),

  '&:last-of-type': {
    borderBottom: '0px',
  },
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: `${theme.palette.grey[100]} !important`,
  padding: theme.spacing(2),
  margin: theme.spacing(1.5, 0, 1, 0),
  color: theme.palette.common.black,

  '& .MuiAlert-message, .MuiAlert-icon': {
    padding: '0',

    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.black,
      alignSelf: 'center',

    },
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.common.black,
  fontFamily: theme.typography.bold.fontFamily,
}));

