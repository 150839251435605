export default (enableScroll, screensize) => {
  const html = document.getElementsByTagName('html');
  const noScrollClassName = screensize ? `no-scroll-${screensize}` : 'no-scroll';
  if (html && html[0]) {
    if (enableScroll) {
      html[0].classList.remove(noScrollClassName);
    } else {
      html[0].classList.add(noScrollClassName);
    }
  }
};
