import React from 'react';

const LightModeIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_4036)">
        <path d="M10.5 7.875C11.9438 7.875 13.125 9.05625 13.125 10.5C13.125 11.9438 11.9438 13.125 10.5 13.125C9.05625 13.125 7.875 11.9438 7.875 10.5C7.875 9.05625 9.05625 7.875 10.5 7.875ZM10.5 6.125C8.085 6.125 6.125 8.085 6.125 10.5C6.125 12.915 8.085 14.875 10.5 14.875C12.915 14.875 14.875 12.915 14.875 10.5C14.875 8.085 12.915 6.125 10.5 6.125ZM1.75 11.375H3.5C3.98125 11.375 4.375 10.9812 4.375 10.5C4.375 10.0188 3.98125 9.625 3.5 9.625H1.75C1.26875 9.625 0.875 10.0188 0.875 10.5C0.875 10.9812 1.26875 11.375 1.75 11.375ZM17.5 11.375H19.25C19.7313 11.375 20.125 10.9812 20.125 10.5C20.125 10.0188 19.7313 9.625 19.25 9.625H17.5C17.0187 9.625 16.625 10.0188 16.625 10.5C16.625 10.9812 17.0187 11.375 17.5 11.375ZM9.625 1.75V3.5C9.625 3.98125 10.0188 4.375 10.5 4.375C10.9812 4.375 11.375 3.98125 11.375 3.5V1.75C11.375 1.26875 10.9812 0.875 10.5 0.875C10.0188 0.875 9.625 1.26875 9.625 1.75ZM9.625 17.5V19.25C9.625 19.7313 10.0188 20.125 10.5 20.125C10.9812 20.125 11.375 19.7313 11.375 19.25V17.5C11.375 17.0187 10.9812 16.625 10.5 16.625C10.0188 16.625 9.625 17.0187 9.625 17.5ZM5.24125 4.0075C4.9 3.66625 4.34 3.66625 4.0075 4.0075C3.66625 4.34875 3.66625 4.90875 4.0075 5.24125L4.935 6.16875C5.27625 6.51 5.83625 6.51 6.16875 6.16875C6.50125 5.8275 6.51 5.2675 6.16875 4.935L5.24125 4.0075ZM16.065 14.8313C15.7238 14.49 15.1638 14.49 14.8313 14.8313C14.49 15.1725 14.49 15.7325 14.8313 16.065L15.7587 16.9925C16.1 17.3337 16.66 17.3337 16.9925 16.9925C17.3337 16.6512 17.3337 16.0913 16.9925 15.7587L16.065 14.8313ZM16.9925 5.24125C17.3337 4.9 17.3337 4.34 16.9925 4.0075C16.6512 3.66625 16.0913 3.66625 15.7587 4.0075L14.8313 4.935C14.49 5.27625 14.49 5.83625 14.8313 6.16875C15.1725 6.50125 15.7325 6.51 16.065 6.16875L16.9925 5.24125ZM6.16875 16.065C6.51 15.7238 6.51 15.1638 6.16875 14.8313C5.8275 14.49 5.2675 14.49 4.935 14.8313L4.0075 15.7587C3.66625 16.1 3.66625 16.66 4.0075 16.9925C4.34875 17.325 4.90875 17.3337 5.24125 16.9925L6.16875 16.065Z" fill="#282828" />
      </g>
      <defs>
        <clipPath id="clip0_1_4036">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightModeIcon;
