import {
  setFetchingState,
  setSuccessState,
  setFailureState,
} from './utils/reducers';

export const types = {
  FETCH_REGION_COMPATIBLE_VEHICLES:
    'developer-client/Simulator/FETCH_REGION_COMPATIBLE_VEHICLES',
  FETCH_REGION_COMPATIBLE_VEHICLES_SUCCESS:
    'developer-client/Simulator/FETCH_REGION_COMPATIBLE_VEHICLES_SUCCESS',
  FETCH_REGION_COMPATIBLE_VEHICLES_FAILURE:
    'developer-client/Simulator/FETCH_REGION_COMPATIBLE_VEHICLES_FAILURE',

  FETCH_VIN_COMPATIBILITY:
    'developer-client/Simulator/FETCH_VIN_COMPATIBILITY',
  FETCH_VIN_COMPATIBILITY_SUCCESS:
    'developer-client/Simulator/FETCH_VIN_COMPATIBILITY_SUCCESS',
  FETCH_VIN_COMPATIBILITY_FAILURE:
    'developer-client/Simulator/FETCH_VIN_COMPATIBILITY_FAILURE',

  FETCH_VEHICLE_CAPABILITIES:
    'developer-client/Simulator/FETCH_VEHICLE_CAPABILITIES',
  FETCH_VEHICLE_CAPABILITIES_SUCCESS:
    'developer-client/Simulator/FETCH_VEHICLE_CAPABILITIES_SUCCESS',
  FETCH_VEHICLE_CAPABILITIES_FAILURE:
    'developer-client/Simulator/FETCH_VEHICLE_CAPABILITIES_FAILURE',

  FETCH_VEHICLES:
    'developer-client/Simulator/FETCH_VEHICLES',
  FETCH_VEHICLES_SUCCESS:
    'developer-client/Simulator/FETCH_VEHICLES_SUCCESS',
  FETCH_VEHICLES_FAILURE:
    'developer-client/Simulator/FETCH_VEHICLES_FAILURE',

  SET_SELECTED_REGION:
    'developer-client/Simulator/SET_SELECTED_REGION',

  SET_SELECTED_VEHICLE:
    'developer-client/Simulator/SET_SELECTED_VEHICLE',

  CREATE_VEHICLE:
    'developer-client/Simulator/CREATE_VEHICLE',
  CREATE_VEHICLE_SUCCESS:
    'developer-client/Simulator/CREATE_VEHICLE_SUCCESS',
  CREATE_VEHICLE_FAILURE:
    'developer-client/Simulator/CREATE_VEHICLE_FAILURE',

  DELETE_VEHICLE:
    'developer-client/Simulator/DELETE_VEHICLE',
  DELETE_VEHICLE_SUCCESS:
    'developer-client/Simulator/DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILURE:
    'developer-client/Simulator/DELETE_VEHICLE_FAILURE',

  CHECK_CONNECTED_VEHICLE:
    'developer-client/Simulator/CHECK_CONNECTED_VEHICLE',
  CHECK_CONNECTED_VEHICLE_SUCCESS:
    'developer-client/Simulator/CHECK_CONNECTED_VEHICLE_SUCCESS',
  CHECK_CONNECTED_VEHICLE_FAILURE:
    'developer-client/Simulator/CHECK_CONNECTED_VEHICLE_FAILURE',

  CANCEL_FETCHING: 'developer-client/Simulator/CANCEL_FETCHING',
};

export const initialState = {
  isFetchingSimulator: {
    allCompatibleVehicles: false,
    vinCompatibility: false,
    vehicleCapabilities: false,
    simulatedVehicles: true,
    creatingVehicle: false,
  },
  allCompatibleVehicles: {},
  vinCompatibility: {},
  simulatedVehicles: {},
  selectedVehicle: {},
  selectedRegion: 'US',
  fetchingSimulatorErrors: {
    allCompatibleVehicles: false,
    vinCompatibility: false,
    vehicleCapabilities: false,
    simulatedVehicles: false,
    creatingVehicle: false,
    deletingVehicle: false,
    checkingConnectedVehicle: false,
  },
};

export const actions = {
  fetchRegionCompatibleVehicles: (applicationId, regionCode) => ({
    type: types.FETCH_REGION_COMPATIBLE_VEHICLES,
    payload: regionCode,
    meta: applicationId,
  }),
  fetchRegionCompatibleVehiclesSuccess: regionData => ({
    type: types.FETCH_REGION_COMPATIBLE_VEHICLES_SUCCESS,
    payload: regionData,
    meta: null,
  }),
  fetchRegionCompatibleVehiclesFailure: error => ({
    type: types.FETCH_REGION_COMPATIBLE_VEHICLES_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchVinCompatibility: (applicationId, vin) => ({
    type: types.FETCH_VIN_COMPATIBILITY,
    payload: vin,
    meta: applicationId,
  }),
  fetchVinCompatibilitySuccess: result => ({
    type: types.FETCH_VIN_COMPATIBILITY_SUCCESS,
    payload: result,
    meta: null,
  }),
  fetchVinCompatibilityFailure: error => ({
    type: types.FETCH_VIN_COMPATIBILITY_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchVehicleCapabilities: (applicationId, vehicleDefinitionId) => ({
    type: types.FETCH_VEHICLE_CAPABILITIES,
    payload: vehicleDefinitionId,
    meta: applicationId,
  }),
  fetchVehicleCapabilitiesSuccess: data => ({
    type: types.FETCH_VEHICLE_CAPABILITIES_SUCCESS,
    payload: data,
    meta: null,
  }),
  fetchVehicleCapabilitiesFailure: error => ({
    type: types.FETCH_VEHICLE_CAPABILITIES_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchAllSimulatedVehicles: (applicationId, selectedVin) => ({
    type: types.FETCH_VEHICLES,
    payload: { selectedVin },
    meta: applicationId,
  }),
  fetchAllSimulatedVehiclesSuccess: vehicles => ({
    type: types.FETCH_VEHICLES_SUCCESS,
    payload: vehicles,
    meta: null,
  }),
  fetchAllSimulatedVehiclesFailure: error => ({
    type: types.FETCH_VEHICLES_FAILURE,
    payload: error,
    meta: null,
  }),

  setSelectedRegion: regionCode => ({
    type: types.SET_SELECTED_REGION,
    payload: regionCode,
    meta: null,
  }),

  setSelectedVehicle: vehicle => ({
    type: types.SET_SELECTED_VEHICLE,
    payload: vehicle,
    meta: null,
  }),

  createVehicle: (applicationId, vehicle) => ({
    type: types.CREATE_VEHICLE,
    payload: vehicle,
    meta: applicationId,
  }),
  createVehicleSuccess: vehicle => ({
    type: types.CREATE_VEHICLE_SUCCESS,
    payload: vehicle,
    meta: null,
  }),
  createVehicleFailure: error => ({
    type: types.CREATE_VEHICLE_FAILURE,
    payload: error,
    meta: null,
  }),

  deleteVehicle: (applicationId, vehicleId) => ({
    type: types.DELETE_VEHICLE,
    payload: vehicleId,
    meta: applicationId,
  }),
  deleteVehicleSuccess: message => ({
    type: types.DELETE_VEHICLE_SUCCESS,
    payload: message,
    meta: null,
  }),
  deleteVehicleFailure: error => ({
    type: types.DELETE_VEHICLE_FAILURE,
    payload: error,
    meta: null,
  }),

  checkConnectedVehicle: (applicationId, vin) => ({
    type: types.CHECK_CONNECTED_VEHICLE,
    payload: vin,
    meta: applicationId,
  }),
  checkConnectedVehicleSuccess: data => ({
    type: types.CHECK_CONNECTED_VEHICLE_SUCCESS,
    payload: data,
    meta: null,
  }),
  checkConnectedVehicleFailure: error => ({
    type: types.CHECK_CONNECTED_VEHICLE_FAILURE,
    payload: error,
    meta: null,
  }),

  cancelFetching: key => ({
    type: types.CANCEL_FETCHING,
    payload: key,
    meta: null,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_REGION_COMPATIBLE_VEHICLES:
      return setFetchingState(state, 'allCompatibleVehicles', action);
    case types.FETCH_REGION_COMPATIBLE_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetchingSimulator: {
          ...state.isFetchingSimulator,
          allCompatibleVehicles: false,
        },
        allCompatibleVehicles: {
          ...state.allCompatibleVehicles,
          ...action.payload,
        },
      };
    case types.FETCH_REGION_COMPATIBLE_VEHICLES_FAILURE:
      return setFailureState(state, 'allCompatibleVehicles', action);

    case types.FETCH_VIN_COMPATIBILITY:
      return setFetchingState(state, 'vinCompatibility', action);
    case types.FETCH_VIN_COMPATIBILITY_SUCCESS:
      return setSuccessState(state, 'vinCompatibility', action);
    case types.FETCH_VIN_COMPATIBILITY_FAILURE:
      return setFailureState(state, 'vinCompatibility', action);

    case types.FETCH_VEHICLE_CAPABILITIES:
      return setFetchingState(state, 'vehicleCapabilities', action);
    case types.FETCH_VEHICLE_CAPABILITIES_SUCCESS:
      return {
        ...state,
        isFetchingSimulator: {
          ...state.isFetchingSimulator,
          vehicleCapabilities: false,
        },
        selectedVehicle: {
          ...state.selectedVehicle,
          ...action.payload,
        },
      };
    case types.FETCH_VEHICLE_CAPABILITIES_FAILURE:
      return setFailureState(state, 'vehicleCapabilities', action);

    case types.FETCH_VEHICLES:
      return setFetchingState(state, 'simulatedVehicles');
    case types.FETCH_VEHICLES_SUCCESS:
      return setSuccessState(state, 'simulatedVehicles', action);
    case types.FETCH_VEHICLES_FAILURE:
      return setFailureState(state, 'simulatedVehicles', action);

    case types.SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: action.payload,
      };

    case types.SET_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: {
          ...action.payload,
        },
      };

    case types.CREATE_VEHICLE:
      return setFetchingState(state, 'creatingVehicle', action);
    case types.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingSimulator: {
          ...state.isFetchingSimulator,
          creatingVehicle: false,
        },
        selectedVehicle: {
          ...state.selectedVehicle,
          username: action.payload.username,
          password: action.payload.password,
          id: action.payload.id,
        },
        vinCompatibility: {},
      };
    case types.CREATE_VEHICLE_FAILURE:
      return setFailureState(state, 'creatingVehicle', action);

    case types.DELETE_VEHICLE_SUCCESS:
      return setSuccessState(state, 'deletingVehicle', action);
    case types.DELETE_VEHICLE_FAILURE:
      return setFailureState(state, 'deletingVehicle', action);

    case types.CHECK_CONNECTED_VEHICLE_SUCCESS:
      return {
        ...state,
        isFetchingSimulator: {
          ...state.isFetchingSimulator,
          checkingConnectedVehicle: false,
        },
        simulatedVehicles: {
          ...state.simulatedVehicles,
          [action.payload.vin]: {
            ...state.simulatedVehicles[action.payload.vin],
            smartcarId: action.payload.smartcarId,
          },
        },
        selectedVehicle: {
          ...state.selectedVehicle,
          smartcarId: action.payload.smartcarId,
        },

      };
    case types.CHECK_CONNECTED_VEHICLE_FAILURE:
      return setFailureState(state, 'checkingConnectedVehicle', action);

    case types.CANCEL_FETCHING:
      return {
        ...state,
        isFetchingSimulator: {
          ...state.isFetchingSimulator,
          [action.payload]: false,
        },
      };
    default:
      return state;
  }
}
