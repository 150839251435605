import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Feedback } from '../../components';
import './Toast.scss';

const Toast = (message, type = '', position = toast.POSITION.BOTTOM_LEFT) => {
  const content = <Feedback message={message} type={type} />;

  toast(content, {
    className: 'toast',
    closeButton: false,
    position,
    autoClose: 5000,
    hideProgressBar: true,
  });
};

export default Toast;
