import { styled } from '@mui/material/styles';

export const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  '& > .MuiTypography-h3': {
    marginBottom: theme.spacing(3),
  },
  width: '303px',
  borderBottomLeftRadius: theme.border.radius,
  borderBottomRightRadius: theme.border.radius,
  border: `1px solid ${theme.palette.primary.main}`,
  marginTop: '-3px',

}));

export const EngineOptions = styled('div')(({ theme }) => ({
  '& label.checkbox-label': {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),

    '& svg': {
      marginRight: theme.spacing(1),
      marginBottom: '-2px',
      width: '15px',
    },
  },
}));
