import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Spinner } from '../../../../../../../../components';

import {
  Chip,
  EmptyStateImage,
  EndpointItem,
  EndpointRow,
  LatencyContainer,
  TableCell,
  TableWrapper,
} from '../../styles';

import { getEndpointLabel, getEndpointIcon } from '../../../../utils/endpointDisplayMap';

import platformFeatures from '../../../../../../../../assets/images/platform-features.png';
import staticText from '../../../../../../../../localization/Application/Simulator/create';

const getLatencyFigures = (endpoint) => {
  const latencyRange = `${endpoint.latencyLowerBound} - ${endpoint.latencyUpperBound}`;
  const latencyAverage =
    ((endpoint.latencyUpperBound - endpoint.latencyLowerBound) / 2) +
    endpoint.latencyLowerBound;

  if (latencyAverage === 0) {
    return {
      latencyRange: staticText.latencyTable.unavailable,
      latencyAverage: staticText.latencyTable.unavailable,
    };
  }
  return {
    latencyRange: `${latencyRange} ${staticText.latencyTable.seconds}`,
    latencyAverage: `${latencyAverage} ${staticText.latencyTable.seconds}`,
  };
};

const hasMMY = selectedVehicle =>
  selectedVehicle.make && selectedVehicle.model && selectedVehicle.year;

const ApiCapabilitiesTable = ({ selectedVehicle, isFetchingSimulator }) => {
  const theme = useTheme();

  const endpointData = selectedVehicle.endpointsWithLatency || [];

  const formattedEndpointData = endpointData.reduce((acc, endpoint) => {
    if (acc[endpoint.path]) {
      acc[endpoint.path].method.push(endpoint.method);
    } else {
      acc[endpoint.path] = {
        ...endpoint,
        method: [endpoint.method],
      };
    }
    return acc;
  }, {});

  const getTableAddendum = () => {
    if (isFetchingSimulator.vehicleCapabilities || isFetchingSimulator.allCompatibleVehicles) {
      return <Spinner additionalClassNames="simulator-capability-table" />;
    } else if (!endpointData.length && !hasMMY(selectedVehicle)) {
      return <EmptyStateImage src={platformFeatures} alt="astronaut with a car" />;
    }
    return endpointData.length ? (
      <Link
        href={staticText.latencyTable.footnote.link}
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ fontSize: theme.spacing(1.5) }}
      >
        {staticText.latencyTable.footnote.text}
      </Link>
    ) : null;
  };

  return (
    <React.Fragment>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              {staticText.latencyTable.headers.map((header) => {
                return (
                  <TableCell key={header}>
                    <Typography variant="h3">{header}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {endpointData.length ? (
            <TableBody>
              {Object.values(formattedEndpointData).map((endpoint) => {
                const { latencyRange, latencyAverage } = getLatencyFigures(endpoint);
                return (
                  <EndpointRow key={endpoint.path}>
                    <TableCell>
                      <EndpointItem>
                        <img
                          src={getEndpointIcon(endpoint.path)}
                          alt={getEndpointLabel(endpoint.path)}
                        />
                        <Typography sx={{ marginLeft: 2 }} variant="tableData">
                          {getEndpointLabel(endpoint.path)}
                        </Typography>
                      </EndpointItem>
                    </TableCell>
                    <TableCell>
                      {endpoint.method.map(method => <Chip key={method} component="div" method={method}>{method}</Chip>)}
                    </TableCell>
                    <TableCell>
                      {endpoint.method.map((method, i) => (
                        <Box marginTop={i !== 0 && theme.spacing(1)} key={method}>
                          <Typography variant="snippet">{endpoint.path}</Typography>
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell>
                      <LatencyContainer
                        disabled={latencyRange === staticText.latencyTable.unavailable}
                      >
                        {endpoint.method.map(method => (
                          <EndpointItem key={`${endpoint.path}-${latencyRange}-${method}`}>
                            <Typography variant="tableData" >{latencyRange}</Typography>
                          </EndpointItem>
                        ))}
                      </LatencyContainer>
                    </TableCell>
                    <TableCell>
                      <LatencyContainer
                        disabled={latencyAverage === staticText.latencyTable.unavailable}
                      >
                        {endpoint.method.map(method => (
                          <EndpointItem key={`${endpoint.path}-${latencyAverage}-${method}`}>
                            <Typography variant="tableData">{latencyAverage}</Typography>
                          </EndpointItem>
                        ))}
                      </LatencyContainer>
                    </TableCell>
                  </EndpointRow>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
      </TableWrapper>
      {getTableAddendum()}
    </React.Fragment>
  );
};

export default ApiCapabilitiesTable;

ApiCapabilitiesTable.propTypes = {
  isFetchingSimulator: shape({
    vehicleCapabilities: bool.isRequired,
    allCompatibleVehicles: bool.isRequired,
  }).isRequired,
  selectedVehicle: shape({
    endpointsWithLatency: arrayOf(shape({
      path: string.isRequired,
      latencyLowerBound: number.isRequired,
      latencyUpperBound: number.isRequired,
      method: string.isRequired,
    })),
    make: string,
    model: string,
    year: number,
  }).isRequired,
};
