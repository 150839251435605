import React, { useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { reportToSegment, types } from '@smartcar/morse';
import { Box, useTheme } from '@mui/material';

import applicationsProps from '../../../../types/applications';
import { Credentials, PermissionGate } from '../../../../components';
import {
  AuthenticationSettings,
  DeleteApplication,
  ManagementToken,
  RedirectUriList,
} from './components';
import staticText from '../../../../localization/Application/configuration';
import ApplicationHeader from '../ApplicationHeader';
import { getOrgApps } from '../../utils';
import { Section } from '../../styles';

const Configuration = ({
  match,
  applications,
  isUpdating,
  actions: {
    updateApplication,
    deleteApplication,
    hideSecret,
    regenerateManagementToken,
  },
  selectedOrganization,
}) => {
  const theme = useTheme();
  const { applicationId } = match.params;
  const application = applications[applicationId];
  const {
    name,
    redirectUris,
  } = application;

  const handleUpdateUris = (uris) => {
    updateApplication(applicationId, { redirectUris: uris });
  };
  const orgApps = getOrgApps(applications, selectedOrganization);
  // prevent deleting the org's last app,
  // otherwise we'd automatically generate a new app in the initialization step
  const canDeleteApplication = Object.keys(orgApps).length > 1;

  useEffect(() => {
    reportToSegment(types.PAGE, 'Configuration');
    return (() => {
      hideSecret();
    });
  }, []);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '750px' }}>
      <ApplicationHeader heading={staticText.heading} />
      <Section>
        <AuthenticationSettings
          application={application}
          updateApplication={updateApplication}
        />
      </Section>
      <Section>
        <Credentials />
        <RedirectUriList
          updateUris={handleUpdateUris}
          redirectUris={redirectUris}
          clientId={applicationId}
          isUpdating={isUpdating}
        />
      </Section>
      <Section>
        <ManagementToken
          applicationName={name}
          managementToken={application.managementToken}
          regenerateManagementToken={regenerateManagementToken}
        />
      </Section>
      {canDeleteApplication && (
        <PermissionGate dashboardPermission="delete_application">
          <Section>
            <DeleteApplication
              applicationId={applicationId}
              applicationName={name}
              deleteApplication={deleteApplication}
            />
          </Section>
        </PermissionGate>
      )}
    </Box>
  );
};

export default Configuration;

Configuration.propTypes = {
  actions: shape({
    updateApplication: func.isRequired,
    deleteApplication: func.isRequired,
    hideSecret: func.isRequired,
    regenerateManagementToken: func.isRequired,
  }).isRequired,
  match: shape({
    path: string.isRequired,
    params: shape({
      applicationId: string.isRequired,
    }),
  }).isRequired,
  ...applicationsProps,
  selectedOrganization: string.isRequired,
};
