import React from 'react';
import { bool, func, number, shape } from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import { PermissionGate, SelectIcon } from '../../../../../../../../../../components';
import { TripButton, TripSelect } from './styles';
import { Tooltip } from '../../../TripData/styles';
import staticText from '../../../../../../../../../../localization/Application/Simulator/simulator';
import tripOptions from '../../../../utils/tripOptions';

/* istanbul ignore next */
const TimelineControls = ({
  handleCreateTrip,
  handleTripAction,
  handleSpeedChange,
  tripStatus,
  disabled,
}) => {
  const theme = useTheme();
  const { tripStarted, tripPaused, tripSpeed } = tripStatus;

  // If the trip hasn't started, or the trip has started and is paused, render the 'play' icon.
  // Otherwise render the 'pause' icon.
  const playPauseButton = !tripStarted || (tripStarted && tripPaused) ? (
    <TripButton
      id="start-or-resume-simulated-trip"
      variant="contained"
      onClick={tripStarted ? () => handleTripAction('RESUME') : handleCreateTrip}
      disabled={disabled}
    >
      <PlayArrowIcon color="white" />
    </TripButton>
  ) : (
    <TripButton
      id="pause-simulated-trip"
      variant="outlined"
      onClick={() => handleTripAction('PAUSE')}
      disabled={disabled}
    >
      <PauseIcon color={theme.palette.common.white} />
    </TripButton>
  );

  return (
    <PermissionGate dashboardPermission="write_simulator">
      <Box display="flex" alignItems="center" justifyContent="center">

        {playPauseButton}
        <TripButton
          id="reset-simulated-trip"
          variant="outlined"
          disabled={disabled || !tripStarted}
          onClick={() => handleTripAction('CHANGE_FRAME', 0)}
        >
          <SkipPreviousIcon />
        </TripButton>
        <FormControl variant="outlined">
          <InputLabel id="trip-speed-select-label" shrink="true">{staticText.tripOptions.speedDropdown.label}</InputLabel>
          <TripSelect
            name="speed"
            value={tripSpeed}
            id="trip-speed-select"
            label={staticText.tripOptions.speedDropdown.label}
            labelId="trip-speed-select-label"
            IconComponent={SelectIcon}
            onChange={handleSpeedChange}
            variant="outlined"
            disabled={disabled}
          >
            {tripOptions.tripSpeeds.map((option) => {
            const name = `${option}x`;
            return (
              <MenuItem key={name} value={option}>
                {name}
              </MenuItem>
            );
          })}
          </TripSelect>
        </FormControl>
        <Tooltip
          title={staticText.tripOptions.speedDropdown.tooltip}
          arrow
          placement="top"
        >
          <InfoOutlined
            htmlColor={theme.palette.text.disabled}
            fontSize="inherit"
          />
        </Tooltip>
      </Box>
    </PermissionGate>
  );
};

export default TimelineControls;

TimelineControls.propTypes = {
  handleCreateTrip: func.isRequired,
  handleTripAction: func.isRequired,
  handleSpeedChange: func.isRequired,
  tripStatus: shape({
    tripStarted: bool.isRequired,
    tripPaused: bool.isRequired,
    tripSpeed: number.isRequired,
  }).isRequired,
  disabled: bool.isRequired,
};
