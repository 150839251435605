/* istanbul ignore file */
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { useEffect, useRef, useState } from 'react';

/**
 * NOTE: the use of 'ref' and event listeners in this component is to detect clicks outside of
 * dropdown menu so that the menu can be closed by clicking elsewhere on the page without preventing
 * that click from also having its own effect on another component.
 *
 * The `menuRef` prop returned here will need to be added to the dropdown menu
 * wrapper element in the component using this hook. (ref={menuRef})
 *
 * To learn more about refs: https://reactjs.org/docs/hooks-reference.html#useref
 */
const useToggleMenu = (segmentEvent) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { label, text } = segmentEvent;

  const openMenu = () => {
    reportToSegment(types.TRACK, eventNames.dropdownOpened, {
      label,
      text,
    });
    setMenuOpen(true);
  };

  const closeMenu = (withTracking = true) => {
    if (menuOpen && withTracking) {
      reportToSegment(types.TRACK, eventNames.dropdownClosed, {
        label,
        text,
      });
    }
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    if (menuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const menuRef = useRef();
  const handleClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return {
    menuOpen,
    openMenu,
    closeMenu,
    toggleMenu,
    handleClick,
    menuRef,
  };
};

export default useToggleMenu;
