import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Button, Typography, useTheme } from '@mui/material';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';

import { setScrollAbility } from '../../../../../../services/styleUtils';
import { ValidateNameModal } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/configuration';

const DeleteApplication = ({
  applicationId,
  applicationName,
  deleteApplication,
}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (withTracking = true) => {
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], {
        label: 'delete application',
        title: staticText.deleteApplication,
      });
    }

    setScrollAbility(modalOpen);
    setModalOpen(!modalOpen);
  };

  // TODO: extract to localization
  const description = (
    <Typography>
      This will permanently delete the
      <Typography variant="bold" component="span"> {applicationName} </Typography>
      application. Enter your application name below to confirm.
    </Typography>
  );

  return (
    <div>
      {modalOpen && (
        <ValidateNameModal
          actionTitle="Delete application"
          actionDescription={description}
          applicationName={applicationName}
          submitAction={() => deleteApplication(applicationId)}
          toggleModal={toggleModal}
        />
      )}
      <div>
        <Typography variant="h2" mb={2}>{staticText.delete.heading}</Typography>
        <Typography mb={2}>{staticText.delete.text}</Typography>
        <Button onClick={toggleModal} color="error" variant="outlined" id="delete-app-button">
          <DeleteOutlineRounded
            color={theme.palette.common.white}
            sx={{ marginRight: theme.spacing(0.5), marginTop: '-3px', fontSize: '20px' }}
          />
          {staticText.delete.buttonText}
        </Button>
      </div>
    </div>
  );
};

export default DeleteApplication;

DeleteApplication.propTypes = {
  applicationId: string.isRequired,
  applicationName: string.isRequired,
  deleteApplication: func.isRequired,
};
