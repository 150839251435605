import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { HomeLink, CtaLink, Icon } from './styles';
import PermissionGate from '../../../../../../../components/PermissionGate/';

const SimulatorHeader = ({
  heading,
  homeLink,
  cta,
  applicationId,
  setSelectedVehicle,
  setIsFetching,
}) => {
  const theme = useTheme();

  const handleHomeClick = () => {
    if (setIsFetching) {
      setIsFetching(true);
    }
    setSelectedVehicle({});
  };

  return (
    <Box marginTop={1} marginBottom={2}>
      {homeLink && setSelectedVehicle && applicationId && (
        <HomeLink>
          <Link to={`/apps/${applicationId}/simulator`} onClick={handleHomeClick}>
            <Typography><ArrowBackRoundedIcon fontSize="16px" />All vehicles</Typography>
          </Link>
        </HomeLink>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={theme.spacing(2)}
      >
        <Typography variant="h1">{heading}</Typography>
        {cta && cta.text && cta.onclick && (
          <PermissionGate dashboardPermission="write_simulator">
            <div>
              <CtaLink
                to={cta.path}
                id={`${heading}-header-cta`}
                onClick={cta.onclick}
                variant="outlined"
                display="flex"
              >
                {cta.plusIcon && (
                <Icon><AddRoundedIcon fontSize="small" /></Icon>
                )}
                {cta.text}
              </CtaLink>
            </div>
          </PermissionGate>
          )}
      </Box>
    </Box>
  );
};

export default SimulatorHeader;

SimulatorHeader.propTypes = {
  heading: string.isRequired,
  homeLink: bool,
  applicationId: string,
  setSelectedVehicle: func,
  setIsFetching: func,
  cta: shape({
    text: string.isRequired,
    path: string.isRequired,
    onclick: func.isRequired,
    variant: string,
    plusIcon: bool,
  }),
};

SimulatorHeader.defaultProps = {
  homeLink: true,
  applicationId: null,
  setSelectedVehicle: null,
  setIsFetching: null,
  cta: null,
};
