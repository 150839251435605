import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { GridRow } from '@mui/x-data-grid-pro';

import { InlineRoute, LogTable } from '../../../../../../../../components';
import { featureSetName } from '../../../../../../../../services/featureGate';
import { Banner, BannerRow } from './styles';

const ConnectedVehiclesTable = ({
  organization: {
    featureSetId,
  },
  filterValues: {
    mode,
  },
  ...logTableProps
}) => {
  const theme = useTheme();
  const isFreeFeatureSet = featureSetName(featureSetId) === 'free';
  const isLiveMode = mode === 'live';
  const showFreePlanBanner = isFreeFeatureSet && isLiveMode;

  // For the free plan, we show a banner indicating that they only have access to 1 vehicle
  const decoratedRows = showFreePlanBanner ? (() => {
    const activeVehicleRow = logTableProps.pageInfo.rows.find(
      row => row.vehicleId === logTableProps.pageInfo.freePlanActiveVehicle,
    );

    const banner = {
      vehicleId: `${logTableProps.pageInfo.freePlanActiveVehicle}-banner`,
      isBanner: true,
    };

    // If the active vehicle is in the list, we put it at the top
    if (activeVehicleRow) {
      return [
        activeVehicleRow,
        banner,
        ...logTableProps.pageInfo.rows.filter(row =>
          row.vehicleId !== logTableProps.pageInfo.freePlanActiveVehicle,
        ),
      ];
    }

    return [
      banner,
      ...logTableProps.pageInfo.rows,
    ];
  })() : logTableProps.pageInfo.rows;


  const customRowRender = (params) => {
    if (params.row.isBanner) {
      return (
        <BannerRow {...params}>
          <Banner>
            <LockOutlinedIcon sx={{ color: theme.palette.monetization.main }} />
            <div>
              <Typography variant="body">Your current plan allows access of up to <b>1 vehicle.</b></Typography>
              <InlineRoute
                text=" |+|Upgrade now@@/team/billing|+| to access more vehicles."
              />
            </div>
          </Banner>
        </BannerRow>
      );
    }

    return (
      <GridRow
        {...params}
        style={
          showFreePlanBanner &&
          params.row.vehicleId !== logTableProps.pageInfo.freePlanActiveVehicle &&
          { opacity: 0.5 }
        }
      />
    );
  };

  return (
    <LogTable
      {...logTableProps}
      pageInfo={{
        ...logTableProps.pageInfo,
        rows: decoratedRows,
      }}
      customRowRender={customRowRender}
    />
  );
};

export default ConnectedVehiclesTable;


ConnectedVehiclesTable.propTypes = {
  organization: shape({
    featureSetId: string.isRequired,
    rolePermissions: arrayOf(string).isRequired,
  }).isRequired,

  filterValues: shape({
    mode: string.isRequired,
  }).isRequired,
};

