import { styled } from '@mui/material/styles';

export const BannerRow = styled('div')(() => ({ borderBottom: '1px solid #efefef' }));

export const Banner = styled('div')(({ theme }) => ({
  margin: '0.75rem 1.5rem',
  padding: '1rem',
  textAlign: 'center',
  backgroundColor: '#F2BD331A',
  border: `1px solid ${theme.palette.monetization.main} !important`,
  color: 'black',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  gap: '1rem',
}));
