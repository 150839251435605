import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Typography } from '@mui/material';

import { Cards } from './styles';
import ArrowLink from '../ArrowLink';

export const Card = ({
  title, text, link, linkText,
}) => (
  <div>
    <Typography variant="h2">{title}</Typography>
    <Typography>{text}</Typography>
    <ArrowLink link={link} text={linkText} variant="bold" icon="launch" />
  </div>
);

Card.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  link: string.isRequired,
  linkText: string.isRequired,
};

const ResourceCards = ({ cards }) => (
  <Cards>
    {cards.map(card => (
      <Card
        key={card.text}
        title={card.title}
        text={card.text}
        link={card.link}
        linkText={card.linkText}
      />
    ))}
  </Cards>
);

export default ResourceCards;

ResourceCards.propTypes = {
  cards: arrayOf(shape({ ...Card.propTypes })).isRequired,
};
