import { Button, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TripButton = styled(Button)(({ theme }) => ({
  padding: '4px',
  marginRight: theme.spacing(1),
  minWidth: '0',

  svg: {
    height: '28px',
    width: '28px',
  },

  '&.MuiButton-contained': {
    border: `1px solid ${theme.palette.primary.main}`,

    svg: {
      fill: theme.palette.common.white,
    },

    '&:hover, &:focus': {
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },

  '&.MuiButton-outlined': {
    border: `1px solid ${theme.palette.primary.main}`,

    svg: {
      fill: theme.palette.primary.main,
    },

    '&:hover': {
      border: `1px solid ${theme.palette.primary.dark}`,

      svg: {
        fill: theme.palette.primary.dark,
      },
    },
  },

  '&.Mui-disabled': {
    border: `1px solid ${theme.palette.text.disabled}`,

    svg: {
      fill: theme.palette.text.disabled,
    },
  },
}));

export const TripSelect = styled(Select)(({ theme }) => ({
  width: '100px',
  backgroundColor: theme.palette.common.white,

  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
}));
