/* istanbul ignore file */

import React, { cloneElement } from 'react';
import { bool, node, shape, string } from 'prop-types';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import ChartError from '../ChartError';
import {
  ChartLabel,
  ChartLabelContainer,
  Container,
  LoadingLabel,
} from './styles';


const ChartWrapper = ({
  chartTitle, children, isLoading, isErrored,
}) => {
  const theme = useTheme();
  const content = (
    <React.Fragment>
      {isLoading
        ? <LoadingLabel />
        : (
          <ChartLabelContainer>
            <ChartLabel variant="subtitle2" component="h3">
              {chartTitle.title}
            </ChartLabel>
            {chartTitle.tooltip &&
              <Tooltip title={chartTitle.tooltip} arrow interactive placement="top">
                <InfoOutlined
                  htmlColor={theme.palette.primary.main}
                  fontSize="inherit"
                />
              </Tooltip>
            }
          </ChartLabelContainer>
        )
      }
      {cloneElement(children, { isLoading })}
    </React.Fragment>
  );

  return (
    <Container p={3}>
      {isErrored ? <ChartError /> : content}
    </Container>
  );
};

export default ChartWrapper;

ChartWrapper.propTypes = {
  chartTitle: shape({
    title: string.isRequired,
    tooltip: string,
  }).isRequired,
  children: node.isRequired,
  isErrored: bool.isRequired,
  isLoading: bool.isRequired,
};
