import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RequestLogs from './RequestLogs';
import { actions } from '../../reducers';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    ...state.logs,
    featureSetId: orgSelectors.getCurrentOrganization(state).featureSetId,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchRequestLogs,
    fetchAllCompatibleVehicles,
    updateFilterValues,
    fetchLogDrawerVehicleDetails,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchRequestLogs,
        fetchAllCompatibleVehicles,
        updateFilterValues,
        fetchLogDrawerVehicleDetails,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestLogs);
