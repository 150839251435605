const staticText = {
  sessionInformation: 'Session Information',
  timeline: 'Timeline',
  grantedAccess: 'Granted access',
  permissions: 'Permissions',
  viewAll: 'View all',
  viewLess: 'View less',
  id: 'ID: ',
};

export default staticText;
