import { Button as MuiButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'start',
  gap: '1rem',
});

export const PaymentAlertContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5em',
});

export const AlertTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.subtitle,
}));

export const AlertSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.subtitle,
  fontSize: '0.875rem',
}));

export const Button = styled(MuiButton)(({ theme, isButtonLoading }) => ({
  alignSelf: 'start',
  ...(isButtonLoading && {
    ...spinningButtonStyles,
    '&:before': {
      ...spinningButtonStyles['&:before'],
      backgroundColor: theme.palette.error.main,
    },
  }),
}));
