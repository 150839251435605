import { Select as MuiSelect, Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(6),
}));

export const Tooltip = styled(MuiTooltip)(() => ({
  marginLeft: '4px',
}));

export const LatencyDrawerButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  cursor: 'pointer',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export const TripDataContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(8),
  gridTemplateColumns: 'repeat(3, 1fr)',

  [theme.breakpoints.down('iPadPro')]: {
    gridGap: theme.spacing(4),
  },
}));

export const TripDataColumn = styled('div')(({ theme }) => ({
  '> * + *': {
    marginTop: theme.spacing(2),
  },
}));

export const Select = styled(MuiSelect)(() => ({
  minWidth: '120px',

  '& #trip-type-select': {
    width: '180px',
  },

  '& #trip-latency-select': {
    width: '100px',
  },

  '& .MuiSelect-select': {
    padding: '8px 12px',
  },
}));

