export const types = {
  REPORT_ERROR: 'developer-client/applications/REPORT_ERROR',
  REPORT_ERROR_SUCCESS: 'developer-client/applications/REPORT_ERROR_SUCCESS',
  REPORT_ERROR_FAILURE: 'developer-client/applications/REPORT_ERROR_FAILURE',
};

export const initialState = {
  errorMessage: null,
};

export const actions = {
  reportError: error => ({
    type: types.REPORT_ERROR,
    payload: { error },
    meta: null,
  }),
  reportErrorSuccess: () => ({
    type: types.REPORT_ERROR_SUCCESS,
    payload: null,
    meta: null,
  }),
  reportErrorFailure: error => ({
    type: types.REPORT_ERROR_FAILURE,
    payload: error,
    meta: null,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_ERROR: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case types.REPORT_ERROR_SUCCESS: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case types.REPORT_ERROR_FAILURE: {
      return {
        ...state,
        errorMessage: (action.payload && action.payload.message) || 'Error reporting error to Sentry.',
      };
    }
    default:
      return state;
  }
}
