export const filterMakesByEngineType = (
  selectedBrands,
  capabilitiesByBrand,
  brandManagementFilters,
  defaultSupportedBrands,
  selectBrandsIsGated,
) => {
  const allBrandsAfterFiltersApplied = [];
  const brandsFilteredOut = [];

  if (selectBrandsIsGated) {
    if (brandManagementFilters.length === 4) {
      return {
        filteredSelectedBrands: defaultSupportedBrands.map(brand => brand.make),
        brandsFilteredOut: [],
      };
    } else if (brandManagementFilters.length === 0) {
      return {
        filteredSelectedBrands: [],
        brandsFilteredOut: defaultSupportedBrands.map(brand => brand.make),
      };
    }
  }

  defaultSupportedBrands.forEach(({ make }) => {
    if (capabilitiesByBrand[make] && capabilitiesByBrand[make].engineType) {
      const engineTypes = capabilitiesByBrand[make].engineType;
      const brandHasEngineType = engineTypes.some(type => brandManagementFilters.includes(type));

      if (brandHasEngineType) {
        allBrandsAfterFiltersApplied.push(make);
      } else {
        brandsFilteredOut.push(make);
      }
    } else {
      // if the brand is missing engine type specs,
      // fallback to assuming it supports all engine types
      allBrandsAfterFiltersApplied.push(make);
    }
  });

  const brandsToFilter = selectBrandsIsGated ?
    defaultSupportedBrands.map(brand => brand.make) : selectedBrands;
  const filteredSelectedBrands = brandsToFilter
    .filter(make => allBrandsAfterFiltersApplied.includes(make));

  return {
    filteredSelectedBrands,
    brandsFilteredOut,
  };
};

export const determineSelectedBrands = ({
  engineFilterIsGated,
  selectBrandsIsGated,
  selectedMakes,
  brandManagementFilters,
  defaultSupportedBrands,
  brandManagementCapabilities,
  setBrandsFilteredOut,
}) => {
  // if all brand features are gated, default to selecting
  // all brands an app has access to
  if (engineFilterIsGated && selectBrandsIsGated) {
    return defaultSupportedBrands.map(brand => brand.make);
  }

  // if engine filter feature is not gated and connect config
  // has previously stored supported brands, use those.
  // otherwise fallback to default suppored brands.
  let selectedBrands = !selectBrandsIsGated &&
    selectedMakes.length > 0 ?
    selectedMakes :
    defaultSupportedBrands.map(brand => brand.make);

  // if brand filters were previously saved
  if (brandManagementFilters.length > 0) {
    // filter default brands based on saved engine type filters
    const { filteredSelectedBrands, brandsFilteredOut } = filterMakesByEngineType(
      selectedBrands,
      brandManagementCapabilities,
      brandManagementFilters,
      defaultSupportedBrands,
    );
    selectedBrands = filteredSelectedBrands;
    setBrandsFilteredOut(brandsFilteredOut);
  } else {
    setBrandsFilteredOut([]);
  }

  return selectedBrands;
};
