import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ForgotPassword from './ForgotPassword';
import { actions as ForgotPasswordActions } from '../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { forgotPasswordRequest, resetErrors } = ForgotPasswordActions;

  return {
    actions: bindActionCreators({
      forgotPasswordRequest,
      resetErrors,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
