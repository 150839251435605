import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import { actions, selectors as logsSelectors, types } from './reducers';
import api from '../../../../services/api/api';
import formatRequestLogs from './utils/formatRequestLogs';
import formatFilterValues from './utils/formatFilterValues';
import { errors } from '../../../../localization/Application/Logs/logs';

export function* fetchAllCompatibleVehicles() {
  try {
    const selectedChartOption = yield select(logsSelectors.getSelectedChartOption);
    const filterValues = yield select(logsSelectors.getFilterValues);
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchAllCompatibleVehicles,
      applicationId,
    );

    const compatibleMakes = {};

    // sets all compatible makes to true
    Object.values(data).forEach((vehicle) => {
      compatibleMakes[vehicle.make] = true;
    });

    /*
      If a user clicked on the makes chart on the overview page,
      then this functionality sets the selected make to true (checked),
      while all other makes are set to false (unchecked).

      Otherwise, all makes will be set to true (checked) on initial load.
    */
    const updatedMakes = {};
    const updatedStatusCodes = {};

    if (
      Object.values(filterValues.makes).length === 0
      && !selectedChartOption.makes
    ) {
      Object.values(data).forEach((vehicle) => {
        updatedMakes[vehicle.make] = true;
      });
    }

    if (selectedChartOption.makes) {
      // holds all of the selectedMakes using the frequency counter pattern
      const oemCounter = {};

      selectedChartOption.makes.forEach((selectedVehicle) => {
        oemCounter[selectedVehicle] = true;
      });

      Object.values(data).forEach((vehicle) => {
        if (oemCounter[vehicle.make]) {
          updatedMakes[vehicle.make] = true;
        } else {
          updatedMakes[vehicle.make] = false;
        }
      });
    }

    if (selectedChartOption.statusCodes) {
      Object.keys(filterValues.statusCodes).forEach((statusCode) => {
        updatedStatusCodes[statusCode] = false;
      });

      selectedChartOption.statusCodes.forEach((statusCode) => {
        updatedStatusCodes[statusCode] = true;
      });
    }

    yield put(actions.fetchAllCompatibleVehiclesSuccess(compatibleMakes));
    yield put(actions.updateFilterValues({
      ...filterValues,
      makes:
        !selectedChartOption.makes
        && Object.values(filterValues.makes).length !== 0
          ? filterValues.makes : updatedMakes,
      statusCodes:
        !selectedChartOption.statusCodes
          ? filterValues.statusCodes : updatedStatusCodes,
    }));
    yield put(actions.resetSelectedChartOption());
  } catch (error) {
    yield put(actions.fetchAllCompatibleVehiclesFailure(error));
  }
}

export function* fetchRequestLogs(action) {
  try {
    const { filterValues, newPaginationModel } = action.payload;

    const rowCount = yield select(logsSelectors.getRowCount);
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchRequestLogs,
      applicationId,
      {
        ...filterValues,
        mode: 'live',
      },
    );

    const updatedData = formatRequestLogs(data, filterValues.offset);

    const results = {
      pageInfo: {
        rows: updatedData.rows,
        columns: updatedData.cols,
        // Always keep rowCount from first fetch for offset pagination to work
        rowCount: newPaginationModel.page === 0 ? updatedData.totalCount : rowCount,
      },
    };

    yield put(actions.fetchRequestLogsSuccess(results));
    yield put(actions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchRequestLogsFailure(error));
  }
}

export function* fetchExportData() {
  try {
    const filterValues = yield select(logsSelectors.getFilterValues);
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const updatedFilterValues = formatFilterValues(filterValues);

    const { data } = yield call(
      api.fetchRequestsLogCsv,
      applicationId,
      // `limit` in the filterValues has to be undefined when fetching
      //  export data or you'll be capped at whatever it's set to
      {
        ...updatedFilterValues,
        limit: undefined,
        mode: 'live',
      },
    );

    yield put(actions.fetchExportDataSuccess(data));
  } catch (error) {
    yield put(actions.fetchExportDataFailure(error));
  }
}

export function* fetchLogDrawerVehicleDetails(action) {
  const { payload: vehicleId } = action;
  const applicationId = yield select(applicationSelectors.getSelectedApplication);
  // currently only available to 'live' vehicles, server will default to live mode
  const filterValues = { vehicle_id: vehicleId };

  try {
    const { data } = yield call(
      api.fetchConnectedVehicles,
      applicationId,
      filterValues,
    );

    const vehicleInfo = data.rows.find(row => row.vehicleId === vehicleId);

    if (!vehicleInfo) {
      throw new Error(errors.vehicleNotFound);
    }

    yield put(actions.fetchLogDrawerVehicleDetailsSuccess(vehicleInfo));
  } catch (error) {
    yield put(actions.fetchLogDrawerVehicleDetailsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST, fetchAllCompatibleVehicles);
  yield takeLatest(types.FETCH_REQUEST_LOGS, fetchRequestLogs);
  yield takeLatest(types.FETCH_EXPORT_DATA, fetchExportData);
  yield takeLatest(types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_REQUEST, fetchLogDrawerVehicleDetails);
}
