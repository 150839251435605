import React from 'react';
import { bool } from 'prop-types';
import { startCase } from 'lodash';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  AvatarText,
  ConnectEventCellContainer,
  EventTextContainer,
} from './styles';

import { eventProps } from '../../scenes/Application/components/ConnectInsights/propTypes';

const ConnectEventCard = ({ row, displayAvatar }) => {
  const theme = useTheme();
  return (
    <ConnectEventCellContainer>
      {displayAvatar &&
        <AvatarText variant="h4" component="div">
          {
            row.customerVehicleOwnerId
              ? startCase(row.eventString.username.slice(0, 2))
              : row.eventString.username.match(/[A-Z]/g).join('')
          }
        </AvatarText>
      }
      <EventTextContainer>
        <div className="connect-event-string">
          <Typography variant="body1" component="span">
            {row.eventString.username}
          </Typography>
          {row.eventString.eventBody.map((textSegment, i) => (
            <Typography
              // eslint-disable-next-line react/no-array-index-key
              key={`${textSegment.text}-${i}`}
              variant={textSegment.style === 'bold' ? 'h4' : 'body1'}
              component="span"
              color={textSegment.color || theme.palette.common.black}
              fontStyle={textSegment.style || 'normal'}
            >
              {textSegment.text}
            </Typography>
          ))}
        </div>
        <Typography className="connect-event-timestamp" variant="caption" mt={0.5}>
          {row.eventString.timestamp}
        </Typography>
      </EventTextContainer>
    </ConnectEventCellContainer>
  );
};

export default ConnectEventCard;

ConnectEventCard.propTypes = {
  row: eventProps.isRequired,
  displayAvatar: bool,
};

ConnectEventCard.defaultProps = {
  displayAvatar: true,
};
