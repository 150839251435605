import { Table as MuiTable, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Table = styled(MuiTable)(({ theme }) => ({
  maxWidth: '500px',
  borderBottom: 'none',
  marginBottom: theme.spacing(2),

  'th, td': {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    border: 'none',
    fontSize: '0.7rem',
  },
  th: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle2.fontSize,
    color: theme.palette.text.primary,
  },
  td: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    marginRight: theme.spacing(1),
  },
}));

export const Footnote = styled(Typography)(({ theme }) => ({
  a: {
    fontFamily: theme.typography.bold.fontFamily,
  },
}));
