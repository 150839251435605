import {
  acuraLogo,
  alfaRomeoLogo,
  audiLogo,
  bmwLogo,
  buickLogo,
  bydLogo,
  cadillacLogo,
  chevroletLogo,
  chryslerLogo,
  citroenLogo,
  cupraLogo,
  daciaLogo,
  dodgeLogo,
  dsLogo,
  fiatLogo,
  fordLogo,
  genesisLogo,
  gmcLogo,
  hondaLogo,
  hyundaiLogo,
  infinitiLogo,
  jaguarLogo,
  jeepLogo,
  landRoverLogo,
  lexusLogo,
  lincolnLogo,
  lucidLogo,
  kiaLogo,
  mazdaLogo,
  mercedesBenzLogo,
  mgLogo,
  miniLogo,
  nissanLogo,
  opelLogo,
  peugeotLogo,
  porscheLogo,
  ramLogo,
  renaultLogo,
  rivianLogo,
  seatLogo,
  skodaLogo,
  subaruLogo,
  teslaLogo,
  toyotaLogo,
  vauxhallLogo,
  volkswagenLogo,
  volvoLogo,
} from '../../../../../assets/images/logos';


const brandLogoMap = {
  acura: acuraLogo,
  alfa_romeo: alfaRomeoLogo,
  audi: audiLogo,
  bmw: bmwLogo,
  buick: buickLogo,
  byd: bydLogo,
  cadillac: cadillacLogo,
  chevrolet: chevroletLogo,
  chrysler: chryslerLogo,
  citroen: citroenLogo,
  cupra: cupraLogo,
  dacia: daciaLogo,
  dodge: dodgeLogo,
  ds: dsLogo,
  fiat: fiatLogo,
  ford: fordLogo,
  genesis: genesisLogo,
  gmc: gmcLogo,
  honda: hondaLogo,
  hyundai: hyundaiLogo,
  infiniti: infinitiLogo,
  jaguar: jaguarLogo,
  jeep: jeepLogo,
  land_rover: landRoverLogo,
  lexus: lexusLogo,
  lincoln: lincolnLogo,
  lucid: lucidLogo,
  kia: kiaLogo,
  mazda: mazdaLogo,
  mercedes_benz: mercedesBenzLogo,
  mg: mgLogo,
  mini: miniLogo,
  nissan: nissanLogo,
  opel: opelLogo,
  peugeot: peugeotLogo,
  porsche: porscheLogo,
  ram: ramLogo,
  renault: renaultLogo,
  rivian: rivianLogo,
  seat: seatLogo,
  skoda: skodaLogo,
  subaru: subaruLogo,
  tesla: teslaLogo,
  toyota: toyotaLogo,
  vauxhall: vauxhallLogo,
  volkswagen: volkswagenLogo,
  volvo: volvoLogo,
};

const getBrandLogo = (brand) => {
  const transformedBrand = brand.toLowerCase().split(' ').join('_');
  return brandLogoMap[transformedBrand];
};

export default getBrandLogo;
