import _ from 'lodash';
import { errors } from '../../../../localization/Application/VehicleManagement/vehicleManagement';

export const status = {
  INACTIVE: 'inactive',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const PAGE_SIZE = 10;

export const types = {
  FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST: 'developer-client/vehicle-management/FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST',
  FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS: 'developer-client/vehicle-management/FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS',
  FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE: 'developer-client/vehicle-management/FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE',

  FETCH_CONNECTED_VEHICLES_REQUEST: 'developer-client/vehicle-management/FETCH_CONNECTED_VEHICLES_REQUEST',
  FETCH_CONNECTED_VEHICLES_SUCCESS: 'developer-client/vehicle-management/FETCH_CONNECTED_VEHICLES_SUCCESS',
  FETCH_CONNECTED_VEHICLES_FAILURE: 'developer-client/vehicle-management/FETCH_CONNECTED_VEHICLES_FAILURE',

  DISCONNECT_VEHICLE_REQUEST: 'developer-client/vehicle-management/DISCONNECT_VEHICLE_REQUEST',
  DISCONNECT_VEHICLE_SUCCESS: 'developer-client/vehicle-management/DISCONNECT_VEHICLE_SUCCESS',
  DISCONNECT_VEHICLE_FAILURE: 'developer-client/vehicle-management/DISCONNECT_VEHICLE_FAILURE',

  UPDATE_CURSOR_MAP: 'developer-client/vehicle-management/UPDATE_CURSOR_MAP',
  UPDATE_FILTER_VALUES: 'developer-client/vehicle-management/UPDATE_FILTER_VALUES',
  UPDATE_PAGINATION_MODEL: 'developer-client/vehicle-management/UPDATE_PAGINATION_MODEL',
  UPDATE_COUNT_DIFFERENCE: 'developer-client/vehicle-management/UPDATE_COUNT_DIFFERENCE',
  UPDATE_PROVIDE_CONNECT_CTA: 'developer-client/vehicle-management/UPDATE_PROVIDE_CONNECT_CTA',
  UPDATE_DISCONNECT_STATUS: 'developer-client/vehicle-management/UPDATE_DISCONNECT_STATUS',
};

export const initialState = {
  compatibleVehicles: {},
  countDifference: 0,
  disconnectStatus: status.INACTIVE,
  isFetchingInitialVehicles: true,
  isFetchingVehicles: true,
  filterValues: {
    mode: 'live',
    make: '',
    model: '',
    year: '',
    vehicleId: '',
    conDateRange: [null, null],
  },
  pageInfo: {},
  pageToNextCursorMap: {},
  paginationModel: {
    page: 0,
    pageSize: PAGE_SIZE,
  },
  provideConnectCta: false,
  vehicleErrors: {
    compatibleVehicles: '',
    connectedVehicles: '',
    disconnectVehicle: '',
  },
};

export const actions = {
  fetchAllCompatibleVehicles: () => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST,
  }),
  fetchAllCompatibleVehiclesSuccess: data => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS,
    payload: data,
  }),
  fetchAllCompatibleVehiclesFailure: err => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE,
    payload: err,
  }),

  fetchConnectedVehicles: payload => ({
    type: types.FETCH_CONNECTED_VEHICLES_REQUEST,
    payload,
  }),
  fetchConnectedVehiclesSuccess: data => ({
    type: types.FETCH_CONNECTED_VEHICLES_SUCCESS,
    payload: data,
  }),
  fetchConnectedVehiclesFailure: err => ({
    type: types.FETCH_CONNECTED_VEHICLES_FAILURE,
    payload: err,
  }),

  disconnectVehicle: vehicleId => ({
    type: types.DISCONNECT_VEHICLE_REQUEST,
    payload: vehicleId,
  }),
  disconnectVehicleSuccess: message => ({
    type: types.DISCONNECT_VEHICLE_SUCCESS,
    payload: message,
  }),
  disconnectVehicleFailure: err => ({
    type: types.DISCONNECT_VEHICLE_FAILURE,
    payload: err,
  }),

  updateCursorMap: cursorMap => ({
    type: types.UPDATE_CURSOR_MAP,
    payload: cursorMap,
  }),
  updateFilterValues: filterValues => ({
    type: types.UPDATE_FILTER_VALUES,
    payload: filterValues,
  }),
  updatePaginationModel: model => ({
    type: types.UPDATE_PAGINATION_MODEL,
    payload: model,
  }),
  updateCountDifference: difference => ({
    type: types.UPDATE_COUNT_DIFFERENCE,
    payload: difference,
  }),
  updateProvideConnectCta: bool => ({
    type: types.UPDATE_PROVIDE_CONNECT_CTA,
    payload: bool,
  }),
  updateDisconnectStatus: newStatus => ({
    type: types.UPDATE_DISCONNECT_STATUS,
    payload: newStatus,
  }),
};

export const selectors = {
  getPageToNextCursorMap: (state) => {
    return _.get(state, ['vehicles', 'pageToNextCursorMap']);
  },
  getCountDifference: (state) => {
    return _.get(state, ['vehicles', 'countDifference']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST:
      return {
        ...state,
        vehicleErrors: {
          ...state.vehicleErrors,
          compatibleVehicles: '',
        },
      };
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicleErrors: {
          ...state.vehicleErrors,
          compatibleVehicles: '',
        },
        compatibleVehicles: action.payload,
      };
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE:
      return {
        ...state,
        vehicleErrors: {
          ...state.vehicleErrors,
          compatibleVehicles:
          (action.payload && action.payload.message) || errors.compatibleVehicles,
        },
      };

    case types.FETCH_CONNECTED_VEHICLES_REQUEST:
      return {
        ...state,
        isFetchingVehicles: true,
        vehicleErrors: {
          ...state.vehicleErrors,
          connectedVehicles: '',
        },
      };
    case types.FETCH_CONNECTED_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetchingVehicles: false,
        isFetchingInitialVehicles: false,
        pageInfo: action.payload.pageInfo,
      };
    case types.FETCH_CONNECTED_VEHICLES_FAILURE:
      return {
        ...state,
        isFetchingInitialVehicles: false,
        isFetchingVehicles: false,
        vehicleErrors: {
          ...state.vehicleErrors,
          connectedVehicles: (action.payload && action.payload.message) || errors.connectedVehicles,
        },
      };

    case types.DISCONNECT_VEHICLE_REQUEST:
      return {
        ...state,
        disconnectStatus: status.PENDING,
        vehicleErrors: {
          ...state.vehicleErrors,
          disconnectVehicle: '',
        },
      };
    case types.DISCONNECT_VEHICLE_SUCCESS:
      return {
        ...state,
        disconnectStatus: status.SUCCESS,
        vehicleErrors: {
          ...state.vehicleErrors,
          disconnectVehicle: '',
        },
      };
    case types.DISCONNECT_VEHICLE_FAILURE:
      return {
        ...state,
        disconnectStatus: status.FAILURE,
        vehicleErrors: {
          ...state.vehicleErrors,
          disconnectVehicle: (action.payload && action.payload.message) || errors.disconnectVehicle,
        },
      };

    case types.UPDATE_CURSOR_MAP:
      return {
        ...state,
        pageToNextCursorMap: action.payload,
      };
    case types.UPDATE_PAGINATION_MODEL:
      return {
        ...state,
        paginationModel: action.payload,
      };
    case types.UPDATE_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };
    case types.UPDATE_COUNT_DIFFERENCE:
      return {
        ...state,
        countDifference: action.payload,
      };
    case types.UPDATE_PROVIDE_CONNECT_CTA:
      return {
        ...state,
        provideConnectCta: action.payload,
      };
    case types.UPDATE_DISCONNECT_STATUS:
      return {
        ...state,
        disconnectStatus: action.payload,
      };
    default:
      return state;
  }
}

