import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Addendum = styled(Typography)({
  fontSize: '1rem',
  marginLeft: '4px',
});

export const Alert = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));

export const Border = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  width: '0',
  height: '124px',
}));

export const Card = styled('div')(() => ({
  width: '250px',
}));

export const Cardset = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 0',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: theme.spacing(2, 0, 0, 0),
}));

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const Highlights = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  fontSize: '1.5rem',
  gap: theme.spacing(1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: '1.125rem',
  fontFamily: theme.typography.bold.fontFamily,
}));
