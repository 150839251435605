/* eslint-disable react/require-default-props,react/no-unused-prop-types, react/forbid-prop-types */
import React from 'react';
import { defaults, Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

import lineChartLoading from '../../../assets/graphics/line-chart-loading.svg';
import { loadingShimmerKeyframes } from '../../../global-styles/animations/loadingShimmer';

import {
  handleGetElementAtEvent,
  handleGetDatasetAtEvent,
  handleElementClick,
} from '../utils';

const Container = styled('div')({
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Mask = styled('div')({
  backgroundColor: 'transparent',
  position: 'absolute',
  height: '240px',
  zIndex: 100,
  animation: `${loadingShimmerKeyframes} linear 3s infinite`,
  background:
    'linear-gradient(to right, transparent 18%, #FFFFFF 25%, transparent 36%)',
  backgroundSize: '1000px 100%',
});

const defaultChartOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    x: {},
    y: {
      type: 'linear',
      display: true,
      position: 'left',
    },
  },
  elements: {
    point: {
      radius: 2,
      hoverRadius: 6,
      hitRadius: 20,
    },
  },
};

/* istanbul ignore next */
const LineChart = (props) => {
  const { data, options, isLoading } = props;
  const theme = useTheme();

  defaults.font.family = theme.typography.bold.monospace;
  defaults.font.size = theme.spacing(1.5);
  // eslint-disable-next-line prefer-destructuring
  defaults.color = theme.palette.grey[600];

  const chartOptions = {
    ...defaultChartOptions,
    ...options,
  };

  return (
    <Container>
      {data && options && !isLoading ? (
        <Line
          data={data}
          options={chartOptions}
          onElementsClick={handleElementClick}
          getElementAtEvent={handleGetElementAtEvent}
          getDatasetAtEvent={handleGetDatasetAtEvent}
        />
      ) : (
        <React.Fragment>
          <Mask />
          <img
            src={lineChartLoading}
            alt="loading display for API requests over time"
            style={{ width: '65%' }}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

LineChart.propTypes = {
  data: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  height: PropTypes.number,
  plugins: PropTypes.array,
  onElementsClick: PropTypes.func,
  getElementAtEvent: PropTypes.func,
  getDatasetAtEvent: PropTypes.func,
  optionalClasses: PropTypes.string,
  isLoading: PropTypes.bool,
};

LineChart.defaultProps = {
  height: 400,
};

export default LineChart;
