import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WebhookInformationModal from './WebhookInformationModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state) => {
  return {
    webhook: state.webhooks.webhooks[state.webhooks.currentWebhook],
  };
};

const mapDispatchToProps = (dispatch) => {
  const { toggleWebhookModal } = webhooksActions;

  return {
    actions: bindActionCreators({
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookInformationModal);
