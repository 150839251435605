import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { LaunchRounded } from '@mui/icons-material';

import api from '../../../../../../services/api/api';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';
import { AddressDetailTitle, BillingDetails, Button, Detail } from './styles';
import staticText from '../../../../../../localization/Application/Billing/paymentInformation';

const PaymentInformation = ({ organizationId, billingInfo }) => {
  const { stripeCustomerId, billingDetails } = billingInfo;
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { address } = billingDetails;
  const getStripePortalURL = async () => {
    setIsButtonLoading(true);
    const URL = (await api.fetchStripeUrl(organizationId, 'portal', stripeCustomerId));
    window.location.href = URL;
  };

  const addressInfo = address &&
    Object.keys(staticText.billingDetails.address).map((addressDetail, i) => {
    // title
      if (i === 0) {
        return <Typography variant="h3" mb={1} gridColumn="span 2" key="address-title">{staticText.billingDetails.address.title}</Typography>;
      } else if (!address[addressDetail]) {
        return null;
      }

      return (
        <div key={addressDetail}>
          <AddressDetailTitle variant="h4" mb={1} >
            {staticText.billingDetails.address[addressDetail]}
          </AddressDetailTitle>
          <Box display="flex" alignItems="center" mb={1}>
            <Detail variant="copyable" >
              {address && address[addressDetail]}
            </Detail>
          </Box>
        </div>);
    });

  const billingInfoMap = billingDetails && Object.keys(staticText.billingDetails).map((detail) => {
    // We handle address details separately
    if (detail === 'address' ||
    // Display an empty box for tax ID and tax type if they are missing
         (!billingDetails[detail] &&
            (detail !== 'taxId' && detail !== 'taxType')
         )
    ) {
      return null;
    }

    return (
      <div key={detail}>
        <Typography variant="h3" mb={1}>{staticText.billingDetails[detail]}</Typography>
        <Box display="flex" alignItems="center" mb={2}>
          <Detail variant="copyable" >
            {billingDetails[detail] || ''}
          </Detail>
        </Box>
      </div>
    );
  });

  return (
    <BillingDetails className="card">
      <Typography variant="h2" >{staticText.title}</Typography>
      <div className="m-t-lg">
        {billingInfoMap}
        {address &&
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          {addressInfo}
        </div>
          }
      </div>
      <Button
        onClick={() => getStripePortalURL()}
        color="primary"
        variant="contained"
        endIcon={<LaunchRounded fontSize="inherit" />}
        sx={isButtonLoading && spinningButtonStyles}
      >
        {staticText.updatePaymentMethod}
      </Button>
    </BillingDetails>
  );
};

export default PaymentInformation;

PaymentInformation.propTypes = {
  organizationId: string.isRequired,
  billingInfo: shape({
    stripeCustomerId: string.isRequired,
    billingDetails: shape({
      address: shape({
        city: string,
        country: string,
        line1: string,
        line2: string,
        postal_code: string,
        state: string,
      }),
      email: string,
      phone: string,
      taxId: string,
      taxType: string,
      name: string,
    }).isRequired,
  }).isRequired,
};
