const staticText = {
  title: 'Sign up',
  terms: 'By clicking "Sign up" you agree to Smartcar\'s |+|Terms of service@@https://smartcar.com/terms/|+|.',
  subTitle: 'Your Smartcar account will let you:',
  bullets: [
    {
      key: 1,
      text: 'Retrieve your API credentials',
    },
    {
      key: 2,
      text: 'Quickly integrate our API into your mobile or web app',
    },
    {
      key: 3,
      text: 'Send HTTP requests to locate, unlock, and read other data from vehicles across brands',
    },
  ],
  contactUs: 'Not a developer? |+|Contact sales@@https://smartcar.com/demo/|+|',
  subLink: 'Have an account? |+|Sign in@@/login|+|',
  btnSubmit: 'Sign up',
  image: {
    url: '/assets/graphics/platform_features.svg',
    alt: 'Platform features',
  },
  fields: [
    {
      label: 'First name',
      name: 'firstName',
    },
    {
      label: 'Last name',
      name: 'lastName',
    },
    {
      label: 'Email',
      name: 'username',
      type: 'email',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
    },
  ],
};

export default staticText;
