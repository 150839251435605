import moment from 'moment-timezone';

import DU from './dateUtils';

/**
 * Get filter configuration (with timestamps in milliseconds).
 * @param option
 * @param createdAt
 * @returns {Object}
 */
export default function getFilterConfiguration(option, start) {
  if (!['day', 'month'].includes(option)) {
    throw new Error(`Interval option '${option}' is invalid.`);
  }

  const endOfToday = DU.getEndOfDayUtc().getTime();

  let endTime;
  let startTime;

  if (start) {
    const startInCurrentMonth = DU.isSame(
      DU.getStartOfMonthUtc(),
      DU.getStartOfMonthUtc(start),
    );
    const startInCurrentYear = DU.isSame(
      DU.getStartOfYearUtc(),
      DU.getStartOfYearUtc(start),
    );
    // If start is not current month or year, set to its respective ends
    if (!startInCurrentMonth && option === 'day') {
      endTime = Number(moment(start).utc().endOf('month'));
    } else if (!startInCurrentYear && option === 'month') {
      endTime = Number(moment(start).utc().endOf('year'));
    }

    startTime = DU.getTime(start);
  }

  switch (option) {
    case 'day':
      return {
        start: startTime || DU.getStartOfMonthUtc().getTime(),
        end: endTime || endOfToday,
        interval: option,
        timezone: 'todo - delete me and all associated code',
      };
    default:
    case 'month':
      return {
        start: startTime || DU.getStartOfYearUtc().getTime(),
        end: endTime || endOfToday,
        interval: option,
        timezone: 'todo - delete me and all associated code',
      };
  }
}
