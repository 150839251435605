export const tableHeaders = [
  {
    displayName: 'Name',
    key: 'name',
    width: '35%',
  },
  {
    displayName: 'Role',
    key: 'dashboardRole',
    cellStyle: 'capitalize',
    width: '15%',
  },
  {
    displayName: 'Access',
    key: 'access',
    cellStyle: 'capitalize',
    width: '15%',
  },
  {
    displayName: 'Mfa Enabled',
    key: 'mfaEnabled',
    cellStyle: 'capitalize',
    width: '15%',
  },
  {
    displayName: '',
    key: 'status',
    cellStyle: 'capitalize',
    width: '10%',
  },
  {
    displayName: '',
    key: 'moreActions',
    width: '10%',
  },
];

export const menuMap = {
  activeMembers: [
    {
      displayName: 'Edit',
      action: 'editUser',
      // original owners can't be edited
      excludeOwners: true,
    },
    {
      displayName: 'Delete',
      action: 'deleteUser',
      // original owners can't be deleted
      excludeOwners: true,
    },
  ],
  invitedMembers: [
    {
      displayName: 'Resend invite',
      action: 'resendInvite',
    },
    {
      displayName: 'Delete',
      action: 'deleteInvite',
    },
  ],
};

const staticText = {
  emptyState: {
    title: 'No members or invitations match the given criteria',
    buttonText: 'Add Members',
    buttonAction: 'sendInvites',
  },
};

export default staticText;
