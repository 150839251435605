import React from 'react';
import { func, number, object, shape, bool } from 'prop-types';

import { Chip, CodeBlock } from '../../../../../../../../components';

const staticText = {
  response: 'Response',
  buttons: {
    resend: 'Re-send this request',
    done: 'Done',
  },
};

const translateStatusCodeToMessage = (code) => {
  if (code < 300) return 'ok';
  if (code < 400) return 'redirect';
  return 'error';
};

const getCodeFromResponse = (response) => {
  return Object.keys(response).length ? JSON.stringify(response, null, 2) : '';
};

const Response = ({
  response, testWebhook, toggleModal, isFetching,
}) => {
  const { body, statusCode } = response;

  const statusCodeType = translateStatusCodeToMessage(statusCode);

  return (
    <div>
      <div className="flex align-end m-b-med">
        <h3 className="m-b-none m-r-med">{staticText.response}</h3>
        <Chip
          text={`${statusCode ? `${statusCode} ` : ''}${statusCodeType}`.toUpperCase()}
          additionalStyle={statusCodeType}
        />
      </div>
      {body && <CodeBlock code={getCodeFromResponse(body)} /> }
      <div className="flex-space-between m-t-lg m-b-lg">
        <button
          className={`button button-secondary button-med-long resend${isFetching ? ' spin' : ''}`}
          onClick={() => testWebhook(staticText.buttons.resend)}
        >
          {staticText.buttons.resend}
        </button>
        <button className="button button-med-long done" onClick={() => toggleModal(false)}>
          {staticText.buttons.done}
        </button>
      </div>
    </div>
  );
};

export default Response;

Response.propTypes = {
  isFetching: bool.isRequired,
  response: shape({
    body: object,
    statusCode: number,
  }).isRequired,
  testWebhook: func.isRequired,
  toggleModal: func.isRequired,
};
