const staticText = {
  title: 'Welcome back, {{firstName}}',
  description: "It looks like you don't belong to any Teams currently.",
  createTeam: 'Create team',
  defaultTeamName: firstName => `${firstName}'${firstName.endsWith('s') ? '' : 's'} First Team`,
  deleteAccountTitle: 'Delete Smartcar account',
  deleteAccount: 'Delete my account',
  confirmDelete: 'This will permanently delete the {{email}} account. Enter your email address below to confirm.',
  accountDeletionSuccess: 'Successfully deleted your Smartcar account',
};

export const errors = {
  teamCreation: 'Failed to create a new team',
  accountDeletion: 'Failed to delete Smartcar account',
};

export default staticText;
