
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../services/applications/reducers';
import SelectAppsModal from './SelectAppsModal';

const mapStateToProps = (state) => {
  return {
    lockedApplications: state.applications.lockedApplications,
    organizationId: state.organizations.selectedOrganization,
  };
};

function mapDispatchToProps(dispatch) {
  const { updateLockedApplications } = actions;

  return {
    actions: bindActionCreators({ updateLockedApplications }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAppsModal);
