import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const ConnectEventCellContainer = styled('div')(() => ({
  display: 'flex',
  alignItem: 'center',
}));

export const AvatarText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.black,
  width: '42px',
  height: '42px',
  borderRadius: '50%',
  color: theme.palette.common.white,
}));

export const EventTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  marginLeft: theme.spacing(1),
}));
