import React, { useState } from 'react';
import { func, shape, string, bool } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { InlineLinks, Feedback } from '../../../../../../../../components';
import { Inactive, Active, Unverified } from '../';

const staticText = {
  errorMessage: 'Your webhook failed to verify. Please try again.',
  successMessage: 'Your webhook is verified.',
  verification:
    'To verify this webhook, Smartcar will send a challenge to your callback URI. It will contain the following' +
    ' string: ',
  documentation:
    'Your code should generate a signature containing your management token and the challenge string. See the |+|API reference@@https://smartcar.com/docs/api-reference/webhooks/callback-verification|+| for details.',
};

const WEBHOOK_STATUS = Object.freeze({
  ACTIVE: 'ACTIVE',
  UNVERIFIED: 'UNVERIFIED',
  INACTIVE: 'INACTIVE',
});

const Content = ({
  toggleModal,
  verifyWebhookRequest,
  webhook,
  isVerifyingWebhook,
  challengeToVerify,
}) => {
  const [verifyAttempted, setVerifyAttempted] = useState(false);
  const handleWebhookVerify = (buttonText) => {
    verifyWebhookRequest(webhook.id, challengeToVerify);
    setVerifyAttempted(true);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: buttonText });
  };

  const getFeedback = (type) => {
    return (
      <Feedback message={staticText[`${type}Message`]} type={type === 'error' ? 'warn' : 'success'} />
    );
  };

  const SharedTitle = (
    <div className="m-b-med">
      <span>{staticText.verification}</span>
      <span className="bold">{challengeToVerify}</span>
    </div>
  );

  const SharedLink = (
    <div className="m-b-med">
      <InlineLinks text={staticText.documentation} />
    </div>
  );

  switch (webhook.status) {
    case WEBHOOK_STATUS.ACTIVE:
      return <Active webhook={webhook} feedback={getFeedback('success')} />;
    case WEBHOOK_STATUS.UNVERIFIED:
      return (
        <Unverified
          toggleModal={toggleModal}
          handleWebhookVerify={handleWebhookVerify}
          isVerifyingWebhook={isVerifyingWebhook}
          verifyAttempted={verifyAttempted}
          feedback={getFeedback('error')}
          sharedTitle={SharedTitle}
          sharedLink={SharedLink}
        />
      );
    case WEBHOOK_STATUS.INACTIVE:
    default:
      return (
        <Inactive
          handleWebhookVerify={handleWebhookVerify}
          isVerifyingWebhook={isVerifyingWebhook}
          verifyAttempted={verifyAttempted}
          webhook={webhook}
          feedback={getFeedback('error')}
          sharedTitle={SharedTitle}
          sharedLink={SharedLink}
        />
      );
  }
};

export default Content;

Content.propTypes = {
  toggleModal: func.isRequired,
  verifyWebhookRequest: func.isRequired,
  webhook: shape({
    id: string,
    status: string,
  }).isRequired,
  isVerifyingWebhook: bool.isRequired,
  challengeToVerify: string.isRequired,
};
