import { IconButton, MenuItem, TableCell, styled } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';

export const AppList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: theme.spacing(0, 0, 0, 3),
  textIndent: theme.spacing(-3),
  margin: 0,
}));

export const StyledNameCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.25, 3),
  'p:nth-of-type(2)': {
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5),
  },
}));

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'style',
})(({ style }) => ({
  ...(style === 'capitalize' && {
    textTransform: 'capitalize',
  }),
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: '200px',
  fontFamily: theme.typography.bold.fontFamily,
  borderRadius: theme.border.radius,
  padding: theme.spacing(1, 2),

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'isOpen',
})(({ isOpen, theme }) => ({
  padding: '0px',
  borderRadius: '50%',
  border: '1px solid transparent',

  ...(isOpen && {
    backgroundColor: theme.palette.grey[200],
  }),

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const CheckCircleIcon = styled(CheckCircle)(({ theme }) => ({
  marginLeft: '0.375rem',
  width: 20,
  fill: theme.palette.primary.main,
}));

export const CancelIcon = styled(Cancel)(({ theme }) => ({
  marginLeft: '0.375rem',
  width: 20,
  fill: theme.palette.grey[300],
}));
