import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Typography } from '@mui/material';

import { RegenerativeField, ValidateNameModal } from '../../../../../../components';
import { setScrollAbility } from '../../../../../../services/styleUtils';
import staticText from '../../../../../../localization/Application/configuration';

const ManagementToken = ({
  applicationName,
  managementToken,
  regenerateManagementToken,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (withTracking = true) => {
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], {
        label: 'regenerate management token',
        title: staticText.management.regenerateModalTitle,
      });
    }
    setScrollAbility(modalOpen);
    setModalOpen(!modalOpen);
  };

  const description = (
    <div>
      This will permanently change the management API token of the application
      <Typography variant="bold" component="span"> {applicationName}</Typography>.
      Type your application name in the box below to confirm this change.
    </div>
  );

  return (
    <React.Fragment>
      <Typography variant="h2" marginBottom={2}>{staticText.management.heading}</Typography>
      <Typography variant="h3" marginBottom={2}>{staticText.management.managementApiToken}</Typography>
      <RegenerativeField
        content={managementToken}
        regenerateHandler={toggleModal}
      />
      {modalOpen &&
        <ValidateNameModal
          actionTitle={staticText.management.regenerateModalTitle}
          actionDescription={description}
          applicationName={applicationName}
          submitAction={regenerateManagementToken}
          toggleModal={toggleModal}
        />
      }
    </React.Fragment>
  );
};

ManagementToken.propTypes = {
  applicationName: string.isRequired,
  managementToken: string.isRequired,
  regenerateManagementToken: func.isRequired,
};

export default ManagementToken;
