const staticText = {
  downgradeModalTitle: 'Downgrade plan',
  warning: {
    span1: 'Warning: ',
    span2: applicationLimit =>
      `Once your plan is downgraded, you will only have access to ${applicationLimit} ${
        applicationLimit > 1 ? 'applications' : 'application'
      }.`,
  },
  businessToBuildCTA:
    'Business (legacy) is no longer offered. Are you sure you want to switch?',
  businessToFreeCTA:
    'Business (legacy) is no longer offered. Are you sure you want to cancel?',
  downgradeModalDescription: {
    span1: email => (`Please enter your email (${email}) to confirm downgrading to the `),
    span2: ' plan.',
  },
  downgradeReason: {
    label: 'Reason for downgrading',
    options: [
      'I need more time',
      'The integration was too complex',
      'My use case is not supported',
      'The price isn’t right for me',
      'Other',
    ],
    comments: 'Additional comments',
  },
  downgradeButton: 'Downgrade',
  inputKey: 'billingDowngrade',
};

export default staticText;
