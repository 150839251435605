import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SignUp from './SignUp';
import { actions as signUpActions } from '../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    createDashboardUser,
    resetErrors,
    setAccountType,
    setPlanRedirect,
    setPostAuthRedirect,
    triggerOauthFlowRequest,
    setPrefilledInvitedUsername,
    resetLoginPage,
    resetOauthInvitation,
  } = signUpActions;

  return {
    actions: bindActionCreators({
      createDashboardUser,
      resetErrors,
      setAccountType,
      setPlanRedirect,
      setPostAuthRedirect,
      triggerOauthFlowRequest,
      setPrefilledInvitedUsername,
      resetLoginPage,
      resetOauthInvitation,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
