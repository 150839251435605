/* istanbul ignore file */
import React from 'react';

const KeyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9167 6.74984L15.8333 3.83317M17.5 2.1665L15.8333 3.83317L17.5 2.1665ZM9.49165 10.1748C9.92194 10.5994 10.264 11.1049 10.4981 11.6622C10.7322 12.2195 10.8538 12.8176 10.8558 13.422C10.8579 14.0265 10.7403 14.6254 10.5099 15.1843C10.2795 15.7431 9.94087 16.2509 9.51344 16.6783C9.08601 17.1057 8.57825 17.4444 8.0194 17.6748C7.46055 17.9052 6.86165 18.0227 6.25718 18.0207C5.65271 18.0187 5.0546 17.8971 4.49731 17.663C3.94002 17.4288 3.43454 17.0868 3.00998 16.6565C2.17509 15.7921 1.71312 14.6343 1.72356 13.4326C1.734 12.2308 2.21603 11.0813 3.06582 10.2315C3.9156 9.38172 5.06516 8.89969 6.2669 8.88925C7.46863 8.87881 8.62639 9.34078 9.49082 10.1757L9.49165 10.1748ZM9.49165 10.1748L12.9167 6.74984L9.49165 10.1748ZM12.9167 6.74984L15.4167 9.24984L18.3333 6.33317L15.8333 3.83317L12.9167 6.74984Z" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default KeyIcon;
