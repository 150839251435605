const staticText = {
  'Business (Legacy)': {
    heading: 'Your Business (Legacy) plan',
    pricing: '$500',
    pricingAddendum: '/Month',
    highlights: [
      { text: '100 vehicles' },
      { text: '$5 for each additional vehicle/month' },
      { text: '500 API calls per vehicle' },
    ],
    feature: 'For product features, refer to the Build plan below',
    tooltip:
      'Please note that our Business plan is now a legacy plan. Although your current billing plan will remain unchanged, we recommend you upgrade to access more features such as customizable Connect workflows, higher API call limits, and more.',
  },
  'Build Q4 2022': { // https://app.metronome.com/plans/c8b0e94f-5d11-47bf-8c5b-80a319d6e04b
    heading: 'Your Build (Legacy) plan',
    pricing: '$70',
    pricingAddendum: '/Month',
    highlights: [
      { text: '10 vehicles included' },
      { text: '$7 for each additional vehicle/month' },
    ],
    feature: 'For product features, refer to the Build plan below',
    tooltip:
    'Please note that your Build plan is now a legacy plan.',
  },
  'Build Q2 2023': { // https://app.metronome.com/plans/2f50a42e-ba0e-4177-993a-c678cadf484b
    heading: 'Your Build (Legacy) plan',
    pricing: '$70',
    pricingAddendum: '/Month',
    highlights: [
      { text: '10 vehicles included' },
      { text: '$7 for each additional vehicle/month' },
    ],
    feature: 'For product features, refer to the Build plan below',
    tooltip:
    'Please note that your Build plan is now a legacy plan.',
  },
  'Build Q4 2023': { // https://app.metronome.com/plans/52c98f1d-91b8-4c56-9575-9b4546520b80
    heading: 'Your Build (Legacy) plan',
    pricing: '$19.99',
    pricingAddendum: '/Month',
    highlights: [
      { text: '3 vehicles included' },
      { text: '$7 for each additional vehicle/month' },
    ],
    feature: 'For product features, refer to the Build plan below',
    tooltip:
    'Please note that your Build plan is now a legacy plan.',
  },
};

export default staticText;
