import React from 'react';
import { func, string, shape } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Modal } from '../../../../../../components';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'deactivate';

const staticText = {
  body:
    'Would you like to deactivate this webhook? If you deactivate this webhook and decide to re-activate it later, subscribed vehicles will automatically be saved and re-subscribed.',
  cancel: 'Cancel',
};

const DeactivateWebhookModal = (props) => {
  const {
    webhookId,
    actions: { toggleWebhookModal, updateWebhookRequest },
  } = props;

  const updatedWebhook = {
    enabled: false,
    disabledReason: 'developer',
  };

  const toggleModal = () =>
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent: true });

  const handleButtonClick = () => {
    updateWebhookRequest(webhookId, updatedWebhook);
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent: false });
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: modalConfig[modalName].buttonText,
    });
  };

  const content = (
    <div>
      <p>{staticText.body}</p>
      <div className="flex-space-between m-t-lg">
        <button className="button button-secondary button-med-long" onClick={toggleModal}>
          {staticText.cancel}
        </button>
        <button className="button button-med-long" onClick={handleButtonClick}>
          {modalConfig[modalName].buttonText}
        </button>
      </div>
    </div>
  );

  return <Modal title={modalConfig[modalName].title} content={content} toggleModal={toggleModal} />;
};

DeactivateWebhookModal.propTypes = {
  webhookId: string.isRequired,
  actions: shape({
    toggleWebhookModal: func.isRequired,
    updateWebhookRequest: func.isRequired,
  }).isRequired,
};

export default DeactivateWebhookModal;
