import { initialState } from '../reducers';
import dateUtils from '../../Overview/utils/dateUtils';

const defaultFilterValues = initialState.filterValues;

const formatFilterValues = (filterValues) => {
  return {
    ...(filterValues.vehicleId !== defaultFilterValues.vehicleId && {
      vehicle_id: filterValues.vehicleId,
    }),
    ...(filterValues.make !== defaultFilterValues.make && {
      vehicle_make: filterValues.make.split(' ').join('_'),
    }),
    ...(filterValues.model !== defaultFilterValues.model && {
      vehicle_model: filterValues.model,
    }),
    ...(filterValues.year !== defaultFilterValues.year && {
      vehicle_year: filterValues.year,
    }),
    ...(filterValues.conDateRange[0] && {
      start_date: new Date(filterValues.conDateRange[0]).toISOString(),
    }),
    ...(filterValues.conDateRange[1] && {
      end_date: dateUtils.getEndOfDayUtc(filterValues.conDateRange[1]).toISOString(),
    }),
    mode: filterValues.mode,
  };
};

export default formatFilterValues;
