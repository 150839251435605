import { gatedTeamRoles } from '../../scenes/Application/components/Members/utils/roles';

// must be in sync with list in Connect
const gatedFeatures = {
  plans: [
    {
      planName: 'free',
      featureSetId: 'dc784bad-8b0e-41cc-acad-1d42e62317cf',
      featureSetName: 'Free Q1-2020.v1',
      selfServe: true,
      features: {
        maxSimulatedVehicles: 5,
        scheduledWebhooks: true, // Display upgrade messages and cta
        logDays: 3, // Minimum log filter date set to logDays prior to current date
        logExport: true, // Changes export onClick to show upsell modal
        applications: 1, // When a user attempts to create more applications, show modal
        chatSupport: true, // Hides chat support
        connectConfigTheme: true, // Disables appearance > theme input
        connectConfigSelectBrands: true, // Disables appearance > select brands input
        connectConfigSelectCountries: true, // Disables appearance > select countries input
        teams: false,
        teamsRoles: gatedTeamRoles, // roles not available on teams
        connectInsightsDays: 1,
        connectFunnel: true,
        brandSelect: true, // upgrade messaging in connect insights
        singleSelect: true, // upgrade messaging in connect insights
      },
    },
    {
      planName: 'build',
      legacy: true,
      featureSetId: '78473526-443f-44e9-b07b-2645da7143c7',
      featureSetName: 'Build Q4-2022.v1',
      selfServe: true,
      features: {
        logDays: 7,
        logExport: true,
        applications: 2,
        chatSupport: true,
        connectConfigTheme: true,
        connectConfigSelectBrands: true,
        connectConfigSelectCountries: true,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 3,
        connectFunnel: true,
        brandSelect: true,
        singleSelect: true,
        maxSimulatedVehicles: 3,
      },
    },
    {
      planName: 'scale',
      legacy: true,
      featureSetId: '2b52a32d-9351-40bb-82d9-ab2213eb3a8c',
      featureSetName: 'Scale Q4-2022.v1',
      selfServe: true,
      features: {
        logDays: 90,
        applications: 5,
        teams: false,
        teamsRoles: gatedTeamRoles,
        chatSupport: true,
        connectInsightsDays: 7,
        connectFunnel: true,
        brandSelect: true,
        singleSelect: true,
        maxSimulatedVehicles: 10,
      },
    },
    {
      planName: 'enterprise',
      featureSetId: '01be4b83-49bb-4aab-8760-cc362e455b7a',
      featureSetName: 'Enterprise Q1-2022.v1',
      selfServe: false,
      features: {
        logDays: 365,
        applications: 50,
        teamsRoles: [], // all roles available on teams
        connectInsightsDays: 14,
        maxSimulatedVehicles: 25,
      },
    },
    {
      planName: 'build',
      legacy: true,
      featureSetId: '68e964a8-397c-4a7b-bc7e-e7897ed4850e',
      featureSetName: 'Build Q2-2023.v1',
      selfServe: true,
      features: {
        scheduledWebhooks: true,
        logDays: 7,
        logExport: true,
        applications: 2,
        connectConfigTheme: true,
        connectConfigSelectBrands: true,
        connectConfigSelectCountries: true,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 3,
        connectFunnel: true,
        brandSelect: true,
        singleSelect: true,
        maxSimulatedVehicles: 3,
      },
    },
    {
      planName: 'scale',
      featureSetId: 'efd795dc-0e02-4a35-95cb-16b1faf0a468',
      featureSetName: 'Scale Q2-2023.v1',
      selfServe: false,
      features: {
        scheduledWebhooks: true,
        logDays: 30,
        logExport: true,
        applications: 5,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 7,
        connectFunnel: true,
        brandSelect: true,
        singleSelect: true,
        maxSimulatedVehicles: 10,
      },
    },
    {
      planName: 'build',
      featureSetId: '83e37c6f-ce51-493a-b55d-74c0c4a41316',
      featureSetName: 'Build Q4-2024.v1',
      selfServe: true,
      features: {
        scheduledWebhooks: true,
        logDays: 7,
        logExport: true,
        applications: 2,
        connectConfigTheme: true,
        connectConfigSelectBrands: true,
        connectConfigSelectCountries: true,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 7,
        connectFunnel: true,
        brandSelect: true,
        singleSelect: true,
        maxSimulatedVehicles: 10,
        chatSupport: true,
      },
    },
  ],
};
export default gatedFeatures;
