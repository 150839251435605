import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { bool, element, node, oneOfType, string } from 'prop-types';
import { Button as MuiButton, ClickAwayListener, Grow, Popper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const Button = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'open' || prop !== 'disabled',
})(({ theme, open, disabled }) => ({
  height: '2.625rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[200]}`,

  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& p': {
    color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
  },

  '& svg.engine-type': {
    transform: 'none',
    margin: '0 5px 0 5px',
  },
  '& svg': {
    transition: 'transform 0.25s ease',

    ...(open && {
      transform: 'rotate(180deg)',
    }),

    ...(disabled && {
      fill: theme.palette.text.disabled,
    }),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: 'flex',
  alignItems: 'normal',

  '& svg': {
    marginRight: '5px',
  },
}));

/* istanbul ignore next */
const MenuItemWrapper = ({
  title,
  Icon,
  isFilterActive,
  disabled,
  id,
  children,
  minWidth,
  marginTop,
  marginBottom,
  height,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        filterActive={isFilterActive}
        open={open}
        onClick={handleToggle}
        variant="outlined"
        disabled={disabled}
        id={id}
        sx={{
          minWidth,
          marginTop,
          marginBottom,
          height,
         }}
      >
        <Title
          variant={isFilterActive ? 'bold' : 'body1'}
          color={disabled ? theme.palette.text.disabled : theme.palette.primary.main}
        >
          {title}
        </Title>
        {Icon && <Icon size={20} strokeWidth={2} />}
      </Button>
      <Popper sx={{ zIndex: 10000000000 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <div>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  {cloneElement(children, { setOpen })}
                </div>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default MenuItemWrapper;

MenuItemWrapper.propTypes = {
  title: string.isRequired,
  Icon: oneOfType([node, element]),
  isFilterActive: bool,
  disabled: bool,
  id: string.isRequired,
  children: node.isRequired,
  minWidth: string,
  marginTop: string,
  marginBottom: string,
  height: string,
};

MenuItemWrapper.defaultProps = {
  isFilterActive: false,
  disabled: false,
  Icon: null,
  minWidth: null,
  marginTop: null,
  marginBottom: null,
  height: null,
};
