const getTotalCount = (total, currItem) => {
  if (Number.isFinite(currItem.value)) {
    // eslint-disable-next-line no-param-reassign
    total += Number(currItem.value);
  }

  return total;
};

export default getTotalCount;
