import { Box, Typography } from '@mui/material';
import React from 'react';
import { shape, string } from 'prop-types';
import { useTheme } from '@mui/material/styles';
import staticText from '../../../../../../../../../../localization/Application/Logs/logDetailsDrawer';
import { articleIcon, keyboardArrowRight } from '../../../../../../../../../../assets/icons';

import { StyledSummaryContainer, Image, StyledLink } from './styles';

/* istanbul ignore file */
const Summary = ({ apiError }) => {
  const theme = useTheme();

  return (
    <Box marginTop="25px">
      <Typography fontSize="20px" marginBottom="16px" fontWeight="500px">
        {staticText.summary}
      </Typography>
      <Typography>{apiError.errorText}</Typography>
      <StyledLink href={apiError.href} underline="none" target="_blank">
        <StyledSummaryContainer>
          <Image src={articleIcon} alt="view documentation link" />
          <Typography
            fontSize="13px"
            color={theme.palette.text.primary}
            noWrap
          >
            {staticText.viewDocumentation}
          </Typography>
          <Image
            className="keyboardArrowRight"
            src={keyboardArrowRight}
            alt="view documentation link"
          />
        </StyledSummaryContainer>
      </StyledLink>
    </Box>
  );
};

export default Summary;

Summary.propTypes = {
  apiError: shape({
    errorText: string.isRequired,
    href: string.isRequired,
  }).isRequired,
};
