import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Application from './Application';
import { actions as applicationsActions } from '../../services/applications/reducers';
import { actions as orgActions, selectors as orgSelectors } from '../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    ...state.applications,
    billingInfo: state.billing.billingInfo,
    userContext: state.auth.session.userContext,
    authErrorMessage: state.auth.authErrorMessage,
    selectedOrganization: state.organizations.selectedOrganization,
    isFetchingOrganizations: state.organizations.isFetchingOrganizations,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { selectOrganization } = orgActions;

  return {
    actions: bindActionCreators({
      ...applicationsActions,
      selectOrganization,
    },
    dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
