import React from 'react';
import { func } from 'prop-types';

import {
  Modal,
  VerifyEmailModalContent,
} from '../../../../../../components';

import staticText from '../../../../../../localization/Application/Billing/verifyEmailModal';
import { getToggleModalWithTracking } from '../../utils/modalUtils';

const VerifyEmailModal = (props) => {
  const {
    toggleModal,
  } = props;

  const content = (
    <VerifyEmailModalContent openingText={staticText.sendVerificationOpening} />
  );

  const toggleModalWithTracking = getToggleModalWithTracking(staticText.title, toggleModal);

  return (
    <Modal
      toggleModal={toggleModalWithTracking}
      content={content}
      title={staticText.title}
    />
  );
};

export default VerifyEmailModal;

VerifyEmailModal.propTypes = {
  toggleModal: func.isRequired,
};
