import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../services/api/api';
import { actions, types } from './reducers';

export function* sendSupportRequest(action) {
  try {
    const { payload } = action;

    yield call(
      api.sendSupportRequest,
      payload,
    );

    yield put(actions.supportRequestSuccess());
  } catch (error) {
    yield put(actions.supportRequestFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.SEND_SUPPORT_REQUEST, sendSupportRequest);
}
