import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TopbarNav from './TopbarNav';
import { actions as authActions } from '../../../../services/auth/reducers';
import { actions as orgActions, selectors as orgSelectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const { session: { userContext } } = state.auth;
  const { selectedApplication } = state.applications;
  const { billingInfo } = state.billing;

  return {
    organizations: Object.values(state.organizations.organizations),
    organization: orgSelectors.getCurrentOrganization(state),
    userContext,
    selectedApplication,
    billingInfo,
  };
}

function mapDispatchToProps(dispatch) {
  const { logoutRequest } = authActions;
  const { selectOrganization } = orgActions;
  return {
    actions: bindActionCreators({
      logoutRequest,
      selectOrganization,
    },
    dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarNav);
