import { connect } from 'react-redux';

import BrandManagement from './BrandManagement';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  return {
    ...state.connectCustomizations,
    rolePermissions: currentOrganization.rolePermissions,
  };
}

export default connect(mapStateToProps)(BrandManagement);
