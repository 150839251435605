import React, { useEffect, useState } from 'react';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, Button, Typography, Grid } from '@mui/material';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { AuthContentContainer, InlineRoute, InputField } from '../../../../components';
import staticText from '../../../../localization/Authentication/forgotPassword';
import authFormState from '../authFormUtils';
import SuccessMessage from '../../../../components/SuccessMessage/SuccessMessage';

const ForgotPassword = ({
  actions: { forgotPasswordRequest },
  inProgress,
  formSuccess,
  forgotPasswordFormErrors,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    setErroredFields,
    erroredFields,
    handleFocus,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState();


  useEffect(() => {
    reportToSegment(types.PAGE, 'Forgot Password');
    setFormSubmitted(false);
  }, []);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields([forgotPasswordFormErrors]);
  }, [inProgress, forgotPasswordFormErrors, formSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    forgotPasswordRequest(values);
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'forgot password request',
      form_content: { values },
    });
  };

  const getGeneralError = () => {
    let error;

    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => !err.field || err.field === 'authToken' || err.field === 'password');
    } else {
      error = erroredFields;
    }
    return error || '';
  };

  return (
    <AuthContentContainer subLink={staticText.subLink}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={7}>
          <Typography variant="h1">{staticText.title}</Typography>
          {formSubmitted && formSuccess ? (
            <SuccessMessage
              message={staticText.successMessage}
              routeInfo={staticText.returnToLogin}
            />
          ) : (
            <span>
              <Box mt={4}>
                <Typography variant="body1">{staticText.subTitle}</Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Typography id="error-container" color="error" variant="caption" position="relative" top="15px">{getGeneralError()}</Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
                  {staticText.fields.map(field => (
                    <InputField
                      key={field.name}
                      inputName={field.name}
                      inputType={field.type}
                      inputValues={values[field.name]}
                      inputPlaceholder={field.label}
                      handleChange={handleChange}
                      handleFocus={handleFocus}
                      inputDisabled={disableFields}
                      errorMessage={getErrorMessage(field)}
                    />
                  ))}
                </Box>
                <Box mt={2} mb={4} pr={6}>
                  <InlineRoute text={staticText.returnToLogin} />
                </Box>
                <Button
                  disabled={disableFields}
                  id="btnSubmit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  {staticText.btnSubmit}
                </Button>
              </form>
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: { xs: 'none', md: 'block' },
            textAlign: 'center',
          }}
        >
          <img
            src={staticText.image.url}
            alt={staticText.image.alt}
            width="230"
          />
        </Grid>
      </Grid>
    </AuthContentContainer>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  actions: shape({
    forgotPasswordRequest: func.isRequired,
  }).isRequired,
  inProgress: bool,
  formSuccess: bool,
  forgotPasswordFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
};

ForgotPassword.defaultProps = {
  forgotPasswordFormErrors: [],
  inProgress: false,
  formSuccess: false,
};

