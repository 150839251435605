/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import { shape, func, string, number, bool } from 'prop-types';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import staticText from '../../../../../../../../localization/Application/Logs/logDetailsDrawer';
import apiErrors from '../../../../../../../../../config/requestLogs/apiErrors';

import {
  StyledDrawer,
  StyledHeader,
  StyledData,
  StyledCarData,
  Logo,
  Close,
} from './styles';
import getBrandLogo from '../../../../../Simulator/utils/brandLogoMap';
import { Chip, Disclaimer, Spinner } from '../../../../../../../../components';
import Summary from './components/Summary';
import { errors } from '../../../../../../../../localization/Application/Logs/logs';

/* istanbul ignore file */
const LogDetailsDrawer = ({
  actions: { fetchLogDrawerVehicleDetails },
  closeModal,
  log,
  vehicleInfo,
  vehicleInfoError,
  loading,
}) => {
  const { vehicle } = vehicleInfo;
  const { responseErrorType } = log;

  const logo = vehicle && getBrandLogo(vehicle.make);

  const apiError = apiErrors[responseErrorType];
  const showHeader = responseErrorType && responseErrorType !== 'N/A';
  const showVehicle = !vehicleInfoError && vehicle;

  // request succeeded but there's no vehicle (vehicle was disconnected)
  const isDisconnectedError = vehicleInfoError === errors.vehicleNotFound;

  // The request failed - we don't know if the vehicle was disconnected or some other issue
  const isOtherError = vehicleInfoError && !isDisconnectedError;

  const endpoint = log.requestRoute.route;

  useEffect(() => {
    fetchLogDrawerVehicleDetails(log.vehicleId);
  }, []);

  return (
    <StyledDrawer onClose={closeModal} anchor="right" open>
      {loading && <Spinner />}
      {!loading && (
        <Box display="flex" flexDirection="column">
          <Close onClick={() => closeModal(null)}>
            <CloseIcon />
          </Close>
          {endpoint}
          {showHeader && <StyledHeader>{responseErrorType}</StyledHeader>}
          <StyledData>
            <Typography>
              {showVehicle ? staticText.car : null}
            </Typography>
            <StyledCarData>
              {vehicle &&
                <Logo>
                  <img src={logo} alt={vehicle.make} />
                </Logo>
              }
              <Typography
                variant={vehicleInfoError && 'caption'}
                color={vehicleInfoError && 'error'}
              >
                {isOtherError && vehicleInfoError}
                {isDisconnectedError && <Chip text={staticText.disconnected} />}
                {vehicle && `${vehicle.make} ${vehicle.model} ${vehicle.year}` }
              </Typography>
            </StyledCarData>
          </StyledData>
          {Object.keys(log).map((logData) => {
            if (
              logData === 'vehicleId' ||
              logData === 'responseErrorCode' ||
              logData === 'requestTimestamp'
            ) {
              return (
                <StyledData key={logData}>
                  <Typography>{staticText[logData]}</Typography>
                  <Typography>{log[logData]}</Typography>
                </StyledData>
              );
            }
            return null;
          })}
          {apiError && <Summary apiError={apiError} />}
          <Disclaimer />
        </Box>
      )}
    </StyledDrawer>
  );
};

export default LogDetailsDrawer;

LogDetailsDrawer.propTypes = {
  closeModal: func.isRequired,
  actions: shape({
    fetchLogDrawerVehicleDetails: func.isRequired,
  }).isRequired,
  log: shape({
    id: string.isRequired,
    vehicleId: string.isRequired,
    responseErrorCode: string.isRequired,
    requestTimestamp: string.isRequired,
  }).isRequired,
  vehicleInfo: shape({
    connectedAt: string,
    userId: string,
    vehicle: shape({
      make: string,
      model: string,
      year: number,
    }),
    vehicleId: string,
  }),
  vehicleInfoError: string.isRequired,
  loading: bool.isRequired,
};

LogDetailsDrawer.defaultProps = {
  vehicleInfo: {
    connectedAt: '',
    userId: '',
    vehicleId: '',
    vehicle: {
      make: '',
      model: '',
      year: '',
    },
  },
};
