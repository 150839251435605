import { styled } from '@mui/material/styles';
import loadingShimmerStyles from '../../../global-styles/animations/loadingShimmer';

export const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  width: '100%',
});

export const LoadingBar = styled('div', {
  shouldForwardProp: prop => prop !== 'height' && prop !== 'delay',
})(({ height, delay }) => ({
  borderRadius: '2px',
  // 7 * 24px gutters = 168px, divide remaining space into 8 sections
  width: 'calc(calc(100% - 168px) / 8)',
  height,
  ...loadingShimmerStyles,
  animationDelay: `${delay * 115}ms`,
}));

export const Container = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
