const staticText = {
  title: 'User Security',
  resetPassword: {
    title: 'Password',
    action: 'Reset Password',
  },
  mfaSetup: {
    title: 'Multi-Factor Authentication (MFA)',
    method: 'Authenticator App',
    enroll: 'Enroll',
    cancel: 'Cancel',
    disable: 'Disable',
    scanCode: 'Scan the QR code with your authenticator app:',
    manualCode: 'Can’t scan the code? Enter the following secret instead:',
    disableMfaDescription: 'To disable MFA, enter the code from your authenticator app:',
    enterCode: 'Enter the passcode from your app',
    verify: 'Verify',
    invalidCode: 'Incorrect passcode.',
    enabledToast: 'Multi-factor authentication successfully enabled.',
    disabledToast: 'Multi-factor authentication successfully disabled.',
  },
  mfaChallenge: {
    title: 'Multi-factor authentication',
    noAccess: "I don't have access to the code.",
    back: '|+|Back to Login@@/login|+|',
  },
  mfaRecoveryRequest: {
    title: 'Recovery email sent',
    message: 'A recovery email has been sent to the email on the account. Click on the link in the email to reset your multi-factor authentication.',
    button: 'Return to login',
    image: {
      url: '/assets/graphics/smartcar_envelope.svg',
      alt: 'Open envelope with a page with a Smartcar logo on it',
    },

  },
  mfaEnroll: {
    title: 'Set up multi-factor authentication',
  },
};

export default staticText;

