import React, { useEffect } from 'react';
import { func, object, shape, string } from 'prop-types';

import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { VehiclesList } from './components';
import { SimulatorHeader } from '../shared';
import { EmptyList, ResourceCards } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/Simulator/home';

const SimulatorHome = ({
  actions: {
    setSelectedVehicle,
    setSelectedRegion,
    fetchVehicleTrip,
    deleteVehicle,
  },
  applicationId,
  simulatedVehicles,
}) => {
  const hasVehicles = Object.keys(simulatedVehicles).length > 0;

  const handleCreateVehicleClick = () => {
    reportToSegment(
      types.TRACK,
      eventNames.linkClicked,
      {
        section: 'header',
        style: 'cta primary',
        label: '[simulator] create new vehicle',
        text: staticText.newVehicle,
        path: `/apps/${applicationId}/simulator/create-vehicle`,
      });
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Simulator - Home');
  }, []);

  return (
    <div id="simulator-home">
      <SimulatorHeader
        heading={staticText.heading}
        homeLink={false}
        cta={{
          text: staticText.newVehicle,
          path: `/apps/${applicationId}/simulator/create-vehicle`,
          onclick: handleCreateVehicleClick,
          plusIcon: true,
        }}
      />
      {hasVehicles ?
        <VehiclesList
          applicationId={applicationId}
          fetchVehicleTrip={fetchVehicleTrip}
          setSelectedVehicle={setSelectedVehicle}
          setSelectedRegion={setSelectedRegion}
          handleCreateVehicleClick={handleCreateVehicleClick}
          deleteVehicle={deleteVehicle}
          vehicles={simulatedVehicles}
        /> :
        <EmptyList
          heading={staticText.emptyState.heading}
          details={staticText.emptyState.details}
          cta={{
            text: staticText.vehiclesList.createFirstVehicle,
            path: `/apps/${applicationId}/simulator/create-vehicle`,
            onClick: handleCreateVehicleClick,
          }}
          dashboardPermission="write_simulator"
        />
      }
      <ResourceCards cards={staticText.resourceCards} />
    </div>
  );
};

export default SimulatorHome;

SimulatorHome.propTypes = {
  actions: shape({
    setSelectedVehicle: func.isRequired,
    setSelectedRegion: func.isRequired,
    fetchVehicleTrip: func.isRequired,
    deleteVehicle: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  simulatedVehicles: object.isRequired,
};
