/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { arrayOf, bool, func, shape, string, array, object } from 'prop-types';
import { Typography } from '@mui/material';

import staticText from '../../../../../../localization/Application/connect-config';
import { Dialog, InlineLinks } from '../../../../../../components';

import { BrandList } from './components';
import ErrorState from '../ErrorState';
import { CustomizationGroupDescription, CustomizationGroupHeading, FeatureGatedBanner } from '../../styles';

const BrandManagement = ({
  customizations: {
    supportedMakes,
    brandManagementFilters,
    newBrandOptIn,
  },
  updateCustomizations,
  defaultSupportedBrands,
  disabledBrands,
  suggestionDialogOpen,
  setSuggestionDialogOpen,
  rolePermissions,
  selectBrandsIsGated,
  engineFilterIsGated,
  fetchingConnectConfigErrors,
  brandSelectDialogKey,
}) => {
  const canWriteBilling = rolePermissions.includes('write_billing');

  const updateSelectedMakes = (makes) => {
    updateCustomizations({ supportedMakes: makes });
  };

  const getFeatureGatedMessage = () => {
    return (
      <FeatureGatedBanner sx={{ mb: 2 }}>
        <img src="/assets/icons/lock.svg" alt="lock icon" />
        <Typography component="p">
          {engineFilterIsGated && (
            <span>{staticText.brandManagement.featureGated.engineFilter}{' '}</span>
          )}
          <span>{staticText.brandManagement.featureGated.selectBrands}{' '}</span>
          <InlineLinks
            text={canWriteBilling ?
              staticText.brandManagement.featureGated.upgrade :
              staticText.brandManagement.featureGated.contactOwnersToUpgrade}
            targetBlank={false}
          />
        </Typography>
      </FeatureGatedBanner>
    );
  };

  return (
    fetchingConnectConfigErrors && (
      fetchingConnectConfigErrors.brandManagementCapabilities ||
      fetchingConnectConfigErrors.defaultSupportedBrands ||
      fetchingConnectConfigErrors.makesByCountry
    ) ? (
      <ErrorState message={staticText.errors.fetchingBrandManagement} />
      ) : (
        <div>
          <CustomizationGroupHeading variant="h2">{staticText.brandManagement.heading}</CustomizationGroupHeading>
          <CustomizationGroupDescription>
            {staticText.brandManagement.description}
          </CustomizationGroupDescription>
          {engineFilterIsGated && selectBrandsIsGated && (
            getFeatureGatedMessage()
          )}
          <div>
            <BrandList
              disabledBrands={disabledBrands}
              defaultSupportedBrands={defaultSupportedBrands}
              selectedMakes={supportedMakes || []}
              updateSelectedMakes={updateSelectedMakes}
              selectedNewBrandOptIn={newBrandOptIn}
              selectedBrandManagementFilters={brandManagementFilters}
              updateCustomizations={updateCustomizations}
              engineFilterIsGated={engineFilterIsGated}
              selectBrandsIsGated={selectBrandsIsGated}
              featureGatedMessage={
                selectBrandsIsGated &&
                !engineFilterIsGated &&
                getFeatureGatedMessage()}
            />
          </div>
          <Dialog
            open={suggestionDialogOpen}
            setDialogOpen={setSuggestionDialogOpen}
            heading={staticText.brandManagement.singleBrandSuggestion.heading}
            text={<InlineLinks text={staticText.brandManagement.singleBrandSuggestion.text} />}
            cancelText={staticText.brandManagement.singleBrandSuggestion.close}
            segmentLabel="[connect config - brand management]"
            viewedStorageKey={brandSelectDialogKey}
          />
        </div>
      )
  );
};

export default BrandManagement;

BrandManagement.propTypes = {
  customizations: shape({
    supportedMakes: arrayOf(string),
    brandManagementFilters: arrayOf(array).isRequired,
    newBrandOptIn: bool.isRequired,
  }).isRequired,
  defaultSupportedBrands: arrayOf(
    shape({
      make: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  brandManagementCapabilities: arrayOf(shape(object)).isRequired,
  updateCustomizations: func.isRequired,
  fetchingConnectConfigErrors: shape({
    applicationMakes: string,
    makesByCountry: string,
  }).isRequired,
  suggestionDialogOpen: bool.isRequired,
  setSuggestionDialogOpen: func.isRequired,
  disabledBrands: arrayOf(string).isRequired,
  rolePermissions: arrayOf(string).isRequired,
  selectBrandsIsGated: bool,
  engineFilterIsGated: bool,
  brandSelectDialogKey: string.isRequired,
};

BrandManagement.defaultProps = {
  selectBrandsIsGated: false,
  engineFilterIsGated: false,
};
