import React, { useEffect } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Chip, CodeBlock, InlineLinks, Modal, Spinner } from '../../../../../../components';
import { Response } from './components';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'test';

const staticText = {
  chip: 'POST',
  request: 'Request',
  documentation: 'This will send the above payload to your callback URI. See our |+|Testing guide@@https://smartcar.com/docs/api-reference/webhooks/callback-verification|+| to learn more.',
  buttons: {
    test: 'Send test request',
  },
};

const TestWebhookModal = (props) => {
  const {
    webhooks,
    currentWebhook,
    testEvent,
    testWebhookResponse,
    isTestingWebhook,
    actions: {
      fetchTestEventRequest, testWebhookRequest, clearTestData, toggleWebhookModal,
    },
  } = props;

  useEffect(() => {
    fetchTestEventRequest(currentWebhook);
  }, []);

  const { callbackUri } = webhooks[currentWebhook];

  const toggleModal = (trackCloseEvent = true) => {
    clearTestData();
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent });
  };

  const testWebhook = (buttonText) => {
    testWebhookRequest(currentWebhook, testEvent);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: buttonText });
  };

  const webhookTested = testWebhookResponse !== null;

  const requestBlock = (
    <div className="flex-grid-column flex-grow">
      <div className="m-b-lg">
        <Chip text={staticText.chip} additionalStyle="post" />
        <span className="m-l-med">{callbackUri}</span>
      </div>
      <h3>{staticText.request}</h3>
      {testEvent
        ? (
          <React.Fragment>
            <CodeBlock code={JSON.stringify(testEvent, null, 2)} />
            <div className="m-t-med m-b-med"><InlineLinks text={staticText.documentation} /></div>
            {!webhookTested
              && (
                <div className="button-wrapper right m-t-auto">
                  <button
                    type="button"
                    className={`button button-med-long send-test${isTestingWebhook ? ' spin' : ''}`}
                    onClick={() => testWebhook(staticText.buttons.test)}
                  >
                    {staticText.buttons.test}
                  </button>
                </div>
              )}
          </React.Fragment>
        ) : <Spinner />
      }
    </div>
  );

  const content = (
    // I apologize profusely for manual calculation, however it's hard to get
    // the submit button to stick to the bottom of the modal given that the modal's
    // content is not a flex-box. Once that changes we can remove this.
    <div className="border-box flex-grid-column" style={{ height: 'calc(100% - 4.25rem)' }}>
      {requestBlock}
      {webhookTested && (
        <Response
          isFetching={isTestingWebhook}
          response={testWebhookResponse}
          testWebhook={testWebhook}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );

  return (
    <Modal title={modalConfig[modalName].title} content={content} toggleModal={toggleModal} additionalClassNames="fixed-height" />
  );
};

export default TestWebhookModal;

TestWebhookModal.propTypes = {
  webhooks: object.isRequired,
  currentWebhook: string.isRequired,
  testEvent: object,
  testWebhookResponse: object,
  isTestingWebhook: bool.isRequired,
  actions: shape({
    fetchTestEventRequest: func.isRequired,
    testWebhookRequest: func.isRequired,
    clearTestData: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
};

TestWebhookModal.defaultProps = {
  testEvent: null,
  testWebhookResponse: null,
};
