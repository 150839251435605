/* istanbul ignore file */
import React from 'react';

const IceEngineIcon = () => {
  return (
    <svg
      className="engine-type"
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.0775 1.11207C7.745 0.82332 7.24625 0.82332 6.91375 1.11207C2.64375 4.87457 0.5 8.14707 0.5 10.9296C0.5 15.2871 3.825 18.1046 7.5 18.1046C11.175 18.1046 14.5 15.2871 14.5 10.9296C14.5 8.14707 12.3562 4.87457 8.0775 1.11207ZM3.85125 11.1046C4.175 11.1046 4.4375 11.3321 4.49875 11.6471C4.8575 13.5896 6.49375 14.2546 7.68375 14.1583C8.06 14.1408 8.375 14.4383 8.375 14.8146C8.375 15.1646 8.095 15.4533 7.745 15.4708C5.88125 15.5846 3.7025 14.5171 3.20375 11.8658C3.13375 11.4721 3.44875 11.1046 3.85125 11.1046Z" fill="#6D6D6D" />
    </svg>
  );
};

export default IceEngineIcon;
