import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TeamInvite from './TeamInvite';
import { actions } from '../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  return {
    sendOrgInvitesStatus: state.organizations.sendOrgInvitesStatus,
    username: state.auth.session.userContext.email,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { sendOrgInvites, updateSendOrgInvitesStatus } = actions;


  return {
    actions: bindActionCreators({
      sendOrgInvites,
      updateSendOrgInvitesStatus,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamInvite);
