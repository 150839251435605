import { styled, Card as MuiCard } from '@mui/material';

export const PercentBar = styled('div')(({ percentage, theme }) => ({
  padding: 0,
  position: 'relative',
  backgroundColor: theme.palette.graphs.background,
  height: '130px',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: `${percentage * 100}%`,
    backgroundColor: theme.palette.graphs.main,
    transition: 'height 0.5s ease-in-out',
    // look into optical illusion caused by box shadow and color contrast
  },
}));

export const Card = styled(MuiCard)(() => ({
  minWidth: '200px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  flexGrow: 1,
}));
