/* istanbul ignore file */
import React from 'react';
import { styled } from '@mui/material';

const utmTags = [
  { name: 'UTM Source Most Recent', param: 'utm_source' },
  { name: 'UTM Medium Most Recent', param: 'utm_medium' },
  { name: 'UTM Campaign Most Recent', param: 'utm_campaign' },
  { name: 'UTM Content Most Recent', param: 'utm_content' },
  { name: 'UTM Term Most Recent', param: 'utm_term' },
];

const captureUtmParams = () => {
  // capture utm params from gtm cookie, insert into hidden ortto fields
  // this should more or less stay in sync with form.js on homepage:
  // https://github.com/smartcar/home/blob/master/public/scripts/components/form/form.js
  let queryParams;

  const allCookies = document.cookie.split('; ');
  const utmCookie = allCookies.filter(cookie => cookie.includes('__gtm_campaign_url'))[0];
  if (utmCookie) {
    const utmUrl = decodeURIComponent(utmCookie);
    queryParams = `?${utmUrl.split('?')[1]}`;
  } else {
    // if the gtm cookie is not found, we'll check for any utm params directly located in
    // the URL as a last resort.
    queryParams = window.location.search;
  }

  const params = new URLSearchParams(queryParams);
  return params;
};

const HiddenUtmFields = () => {
  const UtmField = styled('div')(() => ({
    display: 'none',
  }));

  const params = captureUtmParams();

  return (
    <React.Fragment>
      {utmTags.map(({ name, param }) => {
        return (
          <UtmField key={param}>
            <input type="hidden" id={name} name={name} value={params.get(param) || '(null)'} />
          </UtmField>
        );
      })}
    </React.Fragment>
  );
};

export default HiddenUtmFields;
