/* istanbul ignore file */

const handleElementClick = (elem, props) => {
  if (props.onElementsClick) {
    props.onElementsClick(elem);
  }
};

const handleGetElementAtEvent = (elem, props) => {
  if (props.getElementAtEvent) {
    props.getElementAtEvent(elem);
  }
};

const handleGetDatasetAtEvent = (dataset, props) => {
  if (props.getDatasetAtEvent) {
    props.getDatasetAtEvent(dataset);
  }
};

export {
  handleElementClick,
  handleGetDatasetAtEvent,
  handleGetElementAtEvent,
};
