/* istanbul ignore file */

import { useEffect, useRef } from 'react';

const speedRequestIntervals = {
  1: 60000,
  2: 30000,
  8: 7500,
  16: 3750,
};

// Reference for how this custom hook works:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useTripInterval(callback, speed, setTripIntervalId) {
  const savedCallback = useRef();
  const savedSetTripIntervalId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
    savedSetTripIntervalId.current = setTripIntervalId;
  }, [callback]);


  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (speed !== null) {
      const delay = speedRequestIntervals[speed];
      // start the interval
      const id = setInterval(tick, delay);
      // store the interval's id so we may clear the interval when necessary
      savedSetTripIntervalId.current(id);
      // clear the interval on component unmount
      return () => clearInterval(id);
    }
    return undefined;
  }, [speed]);
}

export default useTripInterval;
