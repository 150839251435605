import { styled } from '@mui/material/styles';
import { Box, Button as MuiButton, List } from '@mui/material';

export const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3, 0),
}));

export const Image = styled('img')({
  float: 'center',
  height: '120px',
});

export const FeaturesContainer = styled(List)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  margin: theme.spacing(2, 0),
  padding: '0px',

  '.MuiListItem-root': {
    '&:first-child': {
      paddingTop: theme.spacing(1),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
}));

export const Button = styled(MuiButton)(({ theme }) => ({
  float: 'right',
  fontFamily: theme.typography.bold.fontFamily,
}));
