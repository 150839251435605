import { useState } from 'react';

export const AUTH_SEARCH_PARAMS = {
  EMAIL: 'email',
  PLAN: 'plan',
  RETURN_TO: 'return_to',
};

export const validatePostAuthRedirect = (postAuthRedirect) => {
  const regExAllowList = [/^\/join\?id=[\w-]{36}(?:&orgInvitation=true)?$/];

  // the redirect has to pass at least one of the URL checks
  return regExAllowList.some((re) => {
    const regex = new RegExp(re);

    return regex.test(postAuthRedirect);
  });
};

const authFormState = (form = {}, setShowPasswordRequirements) => {
  const [values, setValues] = useState(form);
  const [erroredFields, setErroredFields] = useState([]);

  const handleChange = ({ key, value }) => {
    setValues(prev => ({ ...prev, [key]: value }));
  };

  /* istanbul ignore next */
  const clearErroredField = (name) => {
    const formattedName = name.split('-input')[0];
    setErroredFields((prevErroredFields) => {
      const newErroredFields = [...prevErroredFields]
        .filter(error => error.field !== formattedName);
      return newErroredFields;
    });
  };

  const handleFocus = ({ target: { name } }) => {
    if (name === 'password' && setShowPasswordRequirements) {
      setShowPasswordRequirements(true);
    }
    clearErroredField(name);
  };

  const getErrorMessage = (field) => {
    let error;
    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => err.field === field);
    }

    return error ? error.message : '';
  };

  const getGeneralError = () => {
    let error;
    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => !err.field);
    }

    return error ? error.message : '';
  };

  return {
    setErroredFields,
    erroredFields,
    handleFocus,
    handleChange,
    values,
    getErrorMessage,
    setValues,
    clearErroredField,
    getGeneralError,
  };
};


export default authFormState;

