/* istanbul ignore file */
import React from 'react';

const CubeIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 13.8334V7.16675C17.4997 6.87448 17.4225 6.58742 17.2763 6.33438C17.13 6.08134 16.9198 5.87122 16.6667 5.72508L10.8333 2.39175C10.58 2.24547 10.2926 2.16846 10 2.16846C9.70744 2.16846 9.42003 2.24547 9.16667 2.39175L3.33333 5.72508C3.08022 5.87122 2.86998 6.08134 2.72372 6.33438C2.57745 6.58742 2.5003 6.87448 2.5 7.16675V13.8334C2.5003 14.1257 2.57745 14.4127 2.72372 14.6658C2.86998 14.9188 3.08022 15.1289 3.33333 15.2751L9.16667 18.6084C9.42003 18.7547 9.70744 18.8317 10 18.8317C10.2926 18.8317 10.58 18.7547 10.8333 18.6084L16.6667 15.2751C16.9198 15.1289 17.13 14.9188 17.2763 14.6658C17.4225 14.4127 17.4997 14.1257 17.5 13.8334Z"
        stroke="#4F4F4F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.7251 6.2998L10.0001 10.5081L17.2751 6.2998"
        stroke="#4F4F4F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 18.9V10.5" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CubeIcon;
