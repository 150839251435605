import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UpdateOrg from './UpdateOrg';
import { actions, selectors } from '../../../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  return {
    organization: selectors.getCurrentOrganization(state),
    updateOrgSettingsStatus: state.organizations.updateOrgSettingsStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    updateOrgSettings,
    updateUpdateOrgSettingsStatus,
  } = actions;

  return {
    actions: bindActionCreators({
      updateOrgSettings,
      updateUpdateOrgSettingsStatus,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrg);
