/* istanbul ignore file */
import React from 'react';

const ProfileIcon = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 17V15.3334C15 14.4493 14.6312 13.6015 13.9748 12.9763C13.3186 12.3512 12.4283 12 11.5 12H4.49998C3.57178 12 2.6815 12.3512 2.02513 12.9763C1.36875 13.6015 1 14.4493 1 15.3334V17" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.49994 8C10.4329 8 12 6.43303 12 4.50006C12 2.56702 10.4329 1 8.49994 1C6.56697 1 5 2.56702 5 4.50006C5 6.43303 6.56697 8 8.49994 8Z" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ProfileIcon;
