import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Overview from './Overview';
import { actions } from './reducers';
import { actions as secretActions } from '../../../../components/Credentials/components/Secret/reducers';
import { actions as logsActions } from '../Logs/reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';

function mapStateToProps(state) {
  return {
    ...state.overview,
    ...state.applications,
    selectedChartOption: state.logs.selectedChartOption,
    showOnboarding: state.onboarding.showOnboarding,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchApiRequestTimeSeries,
    fetchActiveVehiclesTimeSeries,
    fetchResponseStatusSummary,
    fetchMakesSummary,
    setFilter,
    resetFetching,
  } = actions;
  const { hideSecret } = secretActions;
  const { updateStatusCodesAndMakesFromOverviewChart } = logsActions;
  const { startOnboardingPolling } = onboardingActions;

  return {
    actions: bindActionCreators(
      {
        fetchApiRequestTimeSeries,
        fetchActiveVehiclesTimeSeries,
        fetchResponseStatusSummary,
        fetchMakesSummary,
        hideSecret,
        updateStatusCodesAndMakesFromOverviewChart,
        setFilter,
        startOnboardingPolling,
        resetFetching,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
