import { styled } from '@mui/material/styles';
import { Button, Divider, Drawer, IconButton, ListItem } from '@mui/material';

const getFinalListItemsStyling = (showViewAllButton, spacing) => ({
  paddingBottom: spacing,
  ...(!showViewAllButton && {
    borderBottom: '0px',
  }),
});

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: '800px',
    padding: '40px',
    boxSizing: 'border-box',
  },

  '& .icon-inline': {
    padding: theme.spacing(0, 0, 0, 1),

    '& svg': {
      color: theme.palette.common.black,
      margin: '0px',
    },
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '30px',
  right: '28px',

  '& > svg': {
    fill: theme.palette.grey[600],
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[100],
  margin: theme.spacing(3, 0),
}));

export const ViewAllButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1.5, 'auto', '0px', 'auto'),
  display: 'flex',
  color: theme.palette.common.black,
  textDecoration: 'underline',
  lineHeight: 'normal',
}));

export const StyledListItem = styled(ListItem, {
  shouldForwardProp:
    prop => prop !== 'showViewAllButton' && prop !== 'numColumns' && prop !== 'numItems',
})(({
  theme, showViewAllButton, numColumns, numItems,
}) => ({
  padding: theme.spacing(1.5, 0, 1, 0),
  borderBottom: `1px solid ${theme.palette.grey[100]}`,

  // styling of last child depends on whether the view all button is needed
  '&:last-child': {
    ...(getFinalListItemsStyling(showViewAllButton, theme.spacing(1))),
  },

  // when columns are used, the top + bottom row have different padding/border rules
  ...(numColumns && numItems && {
    // find # of items in the last row, if the modulo is 0 then that means that the all
    // columns are used for the last row
    [`&:nth-last-child(-n + ${numItems % numColumns || numColumns})`]: {
      ...(getFinalListItemsStyling(showViewAllButton, theme.spacing(1))),
    },
    // remove padding from items in the first row
    [`&:nth-child(-n + ${numColumns})`]: {
      paddingTop: '0px',
    },
  }),

  '& .MuiListItemSecondaryAction-root': {
    position: 'static',
    transform: 'none',
    display: 'flex',
  },
}));
