import React from 'react';
import { node, string, func } from 'prop-types';
import { Typography } from '@mui/material';
import {
  AuthContainer,
  AuthContent,
  AuthContentMain,
} from './styles';
import InlineRoute from '../InlineRoute';

const AuthContentContainer = ({
  subLink,
  children,
  resetErrors,
  resetLoginPage,
  resetOauthInvitation,
}) => {
  const handleClick = () => {
    // Needed for resetting errors on the sign up
    // and login page
    if (resetErrors) {
      resetErrors();
    }

    // reset login page
    if (resetLoginPage) {
      resetLoginPage();
    }

    // reset oauth invitation state
    if (resetOauthInvitation) {
      resetOauthInvitation();
    }
  };

  return (
    <AuthContainer>
      <AuthContent>
        <AuthContentMain>
          {children}
        </AuthContentMain>
        {subLink && (
          <Typography variant="subtitle2">
            <InlineRoute
              text={subLink}
              handleClick={handleClick}
            />
          </Typography>
        )}
      </AuthContent>
    </AuthContainer>
  );
};

export default AuthContentContainer;

AuthContentContainer.propTypes = {
  subLink: string,
  children: node,
  resetErrors: func,
  resetLoginPage: func,
  resetOauthInvitation: func,
};

AuthContentContainer.defaultProps = {
  subLink: '',
  children: '<div></div>',
  resetErrors: null,
  resetLoginPage: null,
  resetOauthInvitation: null,
};

