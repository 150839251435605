import React from 'react';
import { func } from 'prop-types';
import { Typography } from '@mui/material';

import { useForm, Form, FormControlWrapper, TextInput } from '../../../../../../components/Form/useForm';
import inputs from './inputs';
import applicationProps from '../../../../../../types/application';
import staticText from '../../../../../../localization/Application/configuration';

const AuthenticationSettings = ({
  application,
  updateApplication,
}) => {
  const handleUpdateApplication = (updates) => {
    updateApplication(application.id, updates);
  };

  const {
    formState, handleChange, handleBlur, handleSubmit, disableSubmit,
  } = useForm({
    formName: 'authentication settings form',
    inputs,
    data: { ...application },
    onSubmit: handleUpdateApplication,
    displayToast: true,
  });

  const permission = 'write_configuration';
  return (
    <React.Fragment>
      <Typography variant="h2" mb={2}>{staticText.authentication.heading}</Typography>
      <Form handleSubmit={handleSubmit} disableSubmit={disableSubmit} permission={permission}>
        {inputs.map((input) => {
          const {
            displayName, tooltip, pathOnData, placeholderText,
          } = input;
          const { [pathOnData]: currentInputState } = formState;
          return (
            <div key={displayName}>
              <FormControlWrapper
                displayName={displayName}
                tooltip={tooltip}
                permission={permission}
                currentInputState={currentInputState}
              >
                <TextInput
                  displayName={displayName}
                  placeholderText={placeholderText}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  currentInputState={currentInputState}
                  pathOnData={pathOnData}
                />
              </FormControlWrapper>
            </div>
          );
        })}
      </Form>
    </React.Fragment>
  );
};

export default AuthenticationSettings;

AuthenticationSettings.propTypes = {
  updateApplication: func.isRequired,
  ...applicationProps,
};
