import { styled } from '@mui/material';

export const Image = styled('img')(() => ({
  width: '25%',
  filter: 'blur(3px)',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: theme.spacing(3, 6),
  backgroundColor: theme.palette.common.white,
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));
