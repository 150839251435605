import React, { useEffect } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import {
  AuthContentContainer,
  Header,
  Spinner,
  SendVerificationEmail,
  SendVerificationEmailSuccess,
} from '../../components';

import staticText from '../../localization/VerifyEmail/verifyEmail';

const VerifyEmail = (props) => {
  const {
    actions,
    email,
    emailToken,
    emailVerifiedAt,
    history,
    isSendingVerificationEmail,
    isVerifyingEmailToken,
    sendVerificationEmailError,
    verificationEmailSent,
    verifyEmailTokenError,
  } = props;

  useEffect(() => {
    reportToSegment(types.PAGE, 'Verify Email');
  }, []);

  useEffect(() => {
    if (emailToken) {
      actions.verifyEmailTokenRequest(emailToken);
    }
  }, [emailToken]);

  useEffect(() => {
    // if email has already been verified, redirect to /
    if (emailVerifiedAt) {
      history.push('/');
    } else {
      actions.setVerificationEmailSent(false);
    }
  }, []);

  if (emailToken || isVerifyingEmailToken || isSendingVerificationEmail) {
    return (
      <div>
        <Header />
        <Spinner />;
      </div>
    );
  }

  const getError = (sendEmailError, verifyEmailError) => {
    if (verifyEmailError) {
      return verifyEmailError;
    }
    if (sendEmailError) {
      return sendEmailError;
    }

    return '';
  };

  const error = getError(sendVerificationEmailError, verifyEmailTokenError);

  return (
    <div>
      <Header />
      <AuthContentContainer>
        <Grid container spacing={8}>
          <Grid item xs={12} md={7}>
            <Typography variant="h1">{staticText.title}</Typography>
            {verificationEmailSent ? (
              <Box mt={3} mb={3}>
                <SendVerificationEmailSuccess
                  sendVerificationEmailRequest={actions.sendVerificationEmailRequest}
                />
              </Box>
            ) : (
              <SendVerificationEmail
                email={email}
                error={error}
                isSendingVerificationEmail={isSendingVerificationEmail}
                openingText={staticText.sendVerificationOpening}
                sendVerificationEmailRequest={actions.sendVerificationEmailRequest}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: { xs: 'none', md: 'block' },
              textAlign: 'center',
            }}
          >
            <img
              src={staticText.image.url}
              alt={staticText.image.alt}
              width="230"
            />
          </Grid>
        </Grid>
      </AuthContentContainer>
    </div>
  );
};

export default VerifyEmail;

VerifyEmail.propTypes = {
  actions: shape({
    sendVerificationEmailRequest: func.isRequired,
    setVerificationEmailSent: func.isRequired,
    verifyEmailTokenRequest: func.isRequired,
  }).isRequired,
  email: string.isRequired,
  emailToken: string.isRequired,
  emailVerifiedAt: string,
  history: object.isRequired,
  isSendingVerificationEmail: bool.isRequired,
  isVerifyingEmailToken: bool.isRequired,
  sendVerificationEmailError: string.isRequired,
  verificationEmailSent: bool.isRequired,
  verifyEmailTokenError: string.isRequired,
};

VerifyEmail.defaultProps = {
  emailVerifiedAt: '',
};
