/* istanbul ignore file */
import React from 'react';

const VehicleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_124_9)">
        <path d="M0.999974 6.29978H16.0556M0.999974 6.29978L2.50003 2.5C2.69836 1.94688 3.37669 1.5 4.00003 1.5L13 1.50003C13.6234 1.50003 14.3111 1.94685 14.5 2.49997L16.0556 6.29978M0.999974 6.29978L0.999996 12.7744M1 14C1 14.5156 0.980556 15 1.5 15H2.5C3.01944 15 3.15625 14.5156 3.15625 14M1 14L0.999996 12.7744M1 14H3.15625M3.15625 14V12.7744M3.15625 12.7744H13.8125M3.15625 12.7744H0.999996M13.8125 12.7744V14M13.8125 12.7744H16.0556M13.8125 14C13.8125 14.5156 13.9806 15 14.5 15H15.5C16.0194 15 16.0556 14.5156 16.0556 14M13.8125 14H16.0556M16.0556 14V12.7744M16.0556 6.29978V12.7744M3.5 9.5C3.5 9.22385 3.72388 8.99999 4.00003 9C4.27616 9.00001 4.5 9.22386 4.5 9.5M3.5 9.5C3.5 9.77615 3.72388 10 4.00003 10C4.27616 9.99999 4.5 9.77614 4.5 9.5M3.5 9.5H4.5M13.5 9.5C13.5 9.22387 13.2762 9.00001 13 9C12.7239 8.99999 12.5 9.22385 12.5 9.5M13.5 9.5C13.5 9.77613 13.2762 9.99999 13 10C12.7239 10 12.5 9.77615 12.5 9.5M13.5 9.5H12.5" stroke="#4F4F4F" strokeWidth="1.6" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_124_9">
          <rect width="17" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VehicleIcon;
