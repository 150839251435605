import React from 'react';
import { func, shape, string } from 'prop-types';

import { Modal } from '../../../../../../components';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'error';

const WebhookErrorModal = (props) => {
  const {
    title,
    errorMessage,
    buttonText,
    actions: { toggleWebhookModal },
  } = props;

  const toggleModal = () =>
    toggleWebhookModal({
      nextModal: null,
      curModal: modalName,
      trackCloseEvent: true,
      curTitle: title,
    });

  const content = (
    <div>
      <p className="m-t-lg m-b-lg">{errorMessage}</p>
      <div className="button-wrapper right">
        <button className="button button-med-long" type="button" onClick={toggleModal}>
          {buttonText}
        </button>
      </div>
    </div>
  );

  return <Modal title={title} content={content} toggleModal={toggleModal} />;
};

WebhookErrorModal.propTypes = {
  title: string,
  errorMessage: string.isRequired,
  buttonText: string,
  actions: shape({
    toggleWebhookModal: func.isRequired,
  }).isRequired,
};

WebhookErrorModal.defaultProps = {
  title: modalConfig[modalName].title,
  buttonText: modalConfig[modalName].buttonText,
};

export default WebhookErrorModal;
