import { styled } from '@mui/material/styles';
import { Box, Button as MuiButton, Typography } from '@mui/material';

export const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ModalHeader = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

export const ModalBody = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  marginBottom: theme.spacing(4),
}));

export const PasswordInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.border.radius,
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(2),
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(3),
}));

export const VerifyButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5),
  width: '144px',
  fontSize: '1.125rem',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    textDecoration: 'none',
  },
}));

export const CancelButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.common.black,
  padding: theme.spacing(1.5),
  width: '144px',
  fontSize: '1.125rem',
  '&:hover': {
    backgroundColor: theme.palette.grey[500],
    textDecoration: 'none',
  },
}));
