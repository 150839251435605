import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VerifyEmailModalContent from './VerifyEmailModalContent';
import { actions } from '../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    email: state.auth.session.userContext.email,
    isSendingVerificationEmail: state.auth.isSendingVerificationEmail,
    sendVerificationEmailError: state.auth.sendVerificationEmailError,
    verificationEmailSent: state.auth.verificationEmailSent,
  };
};

function mapDispatchToProps(dispatch) {
  const {
    sendVerificationEmailRequest,
    setVerificationEmailSent,
  } = actions;
  return {
    actions: bindActionCreators({
      sendVerificationEmailRequest,
      setVerificationEmailSent,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailModalContent);
