import React from 'react';
import { func } from 'prop-types';
import { Typography } from '@mui/material';

import { InfoItem, Modal } from '../../../../../../components';
import { Button, FeaturesContainer, Image, ImageContainer } from './styles';
import staticText from '../../../../../../localization/Application/Overview/freeTrialModal';
import plansStaticText from '../../../../../../localization/Application/Billing/planComparisonTable';

const buildFeatures = plansStaticText.plans[1].planFeatures.features;

const FreeTrialModal = ({ closeModal }) => {
  const content = (
    <div>
      <ImageContainer>
        <Image
          src="../../../../../../assets/images/rocket.png"
          alt={staticText.rocketAlt}
        />
      </ImageContainer>
      <Typography>{staticText.description}</Typography>
      <FeaturesContainer>
        {buildFeatures.map(feature => (
          <InfoItem key={feature.text} item={feature} />
        ))}
      </FeaturesContainer>
      <Button variant="contained" size="large" onClick={closeModal}>
        {staticText.getStarted}
      </Button>
    </div>
  );

  return (
    <Modal
      title={staticText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default FreeTrialModal;

FreeTrialModal.propTypes = {
  closeModal: func.isRequired,
};
