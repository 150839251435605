import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { SimulatorHome, CreateVehicle, SelectTrip, SimulateVehicle } from './components';
import { Disclaimer, Spinner } from '../../../../components';

const Simulator = ({
  actions: {
    fetchRegionCompatibleVehicles,
    fetchAllSimulatedVehicles,
  },
  allCompatibleVehicles,
  isFetchingSimulator,
  isFetchingSimulatorTrip,
  match,
  rolePermissions,
  selectedRegion,
  selectedVehicle,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [searchType, setSearchType] = useState('');
  const theme = useTheme();
  const { applicationId } = match.params;

  const canWriteBilling = rolePermissions.includes('write_billing');

  /* istanbul ignore next */
  const determineSimulatorView = () => {
    // if we have a selected vehicle with a selected trip type, go to the simulate screen
    const tripType = selectedVehicle.simulatedVehicleTripType || '';
    if (selectedVehicle.id && tripType) {
      return (<SimulateVehicle
        applicationId={applicationId}
        tripType={tripType}
        setIsFetching={setIsFetching}
        canWriteBilling={canWriteBilling}
      />);
      // if we have a selected vehicle without a selected trip type, go to trip select
    } else if (selectedVehicle.id) {
      return <SelectTrip applicationId={applicationId} setIsFetching={setIsFetching} />;
    }
    // else go to simulator home
    return <Redirect to={`/apps/${applicationId}/simulator`} />;
  };

  useEffect(() => {
    if (match.params.vin !== 'create-vehicle') {
      const selectedVin = selectedVehicle.vin === match.params.vin ? '' : match.params.vin;
      fetchAllSimulatedVehicles(applicationId, selectedVin);
    }
  }, [applicationId, match.params.vin]);

  useEffect(() => {
    if (!Object.keys(allCompatibleVehicles).includes(selectedRegion)) {
      fetchRegionCompatibleVehicles(applicationId, selectedRegion);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (!isFetchingSimulator.simulatedVehicles &&
      !isFetchingSimulator.creatingVehicle &&
      !isFetchingSimulatorTrip.vehicleTrip) {
      setIsFetching(false);
    }
  }, [
    isFetchingSimulator.simulatedVehicles,
    isFetchingSimulator.creatingVehicle,
    isFetchingSimulatorTrip.vehicleTrip,
  ]);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '900px' }}>
      {isFetching ? (
        <Spinner spinnerColor={theme.palette.grey[200]} size="small" additionalClassNames="simulator" delay={200} />
      ) : (
        <Box>
          <Switch>
            <Route path={`/apps/${applicationId}/simulator/create-vehicle`}>
              <CreateVehicle
                applicationId={applicationId}
                searchType={searchType}
                setSearchType={setSearchType}
                setIsFetching={setIsFetching}
              />
            </Route>
            <Route path={`/apps/${applicationId}/simulator/:vin`}>
              {determineSimulatorView()}
            </Route>
            <Route path={`/apps/${applicationId}/simulator`}>
              <SimulatorHome
                applicationId={applicationId}
              />
            </Route>
          </Switch>
          <div style={{ marginLeft: '2px' }}>
            <Disclaimer />
          </div>
        </Box>

      )}
    </Box>
  );
};

export default Simulator;

Simulator.propTypes = {
  actions: shape({
    fetchRegionCompatibleVehicles: func.isRequired,
    fetchAllSimulatedVehicles: func.isRequired,
    clearSimulatedVehicles: func.isRequired,
  }).isRequired,
  allCompatibleVehicles: object.isRequired,
  isFetchingSimulator: shape({
    simulatedVehicles: bool.isRequired,
  }).isRequired,
  isFetchingSimulatorTrip: shape({
    vehicleTrip: bool.isRequired,
  }).isRequired,
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
  rolePermissions: arrayOf(string).isRequired,
  selectedRegion: string.isRequired,
  selectedVehicle: shape({
    id: string,
    vin: string,
    year: number,
    region: string,
    make: string,
    model: string,
    endpointsWithLatency: arrayOf(shape({
      path: string.isRequired,
      latencyLowerBound: number.isRequired,
      latencyUpperBound: number.isRequired,
      method: string.isRequired,
    })),
    username: string,
    simulatedVehicleTripId: string,
    simulatedVehicleTripType: string,
    simulatedVehicleTripStatus: string,
    smartcarId: string,
  }).isRequired,
  simulatedVehicles: object.isRequired,
};
