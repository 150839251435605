/* istanbul ignore file */
import React from 'react';

const BillingIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4352_9652)">
        <path
          d="M12.1794 1.86768V23.8677"
          stroke="#4F4F4F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.1794 5.86768H9.67944C8.75119 5.86768 7.86095 6.23642 7.20457 6.8928C6.54819 7.54918 6.17944 8.43942 6.17944 9.36768C6.17944 10.2959 6.54819 11.1862 7.20457 11.8425C7.86095 12.4989 8.75119 12.8677 9.67944 12.8677H14.6794C15.6077 12.8677 16.4979 13.2364 17.1543 13.8928C17.8107 14.5492 18.1794 15.4394 18.1794 16.3677C18.1794 17.2959 17.8107 18.1862 17.1543 18.8426C16.4979 19.4989 15.6077 19.8677 14.6794 19.8677H6.17944"
          stroke="#4F4F4F"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4352_9652">
          <rect width="24" height="24" fill="white" transform="translate(0.179443 0.867676)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BillingIcon;
