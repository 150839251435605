import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Button, Drawer as MuiDrawer } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { styled } from '@mui/material/styles';

import { CredentialsInfo, LatenciesInfo } from './components';

const SideDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '600px',
    padding: theme.spacing(6),

    '& > * + *': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Close = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  right: theme.spacing(3),
}));

const Drawer = ({
  type, drawerOpen, setDrawerOpen, additionalProps,
}) => {
  let drawerContent;
  switch (type) {
    case 'credentials':
      drawerContent = <CredentialsInfo {...additionalProps} />;
      break;
    case 'latencies':
      drawerContent = <LatenciesInfo {...additionalProps} />;
      break;
    default:
      drawerContent = null;
  }
  return (
    <SideDrawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Close onClick={() => setDrawerOpen(false)}><CloseRoundedIcon /></Close>
      {drawerContent}
    </SideDrawer>
  );
};

export default Drawer;

Drawer.propTypes = {
  type: string.isRequired,
  drawerOpen: bool.isRequired,
  setDrawerOpen: func.isRequired,
  additionalProps: object,
};

Drawer.defaultProps = {
  additionalProps: null,
};
