import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { styled } from '@mui/material';

import SendVerificationEmail from '../SendVerificationEmail';
import SendVerificationEmailSuccess from '../SendVerificationEmailSuccess';

export const ContentWrapper = styled('div')(({ theme }) => ({
  minHeight: '172px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',

  '> p:first-child': {
    marginTop: theme.spacing(3),
  },

  '> button.MuiButton-root': {
    alignSelf: 'center',
  },

  '> svg.MuiSvgIcon-root': {
    alignSelf: 'center',
  },
}));

const VerifyEmailModalContent = ({
  actions: {
    sendVerificationEmailRequest,
    setVerificationEmailSent,
  },
  email,
  isSendingVerificationEmail,
  openingText,
  sendVerificationEmailError,
  verificationEmailSent,
}) => {
  useEffect(() => {
    setVerificationEmailSent(false);
  }, []);

  return (
    <ContentWrapper>
      {verificationEmailSent ? (
        <SendVerificationEmailSuccess
          sendVerificationEmailRequest={sendVerificationEmailRequest}
        />
      ) : (
        <SendVerificationEmail
          email={email}
          error={sendVerificationEmailError}
          isSendingVerificationEmail={isSendingVerificationEmail}
          openingText={openingText}
          sendVerificationEmailRequest={sendVerificationEmailRequest}
        />
      )}
    </ContentWrapper>
  );
};

export default VerifyEmailModalContent;

VerifyEmailModalContent.propTypes = {
  actions: shape({
    sendVerificationEmailRequest: func.isRequired,
    setVerificationEmailSent: func.isRequired,
  }).isRequired,
  isSendingVerificationEmail: bool.isRequired,
  email: string.isRequired,
  openingText: string.isRequired,
  sendVerificationEmailError: string.isRequired,
  verificationEmailSent: bool.isRequired,
};
