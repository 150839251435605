const sessionUtils = {};

sessionUtils.isAuthenticated = (session) => {
  return !!localStorage.getItem('accessToken') && !!session.userContext;
};

sessionUtils.setLocalStorage = (token) => {
  localStorage.setItem('accessToken', token);
};

sessionUtils.clearLocalStorage = () => {
  localStorage.removeItem('accessToken');
};

export default sessionUtils;
