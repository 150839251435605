import React, { useState, useRef, useEffect } from 'react';
import { bool, func, node, string, oneOfType, object } from 'prop-types';
import { ChevronDown } from 'react-feather';
import _ from 'lodash';
import clsx from 'clsx';

import './Select.scss';

const SelectContext = React.createContext();

// TODO: consider deprecating this, most other components use MUI's Select
const Select = ({
  additionalClassNames,
  children,
  defaultValue,
  disabled,
  fixedWidth,
  name,
  onChange,
  placeholder,
  reset,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [open, setOpen] = useState(false);
  const select = useRef(null);

  const handleClick = (e) => {
    if (select.current && !select.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    if (reset) {
      setSelectedOption('');
    }
  }, [reset]);

  const updateSelectedOption = (optionName) => {
    setOpen(false);
    setSelectedOption(optionName);
    onChange({ key: name, value: optionName });
  };

  const optionEnabler = {
    handleOptionClick: updateSelectedOption,
    // eslint-disable-next-line no-unused-vars
    handleOptionKeyDown: (e, optionName) => {
      // TODO: add keydown support
    },
  };

  const selectedOptionNode = _.find(children, { key: selectedOption });
  return (
    <div
      className="select select-component-wrapper"
      ref={select}
      onClick={e => e.stopPropagation()}
    >
      <button
        id={name}
        type="button"
        className={clsx({
          'select-button': true,
          'select-open': open,
          'select-disabled': disabled,
          'select-placeholder': !selectedOptionNode,
          'fixed-width': fixedWidth,
          [additionalClassNames]: additionalClassNames,
        })}
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        <span className="m-r-sm">
          {selectedOptionNode ? selectedOptionNode.props.text : placeholder}
        </span>
        {!disabled && <ChevronDown />}
      </button>
      <SelectContext.Provider value={optionEnabler}>
        {open && <div className="select-option-list">{children}</div>}
      </SelectContext.Provider>
    </div>
  );
};

Select.propTypes = {
  name: string.isRequired,
  defaultValue: string,
  placeholder: string,
  children: oneOfType([node, object]).isRequired,
  onChange: func.isRequired,
  disabled: bool.isRequired,
  fixedWidth: bool,
  reset: bool,
  additionalClassNames: string,
};

Select.defaultProps = {
  defaultValue: null,
  placeholder: 'Select...',
  fixedWidth: false,
  reset: false,
  additionalClassNames: '',
};

Select.Option = ({ name, text }) => {
  return (
    <SelectContext.Consumer>
      {({ handleOptionClick, handleOptionKeyDown }) => (
        <button
          className="unstyled-button option"
          onClick={() => handleOptionClick(name)}
          onKeyDown={(e) => {
            handleOptionKeyDown(e, name);
          }}
        >
          {text}
        </button>
      )}
    </SelectContext.Consumer>
  );
};

Select.Option.propTypes = {
  name: string.isRequired,
  text: string.isRequired,
};

export default Select;
