const staticText = {
  title: 'Forgot password',
  subTitle: 'Please enter your email address to receive a password reset link.',
  returnToLogin: '|+|Return to sign in@@/login|+|',
  subLink: 'Need an account? |+|Sign up@@/signup|+|',
  btnSubmit: 'Send email',
  image: {
    url: '/assets/graphics/smartcar_envelope.svg',
    alt: 'Smartcar user logging in',
  },
  fields: [
    {
      label: 'Email',
      name: 'username',
      type: 'email',
    },
  ],
  successMessage: 'If there is a Smartcar account associated with your email, we will send you a link to reset your password.',
};

export default staticText;

