/* eslint-disable no-underscore-dangle */
import React from 'react';
import { bool, func, string } from 'prop-types';
import { Check, Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

const staticText = {
  check: 'Copied!',
  copy: 'Copy',
};

export default class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCheckIcon: false,
    };

    // without this property, if the user navigates away from the page
    // in <3 sec after clicking this button, we will be calling setState
    // on a component that has unmounted already. We use this property to
    // clear the setTimeout triggered on button click
    // See: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    this.timer = null;

    this.copy = this.copy.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  copy() {
    const { content, updateCredentialsEvent } = this.props;
    this.setState({ showCheckIcon: true });
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'copy', text: content });

    if (updateCredentialsEvent) {
      updateCredentialsEvent();
    }

    // reset copy icon after 3
    this.timer = setTimeout(() => this.setState({ showCheckIcon: false }), 3000);
  }

  render() {
    const { content, showCopyText, color } = this.props;
    const { showCheckIcon } = this.state;

    return (
      <CopyToClipboard text={content}>
        <button onClick={this.copy} className={`icon icon-inline ${color ? '' : 'icon-no-color'}`}>
          {showCheckIcon ? (
            <span className="icon-chip-container success-text">
              <Check size={16} />
              {showCopyText && <span className="chip-text">{staticText.check}</span>}
            </span>
          ) : (
            <span className="icon-chip-container">
              <Copy size={16} strokeWidth="2" />
              {showCopyText && <span className="chip-text">{staticText.copy}</span>}
            </span>
          )}
        </button>
      </CopyToClipboard>
    );
  }
}

CopyButton.propTypes = {
  content: string,
  showCopyText: bool,
  color: bool,
  updateCredentialsEvent: func,
};

CopyButton.defaultProps = {
  content: '',
  showCopyText: true,
  color: true,
  updateCredentialsEvent: null,
};
