import _ from 'lodash';
import { InfoOutlined } from '@mui/icons-material';
import { infoIcon, alertTriangleIcon } from '../../../../../../assets/icons';
import { UNITS, TYPES } from '../../utils/webhookTypes';
import {
  generateDays,
  generateHourIntervals,
  generateHours,
} from './timeUtils';

const defaultFields = {
  eventBased: [
    'type',
    'name',
    'callbackUri',
    'events',
  ],
  schedule: [
    'type',
    'name',
    'callbackUri',
    'frequency',
    'units',
    'permissions',
  ],
  dynamic: [
    'type',
    'name',
    'callbackUri',
    // 'profile', // hidden field since there's only one profile type for now
  ],
};

const sectionNames = {
  WEBHOOK_TYPE: 'Webhook type',
  CONFIGURATION: 'Configuration',
  REVIEW: 'Review',
};

const sections = [
  sectionNames.WEBHOOK_TYPE,
  sectionNames.CONFIGURATION,
  sectionNames.REVIEW,
];

const eventTypes = {
  CHARGING_STARTED: {
    text: 'Charging Started',
    type: 'checkbox',
    permission: 'read_charge_events',
    name: 'CHARGING_STARTED',
  },
  CHARGING_STOPPED: {
    text: 'Charging Stopped',
    type: 'checkbox',
    permission: 'read_charge_events',
    name: 'CHARGING_STOPPED',
  },
  CHARGING_COMPLETED:
  {
    text: 'Charging Completed',
    type: 'checkbox',
    permission: 'read_charge_events',
    name: 'CHARGING_COMPLETED',
  },
};

const stateChangeTypes = {
  PLUGGED_IN: {
    text: 'Vehicle is plugged in',
    name: 'PLUGGED_IN',
  },
};

const dynamicProfiles = {
  managedCharging: {
    text: 'Managed Charging',
    name: 'managedCharging',
    // hard coding these for now until/unless we start having more profile types
    impliedValues: {
      permissions: ['/battery', '/charge', '/location'],
      stateChange: stateChangeTypes.PLUGGED_IN.name,
    },
  },
};

const unitTypes = {
  [UNITS.IMPERIAL]: {
    text: 'Imperial',
    name: UNITS.IMPERIAL,
  },
  [UNITS.METRIC]: {
    text: 'Metric',
    name: UNITS.METRIC,
  },
};

const permissionOptions = [
  {
    text: 'Vehicle attributes',
    path: '/',
    type: 'checkbox',
    name: '/',
    permission: 'read_vehicle_info',
  },
  {
    text: 'EV battery',
    path: '/battery',
    type: 'checkbox',
    name: '/battery',
    permission: 'read_battery',
  },
  {
    text: 'EV battery capacity (deprecated)',
    path: '/battery/capacity',
    type: 'checkbox',
    name: '/battery/capacity',
    permission: 'read_battery',
    deprecated: true,
    tooltip: 'This end point is deprecated, please select /battery/nominal_capacity instead',
  },
  {
    text: 'EV battery capacity',
    path: '/battery/nominal_capacity',
    type: 'checkbox',
    name: '/battery/nominal_capacity',
    permission: 'read_battery',
  },
  {
    text: 'EV charging status',
    path: '/charge',
    type: 'checkbox',
    name: '/charge',
    permission: 'read_charge',
  },
  {
    text: 'EV charge limit',
    path: '/charge/limit',
    type: 'checkbox',
    name: '/charge/limit',
    permission: 'read_charge',
  },
  {
    text: 'Engine oil life',
    path: '/engine/oil',
    type: 'checkbox',
    name: '/engine/oil',
    permission: 'read_engine_oil',
  },
  {
    text: 'Fuel tank',
    path: '/fuel',
    type: 'checkbox',
    name: '/fuel',
    permission: 'read_fuel',
  },
  {
    text: 'Location',
    path: '/location',
    type: 'checkbox',
    name: '/location',
    permission: 'read_location',
  },
  {
    text: 'Odometer',
    path: '/odometer',
    type: 'checkbox',
    name: '/odometer',
    permission: 'read_odometer',
  },
  {
    text: 'Tire pressure',
    path: '/tires/pressure',
    type: 'checkbox',
    name: '/tires/pressure',
    permission: 'read_tires',
  },
  {
    text: 'VIN',
    path: '/vin',
    type: 'checkbox',
    name: '/vin',
    permission: 'read_vin',
  },
  {
    text: 'Lock status',
    path: '/security',
    type: 'checkbox',
    name: '/security',
    permission: 'read_security',
  },
  {
    text: 'Service history',
    path: '/service/history',
    type: 'checkbox',
    name: '/service/history',
    permission: 'read_service_history',
  },
];

const fields = [
  // WEBHOOK TYPE
  {
    label: 'Select Webhook Type',
    altLabel: 'Webhook Type',
    name: 'type',
    type: 'cardSelect',
    disabledMode: ['edit'],
    children: Object.values(TYPES),
    section: sectionNames.WEBHOOK_TYPE,
  },
  // CONFIGURATION SECTION 0
  {
    label: 'Webhook Name',
    name: 'name',
    type: 'text',
    placeholder: 'Webhook name',
    errorMessage: 'Webhook name must be between three and 30 characters long.',
    section: sectionNames.CONFIGURATION,
    configurationSection: 0,
  },
  {
    label: 'Callback URI',
    name: 'callbackUri',
    type: 'text',
    placeholder: 'https://example.com/callback',
    errorMessage:
      'Please enter a secure URL (HTTPS). Localhost URLs are not allowed.',
    labelTooltip: {
      text: 'After changing this URI, you will need to re-verify the webhook.',
      iconSrc: infoIcon,
      alt: 'additional information',
      mode: ['edit'],
    },
    labelSubtext: {
      text: "Smartcar will send payloads to this URI after it's been verified. Check out |+|this guide@@https://smartcar.com/docs/api-reference/webhooks/callback-verification|+| on how to verify your callback URI.",
      type: 'InlineLinks',
    },
    section: sectionNames.CONFIGURATION,
    configurationSection: 0,
  },
  {
    label: 'State Change',
    name: 'stateChange',
    type: 'text',
    labelTooltip: {
      text: 'When to receive data more frequently',
      Icon: InfoOutlined,
      alt: 'additional information',
      showFieldCondition: ['review'],
    },
    section: sectionNames.CONFIGURATION,
    configurationSection: 0,
    showFieldCondition: ['hidden'],
  },
  // CONFIGURATION SECTION 1
  {
    label: 'Event Types',
    name: 'events',
    type: 'events',
    showFieldCondition: [TYPES.eventBased.name],
    labelSubtext: {
      text: 'See our |+|API reference@@https://smartcar.com/docs/api-reference/webhooks/webhook-events|+| to learn more about the following events.',
      type: 'InlineLinks',
    },
    section: sectionNames.CONFIGURATION,
    configurationSection: 1,
    children: Object.values(eventTypes),
  },
  {
    label: 'Frequency',
    name: 'frequency',
    type: 'frequency',
    showFieldCondition: [TYPES.schedule.name],
    labelSubtext: {
      text: 'Select how often you would like to receive data. Timezone is UTC.',
      type: 'text',
    },
    labelTooltip: {
      text: 'Frequency cannot be edited.',
      iconSrc: alertTriangleIcon,
      alt: 'alert triangle',
      mode: ['edit'],
    },
    disabledMode: ['edit'],
    section: sectionNames.CONFIGURATION,
    configurationSection: 1,
    children: [
      { name: 'Select frequency', text: 'Select frequency' },
      {
        text: 'Hourly',
        name: 'hourly',
        children: [
          { text: 'Select interval', name: 'Select Interval' },
          ...generateHourIntervals(),
        ],
      },
      {
        text: 'Daily',
        name: 'daily',
        children: [
          { name: 'Select hour', text: 'Select hour' },
          ...generateHours(),
        ],
      },
      {
        text: 'Weekly',
        name: 'weekly',
        children: [
          { text: 'Select weekday', name: 'Select weekday' },
          { text: 'Sunday', name: 'Sunday' },
          { text: 'Monday', name: 'Monday' },
          { text: 'Tuesday', name: 'Tuesday' },
          { text: 'Wednesday', name: 'Wednesday' },
          { text: 'Thursday', name: 'Thursday' },
          { text: 'Friday', name: 'Friday' },
          { text: 'Saturday', name: 'Saturday' },
        ],
      },
      {
        text: 'Monthly',
        name: 'monthly',
        children: [
          { name: 'Select day', text: 'Select day' },
          ...generateDays(),
        ],
      },
    ],
  },
  {
    label: 'Units',
    name: 'units',
    type: 'dropdown',
    showFieldCondition: [TYPES.schedule.name],
    labelSubtext: {
      text: "The default unit of measurement you'd like to receive data.",
      type: 'text',
    },
    section: sectionNames.CONFIGURATION,
    configurationSection: 1,
    children: [
      {
        name: 'Select units',
        text: 'Select units',
      },
      ...Object.values(unitTypes),
    ],
  },
  // CONFIGURATION SECTION 2
  {
    label: 'Endpoints',
    name: 'permissions',
    type: 'permissions',
    showFieldCondition: [TYPES.schedule.name],
    labelSubtext: {
      text: 'Select which endpoints you’d like to receive data from. See our |+|API reference@@https://smartcar.com/docs/api-reference/permissions|+| to learn more about the following permissions.',
      type: 'InlineLinks',
    },
    section: sectionNames.CONFIGURATION,
    configurationSection: 2,
    children: permissionOptions,
  },
];

const endpoints = _.find(fields, { name: 'permissions' }).children;

const addDeprecatedEndpointReplacement = (webhook) => {
  if (webhook.permissions && webhook.permissions.includes('/battery/capacity')) {
    return {
      ...webhook,
      permissions: [...webhook.permissions, '/battery/nominal_capacity'],
    };
  }
  return webhook;
};

const filterOutDeprecatedEndpoints = (permissions) => {
  const deprecatedEndpoints = permissionOptions
    .filter(permission => permission.deprecated)
    .map(permission => permission.name);
  return [...permissions].filter(permission => !deprecatedEndpoints.includes(permission));
};
const removeDeprecatedEndpoints = (formValues) => {
  if (!formValues.permissions) return formValues;
  return {
    ...formValues,
    permissions: filterOutDeprecatedEndpoints(formValues.permissions),
  };
};

export default {
  defaultFields,
  fields,
  endpoints,
  sections,
  eventTypes,
  unitTypes,
  permissionOptions,
  stateChangeTypes,
  dynamicProfiles,
  sectionNames,
  removeDeprecatedEndpoints,
  addDeprecatedEndpointReplacement,
};
