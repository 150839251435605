import { connect } from 'react-redux';

import ConnectPreview from './ConnectPreview';

function mapStateToProps(state) {
  return {
    ...state.connectCustomizations,
    application: state.applications.applications[state.applications.selectedApplication],
  };
}

export default connect(mapStateToProps)(ConnectPreview);
