import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../../../services/auth/reducers';
import PublicRoute from './PublicRoute';

function mapDispatchToProps(dispatch) {
  const { mfaSetRecoveryToken } = actions;

  return {
    actions: bindActionCreators({
      mfaSetRecoveryToken,
    }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PublicRoute);
