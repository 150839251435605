import { styled } from '@mui/material/styles';
import {
  Button,
  Select as MuiSelect,
} from '@mui/material';

export const ErrorsList = styled('ul')(({ theme, alignItems }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems,
  padding: theme.spacing(0.5, 0, 1),
  margin: 0,
  listStyle: 'none',
}));

export const FiltersContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  rowGap: theme.spacing(1),

  '.MuiButton-root': {
    padding: theme.spacing(1, 2),
  },
  '& > .MuiButtonBase-root': {
    marginLeft: theme.spacing(2),
  },
}));

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: 'auto',
}));

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

export const Select = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '2.625rem',
  border: 'none',
  transition: 'border 0.25s ease',
  '.Mui-disabled': {
    border: 'none',
  },
  '.MuiSelect-select': {
    padding: `${theme.spacing(1, 4.5, 1, 2)} !important`,
  },

  '& fieldset, &.MuiOutlinedInput-root.Mui-disabled > fieldset': {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));
