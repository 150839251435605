import React from 'react';
import { withRouter } from 'react-router-dom';
import { func, object, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { OrgLink, Divider, SelectorList } from '../../../AppSelector/components/AppSelectorList/styles';

const OrgSelectorList = ({
  orgId,
  orgName,
  organizations,
  selectOrganization,
  closeList,
  history,
}) => {
  const handleCurrentOrgClick = () => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: '[current org]',
    });
  };
  const handleOrgLinkClick = (id) => {
    selectOrganization(id);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[switch org: ${id}]`,
    });
    history.push('/');
    closeList();
  };

  return (
    <SelectorList>
      <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>Team</Typography>
      <li key={`org-${orgId}`}>
        <OrgLink onClick={handleCurrentOrgClick}>
          <Typography variant="bold">{orgName}</Typography>
          <CheckRoundedIcon fontSize="small" />
        </OrgLink>
      </li>
      {Object.keys(organizations).length > 1 && (
        <React.Fragment>
          <Divider />
          {Object.keys(organizations).map((organizationId) => {
            const { id, name } = organizations[organizationId];
            const orgNotSelected = (id !== orgId);
            return orgNotSelected ? (
              <li key={`org-${id}`}>
                <OrgLink onClick={() => handleOrgLinkClick(id)}>
                  <Typography>{name}</Typography>
                </OrgLink>
              </li>
            ) : null;
          })}
        </React.Fragment>
      )}
    </SelectorList>
  );
};

export default withRouter(OrgSelectorList);

OrgSelectorList.propTypes = {
  orgId: string.isRequired,
  orgName: string.isRequired,
  organizations: object.isRequired,
  closeList: func.isRequired,
  selectOrganization: func.isRequired,
  history: object.isRequired,
};
