import { array, arrayOf, bool, objectOf, shape, string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const eventProps = {
  eventId: string,
  applicationId: string,
  sessionId: string,
  customerVehicleOwnerId: string,
  eventName: string,
  pageName: string,
  trackedAt: string,
  launchParamMake: string,
  launchParamSingleSelect: bool,
  launchParamSingleSelectVin: string,
  launchParamScope: string,
  make: string,
  mode: string,
  country: string,
  vehiclesAuthorized: array,
  vehiclesNotAuthorized: array,
  vehiclesIncompatible: array,
  scopesAuthorized: objectOf(arrayOf(string)),
  eventString: shape({
    username: string,
    action: string,
    text: string,
    timestamp: string,
  }),
};
