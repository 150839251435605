export const setFetchingState = (state, key) => {
  return {
    ...state,
    isFetchingSimulator: {
      ...state.isFetchingSimulator,
      [key]: true,
    },
    fetchingSimulatorErrors: {
      ...state.fetchingSimulatorErrors,
      [key]: false,
    },
  };
};

export const setSuccessState = (state, key, action) => {
  return {
    ...state,
    isFetchingSimulator: {
      ...state.isFetchingSimulator,
      [key]: false,
    },
    [key]: action.payload,
    fetchingSimulatorErrors: {
      ...state.fetchingSimulatorErrors,
      [key]: false,
    },
  };
};

export const setFailureState = (state, key, action) => {
  return {
    ...state,
    isFetchingSimulator: {
      ...state.isFetchingSimulator,
      [key]: false,
    },
    fetchingSimulatorErrors: {
      ...state.fetchingSimulatorErrors,
      [key]: (action.payload && action.payload.message) || `Something went wrong fetching ${key}`,
    },
  };
};
