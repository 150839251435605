import React from 'react';

import leftFunnel from '../../../../../../../../assets/images/leftFunnel.png';
import rightFunnel from '../../../../../../../../assets/images/rightFunnel.png';
import { UpsellContent } from '../../../../../../../../components';
import FEATURES from '../../../../../../../../services/featureGate/features';
import { Container, Image } from './styles';

const GatedFunnel = () => {
  return (
    <Container>
      <Image src={leftFunnel} alt="connect funnel" />
      <div>
        <UpsellContent feature={FEATURES.CONNECT_FUNNEL} variant="compact" />
      </div>
      <Image src={rightFunnel} alt="connect funnel" />
    </Container>
  );
};

export default GatedFunnel;
