import { styled } from '@mui/material';

export const TopbarWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.topbar,
  minWidth: '100vw',
  boxShadow: theme.border.boxShadow,
  backgroundColor: theme.palette.common.white,
}));

export const SidebarWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.border.boxShadow,
  cursor: 'pointer',
  minHeight: `calc(100vh - ${theme.height.topbar})`,
}));

export const MainWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'sidebarNavExpanded',
})(({ theme, sidebarNavExpanded }) => ({
  width: `calc(100vw - ${sidebarNavExpanded ?
    theme.width.sidebar.expanded :
    theme.width.sidebar.closed
  })`,
  transition: 'width .25s ease',
}));

export const Main = styled('main')(({ theme }) => ({
  margin: theme.spacing(3, 5),
  // minWidth: '1100px',
  // paddingRight: theme.spacing(4),
}));

export const Section = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow,
  padding: theme.spacing(3, 5),
  marginBottom: theme.spacing(3),
}));
