import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Typography, useTheme } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { AppLink } from '../../styles';
import { AppLogoWrapper } from '../../../../styles';

/*
  Regex swaps the current app id for the listed app id in the pathname,
  keeping the rest of the URL
*/
export const buildPathname = (pathname, id) => {
  let path = pathname.replace(/\/apps\/[^/?#]*/, `/apps/${id}`);
  ['simulator', 'vehicles'].forEach((suffix) => {
    if (path.includes(suffix)) {
      path = `${path.split(suffix)[0]}${suffix}`;
    }
  });
  return path;
};

const ApplicationLink = ({
  id,
  name,
  logoUrl,
  pathname,
  current,
  closeList,
  setLockedAppClicked,
  lockedApplications,
}) => {
  const theme = useTheme();
  const lockedApp = lockedApplications.includes(id);
  const path = buildPathname(pathname, id);

  // If user clicks on a locked application, open upsell modal
  const onClick = (e) => {
    if (!lockedApp) {
      if (closeList) {
        closeList();
      }
      reportToSegment(types.TRACK, eventNames.linkClicked, {
        section: 'topbar',
        style: 'dropdown',
        text: name,
        path,
      });
    } else {
      e.preventDefault();
      setLockedAppClicked(true);
    }
  };

  const getAppLogo = () => {
    return (
      <AppLogoWrapper hasLogo={Boolean(logoUrl)}>
        {logoUrl ?
          <img src={logoUrl} alt="" /> :
          <span>{name[0].toUpperCase()}</span>}
      </AppLogoWrapper>
    );
  };

  return (
    <li key={`app-${id}`}>
      <AppLink to={path} onClick={e => onClick(e)}>
        <Box display="flex" alignItems="center">
          {getAppLogo(id)}
          <Typography
            variant={current ? 'bold' : ''}
            style={lockedApp ? { color: theme.palette.text.disabled } : null}
          >{name}
          </Typography>
        </Box>
        {current && <CheckRoundedIcon fontSize="small" />}
      </AppLink>
    </li>
  );
};

ApplicationLink.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  logoUrl: string,
  pathname: string.isRequired,
  current: bool,
  closeList: func,
  setLockedAppClicked: func.isRequired,
  lockedApplications: arrayOf(string).isRequired,
};

ApplicationLink.defaultProps = {
  current: false,
  logoUrl: '',
  closeList: null,
};

export default ApplicationLink;
