import React from 'react';
import { Link as MuiLink, Typography, useTheme } from '@mui/material';
import { string } from 'prop-types';
import { LaunchRounded } from '@mui/icons-material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

const staticText = {
  tryInteractiveDemo: 'Try Interactive Demo',
};

const DemoLink = ({ organizationId, dashboardUserId }) => {
  const theme = useTheme();
  const demoId = '6733f43b0f7c264108cf6e8f'; // implemetation demo
  const coastDemoLink = `https://demos.smartcar.com/share/${demoId}?userId=${dashboardUserId}&teamId=${organizationId}`;

  return (
    <MuiLink
      id="try-interactive-demo"
      href={coastDemoLink}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => reportToSegment(types.TRACK, eventNames.linkClicked, {
        section: 'sidebar', style: 'unstyled', path: coastDemoLink, text: 'Try interactive demo',
      })}
      display="flex"
      sx={{ padding: theme.spacing(2), margin: theme.spacing(0, 2), width: '180px' }}
    >
      <Typography sx={{ fontFamily: theme.typography.bold.fontFamily, marginTop: '2px' }}>
        {staticText.tryInteractiveDemo}
      </Typography>
      <LaunchRounded sx={{ fontSize: 'inherit', marginLeft: '4px', marginTop: '3px' }} />
    </MuiLink>
  );
};

export default DemoLink;

DemoLink.propTypes = {
  organizationId: string.isRequired,
  dashboardUserId: string.isRequired,
};
