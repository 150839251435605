/* istanbul ignore file */
import _ from 'lodash';

const keepProperty = [
  'vehicleId',
  'requestRoute',
  'responseStatusCode',
  'responseErrorType',
  'responseErrorCode',
  'requestId',
  'trackedAt',
  'moreActions',
];

// Strips unused properties for LogTable for RequestLogs
const formatRequestLogs = (logs, offset) => {
  const updatedCols = logs.cols
    .filter(col => keepProperty.includes(col.field))
    .sort((a, b) => keepProperty.indexOf(a.field) - keepProperty.indexOf(b.field));

  const updatedRows = [];

  logs.rows.forEach((row) => {
    // eslint-disable-next-line no-param-reassign
    row.id = `${row.id}-${offset}`;

    const updatedRow = _.pick(row, [
      'id',
      ...keepProperty,
    ]);

    updatedRows.push(updatedRow);
  });


  return {
    rows: updatedRows,
    cols: updatedCols,
    totalCount: Number(logs.totalCount),
  };
};

export default formatRequestLogs;
