import { addDays, isAfter } from 'date-fns';

const utils = {};

/**
 * If the developer is not verified yet after their initial
 * verification email is expired, block their usage of the dashboard.
 * The initial email expires 7 days after developer account creation.
 *
 * @param {string} developerCreatedAt date of developer account creation
 * @param {string | null} developerVerifiedAt date of email verification or null
 * if the email has not been verified yet
 * @returns {boolean}
 */
utils.forceVerification = (developerCreatedAt, developerVerifiedAt) => {
  const gracePeriodEnd = addDays(new Date(developerCreatedAt), 7);

  return !developerVerifiedAt && isAfter(new Date(Date.now()), gracePeriodEnd);
};

/**
 * (Loosely) check to see if email is a valid email format (<string>@<string>.<string>)
 *
 * @param {string} email
 * @returns {boolean}
 */
utils.isEmail = (email) => {
  const emailRegExp = new RegExp(/.+@.+\..+/);

  return emailRegExp.test(email);
};

export default utils;
