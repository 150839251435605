import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { dashboardRole as constants } from '../../scenes/Application/components/Members/utils/roles';
import applicationNameValidator from '../../services/validators/applicationNameValidator/applicationNameValidator';
import errors from '../../localization/Application/Members/errors';

const staticText = {
  description: 'Please enter an application name to get started.',
  button: 'Register',
  input: 'Application Name',
  teamLabel: 'Team:',
};

// TODO: might need to consolidate with PermissionGate
const roleValidator = (role) => {
  if (!([constants.OWNER, constants.ADMIN].includes(role))) {
    throw new Error(errors.noPermissionToRegisterApplication);
  }
  return true;
};

class RegisterApplication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      isValid: false,
      attemptedSubmit: false,
      errorMessage:
        'Application name must be between three and thirty characters in length.',
    };

    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUpdateName(event) {
    const { value: name } = event.target;
    const { applicationNames, organization: { dashboardRole } } = this.props;
    try {
      roleValidator(dashboardRole);
      applicationNameValidator(name, applicationNames);
      this.setState({ name, isValid: true, errorMessage: '' });
    } catch (err) {
      this.setState({ name, isValid: false, errorMessage: err.message });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, isValid, errorMessage } = this.state;
    const {
      toggleModal,
      actions: { registerApplication },
    } = this.props;

    if (isValid) {
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'register application',
        form_content: { name },
      });
      registerApplication({ name });
      toggleModal(false);
    } else {
      reportToSegment(types.TRACK, eventNames.formFailed, {
        label: 'register application',
        form_content: { name, errorMessage },
      });

      this.setState({
        attemptedSubmit: true,
      });
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    const {
      attemptedSubmit, isValid, name, errorMessage,
    } = this.state;
    const { organization } = this.props;

    const showErrorMessage = attemptedSubmit && !isValid;
    const inputClassName = showErrorMessage
      ? 'input input-validate-name input-danger'
      : 'input input-validate-name';

    return (
      <div>
        <h3 className="bold primary-text-color no-transform">
          {staticText.teamLabel} {organization.name}
        </h3>
        <div className="m-b-lg">{staticText.description}</div>
        <h3 className="bold primary-text-color no-transform">
          {staticText.input}
        </h3>
        <form onSubmit={this.handleSubmit}>
          <input
            className={inputClassName}
            type="text"
            placeholder="e.g. Radiator Springs Fuel Delivery"
            defaultValue={name}
            autoFocus="autoFocus"
            onChange={this.handleUpdateName}
            onKeyPress={this.handleKeyPress}
          />
          <div className="error-message">{showErrorMessage && errorMessage}</div>
          <div className="button-wrapper right m-t-lg">
            <button className="button button-med" type="submit">
              {staticText.button}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default RegisterApplication;

RegisterApplication.propTypes = {
  actions: shape({
    registerApplication: func.isRequired,
  }).isRequired,
  toggleModal: func.isRequired,
  applicationNames: arrayOf(string).isRequired,
  organization: shape({
    name: string.isRequired,
    dashboardRole: string.isRequired,
  }).isRequired,
};
