import _ from 'lodash';

import {
  setFetchingState,
  setSuccessState,
  setFailureState,
} from './utils/reducers';

export const types = {
  FETCH_API_REQUEST_TIME_SERIES:
    'developer-client/Overview/FETCH_API_REQUEST_TIME_SERIES',
  FETCH_API_REQUEST_TIME_SERIES_SUCCESS:
    'developer-client/Overview/FETCH_API_REQUEST_TIME_SERIES_SUCCESS',
  FETCH_API_REQUEST_TIME_SERIES_FAILURE:
    'developer-client/Overview/FETCH_API_REQUEST_TIME_SERIES_FAILURE',

  SET_TOTAL_API_REQUESTS: 'developer-client/Overview/SET_TOTAL_API_REQUESTS',

  FETCH_ACTIVE_VEHICLES_TIME_SERIES:
    'developer-client/Overview/FETCH_ACTIVE_VEHICLES_TIME_SERIES',
  FETCH_ACTIVE_VEHICLES_TIME_SERIES_SUCCESS:
    'developer-client/Overview/FETCH_ACTIVE_VEHICLES_TIME_SERIES_SUCCESS',
  FETCH_ACTIVE_VEHICLES_TIME_SERIES_FAILURE:
    'developer-client/Overview/FETCH_ACTIVE_VEHICLES_TIME_SERIES_FAILURE',

  SET_TOTAL_ACTIVE_VEHICLES:
    'developer-client/Overview/SET_TOTAL_ACTIVE_VEHICLES',

  FETCH_RESPONSE_STATUS_SUMMARY:
    'developer-client/Overview/FETCH_RESPONSE_STATUS_SUMMARY',
  FETCH_RESPONSE_STATUS_SUMMARY_SUCCESS:
    'developer-client/Overview/FETCH_RESPONSE_STATUS_SUMMARY_SUCCESS',
  FETCH_RESPONSE_STATUS_SUMMARY_FAILURE:
    'developer-client/Overview/FETCH_RESPONSE_STATUS_SUMMARY_FAILURE',

  SET_TOTAL_FAILED_REQUESTS:
    'developer-client/Overview/SET_TOTAL_FAILED_REQUESTS',

  FETCH_MAKES_SUMMARY: 'developer-client/Overview/FETCH_MAKES_SUMMARY',
  FETCH_MAKES_SUMMARY_SUCCESS:
    'developer-client/Overview/FETCH_MAKES_SUMMARY_SUCCESS',
  FETCH_MAKES_SUMMARY_FAILURE:
    'developer-client/Overview/FETCH_MAKES_SUMMARY_FAILURE',

  SET_FILTER: 'developer-client/Overview/SET_FILTER',
  RESET_FETCHING: 'developer-client/Overview/RESET_FETCHING',
};

export const initialState = {
  isFetchingOverview: {
    apiRequestTimeSeries: true,
    activeVehiclesTimeSeries: true,
    responseStatusSummary: true,
    makesSummary: true,
  },
  filter: {
    timeframe: {},
    logs: {},
  },
  metrics: {
    apiRequestTimeSeries: {},
    activeVehiclesTimeSeries: [],
    responseStatusSummary: {},
    makesSummary: {},
    totalApiRequests: 0,
    totalActiveVehicles: 0,
    totalFailedRequests: 0,
  },
  logsOptions: {
    rowsPerPage: 10, // may be editable in the future
  },
  errors: {
    apiRequestTimeSeries: '',
    activeVehiclesTimeSeries: '',
    responseStatusSummary: '',
    makesSummary: '',
  },
};

export const actions = {
  fetchApiRequestTimeSeries: applicationId => ({
    type: types.FETCH_API_REQUEST_TIME_SERIES,
    payload: null,
    meta: applicationId,
  }),
  fetchApiRequestTimeSeriesSuccess: metrics => ({
    type: types.FETCH_API_REQUEST_TIME_SERIES_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchApiRequestTimeSeriesFailure: error => ({
    type: types.FETCH_API_REQUEST_TIME_SERIES_FAILURE,
    payload: error,
    meta: null,
  }),

  setTotalApiRequests: numRequests => ({
    type: types.SET_TOTAL_API_REQUESTS,
    payload: numRequests,
    meta: null,
  }),

  fetchActiveVehiclesTimeSeries: applicationId => ({
    type: types.FETCH_ACTIVE_VEHICLES_TIME_SERIES,
    payload: null,
    meta: applicationId,
  }),
  fetchActiveVehiclesTimeSeriesSuccess: metrics => ({
    type: types.FETCH_ACTIVE_VEHICLES_TIME_SERIES_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchActiveVehiclesTimeSeriesFailure: error => ({
    type: types.FETCH_ACTIVE_VEHICLES_TIME_SERIES_FAILURE,
    payload: error,
    meta: null,
  }),

  setTotalActiveVehicles: numVehicles => ({
    type: types.SET_TOTAL_ACTIVE_VEHICLES,
    payload: numVehicles,
    meta: null,
  }),

  fetchResponseStatusSummary: applicationId => ({
    type: types.FETCH_RESPONSE_STATUS_SUMMARY,
    payload: null,
    meta: applicationId,
  }),
  fetchResponseStatusSummarySuccess: metrics => ({
    type: types.FETCH_RESPONSE_STATUS_SUMMARY_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchResponseStatusSummaryFailure: error => ({
    type: types.FETCH_RESPONSE_STATUS_SUMMARY_FAILURE,
    payload: error,
    meta: null,
  }),

  setTotalFailedRequests: numFailedRequests => ({
    type: types.SET_TOTAL_FAILED_REQUESTS,
    payload: numFailedRequests,
    meta: null,
  }),

  fetchMakesSummary: applicationId => ({
    type: types.FETCH_MAKES_SUMMARY,
    payload: null,
    meta: applicationId,
  }),
  fetchMakesSummarySuccess: metrics => ({
    type: types.FETCH_MAKES_SUMMARY_SUCCESS,
    payload: metrics,
    meta: null,
  }),
  fetchMakesSummaryFailure: error => ({
    type: types.FETCH_MAKES_SUMMARY_FAILURE,
    payload: error,
    meta: null,
  }),

  setFilter: (filter, key) => ({
    type: types.SET_FILTER,
    payload: { key, filter },
    meta: null,
  }),

  resetFetching: () => ({
    type: types.RESET_FETCHING,
    payload: null,
    meta: null,
  }),
};

export const selectors = {
  getFilter: (state) => {
    return _.get(state, ['overview', 'filter']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_API_REQUEST_TIME_SERIES:
      return setFetchingState(state, 'metrics', 'apiRequestTimeSeries');
    case types.FETCH_API_REQUEST_TIME_SERIES_SUCCESS:
      return setSuccessState(state, 'metrics', 'apiRequestTimeSeries', action);
    case types.FETCH_API_REQUEST_TIME_SERIES_FAILURE:
      return setFailureState(state, 'apiRequestTimeSeries', action);

    case types.SET_TOTAL_API_REQUESTS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          totalApiRequests: action.payload,
        },
      };

    case types.FETCH_ACTIVE_VEHICLES_TIME_SERIES:
      return setFetchingState(state, 'metrics', 'activeVehiclesTimeSeries');
    case types.FETCH_ACTIVE_VEHICLES_TIME_SERIES_SUCCESS:
      return setSuccessState(
        state,
        'metrics',
        'activeVehiclesTimeSeries',
        action,
      );
    case types.FETCH_ACTIVE_VEHICLES_TIME_SERIES_FAILURE:
      return setFailureState(state, 'activeVehiclesTimeSeries', action);

    case types.SET_TOTAL_ACTIVE_VEHICLES:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          totalActiveVehicles: action.payload,
        },
      };

    case types.FETCH_RESPONSE_STATUS_SUMMARY:
      return setFetchingState(state, 'metrics', 'responseStatusSummary');
    case types.FETCH_RESPONSE_STATUS_SUMMARY_SUCCESS:
      return setSuccessState(state, 'metrics', 'responseStatusSummary', action);
    case types.FETCH_RESPONSE_STATUS_SUMMARY_FAILURE:
      return setFailureState(state, 'responseStatusSummary', action);

    case types.SET_TOTAL_FAILED_REQUESTS:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          totalFailedRequests: action.payload,
        },
      };

    case types.FETCH_MAKES_SUMMARY:
      return setFetchingState(state, 'metrics', 'makesSummary');
    case types.FETCH_MAKES_SUMMARY_SUCCESS:
      return setSuccessState(state, 'metrics', 'makesSummary', action);
    case types.FETCH_MAKES_SUMMARY_FAILURE:
      return setFailureState(state, 'makesSummary', action);

    case types.SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.filter,
        },
      };

    case types.RESET_FETCHING:
      return {
        ...state,
        isFetchingOverview: {
          apiRequestTimeSeries: true,
          activeVehiclesTimeSeries: true,
          responseStatusSummary: true,
          makesSummary: true,
        },
      };

    default:
      return state;
  }
}
