const staticText = {
  car: 'Make Model Year',
  vehicleId: 'Vehicle ID',
  responseErrorCode: 'Error Code',
  requestTimestamp: 'Timestamp',

  summary: 'Summary',
  viewDocumentation: 'View documentation',
  disconnected: 'Vehicle Disconnected',
};

export default staticText;
