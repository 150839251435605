import { styled } from '@mui/material/styles';
import { Drawer, Typography, Box, Button } from '@mui/material';


export const StyledDrawer = styled(Drawer)(() => ({
  '.MuiPaper-root': {
    width: '500px',
    padding: '40px',
  },
}));

export const StyledHeader = styled(Typography)(({ theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey['200']}`,
  background: `${theme.palette.grey['100']}`,
  marginBottom: '32px',
  marginTop: '5px',
  fontSize: '32px',
  fontWeight: '600',
  width: 'fit-content',
  textTransform: 'capitalize',
  height: '40px',
  padding: '5px 15px',
}),
);

export const StyledData = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}),
);

export const StyledCarData = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}),
);

export const Logo = styled('div')(({ theme }) => ({
  width: '30px',
  height: '30px',
  marginRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export const Close = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
