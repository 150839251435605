import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TestWebhookModal from './TestWebhookModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state) => {
  return {
    ...state.webhooks,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    fetchTestEventRequest,
    testWebhookRequest,
    clearTestData,
    toggleWebhookModal,
  } = webhooksActions;

  return {
    actions: bindActionCreators({
      fetchTestEventRequest,
      testWebhookRequest,
      clearTestData,
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestWebhookModal);
