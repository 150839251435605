import React from 'react';
import { string, func, bool } from 'prop-types';
import { Button } from '@mui/material';

const ActionButton = ({ action, openModal, disabled }) => (
  <Button variant="contained" sx={{ marginRight: 2, height: '38px', width: '94px' }} onClick={() => openModal(action)} disabled={disabled} >
    {action}
  </Button>
);

export default ActionButton;

ActionButton.propTypes = {
  action: string.isRequired,
  openModal: func.isRequired,
  disabled: bool,
};

ActionButton.defaultProps = {
  disabled: false,
};
