import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectCustomizations from './ConnectCustomizations';
import { actions } from './reducers';
import { actions as applicationsActions } from '../../../../services/applications/reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  const { name } =
    state.applications.applications[state.applications.selectedApplication];

  return {
    ...state.connectCustomizations,
    appId: state.applications.selectedApplication,
    appName: name,
    featureSetId: currentOrganization.featureSetId,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchSupportedCountriesRequest,
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    fetchBrandManagementCapabilitiesRequest,
    updateConnectConfigRequest,
    deleteAppLogo,
    clearErrors,
  } = actions;
  const { updateApplication } = applicationsActions;

  return {
    actions: bindActionCreators(
      {
        fetchSupportedCountriesRequest,
        fetchApplicationMakesRequest,
        fetchConnectConfigRequest,
        fetchBrandManagementCapabilitiesRequest,
        updateConnectConfigRequest,
        updateApplication,
        deleteAppLogo,
        clearErrors,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCustomizations);
