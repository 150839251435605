import React from 'react';
import { arrayOf, shape, string, object } from 'prop-types';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LatencyTable } from './components';
import staticText from '../../../../../../../../../../localization/Application/Simulator/simulator';

const LatenciesInfo = ({ selectedVehicle }) => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Typography variant="h2" sx={{ marginBottom: theme.spacing(4) }}>
        {staticText.latencyDrawerTitle(selectedVehicle.make)}
      </Typography>
      <LatencyTable
        endpointData={selectedVehicle.endpointsWithLatency}
      />
    </React.Fragment>
  );
};

export default LatenciesInfo;

LatenciesInfo.propTypes = {
  selectedVehicle: shape({
    make: string,
    endpointsWithLatency: arrayOf(object),
  }).isRequired,
};
