const UNITS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

const TYPES = {
  schedule: {
    name: 'schedule',
    text: 'Scheduled',
    description: 'Select a cadence at which you want to receive data from connected vehicles.',
  },
  eventBased: {
    name: 'eventBased',
    text: 'Event based',
    description: "Get notified when a vehicle's state changes.",
  },
  dynamic: {
    name: 'dynamic',
    text: 'Dynamic',
    description: "Receive location, battery state of charge and charge session data at varying intervals based on the vehicle's state, increasing in frequency when the vehicle is plugged in.",
    beta: true,
  },
};

export { UNITS, TYPES };
