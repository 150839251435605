import { useState, useEffect } from 'react';

const useShowLatencyData = (selectedVehicle) => {
  const [showLatency, setShowLatency] = useState(false);

  useEffect(() => {
    // If none of the endpoints have latency data, hide the latency section
    if (selectedVehicle.endpointsWithLatency && selectedVehicle.endpointsWithLatency.length) {
      const result = selectedVehicle.endpointsWithLatency.reduce((acc, next) => {
        if (acc === true) return acc;
        if (next.latencyUpperBound > 0) return true;
        return false;
      }, false);
      setShowLatency(result);
    }
  }, [selectedVehicle]);

  return showLatency;
};

export default useShowLatencyData;
