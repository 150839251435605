import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: theme.spacing(5, 3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey[200]}`,
  marginBottom: theme.spacing(3),
}));

export const Border = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  width: '50%',
  height: '100%',
}));
