import _ from 'lodash';
import { errors as accountErrors } from '../../localization/Setup/account';

export const types = {
  RESET_ERRORS: 'developer-client/onboarding/RESET_ERRORS',

  CREATE_ORGANIZATION_REQUEST: 'developer-client/onboarding/CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_SUCCESS: 'developer-client/onboarding/CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE: 'developer-client/onboarding/CREATE_ORGANIZATION_FAILURE',

  FETCH_ONBOARDING_DETAILS: 'developer-client/onboarding/FETCH_ONBOARDING_DETAILS',
  FETCH_ONBOARDING_DETAILS_SUCCESS: 'developer-client/onboarding/FETCH_ONBOARDING_DETAILS_SUCCESS',
  FETCH_ONBOARDING_DETAILS_FAILURE: 'developer-client/onboarding/FETCH_ONBOARDING_DETAILS_FAILURE',

  INITIALIZE_ONBOARDING: 'developer-client/onboarding/INITIALIZE_ONBOARDING',
  INITIALIZE_ONBOARDING_SUCCESS: 'developer-client/onboarding/INITIALIZE_ONBOARDING_SUCCESS',
  INITIALIZE_ONBOARDING_FAILURE: 'developer-client/onboarding/INITIALIZE_ONBOARDING_FAILURE',

  UPDATE_USER_INFO: 'developer-client/onboarding/UPDATE_USER_INFO',
  UPDATE_USER_INFO_SUCCESS: 'developer-client/onboarding/UPDATE_USER_INFO_SUCCESS',
  UPDATE_USER_INFO_FAILURE: 'developer-client/onboarding/UPDATE_USER_INFO_FAILURE',

  UPDATE_ONBOARDING_EVENT: 'developer-client/onboarding/UPDATE_ONBOARDING_EVENT',
  UPDATE_ONBOARDING_EVENT_SUCCESS: 'developer-client/onboarding/UPDATE_ONBOARDING_EVENT_SUCCESS',
  UPDATE_ONBOARDING_EVENT_FAILURE: 'developer-client/onboarding/UPDATE_ONBOARDING_EVENT_FAILURE',

  START_ONBOARDING_POLLING: 'developer-client/onboarding/START_ONBOARDING_POLLING',
  STOP_ONBOARDING_POLLING: 'developer-client/onboarding/STOP_ONBOARDING_POLLING',

  SET_SHOW_ONBOARDING: 'developer-client/onboarding/SET_SHOW_ONBOARDING',

  SET_SHOW_USER_SETUP: 'developer-client/onboarding/SET_SHOW_USER_SETUP',
};

export const initialState = {
  onboardingEvents: {
    liveModeEvents: [],
    testModEvents: [],
  },
  showOnboarding: false,
  isFetching: false,
  isCreatingOrg: false,
  isInitializing: false,
  isUpdating: false,
  onboardingDetailsError: '',
  developerError: '',
  initializeOnboardingError: '',
  updateUserInfoError: [],
  createOrgError: '',
  onboardingPollingError: '',
  showUserSetup: false,
};

export const actions = {
  resetErrors: () => ({
    type: types.RESET_ERRORS,
    payload: null,
    meta: null,
  }),

  createOrganization: organizationName => ({
    type: types.CREATE_ORGANIZATION_REQUEST,
    payload: organizationName,
    meta: null,
  }),
  createOrganizationSuccess: () => ({
    type: types.CREATE_ORGANIZATION_SUCCESS,
    payload: null,
    meta: null,
  }),
  createOrganizationFailure: error => ({
    type: types.CREATE_ORGANIZATION_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchOnboardingDetails: () => ({
    type: types.FETCH_ONBOARDING_DETAILS,
    payload: null,
    meta: null,
  }),
  fetchOnboardingDetailsSuccess: onboardingDetails => ({
    type: types.FETCH_ONBOARDING_DETAILS_SUCCESS,
    payload: onboardingDetails,
    meta: null,
  }),
  fetchOnboardingDetailsFailure: error => ({
    type: types.FETCH_ONBOARDING_DETAILS_FAILURE,
    payload: error,
    meta: null,
  }),

  initializeOnboarding: () => ({
    type: types.INITIALIZE_ONBOARDING,
    payload: null,
    meta: null,
  }),
  initializeOnboardingSuccess: () => ({
    type: types.INITIALIZE_ONBOARDING_SUCCESS,
    payload: null,
    meta: null,
  }),
  initializeOnboardingFailure: error => ({
    type: types.INITIALIZE_ONBOARDING_FAILURE,
    payload: error,
    meta: null,
  }),

  updateUserInfo: userInfo => ({
    type: types.UPDATE_USER_INFO,
    payload: userInfo,
    meta: null,
  }),
  updateUserInfoSuccess: () => ({
    type: types.UPDATE_USER_INFO_SUCCESS,
    payload: null,
    meta: null,
  }),
  updateUserInfoFailure: error => ({
    type: types.UPDATE_USER_INFO_FAILURE,
    payload: error,
    meta: null,
  }),

  updateOnboardingEvent: eventName => ({
    type: types.UPDATE_ONBOARDING_EVENT,
    payload: eventName,
    meta: null,
  }),
  updateOnboardingEventSuccess: () => ({
    type: types.UPDATE_ONBOARDING_EVENT_SUCCESS,
    payload: null,
    meta: null,
  }),
  updateOnboardingEventFailure: error => ({
    type: types.UPDATE_ONBOARDING_EVENT_FAILURE,
    payload: error,
    meta: null,
  }),

  startOnboardingPolling: () => ({
    type: types.START_ONBOARDING_POLLING,
    payload: null,
    meta: null,
  }),
  stopOnboardingPolling: error => ({
    type: types.STOP_ONBOARDING_POLLING,
    payload: error || null,
    meta: null,
  }),
  setShowOnboarding: showOnboarding => ({
    type: types.SET_SHOW_ONBOARDING,
    payload: showOnboarding,
    meta: null,
  }),

  setShowUserSetup: showUserSetup => ({
    type: types.SET_SHOW_USER_SETUP,
    payload: showUserSetup,
    meta: null,
  }),
};

export const selectors = {
  getOnboardingEvents: (state) => {
    return _.get(state, ['onboarding', 'onboardingEvents']);
  },
  getShowUserSetup: (state) => {
    return _.get(state, ['onboarding', 'showUserSetup']);
  },
};

export function getErrorMessage(action) {
  if (action.payload) {
    const { detail = [], message = '' } = _.get(action.payload.response, 'data');
    if (detail) {
      return detail;
    }
    if (message) {
      return message;
    }
  }

  return [];
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_ERRORS:
      return {
        ...state,
        updateUserInfoError: [],
      };

    case types.CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isCreatingOrg: true,
        createOrgError: '',
      };
    case types.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isCreatingOrg: false,
      };
    case types.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        isCreatingOrg: false,
        createOrgError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || accountErrors.teamCreation,
      };

    case types.FETCH_ONBOARDING_DETAILS: {
      return {
        ...state,
        isFetching: true,
        onboardingDetailsError: '',
      };
    }
    case types.FETCH_ONBOARDING_DETAILS_SUCCESS: {
      return {
        ...state,
        onboardingEvents: action.payload.onboardingEvents,
        isFetching: false,
      };
    }
    case types.FETCH_ONBOARDING_DETAILS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        onboardingDetailsError: (action.payload && action.payload.message) || 'Error fetching onboarding details',
      };
    }

    case types.INITIALIZE_ONBOARDING: {
      return {
        ...state,
        isInitializing: true,
        initializeOnboardingError: '',
      };
    }
    case types.INITIALIZE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        isInitializing: false,
      };
    }
    case types.INITIALIZE_ONBOARDING_FAILURE: {
      return {
        ...state,
        isInitializing: false,
        initializeOnboardingError: (action.payload && action.payload.message) || 'Error initializing onboarding',
      };
    }

    case types.UPDATE_USER_INFO: {
      return {
        ...state,
        isUpdating: true,
        updateUserInfoError: [],
      };
    }
    case types.UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        updateUserInfoError: [],
      };
    }
    case types.UPDATE_USER_INFO_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        updateUserInfoError: getErrorMessage(action),
      };
    }

    case types.UPDATE_ONBOARDING_EVENT: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case types.UPDATE_ONBOARDING_EVENT_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case types.UPDATE_ONBOARDING_EVENT_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case types.START_ONBOARDING_POLLING: {
      return {
        ...state,
        onboardingPollingError: '',
      };
    }
    case types.STOP_ONBOARDING_POLLING: {
      return {
        ...state,
        onboardingPollingError: action.payload ? `Polling stopped due to error: ${action.payload.message}` : '',
      };
    }
    case types.SET_SHOW_ONBOARDING: {
      return {
        ...state,
        showOnboarding: action.payload,
      };
    }

    case types.SET_SHOW_USER_SETUP: {
      return {
        ...state,
        showUserSetup: action.payload,
      };
    }

    default:
      return state;
  }
}
