import React, { useEffect } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import { Spinner } from '../../components';

import { googleAnalyticsClientId } from '../../services/auth/utils';


const GoogleOAuth = ({
  actions: {
    authenticateOAuthRequest,
    createOauthRequest,
    resetOauthFlow,
  },
  location,
  history,
  loginFormErrors,
  signUpFormErrors,
  authFlow,
}) => {
  useEffect(() => {
    const anonymousId = window.analytics && typeof window.analytics.user === 'function' && window.analytics.user().anonymousId();
    const gaClientId = googleAnalyticsClientId();
    const code = new URLSearchParams(location.search).get('code');

    if (authFlow === 'signup') {
      createOauthRequest({
        code,
        anonymousId,
        gaClientId,
        loginProvider: 'GoogleOAuth',
      });
    }

    if (authFlow === 'login') {
      authenticateOAuthRequest({
        code,
        anonymousId,
        gaClientId,
        loginProvider: 'GoogleOAuth',
      });
    }
    resetOauthFlow();
  }, [location.search, authenticateOAuthRequest]);

  useEffect(() => {
    if (loginFormErrors && loginFormErrors.length > 0) {
      history.push('/');
    }

    if (signUpFormErrors && signUpFormErrors.length > 0) {
      history.push('/');
    }
  }, [loginFormErrors, signUpFormErrors]);

  return (
    <Spinner size="page" />
  );
};

export default GoogleOAuth;

GoogleOAuth.propTypes = {
  actions: shape({
    authenticateOAuthRequest: func.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  loginFormErrors: arrayOf(string),
  signUpFormErrors: arrayOf(string),
  authFlow: string.isRequired,
};

GoogleOAuth.defaultProps = {
  loginFormErrors: [],
  signUpFormErrors: [],
};
