import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Box, useTheme } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import { Feedback, InlineLinks, NoResults, Spinner } from '../../../../components';
import {
  CardSet,
  DowngradePlanModal,
  FreeTrialModal,
  LegacyCard,
  PaymentInformation,
  PlanComparisonTable,
  VerifyEmailModal,
  PaymentAlert,
} from './components';
import { SupportModal } from '../TopbarNav/components';
import {
  cardSetFormatter,
  dateFormatter,
  isInTrial,
  usageErrorGenerator,
  displayNameFormatter,
} from './utils/textFormatters';
import { toggleModal } from './utils/modalUtils';
import staticText from '../../../../localization/Application/Billing/billing';
import ApplicationHeader from '../ApplicationHeader';
import { launchChat, openChat, shutdownChat, SALES_CHAT_ID, SUPPORT_CHAT_ID } from '../../../../services/front/front';
import { gatedFeatureData, isLegacy, isSelfServe } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import { Section } from '../../styles';

const createBillingError = (errorText, onClick, buttonText) => {
  return (
    <div className="flex-vertical-center">
      <span>{errorText}</span>
      <span className="flex-vertical-center">
        &nbsp;
        {buttonText && onClick && (
          <span>
            <Button
              type="text"
              onClick={onClick}
              classes={{ text: 'mui-error-button' }}
            >
              {buttonText}
            </Button>
          </span>
        )}
      </span>
    </div>
  );
};

const Billing = (props) => {
  const {
    actions: {
      closeFreeTrialModal,
      updateBillingInfo,
      setHasHandledStripeRedirect,
    },
    selectedApp,
    billingInfo,
    billingInfoError,
    isUpdatingBillingInfo,
    hasHandledStripeRedirect,
    organization: {
      id: organizationId,
      featureSetId,
      rolePermissions,
    },
    session: {
      userContext: {
        email,
        emailVerifiedAt,
        dashboardUserId,
        firstName,
        lastName,
      },
    },
    showFreeTrialModal,
    updateBillingInfoError,
    location,
  } = props;
  const theme = useTheme();

  if (!rolePermissions.includes('read_billing')) {
    return (
      <Box sx={{ maxWidth: theme.width.content }}>
        <ApplicationHeader heading={staticText.heading} />
        <NoResults text={staticText.noPermissionToAccessPage} />
      </Box>
    );
  }
  const [currentModal, setCurrentModal] = useState('');
  const closeModal = toggleModal(setCurrentModal, '');

  useEffect(() => {
    reportToSegment(types.PAGE, 'Billing');

    // Check query params for Stripe Checkout session result
    const handleStripeRedirect = () => {
      const stripeCheckoutSessionResult = new URLSearchParams(location.search).get('success');
      /* The query param is a stringified boolean. If the 'success' param exists, we can mark the
          redirect as handled in redux to further prevent sending any duplicate requests. */
      if (typeof (stripeCheckoutSessionResult) === 'string') {
        setHasHandledStripeRedirect(true);
      }

      if (stripeCheckoutSessionResult === 'true') {
        const stripeCheckoutSessionId = new URLSearchParams(location.search).get('session_id');
        const planName = new URLSearchParams(location.search).get('planName');
        updateBillingInfo(planName, stripeCheckoutSessionId);
      }
    };

    /* Check if we are handling or have handled the query params to
        prevent sending duplicate requests */
    if (!isUpdatingBillingInfo && !hasHandledStripeRedirect) {
      handleStripeRedirect();
    }

    // Replace Support chat with Sales chat on Billing page specifically,
    // and shutdown or restart Support chat before navigating to any other screen
    launchChat(SALES_CHAT_ID, {
      applicationId: (selectedApp && selectedApp.id) || '',
      developerId: organizationId,
      dashboardUserId,
      firstName,
      lastName,
      email,
    });

    return () => {
      if (!gatedFeatureData(FEATURES.CHAT_SUPPORT, featureSetId)) {
        launchChat(SUPPORT_CHAT_ID, {
          applicationId: (selectedApp && selectedApp.id) || '',
          developerId: organizationId,
          dashboardUserId,
          firstName,
          lastName,
          email,
        });
      } else {
        shutdownChat();
      }
    };
  }, []);

  useEffect(() => {
    if (!isUpdatingBillingInfo) {
      closeModal();
    }
  }, [isUpdatingBillingInfo]);

  const modalMap = {
    downgradeFree: (
      <DowngradePlanModal
        isLoading={isUpdatingBillingInfo}
        plan={staticText.freePlanName}
        updateBillingInfo={updateBillingInfo}
        toggleModal={closeModal}
        expirationDate={
          billingInfo.billingPeriodEndDate &&
          dateFormatter(billingInfo.billingPeriodEndDate, true)
        }
        currentPlan={billingInfo.planName}
      />
    ),
    downgradeBuild: (
      <DowngradePlanModal
        isLoading={isUpdatingBillingInfo}
        plan={staticText.buildPlanName}
        updateBillingInfo={updateBillingInfo}
        toggleModal={closeModal}
        currentPlan={billingInfo.planName}
      />
    ),
    verifyEmail: <VerifyEmailModal toggleModal={closeModal} />,
    support: <SupportModal toggleModal={closeModal} />,
  };

  // Hide plan comparison table for all non self-serve customers
  const isCurrentPlanLegacy = isLegacy(featureSetId);
  const isEnterprisePlan = billingInfo.planName === staticText.enterprisePlanName;
  const isCurrentScalePlan = billingInfo.planName === staticText.scalePlanName
    && !isCurrentPlanLegacy;
  const showPlanTable = !isEnterprisePlan && !isCurrentScalePlan;

  const usageError = usageErrorGenerator(
    billingInfo.planName,
    billingInfo.numberOfVehiclesAboveBase,
    billingInfo.billedByVehicleConnections,
  );

  const feedbackContent = [];

  // Business logic below can be removed when there are no more Business (legacy) plans
  const buildOrScaleCanceled = billingInfo.hasCanceledSubscription;
  const scalePlanDowngraded =
    (billingInfo.planName === staticText.scalePlanName ||
      billingInfo.planName === staticText.businessPlanName) &&
    billingInfo.scheduledPlanName;

  if (billingInfo.planName !== staticText.freePlanName && !billingInfo.stripeCustomerId) {
    feedbackContent.push({
      key: 'stripeError',
      message: createBillingError(
        staticText.missingStripeCredentialsError,
        () => setCurrentModal('support'),
        'support.',
      ),
      type: 'alert',
    });
  }
  if (isUpdatingBillingInfo) {
    feedbackContent.push({
      key: 'isUpdatingBillingInfo',
      message: createBillingError(
        staticText.isUpdatingBillingInfo,
      ),
      type: 'alert',
    });
  }
  // Don't show banner for sales-led plans
  if (isSelfServe(featureSetId)) {
    if (buildOrScaleCanceled) {
      feedbackContent.push({
        key: 'buildOrScaleCanceled',
        message: createBillingError(
          staticText.planDowngraded(
            dateFormatter(billingInfo.planEndDate, true),
            displayNameFormatter(billingInfo.planName),
            'Free',
          ),
        ),
      });
    } else if (scalePlanDowngraded) {
      feedbackContent.push({
        key: 'scalePlanDowngraded',
        message: createBillingError(
          staticText.planDowngraded(
            dateFormatter(billingInfo.planEndDate, true),
            displayNameFormatter(billingInfo.planName),
            displayNameFormatter(staticText.buildPlanName),
          ),
        ),
      });
    }
  }
  if (usageError.message) {
    feedbackContent.push({
      key: 'usageError',
      message: createBillingError(
        usageError.message,
        // opens sales chat to upgrade
        openChat,
        staticText.contactUs,
      ),
      type: usageError.type,
    });
  }
  if (updateBillingInfoError) {
    feedbackContent.push({
      key: 'billingInfoError',
      message: createBillingError(
        staticText.updateBillingInfoError,
        () => setCurrentModal('support'),
        'support.',
      ),
    });
  }

  const trialActive = isInTrial(billingInfo.planName);

  if (billingInfoError) {
    return (
      <Box sx={{ maxWidth: theme.width.content, minWidth: '1200px' }}>
        <ApplicationHeader heading={staticText.heading} />
        <Feedback
          key={billingInfoError}
          message={
            createBillingError(staticText.billingInfoError,
            () => setCurrentModal('support'),
            'support.')
          }
          type="alert"
          additionalClassNames={['m-t-sm']}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ minWidth: '1200px' }}>
      <ApplicationHeader
        heading={staticText.heading}
        backLink={selectedApp && selectedApp.id ? ({
          path: `/apps/${selectedApp.id}`,
          text: `Back to ${selectedApp.name} application`,
        }) : {}}
      />
      {Object.keys(billingInfo).length > 0 ? (
        <React.Fragment>
          {feedbackContent && feedbackContent.map(({ key, message, type }, index) => (
            <Feedback
              key={key}
              message={message}
              type={type || 'warn'}
              additionalClassNames={[
                    'm-t-sm',
                    index + 1 === feedbackContent.length ? 'm-b-med' : 'm-b-sm',
                  ]}
            />
          ))}
          <Section>
            <CardSet cards={cardSetFormatter(billingInfo, isCurrentPlanLegacy)} />
            {billingInfo.planName !== staticText.freePlanName &&
                !trialActive && (
                  <div className="m-t-lg">
                    <InlineLinks text={staticText.seePastInvoices} />
                  </div>
            )}
          </Section>
          {isCurrentPlanLegacy && (
            <LegacyCard metronomePlanName={billingInfo.metronomePlanName || ''} />
          )}
          {isSelfServe(featureSetId) && billingInfo.delinquent && (
            <PaymentAlert
              organizationId={organizationId}
              billingInfo={billingInfo}
            />
          )}
          {showPlanTable && (
            <PlanComparisonTable
              baseVehicles={billingInfo.numberOfBaseVehicles}
              emailVerifiedAt={emailVerifiedAt}
              planName={billingInfo.planName}
              setCurrentModal={setCurrentModal}
              trialActive={trialActive}
              organizationId={organizationId}
              setHasHandledStripeRedirect={setHasHandledStripeRedirect}
              isCurrentPlanLegacy={isCurrentPlanLegacy}
            />
          )}
          {billingInfo.planName !== staticText.freePlanName && billingInfo.stripeCustomerId && (
            <PaymentInformation
              organizationId={organizationId}
              billingInfo={billingInfo}
              featureSetId={featureSetId}
            />
          )}
          {/* istanbul ignore next */}
          {currentModal && modalMap[currentModal]}
        </React.Fragment>
        ) : <Spinner size="page" />
      }
      {showFreeTrialModal &&
        <FreeTrialModal closeModal={closeFreeTrialModal} />
      }
    </Box>
  );
};

export default Billing;

Billing.propTypes = {
  actions: shape({
    closeFreeTrialModal: func.isRequired,
    updateBillingInfo: func.isRequired,
    setHasHandledStripeRedirect: func.isRequired,
  }).isRequired,
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
  billingInfo: shape({
    planName: string.isRequired,
  }).isRequired,
  billingInfoError: string.isRequired,
  isUpdatingBillingInfo: bool.isRequired,
  hasHandledStripeRedirect: bool.isRequired,
  organization: shape({
    featureSetId: string.isRequired,
    rolePermissions: arrayOf(string).isRequired,
  }).isRequired,
  session: shape({
    userContext: shape({
      email: string.isRequired,
      emailVerifiedAt: string,
    }).isRequired,
  }).isRequired,
  showFreeTrialModal: bool,
  updateBillingInfoError: string.isRequired,
  location: shape({
    search: string,
  }).isRequired,
};

Billing.defaultProps = {
  showFreeTrialModal: false,
};
