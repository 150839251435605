import { useState } from 'react';


const useMultiAppSelect = (appIds, initiallySelectedApps = []) => {
  const [selectedApps, setSelectedApps] = useState(initiallySelectedApps);
  const [appAccessError, setAppAccessError] = useState('');

  const allAppsSelected = selectedApps.length === appIds.length;

  const selectAllApps = () => {
    setSelectedApps(appIds);
  };
  const selectNoApps = () => {
    setSelectedApps([]);
  };
  const handleAppChange = (e) => {
    const selectedOptions = e.target.value;
    setSelectedApps(selectedOptions);
    setAppAccessError('');
  };

  const handleAppDelete = (targetId) => {
    const updatedApps = selectedApps.filter(appId => appId !== targetId);
    setSelectedApps(updatedApps);
  };

  const handleToggleAll = (option) => {
    if (option === 'all') {
      selectAllApps();
    } else if (option === 'none') {
      selectNoApps();
    } else if (allAppsSelected) {
      selectNoApps();
    } else {
      selectAllApps();
    }
    setAppAccessError('');
  };

  return {
    allAppsSelected,
    appAccessError,
    selectedApps,
    handleAppChange,
    handleAppDelete,
    handleToggleAll,
    setAppAccessError,
  };
};

export default useMultiAppSelect;
