import React from 'react';
import { arrayOf, func, string } from 'prop-types';

import { StyledChip, StyledInputAdornment, StyledTextField } from './styles';

import staticText from '../../localization/Components/multiEmailInput';

const MultiEmailInput = (props) => {
  const {
    emails,
    handleChange,
    handleDelete,
    handleKeyDown,
    inputError,
    inputValue,
  } = props;

  return (
    <StyledTextField
      error={Boolean(inputError)}
      helperText={inputError}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: emails.length > 0 ? (
          <StyledInputAdornment position="start">
            {emails.map((email, i) => (
              <StyledChip
                key={email}
                label={email}
                variant="filled"
                onDelete={handleDelete(i)}
              />
            ))}
          </StyledInputAdornment>
        ) : null,
      }}
      label={staticText.inputLabel}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={staticText.inputPlaceholder}
      value={inputValue}
      variant="outlined"
    />
  );
};

export default MultiEmailInput;

MultiEmailInput.propTypes = {
  emails: arrayOf(string).isRequired,
  handleChange: func.isRequired,
  handleDelete: func.isRequired,
  handleKeyDown: func.isRequired,
  inputError: string.isRequired,
  inputValue: string.isRequired,
};
