const staticText = {
  simulatorHeading: 'Vehicle Simulator',
  heading: 'Select vehicle state',
  description: 'Select a simulated vehicle state for your',
  description2: ' Vehicle state options vary by duration, location, and time spent in parked, driving or charging states (if applicable).',
  startOverLink: 'Choose another vehicle',
  trips: {
    US: {
      day_commute: {
        name: 'Day Commute',
        description: {
          ICE: 'The vehicle is driven to and from work along a typical one-way commute. No fuel up takes place on this trip.',
          BEV: 'The vehicle is driven to and from work along a typical one-way commute. The vehicle will be plugged in at the end of the day when it returns home.',
        },
        duration: '24 hours',
        pointA: 'South San Francisco, California',
        pointB: 'Mountain View, California',
        distance: '43 km / 60 minutes (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/commute/us/overview.png',
        stages: [
          {
            duration: 420,
            startFrameIndex: 0,
            startHour: '00:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (7 hours)',
              BEV: 'Plugged in (7 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 420,
            startHour: '07:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 43,
          },
          {
            duration: 510,
            startFrameIndex: 480,
            startHour: '08:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (8.5 hours)',
              BEV: 'Parked (8.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 990,
            startHour: '16:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 43,
          },
          {
            duration: 390,
            startFrameIndex: 1050,
            startHour: '17:30',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (6.5 hours)',
              BEV: 'Plugged in (6.5 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 1,
            startFrameIndex: 1440,
            startHour: '24:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: null,
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      short_trip: {
        name: 'Short Trip',
        description: {
          ICE: 'The vehicle is driven for a quick trip across town. No fuel up takes place on this trip.',
          BEV: 'The vehicle starts out plugged in and is then driven for a quick trip across town.',
        },
        duration: '4 hours',
        pointA: 'Mountain View, California',
        pointB: 'La Honda, California',
        distance: '40 km / 30 minutes (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/short/us/overview.png',
        stages: [
          {
            duration: 30,
            startFrameIndex: 0,
            startHour: '10:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (30 mins)',
              BEV: 'Plugged in (30 mins)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 30,
            startHour: '10:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 40,
          },
          {
            duration: 150,
            startFrameIndex: 60,
            startHour: '11:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (2.5 hours)',
              BEV: 'Parked (2.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 210,
            startHour: '13:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 40,
          },
          {
            duration: 1,
            startFrameIndex: 240,
            startHour: '14:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      long_trip: {
        name: 'Long Trip',
        description: {
          ICE: 'The vehicle is driven for a longer day trip. A fuel up will take place at the second destination before beginning the return drive home.',
          BEV: 'The vehicle is driven for a longer day trip. The vehicle will be plugged in to charge at the second destination before beginning the return drive home.',
        },
        duration: '14 hours',
        pointA: 'San Francisco, California',
        pointB: 'North Lake Tahoe, California',
        distance: '315 km / 3.5 hours (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/long/us/overview.png',
        stages: [
          {
            duration: 60,
            startFrameIndex: 0,
            startHour: '06:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (1 hour)',
              BEV: 'Parked (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 210,
            startFrameIndex: 60,
            startHour: '07:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (3.5 hours)',
              BEV: 'Driving (3.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 315,
          },
          {
            duration: 360,
            startFrameIndex: 270,
            startHour: '10:30',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (6 hours, includes fuel up)',
              BEV: 'Plugged in (6 hours)',
            },
            charge: true,
            fuel: true,
            distance: 0,
          },
          {
            duration: 210,
            startFrameIndex: 630,
            startHour: '16:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (3.5 hours)',
              BEV: 'Driving (3.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 315,
          },
          {
            duration: 1,
            startFrameIndex: 840,
            startHour: '20:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      parked: {
        name: 'Parked',
        description: {
          ICE: 'The vehicle remains parked at home or work. No fuel up will take place.',
          BEV: 'The vehicle remains parked and plugged in at home or work, and will be charged to full capacity.',
        },
        duration: '8 hours',
        pointA: 'Mountain View, California',
        pointB: null,
        distance: '0 km',
        imgSrc: '../../../assets/images/simulatorMaps/parked/us/a.png',
        stages: [{
          label: {
            ICE: 'Parked',
            HEV: 'Parked',
            BEV: 'Plugged in',
          },
          description: {
            ICE: 'Parked',
            BEV: 'Plugged in',
          },
          duration: 480,
          startFrameIndex: 0,
          startHour: '12:00',
          charge: true,
          fuel: false,
          distance: 0,
        },
        {
          duration: 1,
          startFrameIndex: 480,
          startHour: '20:00',
          label: {
            ICE: 'Parked',
            HEV: 'Parked',
            BEV: 'Parked',
          },
          description: null,
          charge: false,
          fuel: false,
          distance: 0,
        }],
      },
    },
    EU: {
      day_commute: {
        name: 'Day Commute',
        description: {
          ICE: 'The vehicle is driven to and from work along a typical one-way commute. No fuel up takes place on this trip.',
          BEV: 'The vehicle is driven to and from work along a typical one-way commute. The vehicle will be plugged in at the end of the day when it returns home.',
        },
        duration: '24 hours',
        pointA: 'Hampstead Heath, London',
        pointB: 'Central London',
        distance: '8 km / 1 hour (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/commute/eu/overview.png',
        stages: [
          {
            duration: 420,
            startFrameIndex: 0,
            startHour: '00:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (7 hours)',
              BEV: 'Plugged in (7 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 420,
            startHour: '07:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 8,
          },
          {
            duration: 510,
            startFrameIndex: 480,
            startHour: '08:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (8.5 hours)',
              BEV: 'Parked (8.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 990,
            startHour: '16:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 8,
          },
          {
            duration: 390,
            startFrameIndex: 1050,
            startHour: '17:30',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (6.5 hours)',
              BEV: 'Plugged in (6.5 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 1,
            startFrameIndex: 1440,
            startHour: '24:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: null,
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      short_trip: {
        name: 'Short Trip',
        description: {
          ICE: 'The vehicle is driven for a quick trip across town. No fuel up takes place on this trip.',
          BEV: 'The vehicle starts out plugged in and is then driven for a quick trip across town.',
        },
        duration: '4 hours',
        pointA: 'Buckingham Palace, London',
        pointB: 'The Angel JD Wetherspoon, London',
        distance: '5 km / 30 minutes (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/short/eu/overview.png',
        stages: [
          {
            duration: 30,
            startFrameIndex: 0,
            startHour: '10:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (30 mins)',
              BEV: 'Plugged in (30 mins)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 30,
            startHour: '10:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 5,
          },
          {
            duration: 150,
            startFrameIndex: 60,
            startHour: '11:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (2.5 hours)',
              BEV: 'Parked (2.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 210,
            startHour: '13:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 5,
          },
          {
            duration: 1,
            startFrameIndex: 240,
            startHour: '14:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      long_trip: {
        name: 'Long Trip',
        description: {
          ICE: 'The vehicle is driven for a longer day trip. A fuel up will take place at the second destination before beginning the return drive home.',
          BEV: 'The vehicle is driven for a longer day trip. The vehicle will be plugged in to charge at the second destination before beginning the return drive home.',
        },
        duration: '8 hours',
        pointA: 'Central London',
        pointB: 'Stonehenge',
        distance: '142 km / 2 hours (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/long/eu/overview.png',
        stages: [
          {
            duration: 60,
            startFrameIndex: 0,
            startHour: '08:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (1 hour)',
              BEV: 'Parked (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 120,
            startFrameIndex: 60,
            startHour: '09:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (2 hours)',
              BEV: 'Driving (2 hours)',
            },
            charge: false,
            fuel: false,
            distance: 142,
          },
          {
            duration: 180,
            startFrameIndex: 180,
            startHour: '11:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (3 hours, includes fuel up)',
              BEV: 'Plugged in (3 hours)',
            },
            charge: true,
            fuel: true,
            distance: 0,
          },
          {
            duration: 120,
            startFrameIndex: 360,
            startHour: '14:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (2 hours)',
              BEV: 'Driving (2 hours)',
            },
            charge: false,
            fuel: false,
            distance: 142,
          },
          {
            duration: 1,
            startFrameIndex: 480,
            startHour: '16:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      parked: {
        name: 'Parked',
        description: {
          ICE: 'The vehicle remains parked at home or work. No fuel up will take place.',
          BEV: 'The vehicle remains parked and plugged in at home or work, and will be charged to full capacity.',
        },
        duration: '8 hours',
        pointA: 'London',
        pointB: null,
        distance: '0 km',
        imgSrc: '../../../assets/images/simulatorMaps/parked/eu/a.png',
        stages: [
          {
            duration: 480,
            startFrameIndex: 0,
            startHour: '12:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 1,
            startFrameIndex: 480,
            startHour: '20:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: null,
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
    },
    CA: {
      day_commute: {
        name: 'Day Commute',
        description: {
          ICE: 'The vehicle is driven to and from work along a typical 30 minute one-way commute. No fuel up takes place on this trip.',
          BEV: 'The vehicle is driven to and from work along a typical 30 minute one-way commute. The vehicle will be plugged in at the end of the day when it returns home.',
        },
        duration: '24 hours',
        pointA: 'Lonsdale, Vancouver',
        pointB: 'Downtown Vancouver',
        distance: '11km / 1 hour (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/commute/ca/overview.png',
        stages: [
          {
            duration: 420,
            startFrameIndex: 0,
            startHour: '00:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (7 hours)',
              BEV: 'Plugged in (7 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 420,
            startHour: '07:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 11,
          },
          {
            duration: 510,
            startFrameIndex: 480,
            startHour: '08:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (8.5 hours)',
              BEV: 'Parked (8.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 60,
            startFrameIndex: 990,
            startHour: '16:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (1 hour)',
              BEV: 'Driving (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 11,
          },
          {
            duration: 390,
            startFrameIndex: 1050,
            startHour: '17:30',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (6.5 hours)',
              BEV: 'Plugged in (6.5 hours)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 1,
            startFrameIndex: 1440,
            startHour: '24:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: null,
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      short_trip: {
        name: 'Short Trip',
        description: {
          ICE: 'The vehicle is driven for a quick trip across town. No fuel up takes place on this trip.',
          BEV: 'The vehicle starts out plugged in and is then driven for a quick trip across town and back.',
        },
        duration: '4 hours',
        pointA: 'Central Toronto',
        pointB: 'Centennial Park, Toronto',
        distance: '23 km / 30 minutes (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/short/ca/overview.png',
        stages: [
          {
            duration: 30,
            startFrameIndex: 0,
            startHour: '10:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (30 mins)',
              BEV: 'Parked (30 mins)',
            },
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 30,
            startHour: '10:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 23,
          },
          {
            duration: 150,
            startFrameIndex: 60,
            startHour: '11:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (2.5 hours)',
              BEV: 'Parked (2.5 hours)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 30,
            startFrameIndex: 210,
            startHour: '13:30',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (30 mins)',
              BEV: 'Driving (30 mins)',
            },
            charge: false,
            fuel: false,
            distance: 23,
          },
          {
            duration: 1,
            startFrameIndex: 240,
            startHour: '14:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      long_trip: {
        name: 'Long Trip',
        description: {
          ICE: 'The vehicle is driven for a longer day trip. A fuel up will take place at the second destination before beginning the return drive home.',
          BEV: 'The vehicle is driven for a longer day trip. The vehicle will be plugged in at the second destination before beginning the return drive home.',
        },
        duration: '13 hours',
        pointA: 'Central Toronto',
        pointB: 'Algonquin Provincial Park',
        distance: '275 km / 3 hours (one way)',
        imgSrc: '../../../assets/images/simulatorMaps/long/ca/overview.png',
        stages: [
          {
            duration: 60,
            startFrameIndex: 0,
            startHour: '08:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked (1 hour)',
              BEV: 'Parked (1 hour)',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
          {
            duration: 180,
            startFrameIndex: 60,
            startHour: '09:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (3 hours)',
              BEV: 'Driving (3 hours)',
            },
            charge: false,
            fuel: false,
            distance: 275,
          },
          {
            duration: 360,
            startFrameIndex: 240,
            startHour: '12:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            description: {
              ICE: 'Parked (6 hours, includes fuel up)',
              BEV: 'Plugged in (6 hours)',
            },
            charge: true,
            fuel: true,
            distance: 0,
          },
          {
            duration: 180,
            startFrameIndex: 600,
            startHour: '18:00',
            label: {
              ICE: 'Driving',
              HEV: 'Driving',
              BEV: 'Driving',
            },
            description: {
              ICE: 'Driving (3 hours)',
              HEV: 'Driving (3 hours)',
              BEV: 'Driving (3 hours)',
            },
            charge: false,
            fuel: false,
            distance: 275,
          },
          {
            duration: 1,
            startFrameIndex: 780,
            startHour: '21:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
      parked: {
        name: 'Parked',
        description: {
          ICE: 'The vehicle remains parked at home or work. No fuel up will take place.',
          BEV: 'The vehicle remains parked and plugged in at home or work, and will be charged to full capacity.',
        },
        duration: '8 hours',
        pointA: 'Hamilton, Ontario',
        pointB: null,
        distance: '0 km',
        imgSrc: '../../../assets/images/simulatorMaps/parked/ca/a.png',
        stages: [
          {
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            duration: 480,
            description: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Plugged in',
            },
            startFrameIndex: 0,
            startHour: '12:00',
            charge: true,
            fuel: false,
            distance: 0,
          },
          {
            duration: 1,
            startFrameIndex: 480,
            startHour: '20:00',
            label: {
              ICE: 'Parked',
              HEV: 'Parked',
              BEV: 'Parked',
            },
            description: null,
            charge: false,
            fuel: false,
            distance: 0,
          },
        ],
      },
    },
  },

};

export default staticText;
