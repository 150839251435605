import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Modal from '../Modal';

import {
  ModalContent,
  ModalHeader,
  ModalBody,
  PasswordInput,
  ErrorText,
  ButtonContainer,
  VerifyButton,
  CancelButton,
} from './styles';

import staticText from '../../localization/Components/verifyPasswordModal';

const VerifyPasswordModal = ({
  verifyPasswordToChangeAccountInfoRequest,
  resetErrors,
  closeModal,
  verifyPasswordError,
}) => {
  const [password, setPassword] = useState('');

  const handleVerify = () => {
    verifyPasswordToChangeAccountInfoRequest({ oldPassword: password });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleVerify(event);
    }
  };

  const handleCancel = () => {
    closeModal();
    resetErrors();
  };

  const content = (
    <ModalContent>
      <ModalHeader>{staticText.textHeader}</ModalHeader>
      <ModalBody>{staticText.textBody}</ModalBody>
      <form onSubmit={e => e.preventDefault()}>
        <PasswordInput
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Password"
        />
        {verifyPasswordError && <ErrorText style={{ color: 'red' }}>{verifyPasswordError}</ErrorText>}
        <ButtonContainer>
          <VerifyButton onClick={handleVerify}>{staticText.verify}</VerifyButton>
          <CancelButton onClick={handleCancel}>{staticText.cancel}</CancelButton>
        </ButtonContainer>
      </form>
    </ModalContent>
  );

  return (
    <Modal
      title={staticText.modalTitle}
      content={content}
      toggleModal={() => {
        closeModal();
        resetErrors();
      }}
    />
  );
};

export default VerifyPasswordModal;

VerifyPasswordModal.propTypes = {
  verifyPasswordToChangeAccountInfoRequest: func.isRequired,
  resetErrors: func.isRequired,
  closeModal: func.isRequired,
  verifyPasswordError: string,
};

VerifyPasswordModal.defaultProps = {
  verifyPasswordError: '',
};
