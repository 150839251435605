import React from 'react';
import { object, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { withRouter } from 'react-router-dom';

import staticText from '../../localization/Application/application';
import image from '../../assets/images/platform-features.png';
import { Button, Container, Image, Link, Text } from './styles';

const handleLearnMoreClick = () => {
  reportToSegment(types.TRACK, eventNames.linkClicked, {
    section: 'article',
    style: 'cta primary',
    text: staticText.paintedDoor.learnMore,
    path: staticText.paintedDoor.learnMoreUrl,
  });
};

const handleUpgradeClick = ({ feature, history }) => {
  const billingPath = '/team/billing';
  reportToSegment(types.TRACK, eventNames.linkClicked, {
    section: 'article',
    style: 'cta primary',
    text: `${staticText.paintedDoor.upgradeNow} (${feature})`,
    path: billingPath,
  });
  history.push(billingPath);
};

const PaintedDoor = ({
  type, feature, history,
}) => {
  return (
    <Container>
      <Image src={image} alt="astronaut checking his phone next to a car" />
      {/* TODO: noData no longer used, verify and remove */}
      {type === 'noData' ? (
        <React.Fragment>
          <Text>{staticText.paintedDoor.noData}</Text>
          <Link
            id="learn-more"
            href={staticText.paintedDoor.learnMoreUrl[feature]}
            onClick={handleLearnMoreClick}
          >
            {staticText.paintedDoor.learnMore}
          </Link>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text>{staticText.paintedDoor.upgradeMessage[feature]}</Text>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpgradeClick({ feature, history })}
            id="upgrade-now"
          >
            {staticText.paintedDoor.upgradeButton}
          </Button>
        </React.Fragment>
      )}
    </Container>
  );
};

export default withRouter(PaintedDoor);

PaintedDoor.propTypes = {
  feature: string,
  type: string,
  history: object.isRequired,
};

PaintedDoor.defaultProps = {
  feature: 'logs',
  type: 'free',
};
