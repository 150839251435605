import React from 'react';
import { func, string, object } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, useTheme } from '@mui/material';
import { withRouter } from 'react-router-dom';

import Modal from '../Modal';
import { Button, ButtonContainer, Image, StyledButton, Text, UpgradeTitle } from './styles';
import staticText from '../../localization/Components/upsellModal';
import image from '../../assets/images/free.png';
import { featureSetName } from '../../services/featureGate';
import { openChat } from '../../services/front/front';

const handleUpgradeClick = ({
  feature, history, toggleModal,
}) => {
  const billingPath = '/team/billing';
  reportToSegment(types.TRACK, eventNames.linkClicked, {
    section: 'article',
    style: 'cta primary',
    text: `upsell (${feature})`,
    path: billingPath,
  });
  history.push(billingPath);
  toggleModal();
};

const UpsellModal = ({
  toggleModal, feature, history, featureSetId,
}) => {
  const theme = useTheme();

  const planName = featureSetName(featureSetId);
  const highestPlan = planName === 'enterprise';

  const handleContactUs = () => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'chat',
      text: `[${feature}] ${staticText.reachedLimit.primaryCta}`,
    });
    openChat();
    toggleModal();
  };

  const content = (
    <Box textAlign="center" marginTop={theme.spacing(1)} marginBottom={theme.spacing(3)}>
      {highestPlan
      ?
        <React.Fragment>
          <UpgradeTitle>{staticText.reachedLimit.title}</UpgradeTitle>
          <Image src={image} alt="shooting star" />
          <Text>{staticText.reachedLimit.body(feature)}</Text>
          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={handleContactUs}
              id="contact-us"
            >
              {staticText.reachedLimit.primaryCta}
            </Button>
            <Button
              fontSize="1.125rem"
              onClick={toggleModal}
              id="close"
              variant="outlined"
            >
              {staticText.reachedLimit.secondaryCta}
            </Button>
          </ButtonContainer>
        </React.Fragment>
      :
        <React.Fragment>
          <UpgradeTitle>{staticText.title}</UpgradeTitle>
          <Image src={image} alt="shooting star" />
          <Text>{staticText[feature].body}</Text>
          <ButtonContainer>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => handleUpgradeClick({
                feature, history, toggleModal,
              })}
              id="upgrade-now"
            >
              {staticText.upgrade}
            </StyledButton>
            <Button
              fontSize="1.125rem"
              onClick={toggleModal}
              id="decline"
              variant="outlined"
            >
              {staticText.decline}
            </Button>
          </ButtonContainer>
        </React.Fragment>
    }
    </Box>
  );

  return <Modal toggleModal={toggleModal} content={content} />;
};

UpsellModal.propTypes = {
  toggleModal: func.isRequired,
  feature: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: object.isRequired,
  featureSetId: string.isRequired,
};

export default withRouter(UpsellModal);
