import { arrayOf, shape, string } from 'prop-types';

export const defaultProps = {
  name: null,
  developerName: null,
  description: null,
  makes: [],
  logo: null,
  redirectUris: [],
  permissions: [],
  createdAt: null,
};

export default {
  name: string,
  developerName: string,
  description: string,
  makes: arrayOf(string),
  logo: string,
  redirectUris: arrayOf(string),
  createdAt: string,
  permissions: arrayOf(shape({
    name: string.isRequired,
    displayName: string.isRequired,
    description: string.isRequired,
  })),
};
