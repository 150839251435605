const staticText = {
  title: "Let's get to know you",
  subtitle: 'This information will help us tailor your onboarding experience.',
  fields: [
    {
      name: 'company',
      label: 'Company',
      required: false,
    },
    {
      name: 'industry',
      label: 'Industry',
      type: 'select',
      options: [
        'Auto Care Services',
        'Auto Insurance',
        'Car Rental and Ridesharing',
        'DERMs, VPP & Energy',
        'Education',
        'EV Charging Networks and Operators',
        'Financial Technology',
        'Fleet Management',
        'Healthcare & Medical Technology',
        'Hobbyist or Personal Use',
        'Information Technology',
        'Roadside Assistance',
        'Software Development',
        'Other',
      ],
      showCustomInput: true,
      required: true,
    },
    {
      name: 'jobTitle',
      label: 'Job title',
      type: 'select',
      options: [
        'Customer Success or Support',
        'Development or Engineering',
        'Driver',
        'Founder or Owner',
        'Hobbyist or Personal Use',
        'Manager',
        'Product Management or Design',
        'Sales',
        'Student or Educator',
        'Other',
      ],
      showCustomInput: true,
      required: true,
    },
  ],
  button: 'Next step',
  skip: 'Skip for now',
};

export default staticText;
