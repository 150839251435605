import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(4),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  '&:last-of-type': {
    marginLeft: theme.spacing(2),
  },
}));
