import React from 'react';
import { string } from 'prop-types';

import './EmptyState.scss';

const EmptyState = ({ description }) => (
  <div className="empty-state m-b-lg">
    <div className="med-11">{description}</div>
  </div>
);

EmptyState.propTypes = {
  description: string.isRequired,
};

export default EmptyState;
