const languageCodes = {
  ENGLISH_US: 'en-US',
  DANISH: 'da',
  GERMAN: 'de',
  SPANISH_SPAIN: 'es-ES',
  SPANISH_LAT_AM: 'es-419',
  FRENCH_CANADA: 'fr-CA',
  ITALIAN: 'it',
  DUTCH: 'nl',
  NORWEGIAN: 'no',
  SWEDISH: 'sv',
};

const languageOptions = {
  [languageCodes.ENGLISH_US]: 'English',
  [languageCodes.DANISH]: 'Danish',
  [languageCodes.GERMAN]: 'German',
  [languageCodes.SPANISH_SPAIN]: 'Spanish (Spain)',
  [languageCodes.SPANISH_LAT_AM]: 'Spanish (Latin America)',
  [languageCodes.FRENCH_CANADA]: 'French',
  [languageCodes.ITALIAN]: 'Italian',
  [languageCodes.DUTCH]: 'Dutch',
  [languageCodes.NORWEGIAN]: 'Norwegian',
  [languageCodes.SWEDISH]: 'Swedish',
};

// eslint-disable-next-line import/prefer-default-export
export const getLanguageDisplayName = languageCode => languageOptions[languageCode];
