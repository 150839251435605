import {
  alertIcon,
  batteryCapacityIcon,
  batteryLevelIcon,
  chargeIcon,
  chargeLocationsIcon,
  chargeRecordsIcon,
  compassIcon,
  controlClimateIcon,
  engineOilIcon,
  extendedVehicleInfoIcon,
  fuelIcon,
  locationIcon,
  lockUnlockIcon,
  navigationIcon,
  odometerIcon,
  recordsIcon,
  smartcarIcon,
  speedometerIcon,
  startIgnitionIcon,
  startStopChargeIcon,
  temperatureIcon,
  tirePressureIcon,
  vehicleInfoIcon,
  vehicleSecurityIcon,
  vinIcon,
} from '../../assets/icons';

export const scopes = {
  control_charge: {
    label: 'Control charge',
    iconSrc: startStopChargeIcon,
  },
  control_climate: {
    label: 'Manage climate settings',
    iconSrc: controlClimateIcon,
  },
  control_ignition: {
    label: 'Start & stop ignition',
    iconSrc: startIgnitionIcon,
  },
  control_navigation: {
    label: 'Control navigation',
    iconSrc: navigationIcon,
  },
  control_security: {
    label: 'Lock & unlock',
    iconSrc: lockUnlockIcon,
  },
  control_trunk: {
    label: 'Open & close trunk',
    iconSrc: smartcarIcon,
  },
  read_alerts: {
    label: 'Vehicle alerts',
    iconSrc: alertIcon,
  },
  read_battery: {
    label: 'EV battery level',
    iconSrc: batteryLevelIcon,
  },
  read_charge: {
    label: 'Charging status',
    iconSrc: chargeIcon,
  },
  read_charge_locations: {
    label: 'Charging locations',
    iconSrc: chargeLocationsIcon,
  },
  read_charge_records: {
    label: 'Charging records',
    iconSrc: chargeRecordsIcon,
  },
  read_climate: {
    label: 'Climate status',
    iconSrc: controlClimateIcon,
  },
  read_compass: {
    label: 'Compass',
    iconSrc: compassIcon,
  },
  read_engine_oil: {
    label: 'Engine oil life',
    iconSrc: engineOilIcon,
  },
  read_extended_vehicle_info: {
    label: 'Extended vehicle information',
    iconSrc: extendedVehicleInfoIcon,
  },
  read_fuel: {
    label: 'Fuel tank level',
    iconSrc: fuelIcon,
  },
  read_location: {
    label: 'Location',
    iconSrc: locationIcon,
  },
  read_odometer: {
    label: 'Odometer',
    iconSrc: odometerIcon,
  },
  read_security: {
    label: 'Lock status',
    iconSrc: vehicleSecurityIcon,
  },
  read_service_history: {
    label: 'Service history',
    iconSrc: recordsIcon,
  },
  read_speedometer: {
    label: 'Speedometer',
    iconSrc: speedometerIcon,
  },
  read_thermometer: {
    label: 'Interior & exterior thermometer',
    iconSrc: temperatureIcon,
  },
  read_tires: {
    label: 'Tire pressure',
    iconSrc: tirePressureIcon,
  },
  read_vehicle_info: {
    label: 'Make, model, year',
    iconSrc: vehicleInfoIcon,
  },
  read_vin: {
    label: 'VIN',
    iconSrc: vinIcon,
  },
  default: {
    iconSrc: smartcarIcon,
  },
};

export const defaultEndpoints = [
  scopes.read_charge,
  scopes.read_battery,
  {
    label: 'Battery capacity',
    iconSrc: batteryCapacityIcon,
  },
  scopes.read_fuel,
  scopes.read_location,
  scopes.read_odometer,
  scopes.control_charge,
  scopes.read_vin,
  scopes.read_tires,
  scopes.control_security,
];
