import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { Box } from '@mui/material';
import {
  Timeline as MuiTimeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { ExitToAppRounded, FlagRounded, WebRounded, SportsScoreRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { ConnectEventCard } from '../../../../../../../../components';

import { BaseTimelineConnector, BaseTimelineDot, CustomDot, CustomDotContainer } from './styles';

import { getRowIconAndStyle } from '../../util';
import { eventProps } from '../../../../propTypes';

const getTimelineIcon = (eventName, lastItem) => {
  const theme = useTheme();
  if (lastItem) {
    return (
      <BaseTimelineDot
        sx={{
          margin: '0px 1px',
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
          opacity: '50%',
        }}
      >
        <FlagRounded fontSize="small" color="primary" />
      </BaseTimelineDot>
    );
  }
  if (eventName === 'smartcar_grant:allowed') {
    return (
      <BaseTimelineDot sx={{ backgroundColor: theme.palette.grey[100] }}>
        <SportsScoreRounded fontSize="small" color="success" />
      </BaseTimelineDot>
    );
  }
  if (eventName === 'smartcar_grant:denied'
    || (eventName && eventName.split(':')[1] === 'returned_to_application')) {
    return (
      <BaseTimelineDot sx={{ backgroundColor: theme.palette.grey[100] }}>
        <ExitToAppRounded fontSize="small" color="error" />
      </BaseTimelineDot>
    );
  }
  if (eventName && eventName.split(':')[1] === 'rendered') {
    return (
      <BaseTimelineDot>
        <WebRounded color="primary" fontSize="small" sx={{ opacity: '50%' }} />
      </BaseTimelineDot>
    );
  }
  return (
    <BaseTimelineDot>
      <CustomDotContainer>
        <CustomDot />
      </CustomDotContainer>
    </BaseTimelineDot>
  );
};

const Timeline = ({ sessionDetails }) => {
  const theme = useTheme();
  return (
    <MuiTimeline sx={{ padding: theme.spacing(0, 1.5), margin: '6px 0px 0px 0px' }}>
      {sessionDetails.map((timelineRow, i) => {
        const statusIcon = getRowIconAndStyle(timelineRow.eventName).Icon;
        return (
          <TimelineItem
            key={timelineRow.id}
            sx={{
              '&::before': {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineSeparator>
              <BaseTimelineConnector transparent={i === 0} />
              {getTimelineIcon(timelineRow.eventName, i === sessionDetails.length - 1)}
              <BaseTimelineConnector transparent={i === sessionDetails.length - 1} />
            </TimelineSeparator>
            <TimelineContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <ConnectEventCard row={timelineRow} displayAvatar={false} />
                {statusIcon}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </MuiTimeline>
  );
};

export default Timeline;

Timeline.propTypes = {
  sessionDetails: arrayOf(shape(eventProps)).isRequired,
};
