import React from 'react';
import { pickersLayoutClasses } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { arrayOf, bool, func, instanceOf, oneOfType, string } from 'prop-types';
import moment from 'moment-timezone';

import { ActionList } from './components';
import { StyledDateRangePicker } from './styles';

/* istanbul ignore next */
const DateRangePicker = ({
  darkerBorder,
  label,
  minDate,
  maxDate,
  onAccept,
  onChange,
  popupMessage,
  value,
  fullWidth,
}) => {
  const [start, end] = value;
  const formattedValue = [start ? moment(start).utc() : null, end ? moment(end).utc() : null];

  return (
    <StyledDateRangePicker
      darkerBorder={darkerBorder}
      label={label}
      minDate={minDate && moment(minDate).utc()}
      maxDate={maxDate && moment(maxDate).utc()}
      onChange={onChange}
      onAccept={onAccept}
      format="YYYY/MM/DD"
      timezone="UTC"
      slotProps={{
        layout: {
          sx: {
            [`.${pickersLayoutClasses.actionBar}`]: {
              gridColumn: 2,
              gridRow: 3,
              paddingX: 2,
              paddingBottom: 2,
            },
          },
        },
      }}
      slots={{
        field: SingleInputDateRangeField,
        actionBar: props => <ActionList {...props} message={popupMessage} />,
      }}
      value={formattedValue}
      sx={{ width: fullWidth ? '100%' : '255px', ...(fullWidth && { maxWidth: '255px' }) }}
    />
  );
};

export default DateRangePicker;

DateRangePicker.propTypes = {
  darkerBorder: bool,
  label: string,
  minDate: oneOfType([string, instanceOf(moment)]),
  maxDate: oneOfType([string, instanceOf(moment)]),
  onAccept: func,
  onChange: func,
  popupMessage: string,
  value: arrayOf(instanceOf(moment)).isRequired,
  fullWidth: bool,
};

DateRangePicker.defaultProps = {
  darkerBorder: false,
  label: null,
  minDate: null,
  maxDate: null,
  onAccept: null,
  onChange: null,
  popupMessage: '',
  fullWidth: false,
};

