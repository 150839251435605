import React from 'react';
import { arrayOf, bool, func, oneOfType, shape, string } from 'prop-types';

import Dropdown from '../Dropdown';

const Frequency = ({
  inputName, children, handleChange, inputValues, disabled,
}) => {
  const handleFrequencyChange = (event) => {
    const { key, value } = event;
    handleChange({ key: inputName, value: { [key]: value } });
  };

  const handleRateChange = (event) => {
    const { key, value } = event;
    handleChange({ key: inputName, value: { ...inputValues, [key]: value } });
  };

  const renderRateDropdown = () => {
    if (!inputValues.frequency || !children) return null;

    const rateOptions = children.find(el => el.name === inputValues.frequency);
    if (!rateOptions || !rateOptions.children) return null;
    return (
      <Dropdown
        inputName={inputValues.frequency}
        handleChange={handleRateChange}
        inputValue={inputValues[inputValues.frequency]}
        disabled={disabled}
      >
        {rateOptions.children}
      </Dropdown>
    );
  };

  return (
    <div className="flex justify-space-between">
      <Dropdown
        inputValue={inputValues.frequency}
        inputName={inputName}
        handleChange={handleFrequencyChange}
        disabled={disabled}
      >
        {children}
      </Dropdown>
      {renderRateDropdown()}
    </div>
  );
};

export default Frequency;

Frequency.propTypes = {
  inputName: string.isRequired,
  children: arrayOf(
    shape({
      name: string,
    }),
  ),
  handleChange: func.isRequired,
  inputValues: oneOfType([
    string,
    shape({
      frequency: string,
    }),
  ]),
  disabled: bool.isRequired,
};

Frequency.defaultProps = {
  inputValues: {},
  children: [],
};
