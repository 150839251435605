import React, { useEffect, useState } from 'react';

import { bool, func, shape, string } from 'prop-types';

import { Box, Button, Chip, Typography, styled } from '@mui/material';

import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { MfaChallenge, MfaChallengeInput } from '../';
import { Toast } from '../../../../../../components';
import staticText from '../../../../../../localization/Authentication/userSecurity';
import { Section } from '../../../../styles';

const EnabledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root': {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '4px',
    borderColor: theme.palette.grey[200],
    textTransform: 'uppercase',
    fontSize: '12px',
  },
}));

const MfaSettings = ({
  actions: {
    mfaEnrollRequest,
    mfaVerificationRequest,
    mfaNewChallengeRequest,
  },
  mfaObj,
  mfaEnabled,
  mfaInProgress,
  mfaError,
}) => {
  const [showMfaChallenge, setShowMfaChallenge] = useState(false);

  const mfaChallengeButton = mfaEnabled ?
    staticText.mfaSetup.disable : staticText.mfaSetup.enroll;

  useEffect(() => {
    if (showMfaChallenge) {
      if (mfaEnabled) {
        Toast(staticText.mfaSetup.enabledToast, 'success');
      } else {
        Toast(staticText.mfaSetup.disabledToast, 'success');
      }
      setShowMfaChallenge(false);
    }
  }, [mfaEnabled]);

  const prepareMfaChallenge = (e) => {
    e.preventDefault();
    if (!showMfaChallenge) {
      setShowMfaChallenge(true);

      if (!mfaEnabled) {
        mfaEnrollRequest();
        reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Started MFA Enrollment' });
      } else {
        mfaNewChallengeRequest();
        reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Started Disable MFA' });
      }
    } else {
      setShowMfaChallenge(false);
    }
  };

  return (
    <Box >
      <Section>
        <Typography variant="h3" sx={{ mb: 3 }}>{staticText.mfaSetup.title}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="subTitle1" sx={{ mr: 2 }}>{staticText.mfaSetup.method}</Typography>
            {mfaEnabled && <EnabledChip variant="outlined" color="success" size="small" label="Enabled" />}
          </Box>
          <Button
            id="startMFAChallenge"
            variant="outlined"
            color="primary"
            type="button"
            onClick={prepareMfaChallenge}
            disabled={mfaInProgress}
          >
            {showMfaChallenge ? staticText.mfaSetup.cancel : mfaChallengeButton}
          </Button>
        </Box>
        {showMfaChallenge && (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            {mfaEnabled &&
            <Typography variant="bold" sx={{ mt: 4 }}>{staticText.mfaSetup.disableMfaDescription}</Typography>
            }
            {!mfaEnabled &&
              <MfaChallenge mfaObj={mfaObj} />
            }
            <MfaChallengeInput
              verifyCallback={mfaVerificationRequest}
              mfaInProgress={mfaInProgress}
              mfaObj={mfaObj}
              mfaError={mfaError}
              mfaSaveFactorId={!mfaEnabled}
            />
          </Box>
        )}
      </Section>
    </Box>
  );
};

export default MfaSettings;

MfaSettings.propTypes = {
  actions: shape({
    mfaEnrollRequest: func.isRequired,
    mfaVerificationRequest: func.isRequired,
  }).isRequired,
  mfaObj: shape({
    challengeId: string,
    factorId: string,
    qrCode: string,
    secretKey: string,
  }),
  mfaEnabled: bool.isRequired,
  mfaInProgress: bool.isRequired,
  mfaError: string,
};

MfaSettings.defaultProps = {
  mfaError: '',
  mfaObj: {
    challengeId: null,
    factorId: null,
    qrCode: null,
    secretKey: null,
  },
};

