import { useRef } from 'react';
import { isEqual } from 'lodash';

const useDeepEqual = (val) => {
  const ref = useRef();

  if (!isEqual(val, ref.current)) {
    ref.current = val;
  }

  return ref.current;
};

export default useDeepEqual;
