import { Box, Typography } from '@mui/material';
import { shape, string } from 'prop-types';
import React from 'react';

import staticText from '../../../../../../localization/Authentication/userSecurity';

const MfaChallenge = ({
  mfaObj,
}) => {
  return (
    <Box>
      <Typography variant="bold" sx={{ mt: 4 }}>{staticText.mfaSetup.scanCode}</Typography>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ mb: 3, mt: 3 }}
          height="160px"
          width="160px"
        >
          {mfaObj.qrCode &&
          <img
            src={`${mfaObj.qrCode}`}
            style={{ width: '160px', height: '160px' }}
            alt="qr code for multi factor identification"
          />}
        </Box>
        {mfaObj.secretKey &&
        <Box>
          <Typography variant="body1">{staticText.mfaSetup.manualCode}</Typography>
          <Typography variant="bold">{mfaObj.secretKey}</Typography>
        </Box>
        }
      </Box>
    </Box>
  );
};

export default MfaChallenge;

MfaChallenge.propTypes = {
  mfaObj: shape({
    challengeId: string,
    factorId: string,
    qrCode: string,
    secretKey: string,
  }),
};

MfaChallenge.defaultProps = {
  mfaObj: {
    challengeId: null,
    factorId: null,
    qrCode: null,
    secretKey: null,
  },
};

