import { dashboardRole } from '../../../utils/roles';

/* istanbul ignore next */
const orderedRoles = [
  dashboardRole.OWNER,
  dashboardRole.ADMIN,
  dashboardRole.EDITOR,
  dashboardRole.VIEWER,
];

const sortRoles = (a, b) => {
  return orderedRoles.indexOf(a) - orderedRoles.indexOf(b);
};

export default sortRoles;
