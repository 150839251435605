import _ from 'lodash';
import validator from 'validator';
import { UNITS, TYPES } from '../../utils/webhookTypes';

const urlValidator = (url) => {
  const isHTTPS = {
    protocols: ['https'],
    require_protocol: true,
  };

  return validator.isURL(url, isHTTPS);
};

const nameValidator = s => !!s && s.length >= 3 && s.length <= 30;
const callbackUriValidator = s => !!s && s.length > 0 && s.length <= 255 && urlValidator(s);
const typeValidator = type => !!type && TYPES[type];

const validators = {
  type: typeValidator,
  name: nameValidator,
  callbackUri: callbackUriValidator,
  frequency: (obj) => {
    if (_.isEmpty(obj)) {
      return false;
    }
    switch (obj.frequency) {
      case 'hourly':
        return !!obj.hourly;
      case 'daily':
        return !!obj.daily;
      case 'weekly':
        return !!obj.weekly;
      case 'monthly':
        return !!obj.monthly;
      default:
        return false;
    }
  },
  events: keys => !!keys && keys.length > 0,
  units: units =>
    !!units &&
      (units.toLowerCase() === UNITS.IMPERIAL ||
        units.toLowerCase() === UNITS.METRIC),
  permissions: keys => !!keys && keys.length > 0,
};

export default { validators };

