import { Button as MuiButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxSizing: 'border-box',
  width: '100%',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Message = styled(Typography)({
  // to maintain the height of the banner when there is no button
  // button height is 32px + 2px for the border
  height: '34px',
  display: 'flex',
  alignItems: 'center',
});

export const Button = styled(MuiButton)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  padding: '4px 8px',
  border: `1px solid ${theme.palette.common.white}`,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
}));
