/* istanbul ignore file */
import React from 'react';

const HevEngineIcon = () => {
  return (
    <svg
      className="engine-type"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.50109 0.625C4.30109 0.625 0.626089 4.0025 0.626089 8.5C0.626089 10.355 1.27359 12.0613 2.34984 13.4088L0.879839 14.8788C0.538589 15.22 0.538589 15.7713 0.879839 16.1125C1.22109 16.4538 1.77234 16.4538 2.11359 16.1125L3.58359 14.6425C4.93984 15.7275 6.64609 16.375 8.50109 16.375C10.5136 16.375 12.5348 15.605 14.0661 14.065C15.6061 12.5338 16.3761 10.5125 16.3761 8.5V2.375C16.3761 1.4125 15.5886 0.625 14.6261 0.625H8.50109ZM11.8523 8.7275L7.33734 12.7787C7.19734 12.91 6.97859 12.9012 6.84734 12.77C6.72484 12.6475 6.70734 12.455 6.81234 12.315L8.94734 9.40125L5.40359 9.05125C5.01859 9.01625 4.85234 8.535 5.14109 8.2725L9.65609 4.22125C9.79609 4.09 10.0148 4.09875 10.1461 4.23C10.2686 4.3525 10.2861 4.545 10.1811 4.685L8.04609 7.59875L11.5898 7.94875C11.9836 7.98375 12.1411 8.465 11.8523 8.7275Z" fill="#6D6D6D" />
    </svg>
  );
};

export default HevEngineIcon;
