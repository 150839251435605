import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, Typography, useTheme, styled } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import PermissionGate from '../../../../components/PermissionGate';
import { HomeLink } from './styles';

const Icon = styled('span')(() => ({
  marginTop: '-3px',
  marginLeft: '-4px',
  lineHeight: '0',
}));

const ApplicationHeader = ({
  backLink,
  heading,
  cta,
  dashboardPermission,
}) => {
  const theme = useTheme();
  const getIcon = () => {
    if (!(cta.plusIcon || cta.disconnectIcon)) return null;

    return (
      <Icon sx={{ marginRight: '4px' }}>
        {(cta.plusIcon && <AddRoundedIcon fontSize="small" />)
          || (cta.disconnectIcon && <LinkOffIcon fontSize="small" />)
        }
      </Icon>
    );
  };

  return (
    <React.Fragment>
      {backLink && backLink.path && (
        <HomeLink>
          <Link to={backLink.path} onClick={backLink.handleClick}>
            <Typography><ArrowBackRoundedIcon fontSize="16px" />{backLink.text}</Typography>
          </Link>
        </HomeLink>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={theme.spacing(2)}
      >
        <Typography variant="h1" minHeight={theme.spacing(6)}>{heading}</Typography>
        {cta && cta.text && cta.onclick && (
          <div>
            <PermissionGate dashboardPermission={dashboardPermission}>
              <Button
                id={`${heading.toLowerCase().replace(' ', '-')}-header-cta`}
                onClick={cta.onclick}
                variant={cta.variant || 'outlined'}
                display="flex"
                disabled={cta.disabled}
              >
                {getIcon()}
                {cta.text}
              </Button>
            </PermissionGate>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ApplicationHeader;

ApplicationHeader.propTypes = {
  backLink: shape({
    path: string,
    handleClick: func,
    text: string,
  }),
  heading: string.isRequired,
  cta: shape({
    text: string.isRequired,
    onclick: func.isRequired,
    variant: string,
    plusIcon: bool,
    disconnectIcon: bool,
    disabled: bool,
  }),
  dashboardPermission: string,
};

ApplicationHeader.defaultProps = {
  backLink: null,
  cta: null,
  dashboardPermission: '',
};
