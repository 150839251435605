import React from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';

const SelectIcon = () => {
  return (
    <ExpandMoreRounded
      sx={{
        position: 'absolute',
        right: '10px',
        userSelect: 'none',
        pointerEvents: 'none',
      }}
      color="primary"
    />
  );
};

export default SelectIcon;
