/* istanbul ignore file */
import React from 'react';

const CustomizationsIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.83092 3.60663C6.34127 3.60663 5 4.74235 5 6.302V19.865C5 21.4246 6.34129 22.5603 7.83092 22.5603H16.9856C18.4752 22.5603 19.8165 21.4247 19.8165 19.865V16.6066H17.8165V19.865C17.8165 20.1779 17.5184 20.5603 16.9856 20.5603H7.83092C7.29817 20.5603 7 20.1779 7 19.865V6.302C7 5.98899 7.29819 5.60663 7.83092 5.60663H16.9856C17.5183 5.60663 17.8165 5.98901 17.8165 6.302V6.60663H19.8165V6.302C19.8165 4.74233 18.4752 3.60663 16.9856 3.60663H7.83092Z"
        fill="#4F4F4F"
      />
      <path
        d="M12.4082 16.322C11.856 16.322 11.4082 16.7697 11.4082 17.322C11.4082 17.8743 11.856 18.322 12.4082 18.322H12.4165C12.9688 18.322 13.4165 17.8743 13.4165 17.322C13.4165 16.7697 12.9688 16.322 12.4165 16.322H12.4082Z"
        fill="#4F4F4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2114 11.7029L21.875 12.2081C22.0045 12.3044 22.0368 12.4808 21.9559 12.6171L21.2681 13.8041C21.2034 13.9084 21.0982 13.9645 20.9849 13.9645C20.9444 13.9645 20.904 13.9565 20.8635 13.9404L20.0948 13.6357L20.0219 13.6838C20.0098 13.6918 19.9956 13.6978 19.9815 13.7038C19.9673 13.7099 19.9531 13.7159 19.941 13.7239L19.8196 14.5339C19.7954 14.6863 19.6578 14.8066 19.496 14.8066H18.1122C17.9504 14.8066 17.8128 14.6943 17.7886 14.5339L17.6672 13.7239C17.6024 13.6918 17.5701 13.6758 17.5053 13.6357L16.7366 13.9324C16.6961 13.9485 16.6557 13.9565 16.6152 13.9565C16.5019 13.9565 16.3886 13.9003 16.332 13.7961L15.6442 12.6091C15.5633 12.4728 15.5956 12.2963 15.7251 12.2001L16.3725 11.6948V11.5104L15.7251 11.0051C15.5956 10.9089 15.5633 10.7325 15.6442 10.5961L16.332 9.40916C16.3967 9.3049 16.5019 9.24876 16.6152 9.24876C16.6557 9.24876 16.6961 9.25678 16.7366 9.27282L17.5053 9.57757L17.5782 9.52945C17.5903 9.52143 17.6045 9.51542 17.6186 9.50941C17.6328 9.50339 17.6469 9.49738 17.6591 9.48936L17.7724 8.67934C17.7966 8.52696 17.9342 8.40666 18.096 8.40666H19.4798C19.6416 8.40666 19.7792 8.52696 19.8034 8.69538L19.9248 9.50539C19.9827 9.53408 20.0083 9.54995 20.0593 9.58166L20.0786 9.59361L20.8473 9.28886C20.8878 9.27282 20.9282 9.2648 20.9687 9.2648C21.082 9.2648 21.1953 9.32094 21.2519 9.4252L21.9397 10.6122C22.0206 10.7485 21.9883 10.9249 21.8588 11.0212L21.2114 11.5264V11.7029ZM17.5782 11.6146C17.5782 12.2803 18.1203 12.8176 18.792 12.8176C19.4636 12.8176 20.0058 12.2803 20.0058 11.6146C20.0058 10.949 19.4636 10.4116 18.792 10.4116C18.1203 10.4116 17.5782 10.949 17.5782 11.6146Z"
        fill="#4F4F4F"
      />
    </svg>
  );
};

export default CustomizationsIcon;
