import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import RegisterApplication from './RegisterApplication';

import { actions as applicationsActions } from '../../services/applications/reducers';
import { selectors as orgsSelectors } from '../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    organization: orgsSelectors.getCurrentOrganization(state),
  };
}

function mapDispatchToProps(dispatch) {
  const { registerApplication } = applicationsActions;
  return {
    actions: bindActionCreators({ registerApplication }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterApplication);
