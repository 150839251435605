import React from 'react';
import { func, bool, node } from 'prop-types';

const staticText = {
  buttonVerify: 'Verify this webhook',
  buttonExit: 'Do this later',
};

const Unverified = ({
  toggleModal,
  handleWebhookVerify,
  isVerifyingWebhook,
  verifyAttempted,
  feedback,
  sharedTitle,
  sharedLink,
}) => {
  return (
    <div>
      {(verifyAttempted && !isVerifyingWebhook) && feedback}
      {sharedTitle}
      {sharedLink}
      <div className="flex-space-between m-t-lg">
        <button className="button button-secondary button-med-long resend" onClick={toggleModal}>
          {staticText.buttonExit}
        </button>
        <button
          className={`button button-med-long done ${isVerifyingWebhook && 'spin'}`}
          onClick={() => handleWebhookVerify(staticText.buttonVerify)}
        >
          {staticText.buttonVerify}
        </button>
      </div>
    </div>
  );
};

export default Unverified;

Unverified.propTypes = {
  toggleModal: func.isRequired,
  handleWebhookVerify: func.isRequired,
  isVerifyingWebhook: bool.isRequired,
  verifyAttempted: bool.isRequired,
  feedback: node.isRequired,
  sharedTitle: node.isRequired,
  sharedLink: node.isRequired,
};
