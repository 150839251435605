import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import GoogleOAuth from './GoogleOAuth';
import { actions } from '../../services/auth/reducers';

function mapStateToProps(state) {
  return {
    ...state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    authenticateOAuthRequest,
    createOauthRequest,
    resetOauthFlow,
  } = actions;

  return {
    actions: bindActionCreators({
      authenticateOAuthRequest,
      createOauthRequest,
      resetOauthFlow,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleOAuth);
