/* istanbul ignore file */
import React from 'react';

const SecurityIcon = () => {
  return (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 7.5V8H13H14C14.8239 8 15.5 8.67614 15.5 9.5V19.5C15.5 20.3239 14.8239 21 14 21H2C1.17614 21 0.5 20.3239 0.5 19.5V9.5C0.5 8.67614 1.17614 8 2 8H3H3.5V7.5V5.5C3.5 3.01614 5.51614 1 8 1C10.4839 1 12.5 3.01614 12.5 5.5V7.5ZM11 8H11.5V7.5V5.5C11.5 3.56386 9.93614 2 8 2C6.06386 2 4.5 3.56386 4.5 5.5V7.5V8H5H11ZM14 20H14.5V19.5V9.5V9H14H2H1.5V9.5V19.5V20H2H14ZM9.5 14.5C9.5 15.3239 8.82386 16 8 16C7.17614 16 6.5 15.3239 6.5 14.5C6.5 13.6761 7.17614 13 8 13C8.82386 13 9.5 13.6761 9.5 14.5Z" fill="#282828" stroke="#4F4F4F" />
    </svg>
  );
};

export default SecurityIcon;
