import * as Sentry from '@sentry/browser';

export default function (error) {
  Sentry.withScope(() => {
    if (error instanceof Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(error);
    }
  });
}
