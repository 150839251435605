import _ from 'lodash';

import errors from '../../localization/Application/Members/errors';

export const status = {
  INACTIVE: 'inactive',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const types = {
  SEND_ORG_INVITES_REQUEST: 'developer-client/organizations/SEND_ORG_INVITES_REQUEST',
  SEND_ORG_INVITES_SUCCESS: 'developer-client/organizations/SEND_ORG_INVITES_SUCCESS',
  SEND_ORG_INVITES_FAILURE: 'developer-client/organizations/SEND_ORG_INVITES_FAILURE',

  VERIFY_ORG_INVITE_REQUEST: 'developer-client/organizations/VERIFY_ORG_INVITE_REQUEST',
  VERIFY_ORG_INVITE_SUCCESS: 'developer-client/organizations/VERIFY_ORG_INVITE_SUCCESS',
  VERIFY_ORG_INVITE_FAILURE: 'developer-client/organizations/VERIFY_ORG_INVITE_FAILURE',

  RESET_VERIFY_ORG_INVITE_ERROR: 'developer-client/organizations/RESET_VERIFY_ORG_INVITE_ERROR',

  FETCH_ORGANIZATIONS_REQUEST: 'developer-client/organizations/FETCH_ORGANIZATIONS_REQUEST',
  FETCH_ORGANIZATIONS_SUCCESS: 'developer-client/organizations/FETCH_ORGANIZATIONS_SUCCESS',
  FETCH_ORGANIZATIONS_FAILURE: 'developer-client/organizations/FETCH_ORGANIZATIONS_FAILURE',

  FETCH_ACTIVE_ORG_MEMBERS_REQUEST: 'developer-client/organizations/FETCH_ACTIVE_ORG_MEMBERS_REQUEST',
  FETCH_ACTIVE_ORG_MEMBERS_SUCCESS: 'developer-client/organizations/FETCH_ACTIVE_ORG_MEMBERS_SUCCESS',
  FETCH_ACTIVE_ORG_MEMBERS_FAILURE: 'developer-client/organizations/FETCH_ACTIVE_ORG_MEMBERS_FAILURE',

  FETCH_INVITED_ORG_MEMBERS_REQUEST: 'developer-client/organizations/FETCH_INVITED_ORG_MEMBERS_REQUEST',
  FETCH_INVITED_ORG_MEMBERS_SUCCESS: 'developer-client/organizations/FETCH_INVITED_ORG_MEMBERS_SUCCESS',
  FETCH_INVITED_ORG_MEMBERS_FAILURE: 'developer-client/organizations/FETCH_INVITED_ORG_MEMBERS_FAILURE',

  FETCH_DASHBOARD_PERMISSION_ROLES_REQUEST: 'developer-client/organizations/FETCH_DASHBOARD_PERMISSION_ROLES_REQUEST',
  FETCH_DASHBOARD_PERMISSION_ROLES_SUCCESS: 'developer-client/organizations/FETCH_DASHBOARD_PERMISSION_ROLES_SUCCESS',
  FETCH_DASHBOARD_PERMISSION_ROLES_FAILURE: 'developer-client/organizations/FETCH_DASHBOARD_PERMISSION_ROLES_FAILURE',

  UPDATE_ORG_SETTINGS_REQUEST: 'developer-client/organizations/UPDATE_ORG_SETTINGS_REQUEST',
  UPDATE_ORG_SETTINGS_SUCCESS: 'developer-client/organizations/UPDATE_ORG_SETTINGS_SUCCESS',
  UPDATE_ORG_SETTINGS_FAILURE: 'developer-client/organizations/UPDATE_ORG_SETTINGS_FAILURE',

  UPDATE_ORG_MEMBER_REQUEST: 'developer-client/organizations/UPDATE_ORG_MEMBER_REQUEST',
  UPDATE_ORG_MEMBER_SUCCESS: 'developer-client/organizations/UPDATE_ORG_MEMBER_SUCCESS',
  UPDATE_ORG_MEMBER_FAILURE: 'developer-client/organizations/UPDATE_ORG_MEMBER_FAILURE',

  DELETE_ORG_INVITE_REQUEST: 'developer-client/organizations/DELETE_ORG_INVITE_REQUEST',
  DELETE_ORG_INVITE_SUCCESS: 'developer-client/organizations/DELETE_ORG_INVITE_SUCCESS',
  DELETE_ORG_INVITE_FAILURE: 'developer-client/organizations/DELETE_ORG_INVITE_FAILURE',

  DELETE_ORG_MEMBER_REQUEST: 'developer-client/organizations/DELETE_ORG_MEMBER_REQUEST',
  DELETE_ORG_MEMBER_SUCCESS: 'developer-client/organizations/DELETE_ORG_MEMBER_SUCCESS',
  DELETE_ORG_MEMBER_FAILURE: 'developer-client/organizations/DELETE_ORG_MEMBER_FAILURE',

  UPDATE_SEND_ORG_INVITES_STATUS: 'developer-client/organizations/UPDATE_SEND_ORG_INVITES_STATUS',
  UPDATE_DELETE_ORG_INVITE_STATUS: 'developer-client/organizations/UPDATE_DELETE_ORG_INVITE_STATUS',
  UPDATE_DELETE_ORG_MEMBER_STATUS: 'developer-client/organizations/UPDATE_DELETE_ORG_MEMBER_STATUS',
  UPDATE_UPDATE_ORG_MEMBER_STATUS: 'developer-client/organizations/UPDATE_UPDATE_ORG_MEMBER_STATUS',
  UPDATE_UPDATE_ORG_SETTINGS_STATUS: 'developer-client/organizations/UPDATE_UPDATE_ORG_SETTINGS_STATUS',

  SELECT_ORGANIZATION: 'developer-client/organizations/SELECT_ORGANIZATION',
};

export const initialState = {
  organizations: {},
  selectedOrganization: null,

  // loading and transitional states
  isFetchingOrganizations: false,
  isVerifyingOrgInvite: false,
  isFetchingActiveOrgMembers: false,
  isFetchingInvitedOrgMembers: false,
  isFetchingRoles: false,
  sendOrgInvitesStatus: status.INACTIVE,
  deleteOrgInviteStatus: status.INACTIVE,
  deleteOrgMemberStatus: status.INACTIVE,
  updateOrgMemberStatus: status.INACTIVE,
  updateOrgSettingsStatus: status.INACTIVE,

  // error states
  // todo: see why this error was an array
  sendOrgInvitesErrors: [],
  verifyOrgInviteError: '',
  fetchOrganizationsError: '',
  fetchActiveOrgMembersError: '',
  fetchInvitedOrgMembersError: '',
  deleteOrgInviteError: '',
  deleteOrgMemberError: '',
  fetchRolesError: '',
  updateOrgSettingsError: '',
  updateOrgMemberError: '',

  activeOrgMembers: [],
  invitedOrgMembers: [],
  dashboardPermissionRoles: [],
};

export const actions = {
  sendOrgInvites: data => ({
    type: types.SEND_ORG_INVITES_REQUEST,
    payload: data,
    meta: null,
  }),
  sendOrgInvitesSuccess: () => ({
    type: types.SEND_ORG_INVITES_SUCCESS,
    payload: null,
    meta: null,
  }),
  sendOrgInvitesFailure: error => ({
    type: types.SEND_ORG_INVITES_FAILURE,
    payload: error,
    meta: null,
  }),

  verifyOrgInvite: invitationId => ({
    type: types.VERIFY_ORG_INVITE_REQUEST,
    payload: invitationId,
    meta: null,
  }),
  verifyOrgInviteSuccess: () => ({
    type: types.VERIFY_ORG_INVITE_SUCCESS,
    payload: null,
    meta: null,
  }),
  verifyOrgInviteFailure: error => ({
    type: types.VERIFY_ORG_INVITE_FAILURE,
    payload: error,
    meta: null,
  }),

  resetVerifyOrgInviteError: () => ({
    type: types.RESET_VERIFY_ORG_INVITE_ERROR,
    payload: null,
    meta: null,
  }),

  fetchOrganizations: () => ({
    type: types.FETCH_ORGANIZATIONS_REQUEST,
    payload: null,
  }),

  fetchOrganizationsSuccess: organizations => ({
    type: types.FETCH_ORGANIZATIONS_SUCCESS,
    payload: organizations,
  }),
  fetchOrganizationsFailure: error => ({
    type: types.FETCH_ORGANIZATIONS_FAILURE,
    payload: error,
  }),

  fetchActiveOrgMembers: organizationId => ({
    type: types.FETCH_ACTIVE_ORG_MEMBERS_REQUEST,
    payload: organizationId,
    meta: null,
  }),
  fetchActiveOrgMembersSuccess: activeOrgMembers => ({
    type: types.FETCH_ACTIVE_ORG_MEMBERS_SUCCESS,
    payload: activeOrgMembers,
    meta: null,
  }),
  fetchActiveOrgMembersFailure: error => ({
    type: types.FETCH_ACTIVE_ORG_MEMBERS_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchInvitedOrgMembers: organizationId => ({
    type: types.FETCH_INVITED_ORG_MEMBERS_REQUEST,
    payload: organizationId,
    meta: null,
  }),
  fetchInvitedOrgMembersSuccess: invitedOrgMembers => ({
    type: types.FETCH_INVITED_ORG_MEMBERS_SUCCESS,
    payload: invitedOrgMembers,
    meta: null,
  }),
  fetchInvitedOrgMembersFailure: error => ({
    type: types.FETCH_INVITED_ORG_MEMBERS_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchDashboardPermissionRoles: () => ({
    type: types.FETCH_DASHBOARD_PERMISSION_ROLES_REQUEST,
    payload: null,
    meta: null,
  }),
  fetchDashboardPermissionRolesSuccess: roles => ({
    type: types.FETCH_DASHBOARD_PERMISSION_ROLES_SUCCESS,
    payload: roles,
    meta: null,
  }),
  fetchDashboardPermissionRolesFailure: error => ({
    type: types.FETCH_DASHBOARD_PERMISSION_ROLES_FAILURE,
    payload: error,
    meta: null,
  }),

  updateOrgSettings: updates => ({
    type: types.UPDATE_ORG_SETTINGS_REQUEST,
    payload: updates,
    meta: null,
  }),

  updateOrgSettingsSuccess: () => ({
    type: types.UPDATE_ORG_SETTINGS_SUCCESS,
    payload: null,
    meta: null,
  }),
  updateOrgSettingsFailure: error => ({
    type: types.UPDATE_ORG_SETTINGS_FAILURE,
    payload: error,
    meta: null,
  }),

  updateOrgMember: data => ({
    type: types.UPDATE_ORG_MEMBER_REQUEST,
    payload: data,
    meta: null,
  }),
  updateOrgMemberSuccess: () => ({
    type: types.UPDATE_ORG_MEMBER_SUCCESS,
    payload: null,
    meta: null,
  }),
  updateOrgMemberFailure: error => ({
    type: types.UPDATE_ORG_MEMBER_FAILURE,
    payload: error,
    meta: null,
  }),

  deleteOrgInvite: invitationId => ({
    type: types.DELETE_ORG_INVITE_REQUEST,
    payload: invitationId,
    meta: null,
  }),
  deleteOrgInviteSuccess: () => ({
    type: types.DELETE_ORG_INVITE_SUCCESS,
    payload: null,
    meta: null,
  }),
  deleteOrgInviteFailure: error => ({
    type: types.DELETE_ORG_INVITE_FAILURE,
    payload: error,
    meta: null,
  }),

  deleteOrgMember: dashboardUser => ({
    type: types.DELETE_ORG_MEMBER_REQUEST,
    payload: dashboardUser,
    meta: null,
  }),
  deleteOrgMemberSuccess: () => ({
    type: types.DELETE_ORG_MEMBER_SUCCESS,
    payload: null,
    meta: null,
  }),
  deleteOrgMemberFailure: error => ({
    type: types.DELETE_ORG_MEMBER_FAILURE,
    payload: error,
    meta: null,
  }),

  updateSendOrgInvitesStatus: newStatus => ({
    type: types.UPDATE_SEND_ORG_INVITES_STATUS,
    payload: newStatus,
    meta: null,
  }),
  updateDeleteOrgInviteStatus: newStatus => ({
    type: types.UPDATE_DELETE_ORG_INVITE_STATUS,
    payload: newStatus,
    meta: null,
  }),
  updateDeleteOrgMemberStatus: newStatus => ({
    type: types.UPDATE_DELETE_ORG_MEMBER_STATUS,
    payload: newStatus,
    meta: null,
  }),
  updateUpdateOrgMemberStatus: newStatus => ({
    type: types.UPDATE_UPDATE_ORG_MEMBER_STATUS,
    payload: newStatus,
    meta: null,
  }),
  updateUpdateOrgSettingsStatus: newStatus => ({
    type: types.UPDATE_UPDATE_ORG_SETTINGS_STATUS,
    payload: newStatus,
    meta: null,
  }),
  // WARNING: anytime we selectOrganization, we need to redirect to '/'
  // to trigger the initializeOnboarding and call api.selectOrganization
  // to maintain parity between client's selectedOrganization and server's sessionCache.developerId
  selectOrganization: organizationId => ({
    type: types.SELECT_ORGANIZATION,
    payload: organizationId,
  }),
};

export const selectors = {
  getVerifyOrgInviteError: (state) => {
    return _.get(state, ['organizations', 'verifyOrgInviteError']);
  },
  getOrganizations: (state) => {
    return _.get(state, ['organizations', 'organizations']);
  },
  getSelectedOrganization: (state) => {
    return _.get(state, ['organizations', 'selectedOrganization']);
  },
  getCurrentOrganization: (state) => {
    const selectedOrganization = selectors.getSelectedOrganization(state);
    return _.get(state, ['organizations', 'organizations', selectedOrganization]);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_ORG_INVITES_REQUEST:
      return {
        ...state,
        sendOrgInvitesStatus: status.PENDING,
        sendOrgInvitesErrors: [],
      };
    case types.SEND_ORG_INVITES_SUCCESS:
      return {
        ...state,
        sendOrgInvitesStatus: status.SUCCESS,
      };
    case types.SEND_ORG_INVITES_FAILURE:
      return {
        ...state,
        sendOrgInvitesStatus: status.FAILURE,
        sendOrgInvitesErrors: action.payload || [errors.sendingTeamInvite],
      };

    case types.VERIFY_ORG_INVITE_REQUEST:
      return {
        ...state,
        isVerifyingOrgInvite: true,
        verifyOrgInviteError: '',
      };
    case types.VERIFY_ORG_INVITE_SUCCESS:
      return {
        ...state,
        isVerifyingOrgInvite: false,
      };
    case types.VERIFY_ORG_INVITE_FAILURE:
      return {
        ...state,
        isVerifyingOrgInvite: false,
        verifyOrgInviteError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.verifyingTeamInvite,
      };

    case types.RESET_VERIFY_ORG_INVITE_ERROR:
      return {
        ...state,
        verifyOrgInviteError: '',
      };

    case types.FETCH_ORGANIZATIONS_REQUEST: {
      return {
        ...state,
        isFetchingOrganizations: true,
        fetchOrganizationsError: '',
      };
    }

    case types.FETCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        organizations: action.payload,
        isFetchingOrganizations: false,
      };
    }
    case types.FETCH_ORGANIZATIONS_FAILURE: {
      return {
        ...state,
        isFetchingOrganizations: false,
        fetchOrganizationsError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || errors.fetchingOrganizations,
      };
    }

    case types.FETCH_ACTIVE_ORG_MEMBERS_REQUEST:
      return {
        ...state,
        isFetchingActiveOrgMembers: true,
        fetchActiveOrgMembersError: '',
      };
    case types.FETCH_ACTIVE_ORG_MEMBERS_SUCCESS:
      return {
        ...state,
        activeOrgMembers: action.payload,
        isFetchingActiveOrgMembers: false,
      };
    case types.FETCH_ACTIVE_ORG_MEMBERS_FAILURE:
      return {
        ...state,
        isFetchingActiveOrgMembers: false,
        fetchActiveOrgMembersError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.fetchingTeamMembers,
      };

    case types.FETCH_INVITED_ORG_MEMBERS_REQUEST:
      return {
        ...state,
        isFetchingInvitedOrgMembers: true,
        fetchInvitedOrgMembersError: '',
      };
    case types.FETCH_INVITED_ORG_MEMBERS_SUCCESS:
      return {
        ...state,
        invitedOrgMembers: action.payload,
        isFetchingInvitedOrgMembers: false,
      };
    case types.FETCH_INVITED_ORG_MEMBERS_FAILURE:
      return {
        ...state,
        isFetchingInvitedOrgMembers: false,
        fetchInvitedOrgMembersError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.fetchingTeamInvites,
      };

    case types.FETCH_DASHBOARD_PERMISSION_ROLES_REQUEST:
      return {
        ...state,
        isFetchingRoles: true,
        fetchRolesError: '',
      };
    case types.FETCH_DASHBOARD_PERMISSION_ROLES_SUCCESS:
      return {
        ...state,
        isFetchingRoles: false,
        dashboardPermissionRoles: action.payload,
      };
    case types.FETCH_DASHBOARD_PERMISSION_ROLES_FAILURE:
      return {
        ...state,
        isFetchingRoles: false,
        fetchRolesError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.fetchingRoles,
      };

    case types.DELETE_ORG_INVITE_REQUEST:
      return {
        ...state,
        deleteOrgInviteStatus: status.PENDING,
        deleteOrgInviteError: '',
      };
    case types.DELETE_ORG_INVITE_SUCCESS:
      return {
        ...state,
        deleteOrgInviteStatus: status.SUCCESS,
      };
    case types.DELETE_ORG_INVITE_FAILURE:
      return {
        ...state,
        deleteOrgInviteStatus: status.FAILURE,
        deleteOrgInviteError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.deleteTeamInvite,
      };

    case types.DELETE_ORG_MEMBER_REQUEST:
      return {
        ...state,
        deleteOrgMemberStatus: status.PENDING,
        deleteOrgMemberError: '',
      };
    case types.DELETE_ORG_MEMBER_SUCCESS:
      return {
        ...state,
        deleteOrgMemberStatus: status.SUCCESS,
      };
    case types.DELETE_ORG_MEMBER_FAILURE:
      return {
        ...state,
        deleteOrgMemberStatus: status.FAILURE,
        deleteOrgMemberError: (
          action.payload
            && action.payload.response
            && action.payload.response.data
            && action.payload.response.data.message
        ) || errors.deleteTeamMember,
      };

    case types.UPDATE_ORG_SETTINGS_REQUEST:
      return {
        ...state,
        updateOrgSettingsStatus: status.PENDING,
        updateOrgSettingsError: '',
      };
    case types.UPDATE_ORG_SETTINGS_SUCCESS:
      return {
        ...state,
        updateOrgSettingsStatus: status.SUCCESS,
      };
    case types.UPDATE_ORG_SETTINGS_FAILURE:
      return {
        ...state,
        updateOrgSettingsStatus: status.FAILURE,
        updateOrgSettingsError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || errors.updateTeamInfo,
      };

    case types.UPDATE_ORG_MEMBER_REQUEST:
      return {
        ...state,
        updateOrgMemberStatus: status.PENDING,
        updateOrgMemberError: '',
      };
    case types.UPDATE_ORG_MEMBER_SUCCESS:
      return {
        ...state,
        updateOrgMemberStatus: status.SUCCESS,
      };
    case types.UPDATE_ORG_MEMBER_FAILURE:
      return {
        ...state,
        updateOrgMemberStatus: status.FAILURE,
        updateOrgMemberError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || errors.updateTeamMember,
      };

    case types.UPDATE_SEND_ORG_INVITES_STATUS:
      return {
        ...state,
        sendOrgInvitesErrors: [],
        sendOrgInvitesStatus: action.payload,
      };
    case types.UPDATE_DELETE_ORG_INVITE_STATUS:
      return {
        ...state,
        deleteOrgInviteError: '',
        deleteOrgInviteStatus: action.payload,
      };
    case types.UPDATE_DELETE_ORG_MEMBER_STATUS:
      return {
        ...state,
        deleteOrgMemberError: '',
        deleteOrgMemberStatus: action.payload,
      };
    case types.UPDATE_UPDATE_ORG_MEMBER_STATUS:
      return {
        ...state,
        updateOrgMemberError: '',
        updateOrgMemberStatus: action.payload,
      };
    case types.UPDATE_UPDATE_ORG_SETTINGS_STATUS:
      return {
        ...state,
        updateOrgSettingsError: '',
        updateOrgSettingsStatus: action.payload,
      };
    case types.SELECT_ORGANIZATION:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    default:
      return state;
  }
}
