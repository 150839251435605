const staticText = {
  apply: 'Apply',
  resetAll: 'Reset all',
  logDaysAllowance: logDays => `Your current plan provides access to the most recent ${logDays} day${logDays > 1 ? 's' : ''} of Connect data.`,
};

export const inputConfig = {
  inputs: [
    {
      filterKey: 'vin',
      label: 'Single Select VIN',
      id: 'filter-vin',
    },
    {
      filterKey: 'sessionId',
      label: 'Session ID',
      id: 'filter-session-id',
    },
    {
      filterKey: 'customerVehicleOwnerId',
      label: 'User',
      id: 'filter-user',
    },
  ],
  dateRange: {
    label: 'Date range',
    id: 'filter-date-range',
  },
};

export default staticText;
