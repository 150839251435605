import fields from '../components/WebhooksForm/fields';

const { endpoints } = fields;

const endpointToPermissionConverter = (endpoint) => {
  const selectedEndpoint = endpoints.find(endpointDetails => endpointDetails.path === endpoint);

  if (selectedEndpoint) {
    return selectedEndpoint.permission;
  }
  return null;
};
export default endpointToPermissionConverter;
