import { styled } from '@mui/material';

export const Nav = styled('nav')(({ theme }) => ({
  height: theme.height.topbar,
  boxSizing: 'border-box',
  padding: theme.spacing(1, 5, 1, 1),
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Logo = styled('a')(({ theme }) => ({
  padding: theme.spacing(1, 1, 0, 1),
  width: '51px',
  boxSizing: 'border-box',
}));

export const TopNavLinks = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '> li': {
    'a, button': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '44px',
      width: '48px',
      padding: 0,
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderRadius: theme.border.radius,

      '.hover-text': {
        display: 'none',
      },

      '&:hover': {
        backgroundColor: theme.palette.grey[100],

        '.hover-text': {
          display: 'block',
        },
      },
    },
  },
}));

export const Divider = styled('div')(({ theme }) => ({
  height: '40px',
  width: '1px',
  backgroundColor: theme.palette.grey[200],
  margin: '5px 4px 0',
}));

export const WarningDot = styled('div')(({ theme }) => ({
  position: 'absolute',
  content: '""',
  top: '4px',
  right: '4px',
  width: '8px',
  height: '8px',
  borderRadius: '100%',
  backgroundColor: theme.palette.error.main,
}));
