import _ from 'lodash';
import { func } from 'prop-types';

export default function generateActionProps(types) {
  const props = {};
  _.forEach(types, (value, key) => {
    props[_.camelCase(key)] = func.isRequired;
  });
  return props;
}
