// eslint-disable-next-line import/prefer-default-export
export const menuItems = [
  {
    displayName: 'View vehicle details',
    action: 'viewDetails',
  },
  {
    displayName: 'Disconnect vehicle',
    action: 'disconnect',
  },
];
