
import { googleLoginButton } from '../../assets/images/loginMethods';

const staticText = {
  title: 'Sign in',
  forgotPassword: '|+|Forgot your password?@@/forgot-password|+|',
  subLink: 'Need an account? |+|Sign up@@/signup|+|',
  btnSubmit: 'Continue',
  image: {
    url: '/assets/graphics/car_person_graphic.svg',
    alt: 'Smartcar user logging in',
  },
  loginMethods: [
    {
      value: 'GoogleOAuth',
      text: oAuthFlow => `${oAuthFlow} with Google`,
      img: googleLoginButton,
    },
    // {
    //   value: 'GitHubOAuth',
    //   text: 'Sign in with GitHub',
    //   img: githubLoginButton,
    // },
    // {
    //   value: 'MicrosoftOAuth',
    //   text: 'Sign in with Microsoft',
    //   img: microsoftLoginButton,
    // }
  ],
  fields: [
    {
      label: 'Email',
      name: 'username',
      type: 'email',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
    },
  ],
};

export default staticText;
