import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

export const HomeLink = styled('div')(({ theme }) => ({
  a: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    textDecoration: 'none',

    p: {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.caption.fontSize,
    },

    svg: {
      marginRight: '4px',
      marginBottom: '-2px',
    },

    '&:hover': {
      textDecoration: 'underline',

      p: {
        color: theme.palette.secondary.main,
      },

      svg: {
        fill: theme.palette.secondary.main,
      },
    },
  },
}));

export const CtaLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.border.radius,
  padding: '6px 16px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  fontFamily: theme.typography.bold.fontFamily,
  transition: '.25s',

  '&:hover': {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const Icon = styled('span')(() => ({
  marginTop: '-3px',
  marginLeft: '-4px',
  lineHeight: '0',
}));
