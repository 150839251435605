/* istanbul ignore file */

import React from 'react';
import { func, number, shape, string } from 'prop-types';
import { ChevronDown } from 'react-feather';
import { styled } from '@mui/material/styles';

import { MenuItemWrapper } from '../../../../../../components';
import { DateDropdown, DateToggle } from './components';

import { getMenuItemWrapperDate } from '../../utils/utils';

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '.MuiButton-root': {
    padding: theme.spacing(1, 2),
  },
  '& > .MuiButtonBase-root': {
    marginLeft: theme.spacing(2),
  },
}));

const DateFilter = ({
  createdAt,
  refreshChartData,
  setFilter,
  timeframe,
}) => {
  return (
    <Container>
      <DateToggle
        interval={timeframe.interval}
        setFilter={setFilter}
        refreshChartData={refreshChartData}
      />
      <MenuItemWrapper title={getMenuItemWrapperDate(timeframe)} Icon={ChevronDown} id="date">
        <DateDropdown
          createdAt={createdAt}
          setFilter={setFilter}
          timeframe={timeframe}
          refreshChartData={refreshChartData}
        />
      </MenuItemWrapper>
    </Container>
  );
};

export default DateFilter;

DateFilter.propTypes = {
  createdAt: string.isRequired,
  refreshChartData: func.isRequired,
  setFilter: func.isRequired,
  timeframe: shape({
    start: number,
    end: number,
    interval: string,
    timezone: string,
  }).isRequired,
};
