/* istanbul ignore file */
import React from 'react';

const PhoneIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1667 2.16667H5.83335C4.91288 2.16667 4.16669 2.91286 4.16669 3.83333V17.1667C4.16669 18.0871 4.91288 18.8333 5.83335 18.8333H14.1667C15.0872 18.8333 15.8334 18.0871 15.8334 17.1667V3.83333C15.8334 2.91286 15.0872 2.16667 14.1667 2.16667Z"
        stroke="#4F4F4F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 15.5H10.0083" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PhoneIcon;
