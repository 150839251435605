import {
  setFetchingState,
  setFailureState,
} from './utils/tripReducerUtils';
import tripOptions from './utils/tripOptions';

export const types = {
  FETCH_VEHICLE_TRIP:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP',
  FETCH_VEHICLE_TRIP_SUCCESS:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP_SUCCESS',
  FETCH_VEHICLE_TRIP_FAILURE:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP_FAILURE',

  FETCH_VEHICLE_TRIP_FRAME:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP_FRAME',
  FETCH_VEHICLE_TRIP_FRAME_SUCCESS:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP_FRAME_SUCCESS',
  FETCH_VEHICLE_TRIP_FRAME_FAILURE:
    'developer-client/Simulator/FETCH_VEHICLE_TRIP_FRAME_FAILURE',

  CREATE_VEHICLE_TRIP:
    'developer-client/Simulator/CREATE_VEHICLE_TRIP',
  CREATE_VEHICLE_TRIP_SUCCESS:
    'developer-client/Simulator/CREATE_VEHICLE_TRIP_SUCCESS',
  CREATE_VEHICLE_TRIP_FAILURE:
    'developer-client/Simulator/CREATE_VEHICLE_TRIP_FAILURE',

  UPDATE_VEHICLE_TRIP:
    'developer-client/Simulator/UPDATE_VEHICLE_TRIP',
  UPDATE_VEHICLE_TRIP_SUCCESS:
    'developer-client/Simulator/UPDATE_VEHICLE_TRIP_SUCCESS',
  UPDATE_VEHICLE_TRIP_FAILURE:
    'developer-client/Simulator/UPDATE_VEHICLE_TRIP_FAILURE',

  COMPLETE_VEHICLE_TRIP:
    'developer-client/Simulator/COMPLETE_VEHICLE_TRIP',
  COMPLETE_VEHICLE_TRIP_SUCCESS:
    'developer-client/Simulator/COMPLETE_VEHICLE_TRIP_SUCCESS',
  COMPLETE_VEHICLE_TRIP_FAILURE:
    'developer-client/Simulator/COMPLETE_VEHICLE_TRIP_FAILURE',

  RESET_VEHICLE_TRIP:
    'developer-client/Simulator/RESET_VEHICLE_TRIP',
};

const initialVehicleTripState = {
  id: '',
  tripId: '',
  tripType: '',
  autoplay: false,
  tripActive: false,
  tripPaused: false,
  tripLatency: tripOptions.tripLatencies[0].code,
  tripSpeed: tripOptions.tripSpeeds[0],
  tripDurationInMins: 0,
  tripFrameData: {},
  tripFrameIndex: 0,
};

export const initialState = {
  isFetchingSimulatorTrip: {
    vehicleTrip: false,
    tripActive: false,
    tripPaused: false,
    tripLatency: false,
    tripSpeed: false,
    tripComplete: false,
    tripFrameData: false,
  },

  vehicleTrip: {
    ...initialVehicleTripState,
  },

  fetchingSimulatorTripErrors: {
    vehicleTrip: false,
    tripActive: false,
    tripPaused: false,
    tripLatency: false,
    tripSpeed: false,
    tripComplete: false,
    tripFrameData: false,
  },
};

export const actions = {
  fetchVehicleTrip: (applicationId, vehicleTripId) => ({
    type: types.FETCH_VEHICLE_TRIP,
    payload: { vehicleTripId },
    meta: applicationId,
  }),
  fetchVehicleTripSuccess: response => ({
    type: types.FETCH_VEHICLE_TRIP_SUCCESS,
    payload: response,
    meta: null,
  }),
  fetchVehicleTripFailure: error => ({
    type: types.FETCH_VEHICLE_TRIP_FAILURE,
    payload: error,
    meta: null,
  }),

  fetchVehicleTripFrame: (applicationId, tripValues, frameIndex) => ({
    type: types.FETCH_VEHICLE_TRIP_FRAME,
    payload: { tripValues, frameIndex },
    meta: applicationId,
  }),
  fetchVehicleTripFrameSuccess: response => ({
    type: types.FETCH_VEHICLE_TRIP_FRAME_SUCCESS,
    payload: response,
    meta: null,
  }),
  fetchVehicleTripFrameFailure: error => ({
    type: types.FETCH_VEHICLE_TRIP_FRAME_FAILURE,
    payload: error,
    meta: null,
  }),

  createVehicleTrip: (applicationId, vehicleInfo, regionCode, tripSettings) => ({
    type: types.CREATE_VEHICLE_TRIP,
    payload: {
      vehicleInfo,
      regionCode,
      tripSettings,
    },
    meta: applicationId,
  }),
  createVehicleTripSuccess: response => ({
    type: types.CREATE_VEHICLE_TRIP_SUCCESS,
    payload: response,
    meta: null,
  }),
  createVehicleTripFailure: error => ({
    type: types.CREATE_VEHICLE_TRIP_FAILURE,
    payload: { error },
    meta: null,
  }),

  // `updateAction` should be one of the following:
  //    'PAUSE', 'RESUME', 'CHANGE_SPEED', or 'CHANGE_LATENCY'
  // `updatePayload` depends on the action:
  //    'PAUSE'|'RESUME': none
  //    'SPEED': 1, 2, 4, or 16
  //    'LATENCY': 'immediate' or 'realistic'
  //    'FRAME': index of the selected stage/frame
  // Reference:
  // https://github.com/smartcar/dashboard-server/blob/b5ad856a588d8cb92cf0306769ba3f08465f3486/processors/vehicle-simulator/trip/update-vehicle-trip.js
  updateVehicleTrip: (applicationId, vehicleId, vin, updateAction, updatePayload) => ({
    type: types.UPDATE_VEHICLE_TRIP,
    payload: {
      vehicleId, vin, updateAction, updatePayload,
    },
    meta: applicationId,
  }),
  updateVehicleTripSuccess: ({ updateAction, updatePayload }) => ({
    type: types.UPDATE_VEHICLE_TRIP_SUCCESS,
    payload: { updateAction, updatePayload },
    meta: null,
  }),
  updateVehicleTripFailure: ({ updateAction, error }) => ({
    type: types.UPDATE_VEHICLE_TRIP_FAILURE,
    payload: { updateAction, error },
    meta: null,
  }),

  completeVehicleTrip: (applicationId, vehicleId) => ({
    type: types.COMPLETE_VEHICLE_TRIP,
    payload: { vehicleId },
    meta: applicationId,
  }),
  completeVehicleTripSuccess: response => ({
    type: types.COMPLETE_VEHICLE_TRIP_SUCCESS,
    payload: response,
    meta: null,
  }),
  completeVehicleTripFailure: error => ({
    type: types.COMPLETE_VEHICLE_TRIP_FAILURE,
    payload: { error },
    meta: null,
  }),

  resetVehicleTrip: () => ({
    type: types.RESET_VEHICLE_TRIP,
    payload: null,
    meta: null,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_VEHICLE_TRIP:
      return setFetchingState(state, 'vehicleTrip');
    case types.FETCH_VEHICLE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingSimulatorTrip: {
          ...state.isFetchingSimulatorTrip,
          vehicleTrip: false,
        },
        vehicleTrip: {
          id: action.payload.vehicleTripId,
          tripId: action.payload.tripId,
          tripType: action.payload.tripType,
          autoplay: action.payload.status === 'auto',
          tripActive: action.payload.status !== 'complete',
          tripPaused: action.payload.status === 'paused',
          tripSpeed: action.payload.speed,
          tripLatency: action.payload.latency,
          tripDurationInMins: action.payload.tripDurationInMins,
          tripFrameData: action.payload.tripFrameData,
          tripFrameIndex: action.payload.tripFrameIndex,
        },
      };
    case types.FETCH_VEHICLE_TRIP_FAILURE:
      return setFailureState(state, 'vehicleTrip', action);

    case types.FETCH_VEHICLE_TRIP_FRAME:
      return setFetchingState(state, 'tripFrameData');
    case types.FETCH_VEHICLE_TRIP_FRAME_SUCCESS:
      return {
        ...state,
        isFetchingSimulatorTrip: {
          ...state.isFetchingSimulatorTrip,
          tripFrameData: false,
        },
        vehicleTrip: {
          ...state.vehicleTrip,
          tripFrameData: action.payload,
        },
      };
    case types.FETCH_VEHICLE_TRIP_FRAME_FAILURE:
      return setFailureState(state, 'tripFrameData');

    case types.CREATE_VEHICLE_TRIP:
      return setFetchingState(state, 'CREATE');
    case types.CREATE_VEHICLE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingSimulatorTrip: {
          ...state.isFetchingSimulatorTrip,
          tripActive: false,
        },
        vehicleTrip: {
          ...state.vehicleTrip,
          id: action.payload.id,
          tripId: action.payload.tripId,
          tripActive: true,
          tripPaused: false,
          tripSpeed: action.payload.tripSpeed,
          tripLatency: action.payload.tripLatency,
          tripDurationInMins: action.payload.tripDurationInMins,
          tripFrameData: action.payload.tripFrameData,
          tripFrameIndex: 0,
        },
      };
    case types.CREATE_VEHICLE_TRIP_FAILURE:
      return setFailureState(state, 'CREATE', action);

    case types.UPDATE_VEHICLE_TRIP:
      return setFetchingState(state, action.payload.updateAction);
    case types.UPDATE_VEHICLE_TRIP_SUCCESS:
      switch (action.payload.updateAction) {
        case 'RESUME':
          return {
            ...state,
            isFetchingSimulatorTrip: {
              ...state.isFetchingSimulatorTrip,
              tripPaused: false,
            },
            vehicleTrip: {
              ...state.vehicleTrip,
              tripPaused: false,
            },
          };
        case 'PAUSE':
          return {
            ...state,
            isFetchingSimulatorTrip: {
              ...state.isFetchingSimulatorTrip,
              tripPaused: false,
            },
            vehicleTrip: {
              ...state.vehicleTrip,
              tripPaused: true,
            },
          };
        case 'CHANGE_SPEED':
          return {
            ...state,
            isFetchingSimulatorTrip: {
              ...state.isFetchingSimulatorTrip,
              tripSpeed: false,
            },
            vehicleTrip: {
              ...state.vehicleTrip,
              tripSpeed: action.payload.updatePayload,
            },
          };
        case 'CHANGE_LATENCY':
          return {
            ...state,
            isFetchingSimulatorTrip: {
              ...state.isFetchingSimulatorTrip,
              tripLatency: false,
            },
            vehicleTrip: {
              ...state.vehicleTrip,
              tripLatency: action.payload.updatePayload,
            },
          };
        default:
          return {
            ...state,
          };
      }
    case types.UPDATE_VEHICLE_TRIP_FAILURE:
      return setFailureState(state, action.payload.updateAction, action);

    case types.COMPLETE_VEHICLE_TRIP:
      return setFetchingState(state, 'COMPLETE');
    case types.COMPLETE_VEHICLE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingSimulatorTrip: {
          ...state.isFetchingSimulatorTrip,
          tripComplete: false,
        },
        vehicleTrip: {
          ...state.vehicleTrip,
          tripActive: false,
        },
      };
    case types.COMPLETE_VEHICLE_TRIP_FAILURE:
      return setFailureState(state, 'COMPLETE', action);

    case types.RESET_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrip: {
          ...initialVehicleTripState,
        },
      };

    default:
      return state;
  }
}
