import React from 'react';
import { bool, func } from 'prop-types';

import staticText from '../../../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import { NoResults } from '../../../../../../../../components';

const NoResultsFound = ({ provideConnectCta, resetFilters }) => {
  let text;
  let ctaProps;
  if (provideConnectCta) {
    text = staticText.noVehicles;
    ctaProps = {
      link: {
        href: staticText.integrateWithConnectLink,
        text: staticText.integrateWithConnect,
        trackingContext: 'Vehicles',
      },
    };
  } else {
    text = staticText.noVehiclesWithFilters;
    ctaProps = {
      button: {
        handleClick: resetFilters,
        text: staticText.resetFilters,
        trackingContext: 'Vehicles',
      },
    };
  }

  return (
    <NoResults
      text={text}
      {...ctaProps}
    />
  );
};

export default NoResultsFound;

NoResultsFound.propTypes = {
  provideConnectCta: bool.isRequired,
  resetFilters: func.isRequired,
};
