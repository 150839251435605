import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';
import { InlineLinks } from '../../../../components';

const staticText = {
  error: {
    title: 'Account Suspended',
    description:
      'Your account has been suspended. Please |+|contact finance@@mailto:finance@smartcar.com|+| to pay any past due invoices.',
  },
};

const SuspendedOrg = () => {
  useEffect(() => {
    reportToSegment(types.PAGE, 'Account Suspended');
  }, []);

  return (
    <Box width="800px" margin="100px auto">
      <ApplicationHeader heading={staticText.error.title} />
      <Section>
        <Typography mb={3} mt={2}>
          <InlineLinks text={staticText.error.description} icon="" />
        </Typography>
      </Section>
    </Box>
  );
};

export default SuspendedOrg;

SuspendedOrg.propTypes = {};
