import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import { actions, initialState, selectors, types } from './reducers';
import api from '../../../../services/api/api';

export function* fetchConnectEventsRequest(action) {
  try {
    const { filterValues, newPaginationModel } = action.payload;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchConnectEvents,
      applicationId,
      {
        ...filterValues,
        includeTableInfo: true,
      },
    );

    const results = {
      rows: data.rows,
      columns: data.cols,
      rowCount: data.paging.count,
    };

    const pageZero = newPaginationModel.page === 0;
    // Reset cursorMap whenever we fetch page 0
    if (pageZero) {
      yield put(actions.updateCursorMap(initialState.pageToNextCursorMap));
    }

    yield put(actions.fetchConnectEventsSuccess(results));

    const cursorMap = yield select(selectors.getPageToNextCursorMap);
    yield put(actions.updateCursorMap({
      ...cursorMap,
      [newPaginationModel.page]: data.paging.cursor,
    }));
    yield put(actions.updatePaginationModel(newPaginationModel));
  } catch (error) {
    yield put(actions.fetchConnectEventsFailure(error));
  }
}

export function* fetchSessionDetailsRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchConnectEvents,
      applicationId,
      action.payload,
    );

    yield put(actions.fetchSessionDetailsSuccess(data.rows));
  } catch (error) {
    yield put(actions.fetchSessionDetailsFailure(error));
  }
}

export function* fetchConnectFunnelRequest(action) {
  try {
    const filters = action.payload;
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.fetchConnectFunnel,
      applicationId,
      filters,
    );

    yield put(actions.fetchConnectFunnelSuccess(data));
  } catch (error) {
    yield put(actions.fetchConnectFunnelFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_CONNECT_EVENTS_REQUEST, fetchConnectEventsRequest);
  yield takeLatest(types.FETCH_SESSION_DETAILS_REQUEST, fetchSessionDetailsRequest);
  yield takeLatest(types.FETCH_CONNECT_FUNNEL_REQUEST, fetchConnectFunnelRequest);
}

