// this date is the date of the Auth system launch, to prevent
// existing accounts from showing the sdk selector screen (/sdks)
// since the developer createdAt value for existing accounts
// was January 13
export const developerOnboardingLaunch = '2022-01-14T01:56:30.440Z';

export const staticText = {
  heading: 'Overview',
  usageTabs: [
    'Usage',
    'Requests',
    'Vehicles',
  ],
  getWidgetsConfiguration: metrics => ([
    {
      title: 'Errors',
      stat: metrics.totalFailedRequests,
      unit: 'API requests',
      sagaKey: 'responseStatusSummary',
    },
    {
      title: 'Successes',
      stat: metrics.totalApiRequests,
      unit: 'API requests',
      sagaKey: 'apiRequestTimeSeries',
    },
    {
      title: 'Active vehicles',
      stat: metrics.totalActiveVehicles,
      unit: 'Total',
      sagaKey: 'activeVehiclesTimeSeries',
    },
  ]),
  chartTitles: {
    apiRequests: {
      title: 'Successful API requests',
    },
    statusCodes: {
      title: 'Status codes',
    },
    activeVehicles: {
      title: 'Active vehicles',
      tooltip: 'Real vehicles with at least one successful billable API request in the specified month or year.',
    },
    makes: {
      title: 'All vehicles by make',
    },
  },
};
