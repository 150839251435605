import { useState } from 'react';
import _ from 'lodash';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import Form from './Form';
import { FormControlWrapper, ImageUpload, RadioGroup, TextInput } from './components';
import { Toast } from '..';

/**
 * This custom form hook takes in any configuration of input fields needed and returns
 * a formState object as well as handler functions for input changes, form submission, etc.
 *
 * The `inputs` prop should be configured as described below. Individual input components
 * (wrapper in a FormControlWrapper component) can then be passed as children within
 * the Form component.
 *
 * See AuthenticationSettings or Connect > Appearance for examples.
 *
 * @param {string} formName - form name to be reported to segment on submit
 * @param {Object} data - The stored state of the settings you are trying to change (obtained
 * from the redux store) Ex: the application or the user object
 * @param {Object[]} inputs - Configuration object for each input field that on form
 * @param {string} inputs[].pathOnData - Path needed to access the value of the desired setting
 * on the data object. Nested properties use dot notation (ex: serviceLinks.privacyPolicy)
 * @param {string} inputs[].displayName - Name of the input field to be displayed to the user
 * @param {string} inputs[].placeholderText - Placeholder text used in the input field when
 * it's empty
 * @param {function} inputs[].validator - Validator that checks the value inputted
 * @param {function} onSubmit - Function that sends the updated values to the backend
 * @param {function} onChange - (optional) Side effects when an input value is changed
 * @param {function} toggleModal - (optional) Function to close modal if form is rendered in one
 * @param {boolean} displayToast - (optional) Display toast message when form is submitted
 */
const useForm = ({
  formName, data, inputs, onSubmit, onChange, toggleModal, displayToast,
}) => {
  const inputConfig = {};

  const getInitialFormState = () => {
    const initialState = {};
    inputs.forEach((input) => {
      initialState[input.pathOnData] = {
        value: _.get(data, input.pathOnData, ''),
        isValid: true,
        errorMessage: '',
        visited: false,
      };

      inputConfig[input.pathOnData] = _.pick(input, ['validator']);
    });
    return initialState;
  };

  const [formState, setFormState] = useState(getInitialFormState());
  const [disableSubmit, setDisableSubmit] = useState(true);

  const showToast = (updates) => {
    updates.forEach((update) => {
      Toast(`${update} updated!`);
    });
  };

  const hasFieldChanged = (field) => {
    // to prevent an input from changing from an uncontrolled --> controlled component,
    // we have to set the initial state of an input's value to '' if the db value is undefined
    // therefore, we set the default value of the get below to '' so that a blank input isn't
    // seen as 'changed' if the db value was undefined (otherwise undefined !== '')
    const dataValue = _.get(data, field, '');
    const inputValue = _.get(formState, field).value;

    return dataValue !== inputValue;
  };

  // this function is used to disable submission if any one field is invalid
  // or no fields have changed
  const checkValidity = (name, value, isValid) => {
    let allValid = true;
    let noChanges = true;
    /* eslint-disable no-param-reassign */
    inputs.forEach((input) => {
      const {
        [input.pathOnData]: { isValid: storedValidity },
      } = formState;

      if (input.pathOnData === name) {
        if (!isValid) allValid = false;
        if (value !== _.get(data, name)) noChanges = false;
      } else {
        if (!storedValidity) allValid = false;
        if (hasFieldChanged(input.pathOnData)) noChanges = false;
      }
    });
    return !allValid || noChanges;
    /* eslint-enable no-param-reassign */
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    const { validator } = inputConfig[name];

    try {
      if (validator) validator(value);
      setFormState(prevState => ({
        ...prevState,
        [name]: {
          value,
          isValid: true,
          errorMessage: '',
          visited: false,
        },
      }));
      if (onChange) onChange(event.target);
      setDisableSubmit(checkValidity(name, value, true));
    } catch (err) {
      setFormState(prevState => ({
        ...prevState,
        [name]: {
          value,
          isValid: false,
          errorMessage: err.message,
          visited: false,
        },
      }));
      setDisableSubmit(true);
    }
  };

  const handleBlur = (event) => {
    const { name } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        visited: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // updatedFields holds all the fields that have changed + are valid values
    const updatedFields = {};
    const invalidFields = {};
    const toastUpdates = [];
    inputs.forEach((input) => {
      const { pathOnData } = input;
      const currentInputState = formState[pathOnData];

      if (currentInputState.isValid && hasFieldChanged(pathOnData)) {
        // our current db queries update based on this format: { columnName: value } where
        // columnName is a top level property of data, so we need to check if the field to be
        //  updated is a nested property of data
        if (pathOnData.indexOf('.') !== -1) {
          // get the top level property
          const topLevelProperty = pathOnData.slice(0, pathOnData.indexOf('.'));
          // get the rest of the path to the property to be changed
          const propertyChanged = pathOnData.slice(pathOnData.indexOf('.') + 1);
          // make a copy of the object we are updating
          const fieldToUpdate = _.cloneDeep(data[topLevelProperty]);
          // add topLevelProperty as a key on updatedFields, with the object we cloned + updated
          // as the value
          updatedFields[topLevelProperty] = _.set(
            fieldToUpdate,
            propertyChanged,
            currentInputState.value,
          );
        } else {
          // non-nested properties can just be set directly
          updatedFields[pathOnData] = currentInputState.value;
        }
        toastUpdates.push(input.displayName);
      } else if (!currentInputState.isValid && hasFieldChanged(pathOnData)) {
        _.set(invalidFields, pathOnData, {
          value: currentInputState.value,
          errorMessage: currentInputState.errorMessage,
        });
      }
    });
    if (!_.isEmpty(updatedFields)) {
      onSubmit(updatedFields);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: formName,
        form_content: { ...updatedFields },
      });
      if (toggleModal) toggleModal(false);
    } else if (!_.isEmpty(invalidFields)) {
      reportToSegment(types.TRACK, eventNames.formFailed, {
        label: formName,
        form_content: { ...invalidFields },
      });
    }
    setDisableSubmit(true);
    if (displayToast) showToast(toastUpdates);
  };


  return {
    formState,
    handleChange,
    handleBlur,
    handleSubmit,
    disableSubmit,
  };
};

export {
  useForm,
  Form,
  FormControlWrapper,
  ImageUpload,
  RadioGroup,
  TextInput,
};
