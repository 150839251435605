const staticText = {
  title: 'Verify your email',
  sendVerificationOpening: 'In order to continue using the Smartcar Dashboard',
  image: {
    url: '/assets/graphics/smartcar_envelope.svg',
    alt: 'Open envelope with a page with a Smartcar logo on it',
  },
};

export default staticText;
