/* istanbul ignore file */
import React from 'react';

const PhevEngineIcon = () => {
  return (
    <svg
      className="engine-type"
      width="11"
      height="17"
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.00875 4.125L9 1.5C9 1.01875 8.60625 0.625 8.125 0.625C7.64375 0.625 7.25 1.01875 7.25 1.5V4.125H3.75V1.5C3.75 1.01875 3.35625 0.625 2.875 0.625C2.39375 0.625 2 1.01875 2 1.5V4.125H1.99125C1.0375 4.125 0.25 4.9125 0.25 5.86625V9.94375C0.25 10.4075 0.43375 10.8538 0.7575 11.1775L3.3125 13.75V15.5C3.3125 15.9812 3.70625 16.375 4.1875 16.375H6.8125C7.29375 16.375 7.6875 15.9812 7.6875 15.5V13.75L10.2425 11.195C10.5662 10.8625 10.75 10.4163 10.75 9.9525V5.86625C10.75 4.90375 9.97125 4.125 9.00875 4.125Z" fill="#6D6D6D" />
    </svg>
  );
};

export default PhevEngineIcon;
