import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconPadding = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(1),
  height: '17px',
  width: '11px',
}));

export const RequirementsList = styled(Typography, {
  shouldForwardProp: prop => prop !== 'statusClass',
})(({ theme, statusClass }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(statusClass === 'success' && {
    color: theme.palette.success.main,
  }),
  ...(statusClass === 'danger' && {
    color: theme.palette.error.main,
  }),
}));
