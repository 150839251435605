import errorMessages from './errorMessages.json';

export default function isValid(url) {
  // privacy policy is an optional field, so empty string is a valid input
  if (url === '') {
    return true;
  }
  if (!url.startsWith('http')) {
    throw new Error(errorMessages.invalidScheme);
  }
  // URL isn't supported in IE
  if (typeof URL === 'function') {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
    } catch (err) {
      throw new Error(errorMessages.invalidUrl);
    }
  }
  return true;
}
