import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import ApplicationHeader from '../ApplicationHeader';
import UpdateOrg from './components/UpdateOrg';
import { Section } from '../../styles';

const staticText = {
  heading: 'Team settings',
};

const OrgSettings = ({ selectedApp }) => {
  const theme = useTheme();

  useEffect(() => {
    reportToSegment(types.PAGE, 'Team Settings');
  }, []);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '750px' }}>
      <ApplicationHeader
        heading={staticText.heading}
        backLink={selectedApp && selectedApp.id ? ({
          path: `/apps/${selectedApp.id}`,
          text: `Back to ${selectedApp.name} application`,
        }) : {}}
      />
      <Section>
        <UpdateOrg />
      </Section>
    </Box>
  );
};

export default OrgSettings;

OrgSettings.propTypes = {
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
};
