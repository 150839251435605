import { Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardOption = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  ':not(.disabled):hover, &.checked': {
    backgroundColor: '#f3f8fb', // TODO: extract color
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.black,
  },
  '&:hover': {
    boxShadow: theme.border.boxShadow3,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,

  '>.MuiFormControlLabel-label': {
    width: '100%',
  },
}));
