import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterContainer = styled('div')(({ theme }) => ({
  height: '85px',
  marginTop: theme.spacing(8),
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
  },
}));

export const FooterContent = styled('div')(({ theme }) => ({
  maxWidth: '1000px',
  margin: theme.spacing(0, 'auto'),
  paddingTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const FooterLink = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  '& a': {
    marginLeft: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),

    '& a': {
      display: 'block',
      marginLeft: 0,
    },
  },
}));
