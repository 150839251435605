import React from 'react';
import { func, object, string } from 'prop-types';
import { Button, styled, TextField, Typography, useTheme } from '@mui/material';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

import CopyButton from '../CopyButton';
import PermissionGate from '../PermissionGate';

const SecretInput = styled(TextField)(({ theme }) => ({
  minWidth: '230px',

  '.MuiOutlinedInput-root:hover fieldset': {
    borderColor: theme.palette.grey[200],
  },
  '.MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.text.primary,
    padding: '8px 0',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '0px !important',
  },
}));

const RegenerativeField = ({
  content, credentialsEvent, regenerateHandler, updateOnboardingEvent,
}) => {
  const theme = useTheme();

  const sendOnboarding = updateOnboardingEvent && credentialsEvent.eventStatus === false;
  const updateCredentialsEvent = () => {
    updateOnboardingEvent(credentialsEvent.eventName);
  };

  const handleMouseUp = () => {
    const selection = window.getSelection().toString();

    if (content && selection === content) {
      updateCredentialsEvent();
    }
  };

  return (
    <React.Fragment>
      <Typography
        variant="copyable"
        {...(sendOnboarding && { onMouseUp: handleMouseUp })}
      >
        {content || (
          <SecretInput
            type="password"
            disabled
            value="************************************"
          />
        )}
      </Typography>
      {content && (
        <CopyButton
          content={content}
          {...(sendOnboarding && { updateCredentialsEvent })}
        />
    )}
      <PermissionGate dashboardPermission="write_configuration">
        <Button onClick={regenerateHandler} display="flex" id="regenerate-button">
          <CachedRoundedIcon sx={{ marginRight: theme.spacing(0.5), marginTop: '-2px', fontSize: '20px' }} />
          {/* TODO: extract text to localization */}
          Regenerate
        </Button>
      </PermissionGate>
    </React.Fragment>
  );
};

export default RegenerativeField;

RegenerativeField.propTypes = {
  content: string,
  credentialsEvent: object,
  regenerateHandler: func.isRequired,
  updateOnboardingEvent: func,
};

RegenerativeField.defaultProps = {
  content: '',
  credentialsEvent: {},
  updateOnboardingEvent: null,
};

