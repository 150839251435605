import { StepConnector as MuiStepConnector, StepLabel as MuiStepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Form = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(4.5),
}));

export const StepConnector = styled(MuiStepConnector)(({ theme }) => ({
  '&.first-connector': {
    left: theme.spacing(-6), // offset margin of stepper
  },
  '&.last-connector': {
    left: 'calc(50% + 20px)',
    right: theme.spacing(-6), // offset margin of stepper
  },
  '>.MuiStepConnector-line': {
    borderColor: '#80C0CE',
    borderWidth: '2px',
    opacity: 0.2,
  },
}));

export const StepLabel = styled(MuiStepLabel, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  '& .MuiStepLabel-label': {
    color: `${theme.palette.text[active ? 'primary' : 'disabled']} !important`,
    fontSize: '0.875rem',
    '&.Mui-active': {
      fontWeight: 600,
    },
  },
}));
