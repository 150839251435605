const staticText = {
  heading: 'Members',
  deleteOrgInviteSuccessToast: 'Successfully deleted invitation',
  deleteOrgMemberSuccessToast: 'Successfully deleted team member',
  updateOrgMemberSuccessToast: 'Successfully updated team member',
  addMembers: 'Invite members',
};

export const modalType = {
  sendInvites: 'sendInvites',
  editUser: 'editUser',
  deleteUser: 'deleteUser',
};

export default staticText;
