import { TimelineConnector, TimelineDot } from '@mui/lab';
import { styled } from '@mui/material';

export const BaseTimelineConnector = styled(TimelineConnector, {
  shouldForwardProp: prop => prop !== 'transparent',
})(({ theme, transparent }) => ({
  backgroundColor: transparent ? 'transparent' : theme.palette.primary.main,
  opacity: 0.2,
}));

export const BaseTimelineDot = styled(TimelineDot)(({ theme }) => ({
  margin: '0px',
  boxShadow: 'none',
  backgroundColor: theme.palette.common.white,
}));

export const CustomDotContainer = styled('div')(() => ({
  width: '22.85px',
  height: '22.85px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CustomDot = styled('div')(({ theme }) => ({
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
  borderRadius: '50%',
  border: `2px solid ${theme.palette.primary.main}`,
  opacity: '50%',
}));

