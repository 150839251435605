import { reportToSegment, types, eventNames } from '@smartcar/morse';

export const toggleModal = (setCurrentModal, target) => () =>
  setCurrentModal(target);

export const getToggleModalWithTracking = (title, togModal) => () => {
  reportToSegment(types.TRACK, eventNames.modalClosed, {
    label: 'billing modal',
    title,
  });

  togModal();
};

