import _ from 'lodash';

export const types = {
  CLOSE_FREE_TRIAL_MODAL: 'developer-client/billing/CLOSE_FREE_TRIAL_MODAL',

  FETCH_BILLING_INFO: 'developer-client/billing/FETCH_BILLING_INFO',
  FETCH_BILLING_INFO_SUCCESS:
    'developer-client/billing/FETCH_BILLING_INFO_SUCCESS',
  FETCH_BILLING_INFO_FAILURE:
    'developer-client/billing/FETCH_BILLING_INFO_FAILURE',

  INITIALIZE_BILLING: 'developer-client/billing/INITIALIZE_BILLING',
  INITIALIZE_BILLING_SUCCESS:
    'developer-client/billing/INITIALIZE_BILLING_SUCCESS',
  INITIALIZE_BILLING_FAILURE:
    'developer-client/billing/INITIALIZE_BILLING_FAILURE',

  UPDATE_BILLING_INFO: 'developer-client/billing/UPDATE_BILLING_INFO',
  UPDATE_BILLING_INFO_SUCCESS:
    'developer-client/billing/UPDATE_BILLING_INFO_SUCCESS',
  UPDATE_BILLING_INFO_FAILURE:
    'developer-client/billing/UPDATE_BILLING_INFO_FAILURE',

  SET_HAS_HANDLED_STRIPE_REDIRECT: 'developer-client/billing/SET_HAS_HANDLED_STRIPE_REDIRECT',
};

export const initialState = {
  billingInfo: {},
  isFetchingBillingInfo: false,
  isUpdatingBillingInfo: false,
  hasHandledStripeRedirect: false,
  billingInfoError: '',
  updateBillingInfoError: '',
  showFreeTrialModal: false,
};

export const actions = {
  closeFreeTrialModal: () => ({
    type: types.CLOSE_FREE_TRIAL_MODAL,
    payload: false,
    meta: null,
  }),
  fetchBillingInfo: initialization => ({
    type: types.FETCH_BILLING_INFO,
    payload: { initialization },
    meta: null,
  }),
  fetchBillingInfoSuccess: billingInfo => ({
    type: types.FETCH_BILLING_INFO_SUCCESS,
    payload: billingInfo,
    meta: null,
  }),
  fetchBillingInfoFailure: error => ({
    type: types.FETCH_BILLING_INFO_FAILURE,
    payload: error,
    meta: null,
  }),
  initializeBilling: () => ({
    type: types.INITIALIZE_BILLING,
    payload: null,
    meta: null,
  }),
  initializeBillingSuccess: showFreeTrialModal => ({
    type: types.INITIALIZE_BILLING_SUCCESS,
    payload: showFreeTrialModal,
    meta: null,
  }),
  initializeBillingFailure: error => ({
    type: types.INITIALIZE_BILLING_FAILURE,
    payload: error,
    meta: null,
  }),
  updateBillingInfo: (planName, stripeCheckoutSessionId) => ({
    type: types.UPDATE_BILLING_INFO,
    payload: planName,
    meta: stripeCheckoutSessionId,
  }),
  updateBillingInfoSuccess: billingInfo => ({
    type: types.UPDATE_BILLING_INFO_SUCCESS,
    payload: billingInfo,
    meta: null,
  }),
  updateBillingInfoFailure: error => ({
    type: types.UPDATE_BILLING_INFO_FAILURE,
    payload: error,
    meta: null,
  }),
  setHasHandledStripeRedirect: status => ({
    type: types.SET_HAS_HANDLED_STRIPE_REDIRECT,
    payload: status,
  }),
};

export const selectors = {
  getCurrentPlan: (state) => {
    return _.get(state, ['billing', 'billingInfo', 'planName']);
  },
  getBillingAccountId: (state) => {
    return _.get(state, ['billing', 'billingInfo', 'billingAccountId']);
  },
  getBillingPeriod: (state) => {
    return {
      billingPeriodStartDate: _.get(state, [
        'billing',
        'billingInfo',
        'billingPeriodStartDate',
      ]),
      billingPeriodEndDate: _.get(state, [
        'billing',
        'billingInfo',
        'billingPeriodEndDate',
      ]),
    };
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CLOSE_FREE_TRIAL_MODAL: {
      return {
        ...state,
        showFreeTrialModal: action.payload,
      };
    }
    case types.FETCH_BILLING_INFO: {
      return {
        ...state,
        isFetchingBillingInfo: true,
        billingInfoError: '',
        updateBillingInfoError: '',
      };
    }
    case types.FETCH_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        billingInfo: action.payload,
        isFetchingBillingInfo: false,
      };
    }
    case types.FETCH_BILLING_INFO_FAILURE: {
      return {
        ...state,
        isFetchingBillingInfo: false,
        billingInfoError:
          (action.payload && action.payload.response.data.message) ||
          'Error fetching billing info',
      };
    }
    case types.INITIALIZE_BILLING: {
      // Allows resetting billing when switching orgs
      return initialState;
    }
    case types.INITIALIZE_BILLING_SUCCESS: {
      return {
        ...state,
        showFreeTrialModal: action.payload,
      };
    }
    case types.INITIALIZE_BILLING_FAILURE: {
      return {
        ...state,
        showFreeTrialModal: false,
        billingInfoError:
          (action.payload && action.payload.response.data.message) ||
          'Error initializing billing info',
      };
    }
    case types.UPDATE_BILLING_INFO: {
      return {
        ...state,
        isUpdatingBillingInfo: true,
        updateBillingInfoError: '',
      };
    }
    case types.UPDATE_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        billingInfo: action.payload,
        isUpdatingBillingInfo: false,
      };
    }
    case types.UPDATE_BILLING_INFO_FAILURE: {
      let displayMessage = 'Error updating billing information.';
      if (action.payload && action.payload.response && action.payload.response.status === 422) {
        displayMessage = 'We’ve experienced a problem with your credit card.';
      }

      return {
        ...state,
        isUpdatingBillingInfo: false,
        updateBillingInfoError: displayMessage,
      };
    }
    case types.SET_HAS_HANDLED_STRIPE_REDIRECT: {
      return {
        ...state,
        hasHandledStripeRedirect: action.payload,
      };
    }
    default:
      return state;
  }
}
