import React, { useEffect, useState } from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

import { Select } from '../../styles';
import { SelectIcon } from '../../../../../../../../components';

const MMYForm = ({
  allCompatibleVehicles,
  selectedRegion,
  selectedVehicle,
  setSelectedVehicle,
  handleVehicleSearchSubmit,
  showLastSearch,
}) => {
  const getModels = (selectedMake) => {
    return Object.keys(allCompatibleVehicles[selectedRegion][selectedMake]);
  };
  const getYears = (selectedMake, selectedModel) => {
    return allCompatibleVehicles[selectedRegion][selectedMake][selectedModel]
      .map(vehicle => vehicle.year)
      .sort((a, b) => b - a);
  };

  const formInitialState = {
    make: '',
    model: '',
    year: '',
    makes: Object.keys(allCompatibleVehicles[selectedRegion]),
    models: [],
    years: [],
  };

  const [formState, setFormState] = useState(formInitialState);
  const {
    make, model, year, makes, models, years,
  } = formState;

  const handleMMYSubmit = () => {
    const { id } = allCompatibleVehicles[selectedRegion][make][model].find(
      vehicle => vehicle.year === year,
    );
    const vehicle = {
      vehicleDefinitionId: id,
      make,
      model,
      year: Number(year),
      region: selectedRegion,
    };

    handleVehicleSearchSubmit('Simulator MMY Search Form', vehicle);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const updatedState = {};
    if (name === 'make') {
      setSelectedVehicle({});
      updatedState.make = value;
      const mods = getModels(value);
      updatedState.models = mods;
      if (mods.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        updatedState.model = mods[0];
        updatedState.years = getYears(value, mods[0]);
      } else {
        updatedState.model = '';
        updatedState.years = [];
      }
      updatedState.year = '';
    } else if (name === 'model') {
      setSelectedVehicle({});
      updatedState.model = value;
      updatedState.year = '';
      updatedState.years = getYears(make, value);
    } else if (name === 'year') {
      updatedState.year = value;
    }
    setFormState(prevState => ({
      ...prevState,
      ...updatedState,
    }));
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[simulator] MMY form: select vehicle's ${name}`,
    });
  };

  useEffect(() => {
    if (showLastSearch && selectedVehicle.region === selectedRegion) {
      setFormState({
        make: (selectedVehicle.make) || '',
        model: (selectedVehicle.model) || '',
        year: (selectedVehicle.year) || '',
        makes: Object.keys(allCompatibleVehicles[selectedRegion]),
        models: (selectedVehicle.make) ? getModels(selectedVehicle.make) : [],
        years: (selectedVehicle.make && selectedVehicle.model) ?
          getYears(selectedVehicle.make, selectedVehicle.model) : [],
      });
    } else {
      setFormState(formInitialState);
      setSelectedVehicle({});
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (make && model && year) {
      handleMMYSubmit();
    }
  }, [year]);

  return (
    <form onSubmit={handleMMYSubmit}>
      <FormControl variant="outlined">
        <InputLabel id="make-select-label">Make</InputLabel>
        <Select
          name="make"
          value={make}
          id="make-select"
          label="Make"
          labelId="make-select-label"
          IconComponent={SelectIcon}
          onChange={handleSelectChange}
          variant="outlined"
        >
          {makes.sort().map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" disabled={models.length <= 1}>
        <InputLabel id="model-select-label">Model</InputLabel>
        <Select
          name="model"
          value={model}
          id="model-select"
          label="Model"
          labelId="model-select-label"
          IconComponent={SelectIcon}
          onChange={handleSelectChange}
          variant="outlined"
        >
          {models.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" disabled={!years.length}>
        <InputLabel id="year-select-label">Year</InputLabel>
        <Select
          name="year"
          value={year}
          id="year-select"
          label="Year"
          labelId="year-select-label"
          IconComponent={SelectIcon}
          onChange={handleSelectChange}
          variant="outlined"
        >
          {years.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
            ))}
        </Select>
      </FormControl>
    </form>
  );
};

export default MMYForm;

MMYForm.propTypes = {
  allCompatibleVehicles: object.isRequired,
  showLastSearch: bool.isRequired,
  selectedRegion: string.isRequired,
  selectedVehicle: shape({
    make: string,
    model: string,
    year: number,
  }),
  setSelectedVehicle: func.isRequired,
  handleVehicleSearchSubmit: func.isRequired,
};

MMYForm.defaultProps = {
  selectedVehicle: {},
};
