import React, { useState } from 'react';
import { bool, node, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Typography } from '@mui/material';

import secretProps, { defaultProps } from '../../../../types/secret';
import { setScrollAbility } from '../../../../services/styleUtils';
import staticText from '../../../../localization/Application/configuration';

import RegenerativeField from '../../../RegenerativeField';
import ValidateNameModal from '../../../ValidateNameModal';

const Secret = ({
  actions: {
    regenerateSecret,
    updateOnboardingEvent,
  },
  applicationId,
  applicationName,
  credentialsEvent,
  secret,
  secretWarning,
}) => {
  const [open, setOpen] = useState(false);

  const toggleModal = (withTracking = true) => {
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${open ? 'Closed' : 'Opened'}`], {
        label: 'regenerate secret',
        title: 'Regenerate client secret',
      });
    }
    setScrollAbility(open);
    setOpen(!open);
  };

  const handleRegenerateSecret = () => {
    regenerateSecret(applicationId);
  };
  // TODO: extract text to localization
  const description = (
    <div>
      This will permanently change the client secret of the application
      <span className="emphasized-content"> {applicationName}</span>
      . Type your application name in the box below to confirm this change.
    </div>
  );

  return (
    <Box>
      {open && (
        <ValidateNameModal
          actionTitle="Regenerate client secret"
          actionDescription={description}
          applicationName={applicationName}
          submitAction={handleRegenerateSecret}
          toggleModal={toggleModal}
        />
      )}
      <Typography variant="h3" marginBottom={2}>{staticText.oauth.clientSecret}</Typography>
      {secret && secretWarning}
      <RegenerativeField
        content={secret}
        regenerateHandler={toggleModal}
        updateOnboardingEvent={updateOnboardingEvent}
        credentialsEvent={credentialsEvent}
      />
    </Box>
  );
};

export default Secret;

Secret.propTypes = {
  ...secretProps,
  actions: shape({
    ...secretProps.actions,
  }).isRequired,
  credentialsEvent: shape({
    eventName: string.isRequired,
    eventStatus: bool.isRequired,
  }).isRequired,
  secretWarning: node,
};

Secret.defaultProps = {
  ...defaultProps,
  secretWarning: null,
};
