import React from 'react';
import { string, number, arrayOf, node, shape } from 'prop-types';

import { getWebhookInformation } from '../../../WebhookInformationModal/WebhookInformationModal';

const Active = ({ webhook, feedback }) => {
  return (
    <div>
      {feedback}
      {getWebhookInformation(webhook)}
    </div>
  );
};

Active.propTypes = {
  webhook: shape({
    id: string.isRequired,
    callbackUri: string.isRequired,
    permissions: arrayOf(string),
    subscribedVehicles: number.isRequired,
  }).isRequired,
  feedback: node.isRequired,
};

export default Active;
