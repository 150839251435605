import React from 'react';
import { arrayOf, bool, func, number, object } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { TimelineControls, Stage } from './components';
import { TimelineBox, ProgressBar } from './styles';
import staticText from '../../../../../../../../localization/Application/Simulator/simulator';

const Timeline = ({
  selectedVehicle,
  stages,
  frameIndex,
  tripActive,
  tripPaused,
  handleCreateTrip,
  handleTripAction,
  handleSpeedChange,
  selectedTripSpeed,
  autoplay,
}) => {
  const theme = useTheme();

  const totalDuration = stages.reduce((duration, stage) => duration + stage.duration, 0);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" sx={{ color: theme.palette.common.black }}>{staticText.timelineHeading}</Typography>
        <TimelineControls
          handleCreateTrip={handleCreateTrip}
          handleTripAction={handleTripAction}
          handleSpeedChange={handleSpeedChange}
          tripStatus={{
            tripStarted: tripActive,
            tripPaused,
            tripSpeed: selectedTripSpeed,
          }}
          disabled={autoplay}
        />
      </Box>
      <TimelineBox>
        <ProgressBar
          percentComplete={(frameIndex / totalDuration) * 100}
        />
        <Box display="flex" position="absolute" width="100%">
          {stages.map((stage, index) => {
          const active = stage.startFrameIndex <= frameIndex;
          return (
            <Stage
              key={stage.startFrameIndex}
              stage={stage}
              index={index}
              active={active}
              handleTripAction={handleTripAction}
              tripStarted={tripActive}
              tripDuration={totalDuration}
              vehicleType={selectedVehicle.vehicleType}
              autoplay={autoplay}
            />
          );
        })}
        </Box>
      </TimelineBox>
    </div>
  );
};

export default Timeline;

Timeline.propTypes = {
  stages: arrayOf(object).isRequired,
  frameIndex: number.isRequired,
  tripActive: bool.isRequired,
  tripPaused: bool.isRequired,
  selectedTripSpeed: number.isRequired,
  selectedVehicle: object.isRequired,
  handleCreateTrip: func.isRequired,
  handleTripAction: func.isRequired,
  handleSpeedChange: func.isRequired,
  autoplay: bool.isRequired,
};
