import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { Card } from '../../../../../../components';
import { Border, Container } from './styles';

const CardSet = ({ activeMembers }) => {
  const countByRole = activeMembers.reduce((acc, member) => {
    acc[member.dashboardRole] = (acc[member.dashboardRole] || 0) + 1;
    return acc;
  }, { total: activeMembers.length });

  const cards = [
    {
      header: 'MEMBERS',
      title: countByRole.total,
      minWidth: '400px',
    },
    {
      header: 'OWNERS',
      title: countByRole.owner || 0,
      minWidth: '100px',
    },
    {
      header: 'ADMINS',
      title: countByRole.admin || 0,
      minWidth: '100px',
    },
    {
      header: 'EDITORS',
      title: countByRole.editor || 0,
      minWidth: '100px',
    },
    {
      header: 'VIEWERS',
      title: countByRole.viewer || 0,
      minWidth: '100px',
    },
  ];

  return (
    <Container>
      {cards.reduce((acc, card, i) => {
        acc.push(<Card key={card.header} {...card} />);
        if (i !== cards.length - 1) {
          acc.push(
            <div
              key={`card-spacer-${card.header}`}
            >
              <Border />
            </div>,
          );
        }
        return acc;
      }, [])}
    </Container>
  );
};

export default CardSet;

CardSet.propTypes = {
  activeMembers: arrayOf(shape({
    id: string,
    dashboardRole: string,
  })).isRequired,
};
