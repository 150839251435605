import _ from 'lodash';

import initialStatusCodes from '../../../../../config/requestLogs/multiSelectConfig';
import { errors } from '../../../../localization/Application/Logs/logs';

export const PAGE_SIZE = 20;

export const types = {
  FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST: 'developer-client/request-logs/FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST',
  FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS: 'developer-client/request-logs/FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS',
  FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE: 'developer-client/request-logs/FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE',

  FETCH_REQUEST_LOGS: 'developer-client/request-logs/FETCH_REQUEST_LOGS',
  FETCH_REQUEST_LOGS_SUCCESS: 'developer-client/request-logs/FETCH_REQUEST_LOGS_SUCCESS',
  FETCH_REQUEST_LOGS_FAILURE: 'developer-client/request-logs/FETCH_REQUEST_LOGS_FAILURE',

  UPDATE_PAGINATION_MODEL: 'developer-client/request-logs/UPDATE_PAGINATION_MODEL',
  UPDATE_FILTER_VALUES: 'developer-client/request-logs/UPDATE_FILTER_VALUES',

  FETCH_EXPORT_DATA: 'developer-client/request-logs/FETCH_EXPORT_DATA',
  FETCH_EXPORT_DATA_SUCCESS: 'developer-client/request-logs/FETCH_EXPORT_DATA_SUCCESS',
  FETCH_EXPORT_DATA_FAILURE: 'developer-client/request-logs/FETCH_EXPORT_DATA_FAILURE',

  FETCH_LOG_DRAWER_VEHICLE_DETAILS_REQUEST: 'developer-client/request-logs/FETCH_LOG_DRAWER_VEHICLE_DETAILS_REQUEST',
  FETCH_LOG_DRAWER_VEHICLE_DETAILS_SUCCESS: 'developer-client/request-logs/FETCH_LOG_DRAWER_VEHICLE_DETAILS_SUCCESS',
  FETCH_LOG_DRAWER_VEHICLE_DETAILS_FAILURE: 'developer-client/request-logs/FETCH_LOG_DRAWER_VEHICLE_DETAILS_FAILURE',

  SET_LOG_EXPORT_TRIGGERED:
  'developer-client/request-logs/SET_LOG_EXPORT_TRIGGERED.',
  UPDATE_STATUS_CODES_AND_MAKES_FROM_OVERVIEW_CHART: 'developer-client/request-logs/UPDATE_STATUS_CODES_AND_MAKES_FROM_OVERVIEW_CHART',

  RESET_SELECTED_CHART_OPTION: 'developer-client/request-logs/RESET_SELECTED_CHART_OPTION',
};

export const initialState = {
  isFetchingRequestLogs: false,
  isFetchingInitialRequestLogs: true,
  isFetchingCompatibilityMakes: true,
  isFetchingStatusCodes: false,
  isFetchingExportData: false,
  isFetchingLogDrawerVehicleDetails: false,

  exportData: '',
  logExportTriggered: false,

  pageInfo: {},
  paginationModel: {
    page: 0,
    pageSize: PAGE_SIZE, // limit
  },

  selectedChartOption: {
    statusCodes: null,
    makes: null,
    keepSelectedChartMakes: null,
  },

  compatibleMakes: {},
  logDrawerVehicleDetails: {},

  // offset and limit are query params along with other filter Values
  filterValues: {
    makes: {},
    statusCodes: initialStatusCodes,
    end: null,
    start: null,
    offset: 0,
    limit: PAGE_SIZE,
  },

  logErrors: {
    requestLogs: '',
    makes: '',
    statusCodes: '',
    exportData: '',
    logDrawerVehicleDetails: '',
  },
};

export const actions = {
  fetchAllCompatibleVehicles: () => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST,
  }),
  fetchAllCompatibleVehiclesSuccess: data => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS,
    payload: data,
  }),
  fetchAllCompatibleVehiclesFailure: err => ({
    type: types.FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE,
    payload: err,
  }),


  fetchRequestLogs: payload => ({
    type: types.FETCH_REQUEST_LOGS,
    payload,
  }),
  fetchRequestLogsSuccess: data => ({
    type: types.FETCH_REQUEST_LOGS_SUCCESS,
    payload: data,
  }),
  fetchRequestLogsFailure: err => ({
    type: types.FETCH_REQUEST_LOGS_FAILURE,
    payload: err,
  }),
  updatePaginationModel: model => ({
    type: types.UPDATE_PAGINATION_MODEL,
    payload: model,
  }),
  updateFilterValues: updatedFilterValues => ({
    type: types.UPDATE_FILTER_VALUES,
    payload: updatedFilterValues,
  }),

  fetchExportData: () => ({
    type: types.FETCH_EXPORT_DATA,
  }),
  fetchExportDataSuccess: csv => ({
    type: types.FETCH_EXPORT_DATA_SUCCESS,
    payload: csv,
  }),
  fetchExportDataFailure: error => ({
    type: types.FETCH_EXPORT_DATA_FAILURE,
    payload: error,
  }),

  setLogExportTriggered: isTriggered => ({
    type: types.SET_LOG_EXPORT_TRIGGERED,
    payload: isTriggered,
  }),
  updateStatusCodesAndMakesFromOverviewChart: data => ({
    type: types.UPDATE_STATUS_CODES_AND_MAKES_FROM_OVERVIEW_CHART,
    payload: data,
  }),

  fetchLogDrawerVehicleDetails: vehicleId => ({
    type: types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_REQUEST,
    payload: vehicleId,
  }),
  fetchLogDrawerVehicleDetailsSuccess: data => ({
    type: types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_SUCCESS,
    payload: data,
  }),
  fetchLogDrawerVehicleDetailsFailure: err => ({
    type: types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_FAILURE,
    payload: err,
  }),
  resetSelectedChartOption: () => ({
    type: types.RESET_SELECTED_CHART_OPTION,
  }),
};

export const selectors = {
  getRowCount: (state) => {
    return _.get(state, ['logs', 'pageInfo', 'rowCount']);
  },
  getFilterValues: (state) => {
    return _.get(state, ['logs', 'filterValues']);
  },
  getSelectedChartOption: (state) => {
    return _.get(state, ['logs', 'selectedChartOption']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_REQUEST:
      return {
        ...state,
        isFetchingCompatibilityMakes: true,
        logErrors: {
          ...state.logErrors,
          makes: '',
        },
      };
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_SUCCESS:
      return {
        ...state,
        compatibleMakes: action.payload,
        isFetchingCompatibilityMakes: false,
        filterValues: {
          ...state.filterValues,
        },
        logErrors: {
          ...state.logErrors,
          makes: '',
        },
      };
    case types.FETCH_ALL_COMPATIBLE_VEHICLES_FAILURE:
      return {
        ...state,
        isFetchingCompatibilityMakes: false,
        logErrors: {
          ...state.logErrors,
          makes:
          (action.payload && action.payload.message) || errors.makes,
        },
      };

    case types.FETCH_REQUEST_LOGS:
      return {
        ...state,
        isFetchingRequestLogs: true,
        logErrors: {
          ...state.logErrors,
          requestLogs: '',
        },
      };
    case types.FETCH_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        isFetchingInitialRequestLogs: false,
        isFetchingRequestLogs: false,
        pageInfo: action.payload.pageInfo,
      };

    case types.FETCH_REQUEST_LOGS_FAILURE:
      return {
        ...state,
        isFetchingInitialRequestLogs: false,
        isFetchingRequestLogs: false,
        logErrors: {
          ...state.logErrors,
          requestLogs: (action.payload && action.payload.message) || errors.requestLogs,
        },
      };

    case types.UPDATE_PAGINATION_MODEL:
      return {
        ...state,
        paginationModel: action.payload,
      };

    case types.UPDATE_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };

    case types.FETCH_EXPORT_DATA:
      return {
        ...state,
        isFetchingExportData: true,
        exportData: '',
        logErrors: {
          ...state.logErrors,
          exportData: '',
        },
      };
    case types.FETCH_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        isFetchingExportData: false,
        logExportTriggered: true,
        exportData: action.payload,
      };
    case types.FETCH_EXPORT_DATA_FAILURE:
      return {
        ...state,
        isFetchingExportData: false,
        logExportTriggered: false,
        logErrors: {
          ...state.logErrors,
          exportData: (action.payload && action.payload.message) || errors.exportData,
        },
      };

    case types.SET_LOG_EXPORT_TRIGGERED:
      return {
        ...state,
        logExportTriggered: action.payload,
      };
    case types.UPDATE_STATUS_CODES_AND_MAKES_FROM_OVERVIEW_CHART:
      return {
        ...state,
        selectedChartOption: {
          statusCodes: action.payload.statusCodes && action.payload.statusCodes,
          makes: action.payload.makes && action.payload.makes,
          keepSelectedChartMakes: action.payload.keepSelectedChartMakes
            && action.payload.keepSelectedChartMakes,
        },
      };

    case types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingLogDrawerVehicleDetails: true,
        logDrawerVehicleDetails: {},
        logErrors: {
          ...state.logErrors,
          logDrawerVehicleDetails: '',
        },
      };
    case types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingLogDrawerVehicleDetails: false,
        logDrawerVehicleDetails: action.payload,
      };
    case types.FETCH_LOG_DRAWER_VEHICLE_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingLogDrawerVehicleDetails: false,
        logErrors: {
          ...state.logErrors,
          logDrawerVehicleDetails:
          (action.payload && action.payload.message) || errors.logDrawerVehicleDetails,
        },
      };

    case types.RESET_SELECTED_CHART_OPTION:
      return {
        ...state,
        selectedChartOption: {
          statusCodes: null,
          makes: null,
          keepSelectedChartMakes: null,
        },
      };

    default:
      return state;
  }
}
