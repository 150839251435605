import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import loadingShimmerStyles from '../../../../../../global-styles/animations/loadingShimmer';

export const Widget = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow,
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  textTransform: 'none',
}));

export const Stat = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  fontFamily: theme.typography.bold.monospace,
  fontSize: theme.typography.fontSizes.lg,
  color: theme.palette.primary.main,
}));

export const Unit = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.monospace,
  fontSize: theme.typography.fontSizes.xs,
  color: theme.palette.grey[600],
}));

export const ErrorContainer = styled('div')({
  width: '206px',
});

const loadingBarProps = {
  borderRadius: '2px',
  width: '124px',
  ...loadingShimmerStyles,
};

export const LoadingBar1 = styled('div')(({ theme }) => ({
  height: '18px',
  marginBottom: theme.spacing(3),
  ...loadingBarProps,
}));

export const LoadingBar2 = styled('div')({
  height: '64px',
  ...loadingBarProps,
});
