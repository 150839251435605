import { Chip, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)} 0 0 !important`,
  border: 'none',
  backgroundColor: theme.palette.grey[200],
}));

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  width: '100%',

  ...(!error ? {
    marginBottom: '18.5px',
  } : {}),

  '& > .MuiInputBase-root': {
    flexWrap: 'wrap',
    minHeight: '64px',
    padding: theme.spacing(2),
    rowGap: theme.spacing(1),

    '& > .MuiOutlinedInput-input': {
      width: 'auto',
      flexGrow: 1,
      padding: '0px',
    },
  },

  '& button': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
