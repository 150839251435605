import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { Box, FormControlLabel, RadioGroup as MuiRadioGroup, Radio, Tooltip, styled } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

export const RadioButtonLabel = styled(FormControlLabel)(({ theme }) => ({
  marginRight: '40px',

  '.MuiBox-root > svg': {
    fontSize: '16px',
    marginLeft: '4px',
    marginTop: '-2px',
    fill: theme.palette.grey[500],
  },
}));

const RadioGroup = ({
  pathOnData, handleChange, handleBlur, currentInputState, options,
}) => {
  return (
    <MuiRadioGroup
      id={`${pathOnData}-radio-select`}
      row
      onChange={handleChange}
      onBlur={handleBlur}
      value={currentInputState.value || options[0].value}
      name={pathOnData}
    >
      {options.map(option => (
        <RadioButtonLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.tooltip ? (
            <Box display="flex" alignItems="center">
              {option.label}
              <Tooltip placement="top" title={option.tooltip}>
                <InfoOutlined />
              </Tooltip>
            </Box>
            ) : option.label}
        />
      ))}
    </MuiRadioGroup>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  pathOnData: string.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  currentInputState: shape({
    isValid: bool,
    value: string,
    visited: bool,
    errorMessage: string,
  }).isRequired,
  options: arrayOf(shape({
    label: string.isRequired,
    value: string.isRequired,
    tooltip: string,
  })).isRequired,
};
