import { styled } from '@mui/material/styles';

export const OverviewContainer = styled('div')(() => ({
  maxWidth: '100% !important',
}));

export const PanelContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '4fr 25fr',
  columnGap: '24px',
  [theme.breakpoints.down('iPadPro')]: {
    gridTemplateColumns: '1fr 4fr',
  },
}));

export const WidgetsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'repeat(3, 1fr)',
  rowGap: '24px',
  [theme.breakpoints.down('xl')]: {
    maxHeight: '900px',
  },
}));

export const GraphsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  rowGap: '24px',
  columnGap: '24px',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: '1fr',
  },
}));
