import React from 'react';
import { func } from 'prop-types';
import { Button, Typography } from '@mui/material';
import { CheckCircleRounded } from '@mui/icons-material';

import staticText from '../../localization/Components/sendVerificationEmailSuccess';

const SendVerificationEmailSuccess = ({ sendVerificationEmailRequest }) => {
  const handleSendVerificationEmail = () => {
    sendVerificationEmailRequest();
  };

  const resendButton = (
    <Button
      id="resend-verification-button"
      variant="text"
      sx={{ p: 0 }}
      onClick={handleSendVerificationEmail}
    >
      {staticText.resendEmailButtonText}
    </Button>
  );

  return (
    <React.Fragment>
      <CheckCircleRounded fontSize="large" color="secondary" sx={{ mb: 1 }} />
      <Typography variant="body1" sx={{ mb: 2 }}>{staticText.successMessage}</Typography>
      <Typography variant="body1">
        {staticText.tryAgainMessage}
        {resendButton}
      </Typography>
    </React.Fragment>
  );
};

export default SendVerificationEmailSuccess;

SendVerificationEmailSuccess.propTypes = {
  sendVerificationEmailRequest: func.isRequired,
};
