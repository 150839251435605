import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Button, TextField, Typography, useTheme, styled } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import staticText from '../../../../../../../../localization/Application/application';
import textInputValidator from '../../../../../../../../services/validators/textInputValidator';

const Form = styled('form')(({ theme }) => ({
  '& > *': {
    marginTop: theme.spacing(3),
  },
  '.MuiFormControl-root': {
    width: '100%',
  },
  '.MuiInput-root': {
    marginTop: theme.spacing(1.5),
  },
  textarea: {
    padding: 0,
  },
}));

const SupportForm = ({
  actions: {
    sendSupportRequest,
    resetSupportForm,
  },
  applicationId,
  userContext: {
    dashboardUserId,
    email,
    firstName,
    lastName,
  },
  selectedOrganization,
  sendingSupportRequest,
  supportRequestSuccess,
  supportRequestError,
}) => {
  const theme = useTheme();

  const [formState, setFormState] = useState({
    subject: { value: '', valid: true, visited: false },
    message: { value: '', valid: true, visited: false },
  });

  const { subject, message } = formState;

  const handleInputChange = (event) => {
    const { value, name } = event.target;

    setFormState(prevState => ({
      ...prevState,
      [name]: {
        value,
        valid: textInputValidator(value),
        visited: false,
      },
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;

    setFormState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        visited: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const content = {
      subject: subject.value,
      message: message.value,
      developerId: selectedOrganization,
      dashboardUserId,
      applicationId,
      name: `${firstName} ${lastName}`,
      email,
    };
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'contact support attempt',
      form_content: content,
    });
    sendSupportRequest(content);
  };

  useEffect(() => {
    resetSupportForm();
    reportToSegment(types.PAGE, 'Contact Support Modal');
  }, []);

  const invalidSubject = subject.visited && !subject.valid;
  const invalidMessage = message.visited && !message.valid;

  return supportRequestSuccess ? (
    <div>
      <Typography>{staticText.supportModal.form.successMessage}</Typography>
    </div>
  ) : (
    <div>
      <Typography>{staticText.supportModal.text}</Typography>
      <Form onSubmit={handleSubmit} id="support-form">
        <div>
          <TextField
            label={staticText.supportModal.form.subject.title}
            value={subject.value}
            id="subject-input"
            name="subject"
            placeholder={staticText.supportModal.form.subject.placeholder}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={invalidSubject}
            inputProps={{ maxlength: 60 }}
            autoFocus
            helperText={invalidSubject ?
              staticText.supportModal.form.inputError(4, 60) :
              staticText.supportModal.form.subject.helperText}
            FormHelperTextProps={{
              sx: {
                marginLeft: '0',
                color: invalidSubject ?
                  theme.palette.error.main : theme.palette.text.disabled,
              },
            }}
          />
        </div>
        <div>
          <TextField
            id="message-input"
            name="message"
            multiline
            rows={5}
            value={message.value}
            label={staticText.supportModal.form.message.title}
            placeholder={staticText.supportModal.form.message.placeholder}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            error={invalidMessage}
            inputProps={{ maxlength: 500 }}
            helperText={invalidMessage ?
              staticText.supportModal.form.inputError(4, 500) :
              staticText.supportModal.form.message.helperText}
            FormHelperTextProps={{
              sx: {
                marginLeft: '0',
                color: invalidMessage ?
                theme.palette.error.main : theme.palette.text.disabled,
              },
            }}
          />
        </div>
        <div>
          <Button
            id="support-form-submit-btn"
            type="submit"
            variant="contained"
            disabled={
              sendingSupportRequest ||
              !subject.value || !subject.valid ||
              !message.value || !message.valid
            }
          >{staticText.supportModal.form.submit}
          </Button>
        </div>
        {supportRequestError && (
          <Typography color="error" variant="caption" marginTop={2}>
            {staticText.supportModal.form.submissionError}
          </Typography>
        )}
      </Form>
    </div>
  );
};

export default SupportForm;

SupportForm.propTypes = {
  actions: shape({
    sendSupportRequest: func.isRequired,
    resetSupportForm: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  sendingSupportRequest: bool.isRequired,
  supportRequestSuccess: bool.isRequired,
  supportRequestError: bool.isRequired,
  userContext: shape({
    developerId: string.isRequired,
    firstName: string,
    lastName: string,
    email: string.isRequired,
  }).isRequired,
  selectedOrganization: string.isRequired,
};
