import React from 'react';
import { func } from 'prop-types';
import { IconButton } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

const OpenDrawerIcon = ({ handleClick }) => {
  return (
    <IconButton onClick={handleClick} aria-label="Open drawer" title="Open drawer" >
      <NavigateNext />
    </IconButton>
  );
};

export default OpenDrawerIcon;

OpenDrawerIcon.propTypes = {
  handleClick: func,
};

OpenDrawerIcon.defaultProps = {
  handleClick: () => {},
};
