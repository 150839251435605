import { styled } from '@mui/material/styles';

export const AuthContainer = styled('div')({
  height: '100%',
  width: '100%',
});

export const AuthContent = styled('div')(({ theme }) => ({
  margin: theme.spacing(8, 'auto', 0),
  maxWidth: '1000px',
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(2),
  },
}));

export const AuthContentMain = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: theme.spacing(2, 0),
  padding: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(3),
  },
}));
