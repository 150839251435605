import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: 'auto',
}));

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));
