export const ONBOARDING_CHECKLIST_ID = 1842;

export const onboardingChecklistActive = () => {
  if (!window.CommandBar) return false;

  const activeExperiences = window.CommandBar.activeExperiences();
  return Boolean(activeExperiences.find(exp => exp.id === ONBOARDING_CHECKLIST_ID && exp.type === 'checklist'));
};

export const triggerOnboardingChecklist = () => {
  window.CommandBar.triggerChecklist(ONBOARDING_CHECKLIST_ID);
};

export const resetOnboardingChecklist = () => {
  window.CommandBar.resetChecklist(ONBOARDING_CHECKLIST_ID);
};
