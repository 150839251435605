import React from 'react';
import { string, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import './InlineRoute.scss';

const InlineRoute = ({
  text, segmentLabel, handleClick,
}) => {
  const splitOnRoute = text.split('|+|');
  // If there is no route, return the text
  if (splitOnRoute.length === 1) {
    return text;
  }

  return splitOnRoute.map((section) => {
    if (!section) return null;
    if (section.includes('@@')) {
      const [routeText, route] = section.split('@@');
      return (
        <Link
          key={routeText}
          to={route}
          className="inline-link"
          onClick={() => {
            reportToSegment(types.TRACK, eventNames.linkClicked, {
              text: routeText,
              path: route,
              style: 'inline',
              section: 'article',
              label: segmentLabel,
            });

            if (handleClick) {
              handleClick();
            }
          }}
        >
          {routeText}
        </Link>
      );
    }
    return <span key={section}>{section}</span>;
  });
};

export default InlineRoute;

InlineRoute.propTypes = {
  text: string.isRequired,
  segmentLabel: string,
  handleClick: func,
};

InlineRoute.defaultProps = {
  segmentLabel: undefined,
  handleClick: null,
};
