import React from 'react';
import { string, shape, func } from 'prop-types';
import { Link, Typography } from '@mui/material';
import { LaunchRounded, ArrowForwardRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { reportToSegment, types, eventNames } from '@smartcar/morse';

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  textDecoration: 'none',
  '&:hover, &:focus': {
    textDecoration: 'underline',
  },
}));

const iconStyles = {
  fontSize: 'inherit',
  marginLeft: '4px',
  marginBottom: '2px',
};
const Arrow = styled(ArrowForwardRounded)(iconStyles);
const Launch = styled(LaunchRounded)(iconStyles);

const iconMap = {
  launch: <Launch />,
  arrow: <Arrow />,
};

const ArrowLink = ({
  link,
  text,
  segmentProperty,
  variant,
  icon,
  id,
  toggleMenu,
}) => {
  return (
    <Link
      {...(id && { id })}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      sx={{ display: 'inline-flex', alignItems: 'center' }}
      onClick={() => {
        reportToSegment(types.TRACK, eventNames.linkClicked, {
          text,
          path: link,
          style: 'arrow',
          ...segmentProperty,
        });
        toggleMenu(false);
      }}
    >
      <Text variant={variant}>{text}</Text>
      {icon && iconMap[icon]}
    </Link>
  );
};

export default ArrowLink;

ArrowLink.propTypes = {
  link: string.isRequired,
  text: string.isRequired,
  toggleMenu: func,
  segmentProperty: shape({ section: string }),
  variant: string,
  icon: string,
  id: string,
};

ArrowLink.defaultProps = {
  segmentProperty: { section: 'article' },
  variant: '',
  icon: 'launch',
  id: '',
  toggleMenu: () => {},
};
