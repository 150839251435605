/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { array, bool, number, shape } from 'prop-types';
import { LogTable, Spinner } from '../../../../../../../../components';

const WebhooksOverview = ({
  webhooksInfo, loading,
}) => {
  return (
    <React.Fragment>
      {loading && <Spinner size="small" additionalClassNames="webhooks-overview" />}
      {!loading &&
        <LogTable
          getRowId={row => row.webhookId}
          pageInfo={webhooksInfo}
          pageSizeOptions={[10]}
          paginationModel={{
          page: 0,
          pageSize: 10,
        }}
          handlePaginationModelChange={() => {}}
          loading={loading}
          hideFooter
        />
      }
    </React.Fragment>
  );
};

export default WebhooksOverview;

WebhooksOverview.propTypes = {
  webhooksInfo: shape({
    rows: array,
    columns: array,
    rowCount: number,
  }).isRequired,
  loading: bool.isRequired,
};
