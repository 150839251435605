const stateKeyMap = {
  vehicleTrip: 'vehicleTrip',
  allTripStartData: 'allTripStartData',
  tripFrameData: 'tripFrameData',
  CREATE: 'tripActive',
  RESUME: 'tripPaused',
  PAUSE: 'tripPaused',
  CHANGE_SPEED: 'tripSpeed',
  CHANGE_LATENCY: 'tripLatency',
  COMPLETE: 'tripComplete',
};

export const setFetchingState = (state, key) => {
  return {
    ...state,
    isFetchingSimulatorTrip: {
      ...state.isFetchingSimulatorTrip,
      [stateKeyMap[key]]: true,
    },
    fetchingSimulatorTripErrors: {
      ...state.fetchingSimulatorTripErrors,
      [stateKeyMap[key]]: '',
    },
  };
};

export const setFailureState = (state, key) => {
  return {
    ...state,
    isFetchingSimulatorTrip: {
      ...state.isFetchingSimulatorTrip,
      [stateKeyMap[key]]: false,
    },
    fetchingSimulatorTripErrors: {
      ...state.fetchingSimulatorTripErrors,
      [stateKeyMap[key]]: `Something went wrong fetching ${[stateKeyMap[key]]}`,
    },
  };
};
