import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserInfo from './UserInfo';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
    ...state.onboarding,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { updateUserInfo, resetErrors } = onboardingActions;


  return {
    actions: bindActionCreators({
      updateUserInfo,
      resetErrors,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
