import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: theme.spacing(2, 0),
}));

export const Border = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  width: '50%',
  height: '100%',
}));
