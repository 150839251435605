import { useState } from 'react';
import formFields from './fields';

const { sectionNames: { CONFIGURATION } } = formFields;

const useStepper = ({ filteredFields, sections, defaultStep = 0 }) => {
  const [activeStep, setActiveStep] = useState(defaultStep);
  const [configurationStep, setConfigurationStep] = useState(0);

  const totalSteps = sections.length;
  const isLastStep = activeStep === totalSteps - 1;
  const showBackButton = activeStep > 0;
  const currentSection = sections[activeStep];

  const maxConfigurationStep = filteredFields.reduce((max, field) => {
    return field.configurationSection && field.configurationSection > max ?
      field.configurationSection : max;
  }, 0);

  const fieldsBySection = filteredFields.filter(({ section, configurationSection }) => {
    if (section !== currentSection
      || (currentSection === CONFIGURATION && configurationStep !== configurationSection)) {
      return false;
    }
    return true;
  });

  const handleStep = step => () => {
    const newSection = sections[step];
    if (newSection === CONFIGURATION) {
      setConfigurationStep(0);
    }
    setActiveStep(step);
  };

  const hasConfigurationFieldsForStep =
    step => filteredFields.some(field => field.configurationSection === step);

  const handleNext = () => {
    if (currentSection === CONFIGURATION && hasConfigurationFieldsForStep(configurationStep + 1)) {
      setConfigurationStep(configurationStep + 1);
    } else {
      if (sections[activeStep + 1] === CONFIGURATION) {
        setConfigurationStep(0);
      }
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    if (currentSection === CONFIGURATION && hasConfigurationFieldsForStep(configurationStep - 1)) {
      setConfigurationStep(configurationStep - 1);
    } else {
      if (sections[activeStep - 1] === CONFIGURATION) {
        setConfigurationStep(maxConfigurationStep);
      }
      setActiveStep(activeStep - 1);
    }
  };

  return {
    activeStep,
    configurationStep,
    currentSection,
    isLastStep,
    showBackButton,
    fieldsBySection,
    handleStep,
    handleBack,
    handleNext,
  };
};

export default useStepper;
