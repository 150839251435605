import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Typography } from '@mui/material';

import RegionList from './components/RegionList/RegionList';
import { CustomizationGroupDescription, CustomizationGroupHeading, FeatureGatedBanner } from '../../styles';
import staticText from '../../../../../../localization/Application/connect-config';
import { getSupportedCountriesByRegion } from '../../../../../../../config/countries';
import ErrorState from '../ErrorState';
import { InlineLinks } from '../../../../../../components';

const Countries = ({
  customizations: {
    supportedCountries,
  },
  updateCustomizations,
  defaultSupportedCountries,
  fetchingConnectConfigErrors,
  selectCountriesIsGated,
  rolePermissions,
}) => {
  const countriesByRegion = getSupportedCountriesByRegion(defaultSupportedCountries);
  const regionOrder = ['Americas', 'Europe'];

  const canWriteBilling = rolePermissions.includes('write_billing');

  const updateSelectedCountries = (countries) => {
    updateCustomizations({ supportedCountries: countries });
  };

  return (
    fetchingConnectConfigErrors && fetchingConnectConfigErrors.defaultSupportedCountries ? (
      <ErrorState message={staticText.errors.fetchingCountries} />
    ) : (
      <div>
        <CustomizationGroupHeading variant="h2">{staticText.countries.heading}</CustomizationGroupHeading>
        <CustomizationGroupDescription>
          {staticText.countries.description}
        </CustomizationGroupDescription>
        {selectCountriesIsGated && (
          <FeatureGatedBanner>
            <img src="/assets/icons/lock.svg" alt="lock icon" />
            <Typography component="p">
              <InlineLinks
                text={canWriteBilling ?
                  staticText.countries.featureGated.upgrade :
                  staticText.countries.featureGated.contactOwnersToUpgrade}
              />
            </Typography>
          </FeatureGatedBanner>
        )}
        <div>
          {
            regionOrder.map(region => (
              <RegionList
                key={region}
                region={region}
                countriesByRegion={countriesByRegion}
                countries={countriesByRegion[region]}
                selectedCountries={supportedCountries}
                updateSelectedCountries={updateSelectedCountries}
                selectCountriesIsGated={selectCountriesIsGated}
              />
            ))
          }
        </div>
      </div>
    )
  );
};

export default Countries;

Countries.propTypes = {
  customizations: shape({
    supportedCountries: arrayOf(string),
  }).isRequired,
  defaultSupportedCountries: arrayOf(string).isRequired,
  updateCustomizations: func.isRequired,
  fetchingConnectConfigErrors: shape({
    defaultSupportedCountries: bool,
  }),
  selectCountriesIsGated: bool,
  rolePermissions: arrayOf(string).isRequired,
};

Countries.defaultProps = {
  fetchingConnectConfigErrors: {
    defaultSupportedCountries: false,
  },
  selectCountriesIsGated: false,
};
