import React from 'react';
import { func, number, string } from 'prop-types';
import { Box, Button, Typography, useTheme } from '@mui/material';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';

import { CopyButton } from '../../../../../../../../components';
import PermissionGate from '../../../../../../../../components/PermissionGate';
import staticText from '../../../../../../../../localization/Application/configuration';

const RedirectUri = ({ redirectUri, removeRedirectUri, index }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" marginBottom={2}>
      <Typography variant="copyable" sx={{ overflowX: 'auto' }}>{redirectUri}</Typography>
      <CopyButton content={redirectUri} />
      <PermissionGate dashboardPermission="write_configuration">
        <Button onClick={() => removeRedirectUri(redirectUri)} sx={{ marginTop: '3px' }} id={`delete-uri-${index}`}>
          <DeleteOutlineRounded
            sx={{ marginRight: theme.spacing(0.5), marginTop: '-2px', fontSize: '20px' }}
          />
          {staticText.oauth.redirectUris.delete}
        </Button>
      </PermissionGate>
    </Box>
  );
};

RedirectUri.propTypes = {
  redirectUri: string.isRequired,
  removeRedirectUri: func.isRequired,
  index: number.isRequired,
};

export default RedirectUri;
