/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { reportToSegment, types } from '@smartcar/morse';
import { Box } from '@mui/material';

import { PermissionGate } from '../../../../components';
import { ConnectedVehicles, DisconnectModal, VehicleDetails } from './components';
import { LogDetailsDrawer } from '../Logs/components/RequestLogs/components';

const Vehicles = ({
  actions: {
    disconnectVehicle,
  },
  applicationId,
  history,
}) => {
  const [currentModal, setCurrentModal] = useState(null);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Vehicles');
  }, []);

  const openDisconnectModal = (vehicle) => {
    setCurrentModal(
      <PermissionGate
        dashboardPermission="write_vehicle_management"
        disabled
      >
        <DisconnectModal
          closeModal={() => setCurrentModal(null)}
          disconnectVehicle={disconnectVehicle}
          vehicle={vehicle}
        />
      </PermissionGate>,
    );
  };

  const openLogDrawer = (rowItem) => {
    setCurrentModal(
      <LogDetailsDrawer
        closeModal={() => setCurrentModal(null)}
        log={rowItem}
      />,
    );
  };

  return (
    <Box sx={{ maxWidth: '1600px', minWidth: '1100px' }}>
      <Switch>
        <Route path={`/apps/${applicationId}/vehicles/:vehicleId`}>
          <VehicleDetails
            openDisconnectModal={openDisconnectModal}
            openLogDrawer={openLogDrawer}
          />
        </Route>
        <Route path={`/apps/${applicationId}/vehicles`}>
          <ConnectedVehicles openDisconnectModal={openDisconnectModal} history={history} />
        </Route>
      </Switch>
      {currentModal}
    </Box>
  );
};

export default Vehicles;

Vehicles.propTypes = {
  actions: shape({
    disconnectVehicle: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  history: object.isRequired,
};
