import React from 'react';

const DarkModeIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19875 4.82125C8.04125 5.38125 7.9625 5.9675 7.9625 6.5625C7.9625 10.1325 10.8675 13.0375 14.4375 13.0375C15.0325 13.0375 15.6187 12.9588 16.1787 12.8013C15.2687 15.0413 13.0638 16.625 10.5 16.625C7.1225 16.625 4.375 13.8775 4.375 10.5C4.375 7.93625 5.95875 5.73125 8.19875 4.82125ZM10.5 2.625C6.15125 2.625 2.625 6.15125 2.625 10.5C2.625 14.8487 6.15125 18.375 10.5 18.375C14.8487 18.375 18.375 14.8487 18.375 10.5C18.375 10.0975 18.34 9.695 18.2875 9.31C17.43 10.5088 16.03 11.2875 14.4375 11.2875C11.83 11.2875 9.7125 9.17 9.7125 6.5625C9.7125 4.97875 10.4912 3.57 11.69 2.7125C11.305 2.66 10.9025 2.625 10.5 2.625Z"
        fill="#282828"
      />
    </svg>
  );
};

export default DarkModeIcon;
