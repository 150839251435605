import { reportToSegment, types, eventNames } from '@smartcar/morse';

export const modalConfig = {
  verify: {
    title: 'Verify webhook',
  },
  activate: {
    title: 'Verify webhook',
  },
  information: {
    eventBased: 'Event-based webhook information',
    schedule: 'Scheduled webhook information',
    dynamic: 'Dynamic webhook information',
  },
  edit: {
    title: 'Edit webhook',
    buttonText: 'Save',
  },
  test: {
    title: 'Test webhook',
  },
  add: {
    title: 'Add webhook',
    buttonText: 'Add',
  },
  delete: {
    title: 'Delete webhook',
  },
  deactivate: {
    title: 'Deactivate webhook',
    buttonText: 'Deactivate webhook',
  },
  error: {
    title: 'Oh no!',
    buttonText: 'OK',
  },
};

export const toggleModalDecorator = (toggleModal) => {
  return ({
    nextModal, curModal, trackCloseEvent, curTitle, nextTitle,
  }) => {
    const nextModalName = nextModal ? nextModal.toLowerCase() : nextModal;
    const curModalName = curModal ? curModal.toLowerCase() : curModal;

    if (!nextModalName) {
      if (trackCloseEvent) {
        try {
          reportToSegment(types.TRACK, eventNames.modalClosed, {
            label: `${curModalName} webhook`,
            title: curTitle || (modalConfig[curModalName] ? modalConfig[curModalName].title : ''),
          });
        } catch (error) {
          // eslint-disable-next-line
        }
      }
      return toggleModal(null);
    }

    try {
      if (curModalName && trackCloseEvent) {
        reportToSegment(types.TRACK, eventNames.modalClosed, {
          label: `${curModalName} webhook`,
          title: curTitle || (modalConfig[curModalName] ? modalConfig[curModalName].title : ''),
        });
      }
      reportToSegment(types.TRACK, eventNames.modalOpened, {
        label: `${nextModalName} webhook`,
        title: nextTitle || (modalConfig[nextModalName] ? modalConfig[nextModalName].title : ''),
      });
    } catch (error) {
      // eslint-disable-next-line
    }

    return toggleModal(nextModalName);
  };
};
