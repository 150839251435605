import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { ChevronDown, ChevronUp } from 'react-feather';

import { ActionsList } from './components';

import './MoreActions.scss';

const staticText = {
  more: 'More',
};

const MoreActions = ({ actions, openModal, disabled }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleButtonClick = () => {
    setDropdownOpen(!dropdownOpen);
    reportToSegment(types.TRACK, eventNames[`dropdown${!dropdownOpen ? 'Opened' : 'Closed'}`], {
      label: 'action creator',
      text: staticText.more,
    });
  };

  return (
    <div className="more-actions">
      <button
        className={`more-actions-button${dropdownOpen ? ' more-actions-open' : ''}${disabled ? ' disabled' : ''}`}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        <span className="m-r-sm">{staticText.more}</span>
        {dropdownOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {dropdownOpen &&
        <ActionsList
          actions={actions}
          setDropdownOpen={setDropdownOpen}
          openModal={openModal}
        />
      }
    </div>
  );
};

export default MoreActions;

MoreActions.propTypes = {
  actions: arrayOf(
    shape({
      text: string.isRequired,
      modalName: string.isRequired,
      icon: string.isRequired,
    }),
  ).isRequired,
  openModal: func.isRequired,
  disabled: bool,
};

MoreActions.defaultProps = {
  disabled: false,
};
