import { Chip, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  flexWrap: 'wrap',
  height: 'auto',
  maxHeight: 'none',
  rowGap: theme.spacing(1),
}));

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  width: '100%',

  ...(!error ? {
    marginBottom: '18.5px',
  } : {}),

  '& > .MuiInputBase-root': {
    flexWrap: 'wrap',
    minHeight: '64px',
    padding: theme.spacing(2),
    rowGap: theme.spacing(1.5),

    '& > .MuiOutlinedInput-input': {
      width: 'auto',
      flexGrow: 1,
      padding: '0px',
    },
  },
}));
