/* istanbul ignore file */

import React from 'react';
import { bool, number, string } from 'prop-types';

import ChartError from '../ChartError';
import {
  ErrorContainer,
  LoadingBar1,
  LoadingBar2,
  Stat,
  Title,
  Unit,
  Widget,
} from './styles';

const getContents = (isLoading, loading, isErrored, error, success) => {
  if (isLoading) {
    return loading;
  }
  if (isErrored) {
    return error;
  }
  return success;
};

const UsageWidget = ({
  isErrored, isLoading, stat, title, unit,
}) => {
  const loading = (
    <div>
      <LoadingBar1 />
      <LoadingBar2 />
    </div>
  );

  const error = (
    <ErrorContainer>
      <ChartError />
    </ErrorContainer>
  );

  const success = (
    <React.Fragment>
      <Title variant="h3">{title}</Title>
      <Stat id="usage-stat" variant="bold">{stat}</Stat>
      <Unit variant="bold">{unit}</Unit>
    </React.Fragment>
  );

  return (
    <Widget>
      {getContents(isLoading, loading, isErrored, error, success)}
    </Widget>
  );
};

export default UsageWidget;

UsageWidget.propTypes = {
  isErrored: bool.isRequired,
  isLoading: bool.isRequired,
  stat: number.isRequired,
  title: string.isRequired,
  unit: string.isRequired,
};
