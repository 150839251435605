/* eslint-disable react/require-default-props,react/no-unused-prop-types, react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import loadingShimmerStyles from '../../../global-styles/animations/loadingShimmer';
import {
  handleGetElementAtEvent,
  handleGetDatasetAtEvent,
  handleElementClick,
} from '../utils';

import { Container, LoadingContainer, LoadingDoughnut, Legend } from './styles';

const defaultChartOptions = {
  aspectRatio: 1.95,
};

/* istanbul ignore next */
const DoughnutChart = (props) => {
  const { data, options, isLoading } = props;
  const [aspectRatio, setAspectRatio] = useState(defaultChartOptions.aspectRatio);
  const matches = useMediaQuery(theme => theme.breakpoints.between('xl', 'iPadPro'));

  useEffect(() => {
    if (matches) {
      setAspectRatio(1.60);
    } else {
      setAspectRatio(defaultChartOptions.aspectRatio);
    }
  }, [matches]);

  const chartOptions = {
    ...defaultChartOptions,
    ...options,
    aspectRatio,
  };

  let doughnut;
  if (data && data.labels.length === 0 && data.datasets[0].data.length === 0) {
    doughnut = (
      <LoadingContainer>
        <LoadingDoughnut />
      </LoadingContainer>
    );
  } else {
    doughnut = (
      <Doughnut
        data={data}
        options={chartOptions}
        height={200}
        width={500}
        onElementsClick={handleElementClick}
        getElementAtEvent={handleGetElementAtEvent}
        getDatasetAtEvent={handleGetDatasetAtEvent}
      />
    );
  }

  return (
    <Container>
      {data && options && !isLoading ? (
        doughnut
      ) : (
        <LoadingContainer>
          <LoadingDoughnut sx={loadingShimmerStyles} />
          <div style={{ flexGrow: 1 }}>
            <Legend />
            <Legend />
            <Legend />
          </div>
        </LoadingContainer>
      )}
    </Container>
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  height: PropTypes.number,
  plugins: PropTypes.array,
  onElementsClick: PropTypes.func,
  getElementAtEvent: PropTypes.func,
  getDatasetAtEvent: PropTypes.func,
  optionalClasses: PropTypes.string,
  isLoading: PropTypes.bool,
};

DoughnutChart.defaultProps = {
  height: 400,
};

export default DoughnutChart;
