import _ from 'lodash';

export const PAGE_SIZE = 10;

export const types = {
  FETCH_CONNECT_EVENTS_REQUEST: 'developer-client/connect-insights/FETCH_CONNECT_EVENTS_REQUEST',
  FETCH_CONNECT_EVENTS_SUCCESS: 'developer-client/connect-insights/FETCH_CONNECT_EVENTS_SUCCESS',
  FETCH_CONNECT_EVENTS_FAILURE: 'developer-client/connect-insights/FETCH_CONNECT_EVENTS_FAILURE',

  FETCH_SESSION_DETAILS_REQUEST: 'developer-client/connect-insights/FETCH_SESSION_DETAILS_REQUEST',
  FETCH_SESSION_DETAILS_SUCCESS: 'developer-client/connect-insights/FETCH_SESSION_DETAILS_SUCCESS',
  FETCH_SESSION_DETAILS_FAILURE: 'developer-client/connect-insights/FETCH_SESSION_DETAILS_FAILURE',

  FETCH_CONNECT_FUNNEL_REQUEST: 'developer-client/connect-insights/FETCH_CONNECT_FUNNEL_REQUEST',
  FETCH_CONNECT_FUNNEL_SUCCESS: 'developer-client/connect-insights/FETCH_CONNECT_FUNNEL_SUCCESS',
  FETCH_CONNECT_FUNNEL_FAILURE: 'developer-client/connect-insights/FETCH_CONNECT_FUNNEL_FAILURE',

  RESET_CONNECT_INSIGHTS_STATE: 'developer-client/connect-insights/RESET_CONNECT_INSIGHTS_STATE',
  RESET_SESSION_DETAILS: 'developer-client/connect-insights/RESET_SESSION_DETAILS',
  UPDATE_CURSOR_MAP: 'developer-client/connect-insights/UPDATE_CURSOR_MAP',
  UPDATE_FILTER_VALUES: 'developer-client/connect-insights/UPDATE_FILTER_VALUES',
  UPDATE_PAGINATION_MODEL: 'developer-client/connect-insights/UPDATE_PAGINATION_MODEL',
};

export const initialState = {
  // data to show
  eventsTablePageInfo: {},
  funnel: [],
  sessionDetails: [],

  // querying and presentation
  filterValues: {
    vin: '',
    customerVehicleOwnerId: '',
    sessionId: '',
    start: null,
    end: null,
  },
  pageToNextCursorMap: {},
  paginationModel: {
    page: 0,
    pageSize: PAGE_SIZE,
  },

  // fetching status/errors
  connectInsightsFetching: {
    eventsTable: false,
    sessionDetails: false,
    connectFunnel: false,
  },
  connectInsightsErrors: {
    eventsTable: '',
    sessionDetails: '',
    connectFunnel: '',
  },
};

export const actions = {
  fetchConnectEventsRequest: payload => ({
    type: types.FETCH_CONNECT_EVENTS_REQUEST,
    payload,
  }),
  fetchConnectEventsSuccess: data => ({
    type: types.FETCH_CONNECT_EVENTS_SUCCESS,
    payload: data,
  }),
  fetchConnectEventsFailure: err => ({
    type: types.FETCH_CONNECT_EVENTS_FAILURE,
    payload: err,
  }),

  fetchSessionDetailsRequest: payload => ({
    type: types.FETCH_SESSION_DETAILS_REQUEST,
    payload,
  }),
  fetchSessionDetailsSuccess: data => ({
    type: types.FETCH_SESSION_DETAILS_SUCCESS,
    payload: data,
  }),
  fetchSessionDetailsFailure: err => ({
    type: types.FETCH_SESSION_DETAILS_FAILURE,
    payload: err,
  }),

  fetchConnectFunnelRequest: payload => ({
    type: types.FETCH_CONNECT_FUNNEL_REQUEST,
    payload,
  }),
  fetchConnectFunnelSuccess: data => ({
    type: types.FETCH_CONNECT_FUNNEL_SUCCESS,
    payload: data,
  }),
  fetchConnectFunnelFailure: err => ({
    type: types.FETCH_CONNECT_FUNNEL_FAILURE,
    payload: err,
  }),

  resetConnectInsightsState: () => ({
    type: types.RESET_CONNECT_INSIGHTS_STATE,
  }),
  resetSessionDetails: () => ({
    type: types.RESET_SESSION_DETAILS,
  }),
  updateCursorMap: cursorMap => ({
    type: types.UPDATE_CURSOR_MAP,
    payload: cursorMap,
  }),
  updateFilterValues: filterValues => ({
    type: types.UPDATE_FILTER_VALUES,
    payload: filterValues,
  }),
  updatePaginationModel: model => ({
    type: types.UPDATE_PAGINATION_MODEL,
    payload: model,
  }),
};

export const selectors = {
  getPageToNextCursorMap: (state) => {
    return _.get(state, ['connectInsights', 'pageToNextCursorMap']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONNECT_EVENTS_REQUEST:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          eventsTable: true,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          eventsTable: '',
        },
      };
    case types.FETCH_CONNECT_EVENTS_SUCCESS:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          eventsTable: false,
        },
        eventsTablePageInfo: action.payload,
      };
    case types.FETCH_CONNECT_EVENTS_FAILURE:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          eventsTable: false,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          eventsTable: action.payload && action.payload.message,
        },
      };

    case types.FETCH_SESSION_DETAILS_REQUEST:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          sessionDetails: true,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          sessionDetails: '',
        },

      };
    case types.FETCH_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          sessionDetails: false,
        },
        sessionDetails: action.payload,
      };
    case types.FETCH_SESSION_DETAILS_FAILURE:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          sessionDetails: false,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          sessionDetails: action.payload && action.payload.message,
        },
      };

    case types.FETCH_CONNECT_FUNNEL_REQUEST:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          connectFunnel: true,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          connectFunnel: '',
        },
      };
    case types.FETCH_CONNECT_FUNNEL_SUCCESS:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          connectFunnel: false,
        },
        funnel: action.payload,
      };
    case types.FETCH_CONNECT_FUNNEL_FAILURE:
      return {
        ...state,
        connectInsightsFetching: {
          ...state.connectInsightsFetching,
          connectFunnel: false,
        },
        connectInsightsErrors: {
          ...state.connectInsightsErrors,
          connectFunnel: action.payload && action.payload.message,
        },
      };

    case types.RESET_CONNECT_INSIGHTS_STATE:
      return initialState;
    case types.RESET_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: initialState.sessionDetails,
      };
    case types.UPDATE_CURSOR_MAP:
      return {
        ...state,
        pageToNextCursorMap: action.payload,
      };
    case types.UPDATE_PAGINATION_MODEL:
      return {
        ...state,
        paginationModel: action.payload,
      };
    case types.UPDATE_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };
    default:
      return state;
  }
}

