import { call, put, select, takeLatest } from 'redux-saga/effects';
import api from '../../../../services/api/api';
import { actions, types, selectors } from './reducers';
import { actions as errorActions } from '../../../../services/errorReporter/reducers';
import getTotalCount from './utils/sagas';

export function* fetchApiRequestTimeSeries(action) {
  try {
    const filter = yield select(selectors.getFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchApiRequests,
      applicationId,
      filter.timeframe,
    );

    // get total number of API calls for time period
    let apiRequestCount = 0;
    if (data.data.length > 0) {
      apiRequestCount = data.data.reduce(getTotalCount, apiRequestCount);
    }

    yield put(actions.fetchApiRequestTimeSeriesSuccess(data));
    yield put(actions.setTotalApiRequests(apiRequestCount));
  } catch (error) {
    yield put(actions.fetchApiRequestTimeSeriesFailure(error));
    yield put(actions.setTotalApiRequests(null));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchResponseStatusSummary(action) {
  try {
    const filter = yield select(selectors.getFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchResponseStatusSummary,
      applicationId,
      filter.timeframe,
    );

    let failedRequests = 0;
    const non200Responses = [];
    // find indexes of non-200 response statuses
    data.labels.forEach((label, index) => {
      if (!label.startsWith('200')) {
        non200Responses.push(index);
      }
    });
    for (let i = 0; i < non200Responses.length; i += 1) {
      failedRequests += data.data[non200Responses[i]];
    }

    yield put(actions.fetchResponseStatusSummarySuccess(data));
    yield put(actions.setTotalFailedRequests(failedRequests));
  } catch (error) {
    yield put(actions.fetchResponseStatusSummaryFailure(error));
    yield put(actions.setTotalFailedRequests(null));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchMakesSummary(action) {
  try {
    const filter = yield select(selectors.getFilter);
    const { meta: applicationId } = action;

    const { data } = yield call(
      api.fetchMakesSummary,
      applicationId,
      filter.timeframe,
    );

    const { result, timeframe, totalActiveVehicles } = data.activeVehicle;
    yield put(actions.fetchActiveVehiclesTimeSeriesSuccess({ data: result, timeframe }));
    yield put(actions.setTotalActiveVehicles(totalActiveVehicles));
    yield put(actions.fetchMakesSummarySuccess(data));
  } catch (error) {
    yield put(actions.fetchActiveVehiclesTimeSeriesFailure(error));
    yield put(actions.setTotalActiveVehicles(null));
    yield put(actions.fetchMakesSummaryFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_API_REQUEST_TIME_SERIES, fetchApiRequestTimeSeries);
  yield takeLatest(types.FETCH_RESPONSE_STATUS_SUMMARY, fetchResponseStatusSummary);
  yield takeLatest(types.FETCH_MAKES_SUMMARY, fetchMakesSummary);
}
