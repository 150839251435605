const staticText = {
  title: 'Reset password',
  returnToLogin: '|+|Return to sign in@@/login|+|',
  subLink: 'Need an account? |+|Sign up@@/signup|+|',
  btnSubmit: 'Reset password',
  image: {
    url: '/assets/graphics/lock.svg',
    alt: 'Smartcar lock',
  },
  fields: [
    {
      label: 'New password',
      name: 'password',
      type: 'password',
    },
    {
      label: 'Confirm new password',
      name: 'confirmPassword',
      type: 'password',
    },
  ],
  successMessage: 'Your password has been reset.',
  invalidMessage: 'New password and confirmation do not match.',
  noAuthToken: 'No authentication token present.',
};

export default staticText;

