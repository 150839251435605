import React, { useEffect } from 'react';
import { func, objectOf, shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import { AppSelectorList } from '../TopbarNav/components/AppSelector/components';
import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';

const staticText = {
  error: {
    title: 'Application not found',
    description:
      'Oh no! We couldn’t find the application you were looking for. Choose one of your applications below or register a new application with Smartcar.',
  },
};

const ApplicationNotFound = (props) => {
  useEffect(() => {
    reportToSegment(types.PAGE, 'Application Not Found');
  }, []);

  return (
    <Box width="800px" margin="100px auto">
      <ApplicationHeader heading={staticText.error.title} />
      <Section>
        <Typography mb={3} mt={2}>{staticText.error.description}</Typography>
        <AppSelectorList {...props} />
      </Section>
    </Box>
  );
};

export default ApplicationNotFound;

ApplicationNotFound.propTypes = {
  applications: objectOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  toggleModal: func.isRequired,
  applicationId: string.isRequired,
  pathname: string.isRequired,
  dashboardRole: string.isRequired,
};
