/* istanbul ignore file */

/*
Converts the array of selected makes from Overview chart to an object to be consumed by FiltersBar
*/
const updateMakesFilterValues = (makes) => {
  const updatedMakes = {};

  makes.forEach((make) => {
    updatedMakes[make] = true;
  });

  return updatedMakes;
};

/*
Update statusCodes object with the status codes selected from Overview chart
*/
const updateStatusCodesFilterValues = (statusCodes, selectedStatusCodes) => {
  const updatedStatusCodes = {};
  Object.keys(statusCodes).forEach((code) => {
    if (selectedStatusCodes.includes(code)) {
      updatedStatusCodes[code] = true;
    } else {
      updatedStatusCodes[code] = false;
    }
  });

  return updatedStatusCodes;
};

const resetMakesFilterValues = (compatibleMakes) => {
  const newlyResetMakes = {};

  Object.keys(compatibleMakes).forEach((make) => {
    newlyResetMakes[make] = true;
  });

  return newlyResetMakes;
};


export {
  updateMakesFilterValues,
  updateStatusCodesFilterValues,
  resetMakesFilterValues,
};
