import { connect } from 'react-redux';

import SidebarNav from './SidebarNav';

function mapStateToProps(state) {
  const { authErrorMessage } = state.auth;
  const { billingInfo } = state.billing;

  return {
    billingInfo,
    authErrorMessage,
  };
}

export default connect(mapStateToProps)(SidebarNav);
