import { bool, objectOf, shape, string } from 'prop-types';

import generateActionProps from './utils/generateActionProps';
import application from './application';
import { types } from '../services/applications/reducers';

export const actionProps = generateActionProps(types);

// TODO: needs an update
export const defaultProps = {
  isRegistering: false,
  isFetching: false,
  isUpdating: false,
  isDeleting: false,
  isInitializing: false,
  applications: {},
  applicationsErrorMessage: '',
  selectedApplication: null,
};

export default {
  applications: objectOf(shape(application)).isRequired,
  isRegistering: bool.isRequired,
  isUpdating: bool.isRequired,
  isFetching: bool.isRequired,
  isFetchingOrganizations: bool.isRequired,
  isInitializing: bool.isRequired,
  isDeleting: bool.isRequired,
  selectedApplication: string,
};
