import React from 'react';
import { Box } from '@mui/material';

import TimelineEventIcon from '../TimelineEventIcon';
import { ConnectEventCard } from '../../../../../../../../components';

import { getRowIconAndStyle } from '../../util';
import { eventProps } from '../../../../propTypes';

const HighlightedEvent = ({ row }) => {
  return (
    <Box
      backgroundColor={getRowIconAndStyle(row.eventName).backgroundColor}
      mt={3}
      padding={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={1}
    >
      <ConnectEventCard row={row} />
      <TimelineEventIcon eventName={row.eventName} />
    </Box>
  );
};

export default HighlightedEvent;

HighlightedEvent.propTypes = eventProps.isRequired;
