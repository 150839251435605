import React, { useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { CopyButton, Spinner } from '../../../../../../components';
import { ConnectOptions, HighlightedEvent, ScopesList, Timeline, VehiclesList } from './components';

import { CloseButton, StyledDivider, StyledDrawer } from './styles';

import staticText from '../../../../../../localization/Application/ConnectInsights/sessionDetailsDrawer';
import { eventProps } from '../../propTypes';

const showAuthorizedSection = (section, newestEvent) => {
  if (newestEvent.eventName === 'smartcar_grant:allowed' && section) {
    if (Array.isArray(section)) {
      return section.length > 0;
    }
    if (typeof section === 'object') {
      return Object.keys(section).length > 0;
    }
  }
  return false;
};

const SessionDetailsDrawer = ({
  actions: { fetchSessionDetailsRequest, resetSessionDetails },
  connectInsightsFetching: { sessionDetails: isFetchingSessionDetails },
  closeDrawer,
  row,
  sessionDetails,
  featureSetId,
}) => {
  const theme = useTheme();

  useEffect(() => {
    fetchSessionDetailsRequest({ sessionId: row.sessionId });

    return () => {
      resetSessionDetails();
    };
  }, []);

  const newestEvent = sessionDetails[0];

  let vehiclesAuthorized = [];
  let scopesAuthorized = {};
  if (newestEvent) {
    ({ vehiclesAuthorized, scopesAuthorized } = newestEvent);
  }

  return (
    <StyledDrawer open onClose={closeDrawer} anchor="right">
      <CloseButton aria-label="close" onClick={closeDrawer}>
        <CloseRounded />
      </CloseButton>
      <Typography variant="h2" mb={0.5}>{staticText.sessionInformation}</Typography>
      <Box display="flex" whiteSpace="pre-wrap">
        <Typography
          sx={{ fontFamily: theme.typography.bold.fontFamily }}
          component="span"
        >
          {staticText.id}
        </Typography>
        <Typography component="span">{row.sessionId}</Typography>
        <CopyButton content={row.sessionId} showCopyText={false} />
      </Box>
      <HighlightedEvent row={row} />
      <StyledDivider />
      {isFetchingSessionDetails && <Spinner delay={200} />}
      {!isFetchingSessionDetails && sessionDetails.length > 0 ? (
        <React.Fragment>
          <ConnectOptions newestEvent={newestEvent} featureSetId={featureSetId} />
          {showAuthorizedSection(vehiclesAuthorized, newestEvent) &&
            <React.Fragment>
              <StyledDivider />
              <Typography variant="h2" mb={2}>{staticText.grantedAccess}</Typography>
              <VehiclesList vehiclesAuthorized={vehiclesAuthorized} />
            </React.Fragment>
          }
          {showAuthorizedSection(scopesAuthorized, newestEvent) &&
            <React.Fragment>
              <StyledDivider />
              <Typography variant="h2" mb={2}>{staticText.permissions}</Typography>
              <ScopesList scopesAuthorized={scopesAuthorized} />
            </React.Fragment>
          }
          <StyledDivider />
          <Typography variant="h2">{staticText.timeline}</Typography>
          <Timeline sessionDetails={sessionDetails} />
        </React.Fragment>
      ) : null}
    </StyledDrawer>
  );
};

export default SessionDetailsDrawer;

SessionDetailsDrawer.propTypes = {
  actions: shape({
    fetchSessionDetailsRequest: func.isRequired,
  }).isRequired,
  connectInsightsFetching: shape({
    sessionDetails: bool,
  }).isRequired,
  closeDrawer: func.isRequired,
  row: shape(eventProps).isRequired,
  sessionDetails: arrayOf(shape(eventProps)).isRequired,
  featureSetId: string.isRequired,
};
