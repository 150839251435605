/* istanbul ignore file */
import React from 'react';

const HomeIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 7.99984L10 2.1665L17.5 7.99984V17.1665C17.5 17.6085 17.3244 18.0325 17.0118 18.345C16.6993 18.6576 16.2754 18.8332 15.8333 18.8332H4.16667C3.72464 18.8332 3.30072 18.6576 2.98816 18.345C2.67559 18.0325 2.5 17.6085 2.5 17.1665V7.99984Z"
        stroke="#4F4F4F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.8333V10.5H12.5V18.8333"
        stroke="#4F4F4F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
