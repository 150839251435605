import React from 'react';
import clsx from 'clsx';
import { string } from 'prop-types';

const InlineStyledText = ({ text }) => {
  const textArr = text.split('|*|');

  return textArr.map((textChunk) => {
    const textChunkSplit = textChunk.split('@@');

    if (textChunkSplit.length === 1) {
      return <span key={textChunk}>{textChunk}</span>;
    }
    return (
      <span key={textChunkSplit[1]} className={clsx(textChunkSplit[0])}>
        {textChunkSplit[1]}
      </span>
    );
  });
};

export default InlineStyledText;

InlineStyledText.propTypes = {
  text: string.isRequired,
};
