/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { arrayOf, bool, func, number, object, oneOfType, string } from 'prop-types';
import { TextField, styled } from '@mui/material';

const Input = styled(TextField)(() => ({
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },

  '& .MuiInputLabel-asterisk': {
    display: 'none',
  },
}));

const InputField = (props) => {
  const {
    autoFocus,
    handleChange,
    handleFocus,
    inputName,
    inputType,
    inputValues,
    inputPlaceholder,
    inputDisabled,
    errorMessage,
    required,
    minlength,
    maxlength,
    InputProps,
  } = props;
  const handleInputChange = (event) => {
    const {
      target: {
        name, value,
      },
    } = event;

    handleChange({
      key: name,
      value,
    });
  };

  return (
    <Input
      id={inputName}
      name={inputName}
      type={inputType}
      label={inputPlaceholder}
      value={inputValues || ''}
      disabled={inputDisabled}
      error={errorMessage.length > 0}
      onChange={handleInputChange}
      onFocus={handleFocus}
      autoComplete="off"
      variant="outlined"
      margin="normal"
      helperText={errorMessage}
      fullWidth
      required={required}
      inputProps={inputType === 'password' ? { minLength: 8, maxLength: 64 } : { minLength: minlength, maxLength: maxlength }}
      InputProps={InputProps && InputProps}
      autoFocus={autoFocus}
    />
  );
};

export default InputField;

InputField.propTypes = {
  autoFocus: bool,
  handleChange: func.isRequired,
  handleFocus: func,
  inputName: string,
  inputType: string,
  inputValues: oneOfType([string, object, arrayOf(string)]),
  inputPlaceholder: string,
  inputDisabled: bool,
  errorMessage: string,
  required: bool,
  minlength: number,
  maxlength: number,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: object,
};

InputField.defaultProps = {
  handleFocus: null,
  autoFocus: false,
  inputName: '',
  inputType: 'text',
  inputValues: '',
  inputPlaceholder: '',
  inputDisabled: false,
  errorMessage: '',
  required: true,
  minlength: 2,
  maxlength: 64,
  InputProps: {},
};
