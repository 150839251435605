import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VerifyEmail from './VerifyEmail';
import { actions } from '../../services/auth/reducers';

function mapStateToProps(state) {
  return {
    email: state.auth.session.userContext.email,
    emailToken: state.auth.emailToken,
    emailVerifiedAt: state.auth.session.userContext.emailVerifiedAt,
    isSendingVerificationEmail: state.auth.isSendingVerificationEmail,
    isVerifyingEmailToken: state.auth.isVerifyingEmailToken,
    sendVerificationEmailError: state.auth.sendVerificationEmailError,
    verificationEmailSent: state.auth.verificationEmailSent,
    verifyEmailTokenError: state.auth.verifyEmailTokenError,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    sendVerificationEmailRequest,
    setVerificationEmailSent,
    verifyEmailTokenRequest,
  } = actions;

  return {
    actions: bindActionCreators({
      sendVerificationEmailRequest,
      setVerificationEmailSent,
      verifyEmailTokenRequest,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
