const errorTypes = [
  'AUTHENTICATION',
  'BILLING',
  'COMPATIBILITY',
  'CONNECTED_SERVICES_ACCOUNT',
  'PERMISSION',
  'RATE_LIMIT',
  'RESOURCE_NOT_FOUND',
  'SERVER',
  'SUCCESS',
  'UPSTREAM',
  'VALIDATION',
  'VEHICLE_STATE',
];

export default errorTypes;
