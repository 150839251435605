import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from './reducers';


import ConnectInsights from './ConnectInsights';

function mapStateToProps(state) {
  return {
    applicationId: state.applications.selectedApplication,
    featureSetId: state.applications.applications[state.applications.selectedApplication]
      .featureSetId,
    ...state.connectInsights,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchConnectEventsRequest,
    fetchConnectFunnelRequest,
    updateFilterValues,
  } = actions;
  return {
    actions: bindActionCreators(
      {
        fetchConnectEventsRequest,
        fetchConnectFunnelRequest,
        updateFilterValues,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectInsights);
