// TODO: add tests
import { organizationRole } from './roles';

const formatAppAccess = (member, orgApps, isActiveMember = false) => {
  if ([organizationRole.FULL_ACCESS, organizationRole.ALL_APPS].includes(member.organizationRole)) {
    return orgApps;
  }
  // activeMembers.applications type: arrayOf(shape({ id, name}))
  // invitedMembers.applications type: arrayOf(string)
  if (isActiveMember) {
    return member.applications;
  }
  const invitationAppIds = new Set(member.applications);
  return orgApps.filter(app => invitationAppIds.has(app.id));
};

const formatMembers = (members, orgApps) => {
  const { activeMembers, invitedMembers } = members;
  const allMembers = [];
  activeMembers.forEach((member) => {
    allMembers.push({
      ...member,
      type: 'activeMembers',
      applications: formatAppAccess(member, orgApps, true),
    });
  });
  invitedMembers.forEach((member) => {
    allMembers.push({
      ...member,
      type: 'invitedMembers',
      applications: formatAppAccess(member, orgApps),
    });
  });
  allMembers.sort((a, b) => {
    const stringA = (a.name && a.name.toLowerCase()) || (a.email && a.email.toLowerCase());
    const stringB = (b.name && b.name.toLowerCase()) || (b.email && b.email.toLowerCase());
    if (stringA === stringB) return 0;
    return stringA > stringB ? 1 : -1;
  });
  return allMembers;
};

export default formatMembers;
