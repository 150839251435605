import _ from 'lodash';

export const types = {
  FETCH_WEBHOOKS_REQUEST: 'developer-client/webhooks/FETCH_WEBHOOKS_REQUEST',
  FETCH_WEBHOOKS_SUCCESS: 'developer-client/webhooks/FETCH_WEBHOOKS_SUCCESS',
  FETCH_WEBHOOKS_FAILURE: 'developer-client/webhooks/FETCH_WEBHOOKS_FAILURE',

  VERIFY_WEBHOOK_REQUEST: 'developer-client/webhooks/VERIFY_WEBHOOK_REQUEST',
  VERIFY_WEBHOOK_SUCCESS: 'developer-client/webhooks/VERIFY_WEBHOOK_SUCCESS',
  VERIFY_WEBHOOK_FAILURE: 'developer-client/webhooks/VERIFY_WEBHOOK_FAILURE',

  DELETE_WEBHOOK_REQUEST: 'developer-client/webhooks/DELETE_WEBHOOK_REQUEST',
  DELETE_WEBHOOK_SUCCESS: 'developer-client/webhooks/DELETE_WEBHOOK_SUCCESS',
  DELETE_WEBHOOK_FAILURE: 'developer-client/webhooks/DELETE_WEBHOOK_FAILURE',

  CREATE_WEBHOOK_REQUEST: 'developer-client/webhooks/CREATE_WEBHOOK_REQUEST',
  CREATE_WEBHOOK_SUCCESS: 'developer-client/webhooks/CREATE_WEBHOOK_SUCCESS',
  CREATE_WEBHOOK_FAILURE: 'developer-client/webhooks/CREATE_WEBHOOK_FAILURE',

  CREATE_WEBHOOK_INTEREST_REQUEST: 'developer-client/webhooks/CREATE_WEBHOOK_INTEREST_REQUEST',
  CREATE_WEBHOOK_INTEREST_SUCCESS: 'developer-client/webhooks/CREATE_WEBHOOK_INTEREST_SUCCESS',
  CREATE_WEBHOOK_INTEREST_FAILURE: 'developer-client/webhooks/CREATE_WEBHOOK_INTEREST_FAILURE',

  UPDATE_WEBHOOK_REQUEST: 'developer-client/webhooks/UPDATE_WEBHOOK_REQUEST',
  UPDATE_WEBHOOK_SUCCESS: 'developer-client/webhooks/UPDATE_WEBHOOK_SUCCESS',
  UPDATE_WEBHOOK_FAILURE: 'developer-client/webhooks/UPDATE_WEBHOOK_FAILURE',

  FETCH_TEST_EVENT_REQUEST: 'developer-client/webhooks/FETCH_TEST_EVENT_REQUEST',
  FETCH_TEST_EVENT_SUCCESS: 'developer-client/webhooks/FETCH_TEST_EVENT_SUCCESS',
  FETCH_TEST_EVENT_FAILURE: 'developer-client/webhooks/FETCH_TEST_EVENT_FAILURE',

  TEST_WEBHOOK_REQUEST: 'developer-client/webhooks/TEST_WEBHOOK_REQUEST',
  TEST_WEBHOOK_SUCCESS: 'developer-client/webhooks/TEST_WEBHOOK_SUCCESS',
  TEST_WEBHOOK_FAILURE: 'developer-client/webhooks/TEST_WEBHOOK_FAILURE',

  TOGGLE_WEBHOOK_MODAL: 'developer-client/webhooks/TOGGLE_WEBHOOK_MODAL',
  SELECT_WEBHOOK: 'developer-client/webhooks/SELECT_WEBHOOK',
  CLEAR_TEST_DATA: 'developer-client/webhooks/CLEAR_TEST_DATA',
};


export const actions = {
  fetchWebhooksRequest: () => ({
    type: types.FETCH_WEBHOOKS_REQUEST,
  }),
  fetchWebhooksSuccess: data => ({
    type: types.FETCH_WEBHOOKS_SUCCESS,
    payload: data,
  }),
  fetchWebhooksFailure: err => ({
    type: types.FETCH_WEBHOOKS_FAILURE,
    payload: err,
  }),
  verifyWebhookRequest: (webhookId, challenge) => ({
    type: types.VERIFY_WEBHOOK_REQUEST,
    payload: challenge,
    meta: webhookId,
  }),
  verifyWebhookSuccess: (webhookId, data) => ({
    type: types.VERIFY_WEBHOOK_SUCCESS,
    payload: data,
    meta: webhookId,
  }),
  verifyWebhookFailure: err => ({
    type: types.VERIFY_WEBHOOK_FAILURE,
    payload: err,
  }),
  deleteWebhookRequest: webhookId => ({
    type: types.DELETE_WEBHOOK_REQUEST,
    meta: webhookId,
  }),
  deleteWebhookSuccess: webhookId => ({
    type: types.DELETE_WEBHOOK_SUCCESS,
    meta: webhookId,
  }),
  deleteWebhookFailure: error => ({
    type: types.DELETE_WEBHOOK_FAILURE,
    payload: error,
  }),
  createWebhookRequest: fields => ({
    type: types.CREATE_WEBHOOK_REQUEST,
    payload: fields,
  }),
  createWebhookSuccess: webhook => ({
    type: types.CREATE_WEBHOOK_SUCCESS,
    payload: webhook,
  }),
  createWebhookFailure: error => ({
    type: types.CREATE_WEBHOOK_FAILURE,
    payload: error,
  }),
  createWebhookInterestRequest: formData => ({
    type: types.CREATE_WEBHOOK_INTEREST_REQUEST,
    payload: formData,
  }),
  createWebhookInterestSuccess: () => ({
    type: types.CREATE_WEBHOOK_INTEREST_SUCCESS,
    payload: null,
  }),
  createWebhookInterestFailure: error => ({
    type: types.CREATE_WEBHOOK_INTEREST_FAILURE,
    payload: error,
  }),
  updateWebhookRequest: (webhookId, update) => ({
    type: types.UPDATE_WEBHOOK_REQUEST,
    payload: update,
    meta: webhookId,
  }),
  updateWebhookSuccess: (webhookId, updatedWebhook) => ({
    type: types.UPDATE_WEBHOOK_SUCCESS,
    payload: updatedWebhook,
    meta: webhookId,
  }),
  updateWebhookFailure: error => ({
    type: types.UPDATE_WEBHOOK_FAILURE,
    payload: error,
  }),
  fetchTestEventRequest: webhookId => ({
    type: types.FETCH_TEST_EVENT_REQUEST,
    meta: webhookId,
  }),
  fetchTestEventSuccess: testEvent => ({
    type: types.FETCH_TEST_EVENT_SUCCESS,
    payload: testEvent,
  }),
  fetchTestEventFailure: error => ({
    type: types.FETCH_TEST_EVENT_FAILURE,
    payload: error,
  }),
  testWebhookRequest: (webhookId, testBody) => ({
    type: types.TEST_WEBHOOK_REQUEST,
    meta: webhookId,
    payload: testBody,
  }),
  testWebhookSuccess: response => ({
    type: types.TEST_WEBHOOK_SUCCESS,
    payload: response,
  }),
  testWebhookFailure: error => ({
    type: types.TEST_WEBHOOK_FAILURE,
    payload: error,
  }),
  toggleWebhookModal: modalName => ({
    type: types.TOGGLE_WEBHOOK_MODAL,
    payload: modalName,
  }),
  selectWebhook: webhookId => ({
    type: types.SELECT_WEBHOOK,
    payload: webhookId,
  }),
  clearTestData: () => ({
    type: types.CLEAR_TEST_DATA,
  }),
};

export const initialState = {
  webhooks: {},
  webhooksErrorMessage: '',
  interestErrorMessage: '',
  isSubmittingInterest: false,
  isCreatingWebhook: false,
  isTestingWebhook: false,
  isVerifyingWebhook: false,
  testEvent: null,
  testWebhookResponse: null,
  currentModal: null,
  currentWebhook: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_WEBHOOKS_REQUEST:
      return {
        ...state,
        webhooksErrorMessage: '',
      };
    case types.FETCH_WEBHOOKS_SUCCESS:
      return {
        ...state,
        webhooks: action.payload,
      };
    case types.FETCH_WEBHOOKS_FAILURE:
      return {
        ...state,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong fetching webhooks',
      };
    case types.VERIFY_WEBHOOK_REQUEST:
      return {
        ...state,
        isVerifyingWebhook: true,
      };
    case types.VERIFY_WEBHOOK_SUCCESS:
      return {
        ...state,
        isVerifyingWebhook: false,
        webhooks: _.set(_.cloneDeep(state.webhooks), action.meta, action.payload),
      };
    case types.VERIFY_WEBHOOK_FAILURE:
      return {
        ...state,
        isVerifyingWebhook: false,
      };
    case types.DELETE_WEBHOOK_REQUEST:
      return {
        ...state,
        webhooksErrorMessage: '',
      };
    case types.DELETE_WEBHOOK_SUCCESS: {
      const oldWebhooks = _.cloneDeep(state.webhooks);
      const newWebhooks = _.omit(oldWebhooks, [action.meta]);
      return {
        ...state,
        webhooks: newWebhooks,
        webhooksErrorMessage: '',
      };
    }
    case types.DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong deleting this webhook',
      };
    case types.CREATE_WEBHOOK_REQUEST:
      return {
        ...state,
        webhooksErrorMessage: '',
        isCreatingWebhook: true,
      };
    case types.CREATE_WEBHOOK_SUCCESS: {
      const oldWebhooks = _.cloneDeep(state.webhooks);
      const newWebhooks = _.set(oldWebhooks, action.payload.id, action.payload);
      return {
        ...state,
        webhooks: newWebhooks,
        webhooksErrorMessage: '',
        currentWebhook: action.payload.id,
        isCreatingWebhook: false,
      };
    }
    case types.CREATE_WEBHOOK_FAILURE:
      return {
        ...state,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong creating this webhook',
        isCreatingWebhook: false,
      };
    case types.CREATE_WEBHOOK_INTEREST_REQUEST:
      return {
        ...state,
        interestErrorMessage: '',
        isSubmittingInterest: true,
      };
    case types.CREATE_WEBHOOK_INTEREST_SUCCESS: {
      return {
        ...state,
        isSubmittingInterest: false,
      };
    }
    case types.CREATE_WEBHOOK_INTEREST_FAILURE: {
      return {
        ...state,
        isSubmittingInterest: false,
        interestErrorMessage: (action.payload && action.payload.message) || 'Something went wrong submitting your interest',
      };
    }
    case types.UPDATE_WEBHOOK_REQUEST:
      return {
        ...state,
        webhooksErrorMessage: '',
      };
    case types.UPDATE_WEBHOOK_SUCCESS: {
      const oldWebhooks = _.cloneDeep(state.webhooks);
      const newWebhooks = _.set(oldWebhooks, action.meta, action.payload);
      return {
        ...state,
        webhooks: newWebhooks,
        webhooksErrorMessage: '',
      };
    }
    case types.UPDATE_WEBHOOK_FAILURE:
      return {
        ...state,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong updating this webhook',
      };
    case types.FETCH_TEST_EVENT_REQUEST:
      return {
        ...state,
        webhooksErrorMessage: '',
      };
    case types.FETCH_TEST_EVENT_SUCCESS:
      return {
        ...state,
        testEvent: action.payload,
      };
    case types.FETCH_TEST_EVENT_FAILURE:
      return {
        ...state,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong fetching this webhook\'s test data',
      };
    case types.TEST_WEBHOOK_REQUEST:
      return {
        ...state,
        isTestingWebhook: true,
        webhooksErrorMessage: '',
      };
    case types.TEST_WEBHOOK_SUCCESS: {
      return {
        ...state,
        isTestingWebhook: false,
        testWebhookResponse: action.payload,
      }; }
    case types.TEST_WEBHOOK_FAILURE:
      return {
        ...state,
        isTestingWebhook: false,
        webhooksErrorMessage: (action.payload && action.payload.message) || 'Something went wrong testing this webhook',
      };
    case types.TOGGLE_WEBHOOK_MODAL:
      return {
        ...state,
        currentModal: action.payload,
      };
    case types.SELECT_WEBHOOK:
      return {
        ...state,
        currentWebhook: action.payload,
      };
    case types.CLEAR_TEST_DATA:
      return {
        ...state,
        testEvent: null,
        testWebhookResponse: null,
      };
    default:
      return state;
  }
}
