const staticText = {
  disconnectVehicle: 'Disconnect vehicle',
  cancel: 'Cancel',
  body1: 'This action cannot be undone.',
  body2: 'Are you sure you want to disconnect the {{selectedVehicle}}?',
  noPermissionBody1: 'You may not have the appropriate permissions to perform this action.',
  noPermissionBody2: 'Please consult the application owner or an admin on your team.',
};

export default staticText;
