import { Button, TextField, styled } from '@mui/material';

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

export const FiltersContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginLeft: 'auto',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    height: '2.625rem',
    color: theme.palette.text.primary,
    border: 'none',
  },

  '& input': {
    padding: theme.spacing(1, 2),
  },
  '& label': {
    top: '-6px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
  '& fieldset': {
    transition: 'border 0.25s ease',
  },
}));
