import { shape, string } from 'prop-types';

import generateActionProps from './utils/generateActionProps';
import { types as secretTypes } from '../components/Credentials/components/Secret/reducers';

export const actionProps = generateActionProps(secretTypes);

export const defaultProps = {
  secret: null,
};

export default {
  actions: shape({ ...actionProps }).isRequired,
  secret: string,
  applicationId: string.isRequired,
  applicationName: string.isRequired,
};
