import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TimelineBox = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '150px',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ProgressBar = styled('div', {
  shouldForwardProp: prop => (prop !== 'percentComplete'),
})(({ percentComplete, theme }) => ({
  height: '2px',
  width: '100%',
  backgroundColor: theme.palette.grey[200],
  position: 'relative',
  marginLeft: '27px',

  '&::after': {
    position: 'absolute',
    content: '""',
    top: '0',
    left: '0',
    width: `${percentComplete}%`,
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StageWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'leftOffset',
})(({ leftOffset }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '53px',

  ...(leftOffset && {
    left: `calc(${leftOffset} - 15px)`,
  }),
}));

export const StageLabel = styled(Typography)(({ theme }) => ({
  position: 'relative',
  fontSize: theme.typography.fontSizes.xs,
}));

export const StageTime = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down(2000)]: {
    fontSize: theme.typography.fontSizes.xs,
  },
}));

export const StageButton = styled('button', {
  shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
  width: '32px',
  height: '32px',
  margin: theme.spacing(1),
  fontFamily: theme.typography.bold.monospace,
  fontSize: theme.typography.fontSizes.sm,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '100%',
  cursor: 'pointer',

  ...(active && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),

  '&:disabled': {
    cursor: 'auto',
  },

  '& p': {
    margin: '0',
    marginBottom: '-2px',
  },
}));

export const FuelIndicator = styled('div')(() => ({
  position: 'absolute',
  transform: 'translateX(-50%)',
  left: '50%',
  bottom: '100%',
  paddingBottom: '4px',

  img: {
    height: '20px',
    width: '20px',
  },
}));
