import moment from 'moment-timezone';

const dateUtils = {};

/**
 * Get Date of the start of the day for the current UTC date.
 * @param [date] - defaults to current date
 * @returns {Date}
 */
dateUtils.getStartOfDayUtc = date => moment(date).utc().startOf('day').toDate();

/**
 * Get Date of the start of the month for the given date.
 * @param [date] - defaults to current date
 * @returns {Date}
 */
dateUtils.getStartOfMonthUtc = date => moment(date).utc().startOf('month').toDate();

/**
 * Get Date of the start of the year for the given date.
 * @param [date] - defaults to current date
 * @returns {Date}
 */
dateUtils.getStartOfYearUtc = date => moment(date).utc().startOf('year').toDate();

/**
 * Get Date of the corresponding UTC end of the day for the given date
 * @param date
 * @returns {Date}
 */
dateUtils.getEndOfDayUtc = date => moment(date).utc().endOf('day').toDate();

/**
 * Get seconds
 * @param date
 * @returns {Date}
 */
dateUtils.getTime = date => moment(date).toDate().getTime();

/**
 * Subtract days from current time.
 * @returns {Date}
 */
dateUtils.minDate = days => dateUtils.subtract(new Date(), days, 'day');

/**
 * Add unit of time to a Date.
 * @returns {Date}
 */
dateUtils.add = (date, amount, unit) => moment(date).add(amount, unit).toDate();

/**
 * Subtract unit of time to a Date.
 * @returns {Date}
 */
dateUtils.subtract = (date, amount, unit) => moment(date).subtract(amount, unit).toDate();

/**
 * Determine whether or not a date is before another.
 * @returns {boolean}
 */
dateUtils.isBefore = (date1, date2) => moment(date1).isBefore(date2);

/**
 * Determine whether or not a date is after another.
 * @returns {boolean}
 */
dateUtils.isAfter = (date1, date2) => moment(date1).isAfter(date2);

/**
 * Determine whether or not a date is the same.
 * @returns {boolean}
 */
dateUtils.isSame = (date1, date2) => moment(date1).isSame(date2);

/**
 * Determine whether or not a date is before another.
 * @returns {boolean}
 */
dateUtils.format = (date, format) => moment(date).format(format);

/**
 * Converts unix timestamp to a format we needed that in.
 * @returns {string}
 */
dateUtils.timestampToDate = (timestamp, format = 'DD-MMM-YYYY') => {
  return moment(timestamp).utc().format(format);
};

export default dateUtils;
