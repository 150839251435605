import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { CopyButton, InlineLinks } from '../../../../../../../../../../components';
import staticText from '../../../../../../../../../../localization/Application/Simulator/simulator';
import { checkmarkIconGray, checkmarkIconGreen } from '../../../../../../../../../../assets/icons';

const Credential = styled(Typography)(({ theme }) => ({
  width: '300px',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.background,
  padding: '10px',
  margin: theme.spacing(2, 0),
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.monospace,
  fontSize: theme.typography.fontSizes.xs,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  backgroundColor: theme.palette.background,
  padding: '6px 6px 4px',
  marginLeft: theme.spacing(1),
}));

const Callout = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(1),
  borderRadius: theme.border.radius,
}));

const CredentialsInfo = ({
  username, password, vehicleIsConnected, isAutoplay,
}) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        {vehicleIsConnected ?
          <img src={checkmarkIconGreen} alt="success" /> :
          <img src={checkmarkIconGray} alt="pending" />
        }
        <StatValue>{vehicleIsConnected ? 'Vehicle connected' : 'Vehicle not yet connected'}</StatValue>
      </Box>
      {isAutoplay && (
        <div>
          <Typography variant="h2" mb={2}>{staticText.credentialsDrawerContent.autoplayTitle}</Typography>
          <Typography sx={{ lineHeight: theme.typography.lineHeights.paragraph }}>
            {staticText.credentialsDrawerContent.autoplayText}
          </Typography>
        </div>
      )}
      <div>
        <Typography variant="h2" mb={2}>{staticText.credentialsDrawerContent.title1}</Typography>
        <Typography sx={{ lineHeight: theme.typography.lineHeights.paragraph }}>
          <InlineLinks text={staticText.credentialsDrawerContent.text1} />
        </Typography>
        <Callout>
          <Typography variant="caption">
            {staticText.credentialsDrawerContent.callout1}
          </Typography>
        </Callout>
      </div>
      <div>
        <Typography variant="h2" mb={2}>{staticText.credentialsDrawerContent.title2}</Typography>
        <Typography sx={{ lineHeight: theme.typography.lineHeights.paragraph }}>
          <InlineLinks text={staticText.credentialsDrawerContent.text2} />
        </Typography>
      </div>
      <div>
        <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>{staticText.credentialsDrawerContent.emailLabel}</Typography>
        <Box display="flex" alignItems="center">
          <Credential>{username}</Credential><CopyButton content={username} />
        </Box>
        <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>{staticText.credentialsDrawerContent.passwordLabel}</Typography>
        <Box display="flex" alignItems="center">
          <Credential>{password}</Credential><CopyButton content={password} />
        </Box>
      </div>
      <Box mt={-4}>
        <Callout>
          <Typography variant="caption">{staticText.credentialsDrawerContent.footnote}</Typography>
        </Callout>
      </Box>
    </React.Fragment>
  );
};

export default CredentialsInfo;

CredentialsInfo.propTypes = {
  username: string.isRequired,
  password: string.isRequired,
  vehicleIsConnected: bool.isRequired,
  isAutoplay: bool.isRequired,
};
