import { bool, func, objectOf, string } from 'prop-types';
import React from 'react';

import { FiltersContainer, ResetButton, StyledTextField } from './styles';
import { selectFilters, staticText } from '../../../../../../localization/Application/Members/filtersBar';
import { FiltersForm, MenuItemWrapper } from '../../../../../../components';
import getFilterStatusAndTitle from '../../../Logs/utils/getFilterStatusAndTitle';
import { sortRoles } from './utils';

const FiltersBar = ({
  getLabel,
  defaultFilterValues,
  filterValues,
  setFilterValues,
}) => {
  const handleChange = (filterKey, newValue) => {
    const newFilters = {
      ...filterValues,
      [filterKey]: newValue,
    };
    setFilterValues(newFilters);
  };

  const handleInputChange = (e) => {
    handleChange(e.target.id, e.target.value);
  };

  const handleReset = () => {
    setFilterValues(defaultFilterValues);
  };

  return (
    <FiltersContainer>
      <ResetButton
        id="reset-all-button"
        onClick={handleReset}
        variant="text"
      >
        {staticText.resetAll}
      </ResetButton>
      <StyledTextField
        value={filterValues.search}
        onChange={handleInputChange}
        label={staticText.searchLabel}
        id="search"
      />
      {selectFilters.map(({
        filterKey, Icon, formTitle, id, minWidth, columns,
      }) => {
        const {
          isFilterActive,
          menuItemWrapperTitle,
        } = getFilterStatusAndTitle(filterValues, filterKey, formTitle);

        return (
          <MenuItemWrapper
            title={menuItemWrapperTitle}
            isFilterActive={isFilterActive}
            key={filterKey}
            Icon={Icon}
            id={id}
            minWidth={minWidth}
            filterKey={filterKey}
          >
            <FiltersForm
              checkboxOptions={filterValues[filterKey]}
              updateCheckboxes={newValue => handleChange(filterKey, newValue)}
              columns={columns}
              title={formTitle}
              getLabel={option => getLabel(filterKey, option)}
              sortOptions={filterKey === 'roles' ? sortRoles : null}
            />
          </MenuItemWrapper>
        );
      })}
    </FiltersContainer>
  );
};

export default FiltersBar;

const FilterProps = {
  access: objectOf(bool).isRequired,
  roles: objectOf(bool).isRequired,
  search: string.isRequired,
};

FiltersBar.propTypes = {
  getLabel: func.isRequired,
  defaultFilterValues: FilterProps.isRequired,
  filterValues: FilterProps.isRequired,
  setFilterValues: func.isRequired,
};
