import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';

import ApplicationHeader from '../ApplicationHeader';

import staticText from '../../../../localization/Application/Logs/logs';
import RequestLogs from './components/RequestLogs';

const Logs = () => {
  const [currentModal, setCurrentModal] = useState(null);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Logs');
  }, []);

  return (
    <Box sx={{ maxWidth: '1600px', minWidth: '1100px' }}>
      <ApplicationHeader heading={staticText.logsHeader} />
      <RequestLogs setCurrentModal={setCurrentModal} />
      {currentModal}
    </Box>
  );
};

export default Logs;
