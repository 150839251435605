/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Box, Button, Typography } from '@mui/material';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

import {
  Appearance,
  BrandManagement,
  ConnectPreview,
  Countries,
} from './components';
import { SlideInWrapper, Spinner } from '../../../../components';
import {
  CustomizationsContainer,
  PreviewWrapper,
  CustomizationsSidebar,
  CustomizationsGroupButton,
  BackButton,
  PublishButtonContainer,
  CustomizationsGroup,
} from './styles';
import staticText from '../../../../localization/Application/connect-config';
import checkChangeToCustomizations from './utils/checkChangeToCustomizations';
import { determineSelectedBrands, filterMakesByEngineType } from './components/BrandManagement/utils/filterMakesByEngineType';
import previewConfig from './utils/previewConfig';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import { engineTypeOptions } from './components/BrandManagement/components/BrandList/components/EngineFilter/utils';

const ConnectCustomizations = ({
  actions: {
    fetchConnectConfigRequest,
    fetchApplicationMakesRequest,
    fetchSupportedCountriesRequest,
    fetchBrandManagementCapabilitiesRequest,
    updateConnectConfigRequest,
    updateApplication,
    clearErrors,
  },
  appId,
  appName,
  connectConfig,
  defaultSupportedBrands,
  brandManagementCapabilities,
  defaultSupportedCountries,
  fetchingConnectConfigErrors,
  isFetchingConnectConfig,
  isFetchingDefaultSupportedBrands,
  isFetchingDefaultSupportedCountries,
  isFetchingBrandManagementCapabilities,
  isUpdatingConnectConfig,
  featureSetId,
}) => {
  const themeIsGated = gatedFeatureData(FEATURES.CONNECT_CONFIG_THEME, featureSetId);
  const engineFilterIsGated = gatedFeatureData(FEATURES.CONNECT_CONFIG_ENGINE_FILTER, featureSetId);
  const selectBrandsIsGated = gatedFeatureData(FEATURES.CONNECT_CONFIG_SELECT_BRANDS, featureSetId);
  const selectCountriesIsGated =
    gatedFeatureData(FEATURES.CONNECT_CONFIG_SELECT_COUNTRIES, featureSetId);

  /**
  * State across all customizations group components lives here at the parent level.
  * Initial values are set to whatever is saved in the app's connect config record.
  *
  * customizations are stored as a user moves between customizations groups, and will get
  * reset to the original config if the user refreshes or navigates away from the
  * ConnectCustomizations page without publishing.
  *
  * Each customizations group component is passed the selections state and update method
  * as props.
  */
  const [customizations, setCustomizations] =
    useState({});
  const [activeCustomizationsGroup, setActiveCustomizationsGroup] = useState(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [brandsFilteredOut, setBrandsFilteredOut] = useState([]);
  const [suggestionDialogOpen, setSuggestionDialogOpen] = useState(false);
  const brandSelectDialogKey = 'viewedBrandSelectDialog';

  const openCustomizationsGroup = (e) => {
    setActiveCustomizationsGroup(e.target.name);
    reportToSegment(types.TRACK, eventNames.tabSelected, {
      text: e.target.textContent,
      label: 'connect panel',
    });
  };

  // This handler is passed to each customizations group component
  // for handling customization state updates.
  // Updated fields should be passed in the format:
  // { [fieldName]: value }
  const updateCustomizations = (updatedFields) => {
    if (updatedFields.brandManagementFilters) {
      const {
        filteredSelectedBrands,
        brandsFilteredOut: updatedBrandsFilteredOut,
      } = filterMakesByEngineType(
        customizations.supportedMakes,
        brandManagementCapabilities,
        updatedFields.brandManagementFilters,
        defaultSupportedBrands,
        selectBrandsIsGated,
      );
      updatedFields.supportedMakes = filteredSelectedBrands;
      setBrandsFilteredOut(updatedBrandsFilteredOut);
    }

    setCustomizations({ ...customizations, ...updatedFields });
    // Disable publish button when no change to customizations is detected
    const changeDetected = checkChangeToCustomizations(
      customizations,
      updatedFields,
      connectConfig,
      appName,
    );
    setSubmitDisabled(!changeDetected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customizations.appName && appName !== customizations.appName) {
      updateApplication(appId, { name: customizations.appName });
    }
    updateConnectConfigRequest(customizations);
    if (customizations.supportedMakes.length === 1) {
      // only display dialog if user hasn't already seen it
      const hasViewedBrandSelectDialog = localStorage.getItem(brandSelectDialogKey) === 'true';
      if (!hasViewedBrandSelectDialog) {
        setSuggestionDialogOpen(true);
      }
    }
    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'Customize Connect',
      form_content: {
        ...customizations,
      },
    });
    setSubmitDisabled(true);
  };

  const renderPublishingError = () => (
    fetchingConnectConfigErrors && fetchingConnectConfigErrors.updatingConnectConfig ? (
      <Typography variant="caption" color="error" mt={1}>
        {staticText.errors.updatingConnectConfig}
      </Typography>
    ) : null
  );

  const renderBackButton = () => (
    <BackButton
      onClick={() => {
        setActiveCustomizationsGroup(null);
      }}
    >
      <ChevronLeft />
      {staticText.backButton}
    </BackButton>
  );

  useEffect(() => {
    // Once all data has been fetched, set the true initial state
    if (
      !isFetchingConnectConfig
      && !isFetchingDefaultSupportedBrands
      && !isFetchingDefaultSupportedCountries
      && !isFetchingBrandManagementCapabilities
    ) {
      const selectedMakes = (
        connectConfig && connectConfig.supportedMakes && connectConfig.supportedMakes.length > 0) ?
        connectConfig.supportedMakes : defaultSupportedBrands.map(brand => brand.make);
      const brandManagementFilters = (
        !engineFilterIsGated &&
        connectConfig &&
        connectConfig.brandManagementFilters &&
        connectConfig.brandManagementFilters.length > 0) ? connectConfig.brandManagementFilters :
        engineTypeOptions.map(option => option.type);
      const selectedBrands = determineSelectedBrands({
        engineFilterIsGated,
        selectBrandsIsGated,
        selectedMakes,
        brandManagementFilters,
        defaultSupportedBrands,
        brandManagementCapabilities,
        setBrandsFilteredOut,
      });

      setCustomizations({
        appName,
        theme: (!themeIsGated && connectConfig && connectConfig.theme) ? connectConfig.theme : 'system',
        logoUrl: (connectConfig && connectConfig.logoUrl) ? connectConfig.logoUrl : '',
        supportedMakes: selectedBrands,
        brandManagementFilters,
        newBrandOptIn: !selectBrandsIsGated ?
          !((connectConfig && connectConfig.newBrandOptIn === false)) :
          true,
        supportedCountries:
          (!selectCountriesIsGated &&
            connectConfig &&
            connectConfig.supportedCountries &&
            connectConfig.supportedCountries.length > 0) ?
            connectConfig.supportedCountries : defaultSupportedCountries,
      });

      setIsInitialized(true);
    }
    // clear any errors on page refresh
    return () => {
      clearErrors();
    };
  }, [
    isFetchingConnectConfig,
    isFetchingDefaultSupportedBrands,
    isFetchingDefaultSupportedCountries,
    isFetchingBrandManagementCapabilities,
  ]);

  useEffect(() => {
    // Any initial data required for connect customizations should be fetched here
    fetchConnectConfigRequest(); // previously saved config if any
    fetchApplicationMakesRequest(); // makes app has access to
    fetchSupportedCountriesRequest(); // countries app has access to
    fetchBrandManagementCapabilitiesRequest(); // brand management capabilities

    reportToSegment(types.PAGE, 'Customizations');
  }, []);

  return (
    <CustomizationsContainer>
      <PreviewWrapper>
        {isInitialized && (
          <ConnectPreview
            customizations={customizations}
            config={previewConfig[activeCustomizationsGroup]}
            defaultSupportedCountries={defaultSupportedCountries}
          />
        )}
      </PreviewWrapper>
      <SlideInWrapper>
        <CustomizationsSidebar>
          {!isInitialized && <Spinner />}
          {isInitialized && !activeCustomizationsGroup && (
            <Box padding={3}>
              <Typography variant="h1" marginBottom={2}>
                {staticText.heading}
              </Typography>
              <Typography variant="body1" marginBottom={4}>
                {staticText.description}
              </Typography>
              <CustomizationsGroupButton
                name="appearance"
                onClick={openCustomizationsGroup}
              >
                {staticText.appearance.heading}
                <ChevronRight />
              </CustomizationsGroupButton>
              <CustomizationsGroupButton
                name="brands"
                onClick={openCustomizationsGroup}
              >
                {staticText.brandManagement.heading}
                <ChevronRight />
              </CustomizationsGroupButton>
              <CustomizationsGroupButton
                name="countries"
                onClick={openCustomizationsGroup}
              >
                {staticText.countries.heading}
                <ChevronRight />
              </CustomizationsGroupButton>
            </Box>
          )}

          {activeCustomizationsGroup === 'appearance' && (
            <CustomizationsGroup id="appearance">
              <SlideInWrapper>
                <div>
                  {renderBackButton()}
                  <Appearance
                    customizations={customizations}
                    updateCustomizations={updateCustomizations}
                    themeIsGated={themeIsGated}
                  />
                </div>
              </SlideInWrapper>
            </CustomizationsGroup>
          )}
          {activeCustomizationsGroup === 'brands' && (
            <CustomizationsGroup id="brands">
              <SlideInWrapper>
                <div>
                  {renderBackButton()}
                  <BrandManagement
                    disabledBrands={brandsFilteredOut}
                    customizations={customizations}
                    updateCustomizations={updateCustomizations}
                    suggestionDialogOpen={suggestionDialogOpen}
                    setSuggestionDialogOpen={setSuggestionDialogOpen}
                    selectBrandsIsGated={selectBrandsIsGated}
                    engineFilterIsGated={engineFilterIsGated}
                    brandSelectDialogKey={brandSelectDialogKey}
                  />
                </div>
              </SlideInWrapper>
            </CustomizationsGroup>
          )}
          {activeCustomizationsGroup === 'countries' && (
            <CustomizationsGroup id="countries">
              <SlideInWrapper>
                <div>
                  {renderBackButton()}
                  <Countries
                    customizations={customizations}
                    updateCustomizations={updateCustomizations}
                    selectCountriesIsGated={selectCountriesIsGated}
                  />
                </div>
              </SlideInWrapper>
            </CustomizationsGroup>
          )}

          {isInitialized && (
            <PublishButtonContainer>
              <Button
                id="publish-customizations"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                disabled={submitDisabled}
              >
                {isUpdatingConnectConfig ? <Spinner size="button" spinnerColor="#FFF" /> : staticText.publishAllChanges}
              </Button>
              {renderPublishingError()}
            </PublishButtonContainer>)}
        </CustomizationsSidebar>
      </SlideInWrapper>
    </CustomizationsContainer>
  );
};

export default ConnectCustomizations;

ConnectCustomizations.propTypes = {
  actions: shape({
    fetchConnectConfigRequest: func.isRequired,
    fetchApplicationMakesRequest: func.isRequired,
    fetchSupportedCountriesRequest: func.isRequired,
    updateConnectConfigRequest: func.isRequired,
    updateApplication: func.isRequired,
    deleteAppLogo: func.isRequired,
    clearErrors: func.isRequired,
  }).isRequired,
  appId: string.isRequired,
  appName: string.isRequired,
  connectConfig: shape({
    theme: string,
    logoUrl: string,
    supportedCountries: arrayOf(string),
  }),
  defaultSupportedBrands: arrayOf(
    shape({
      make: string.isRequired,
      displayName: string.isRequired,
    })).isRequired,
  brandManagementCapabilities: arrayOf(shape(object)).isRequired,
  defaultSupportedCountries: arrayOf(string).isRequired,
  isFetchingConnectConfig: bool.isRequired,
  isFetchingDefaultSupportedBrands: bool.isRequired,
  isFetchingDefaultSupportedCountries: bool.isRequired,
  isFetchingBrandManagementCapabilities: bool.isRequired,
  updateConnectConfigRequest: func,
  isUpdatingConnectConfig: bool.isRequired,
  fetchingConnectConfigErrors: shape({
    connectConfig: string,
  }),
  featureSetId: string.isRequired,
};

ConnectCustomizations.defaultProps = {
  fetchingConnectConfigErrors: {},
  connectConfig: {},
  updateConnectConfigRequest: () => {},
};
