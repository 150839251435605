import React from 'react';

const ContrastIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 19.25C15.33 19.25 19.25 15.33 19.25 10.5C19.25 5.67 15.33 1.75 10.5 1.75C5.67 1.75 1.75 5.67 1.75 10.5C1.75 15.33 5.67 19.25 10.5 19.25ZM11.375 3.56125C14.8225 3.99 17.5 6.93 17.5 10.5C17.5 14.07 14.8313 17.01 11.375 17.4388V3.56125Z" fill="#282828" />
    </svg>
  );
};

export default ContrastIcon;
