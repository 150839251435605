import React, { useEffect, useState } from 'react';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, Button, Typography, Grid, InputAdornment, IconButton } from '@mui/material';
import { arrayOf, bool, func, shape, string } from 'prop-types';

import { VisibilityOff, Visibility } from '@mui/icons-material';
import { AuthContentContainer, InlineRoute, InputField } from '../../../../components';
import staticText from '../../../../localization/Authentication/resetPassword';
import authFormState from '../authFormUtils';
import SuccessMessage from '../../../../components/SuccessMessage/SuccessMessage';
import urlUtils from '../../../../services/url/urlUtils';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';

const ResetPassword = ({
  actions: { resetPasswordRequest, logoutRequest, resetErrors },
  inProgress,
  formSuccess,
  resetPasswordFormErrors,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const {
    setErroredFields,
    erroredFields,
    handleFocus,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState();

  useEffect(() => {
    logoutRequest(false);
    resetErrors();
    reportToSegment(types.PAGE, 'Reset Password', { path: window.location.pathname });
    setFormSubmitted(false);
  }, []);

  useEffect(() => {
    const locationSearch = window.location.search;
    setAuthToken(locationSearch ? urlUtils.getQueryParam(locationSearch, 'auth_token') : undefined);
  }, [authToken]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields([resetPasswordFormErrors]);
  }, [inProgress, resetPasswordFormErrors, formSuccess]);

  const validateFields = (fields) => {
    return fields.password && (fields.password === fields.confirmPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateFields(values)) {
      setFormSubmitted(true);
      resetPasswordRequest(values, authToken);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'reset password success',
        form_content: {},
      });
    } else {
      setErroredFields([{ message: staticText.invalidMessage }]);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'reset password failure',
        form_content: {},
      });
    }
  };

  const getGeneralError = () => {
    let error;
    if (erroredFields.length > 0 && typeof erroredFields[0].message === 'string') {
      return erroredFields[0].message;
    }

    if (Array.isArray(erroredFields)) {
      error = erroredFields.find(err => !err.field || err.field === 'authToken' || err.field === 'password');
    } else {
      error = erroredFields;
    }
    return error || '';
  };

  const togglePasswordVisibility = () => {
    /* istanbul ignore next */
    setShowPassword(show => !show);
  };

  return (
    <AuthContentContainer subLink={staticText.subLink}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={7}>
          <Typography variant="h1">{staticText.title}</Typography>
          {formSubmitted && formSuccess ? (
            <SuccessMessage
              message={staticText.successMessage}
              routeInfo={staticText.returnToLogin}
            />
          ) : (
            <span>
              <form onSubmit={handleSubmit}>
                <Typography id="error-container" color="error" variant="caption" position="relative" top="15px">
                  {getGeneralError()}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
                  {staticText.fields.map(field => (
                    <InputField
                      key={field.name}
                      inputName={field.name}
                      inputType={!showPassword ? field.type : 'text'}
                      inputValues={values[field.name]}
                      inputPlaceholder={field.label}
                      handleChange={handleChange}
                      handleFocus={handleFocus}
                      inputDisabled={disableFields}
                      errorMessage={getErrorMessage(field)}
                      InputProps={field.name === 'confirmPassword' ? {
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={togglePasswordVisibility}
                        >
                          { showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  } : {}}
                    />
                  ))}
                </Box>
                <Box mt={2} mb={4} pr={6}>
                  <PasswordRequirements inputString={values.password} />
                </Box>
                <Box mt={2} mb={4} pr={6}>
                  <InlineRoute text={staticText.returnToLogin} />
                </Box>
                <Button
                  disabled={disableFields}
                  id="btnSubmit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  {staticText.btnSubmit}
                </Button>
              </form>
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: { xs: 'none', md: 'block' },
            textAlign: 'center',
          }}
        >
          <img
            src={staticText.image.url}
            alt={staticText.image.alt}
            width="230"
          />
        </Grid>
      </Grid>
    </AuthContentContainer>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  actions: shape({
    resetPasswordRequest: func.isRequired,
    logoutRequest: func.isRequired,
  }).isRequired,
  inProgress: bool,
  formSuccess: bool,
  resetPasswordFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
};

ResetPassword.defaultProps = {
  resetPasswordFormErrors: [],
  inProgress: false,
  formSuccess: false,
};

