import validator from 'validator';

import { errors } from '../../../../../localization/Application/VehicleManagement/vehicleManagement';

const validateFilterValues = (filterValues) => {
  const { vehicleId } = filterValues;
  try {
    if (vehicleId && !validator.isUUID(vehicleId)) {
      throw new Error(errors.invalidVehicleId);
    }
    return filterValues;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export default validateFilterValues;
