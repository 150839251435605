import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DeactivateWebhookModal from './DeactivateWebhookModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state) => {
  return {
    webhookId: state.webhooks.currentWebhook,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { toggleWebhookModal, updateWebhookRequest } = webhooksActions;

  return {
    actions: bindActionCreators({
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
      updateWebhookRequest,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateWebhookModal);
