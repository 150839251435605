import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, styled, TextField, Typography } from '@mui/material';

import { Spinner } from '../../../../../../../../components';
import staticText from '../../../../../../../../localization/Application/configuration';
import redirectUriValidator from '../../../../../../../../services/validators/redirectUriValidator';

const UriInput = styled(TextField)(() => ({
  '.MuiOutlinedInput-root': {
    borderRadius: '4px 0 0 4px',
  },
  '.MuiOutlinedInput-input.MuiInputBase-input': {
    padding: '8px 14px',
  },
}));

const RedirectUriForm = ({
  isUpdating,
  clientId,
  redirectUris,
  createRedirectUri,
}) => {
  const initialState = {
    isValid: false,
    redirectUri: '',
    attemptedSubmit: false,
    errorMessage: 'Please enter a valid URI.',
    overUriLimit: false,
    attemptedInput: false,
  };
  const [state, setState] = useState(initialState);
  const {
    isValid,
    redirectUri,
    attemptedSubmit,
    errorMessage,
    overUriLimit,
    attemptedInput,
  } = state;

  const isOverLimit = uris => uris.length >= 16;

  const handleChange = (event) => {
    const { value: uri } = event.target;
    if (uri.length > 254) {
      setState({
        redirectUri: uri,
        isValid: false,
        errorMessage: 'The uri limit is 254 characters',
        attemptedSubmit: true,
      });
      return;
    }
    try {
      redirectUriValidator(uri, clientId, redirectUris);
      setState({
        redirectUri: uri, isValid: true, errorMessage: '', attemptedSubmit: false,
      });
    } catch (err) {
      setState({
        redirectUri: uri, isValid: false, errorMessage: err.message, attemptedSubmit: false,
      });
    }
  };

  const createUri = () => {
    createRedirectUri(redirectUri);
    setState(initialState);
  };

  const handleBlur = () => {
    setState(prev => ({
      ...prev,
      attemptedSubmit: true,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      reportToSegment(types.TRACK, eventNames.formSubmitted, { label: 'redirect uri form', form_content: { redirectUri } });
      createUri(event);
    }
  };

  useEffect(() => {
    setState(prev => ({
      ...prev,
      overUriLimit: isOverLimit(redirectUris),
      attemptedInput: false,
    }));
  }, [redirectUris]);

  return (
    !isUpdating ? (
      <form onSubmit={handleSubmit}>
        <Box display="flex">
          <UriInput
            id="add-uri-input"
            placeholder="Add new redirect URI"
            value={redirectUri}
            onChange={handleChange}
            onBlur={handleBlur}
            color={attemptedSubmit && !isValid ? 'error' : ''}
            disabled={overUriLimit}
            onClick={() => setState(prev => ({
              ...prev,
              attemptedInput: true,
            }))}
          />
          <Button
            id="submit-uri-button"
            disabled={!redirectUri || !isValid || overUriLimit}
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
          >
            {staticText.oauth.redirectUris.save}
          </Button>
        </Box>
        {attemptedSubmit && !isValid && <Typography variant="caption" color="error">{errorMessage}</Typography>}
        {overUriLimit && (
          <Typography
            id="uri-limit-message"
            color={attemptedInput ? 'error' : ''}
            sx={{ marginTop: '6px' }}
          >
            {staticText.oauth.redirectUris.limitMessage}
          </Typography>
        )}
      </form>
    ) : (
      <Spinner size="small" />
    )
  );
};

export default RedirectUriForm;

RedirectUriForm.propTypes = {
  createRedirectUri: func.isRequired,
  clientId: string.isRequired,
  isUpdating: bool.isRequired,
  redirectUris: arrayOf(string).isRequired,
};
