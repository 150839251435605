/* istanbul ignore file */

import { reportToSegment, types, eventNames } from '@smartcar/morse';

const defaultCalendlyUrl = 'https://calendly.com/smartcar-introductory-call/15-min?embed_domain=smartcar.com&embed_type=inline&utm_medium=internal&utm_source=dashboard&utm_campaign=dev_product_tour';

// maps calendly events to morse calendly events
const calendlyEvents = {
  'calendly.date_and_time_selected': eventNames.calendlyDemoTimeSelected,
  'calendly.event_scheduled': eventNames.calendlyDemoRequestSubmitted,
  'calendly.event_type_viewed': eventNames.calendlyDemoEventTypeViewed,
  'calendly.profile_page_viewed': eventNames.calendlyDemoProfilePageViewed,
};

// Before allowing custom properties, must solve the issue of attaching
// identical event listeners on subsequent calendly launches
const getDefaultProperties = () => {
  return {
    section: 'toc',
    text: 'Request a demo', // configured in CmdAI
    page: window.location.pathname,
  };
};

const reportCalendlyEvent = (event) => {
  const eventName = calendlyEvents[event.data.event];

  if (eventName) {
    reportToSegment(types.TRACK, eventName, getDefaultProperties());
  }
};

const isCalendlyEvent = (e) => {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
};

// Must have stable reference to this function to avoid
// attaching identical event listeners on subsequent calendly launches
const eventListener = (event) => {
  if (isCalendlyEvent(event)) {
    reportCalendlyEvent(event);
  }
};

const setCalendlyListener = () => {
  window.addEventListener(
    'message',
    eventListener,
    { passive: true },
  );
};

const launchCalendlyModal = ({
  userContext,
}) => {
  if (window.Calendly) {
    setCalendlyListener();
    window.Calendly.initPopupWidget({
      url: `${defaultCalendlyUrl}&name=${encodeURIComponent(`${userContext.firstName} ${userContext.lastName}`)}&email=${encodeURIComponent(userContext.email)}`,
    });

    reportToSegment(types.TRACK, eventNames.calendlyDemoModalOpened, getDefaultProperties());
    // Set listeners for other calendly events
  }
};

export default launchCalendlyModal;
