import {
  batteryCapacityIcon,
  batteryLevelIcon,
  chargeIcon,
  chargeLimitIcon,
  compassIcon,
  engineOilIcon,
  fuelIcon,
  lockUnlockIcon,
  locationIcon,
  odometerIcon,
  tirePressureIcon,
  vehicleInfoIcon,
  vinIcon,
  speedometerIcon,
  temperatureIcon,
} from '../../../../../assets/icons';

const endpointTypes = {
  BATTERY: '/battery',
  BATTERY_CAPACITY: '/battery/capacity',
  CHARGE: '/charge',
  CHARGE_AMPERAGE: '/charge/ammeter',
  CHARGE_LIMIT: '/charge/limit',
  CHARGE_VOLTAGE: '/charge/voltmeter',
  CHARGE_WATTAGE: '/charge/wattmeter',
  CLIMATE_CABIN: '/climate/cabin',
  CLIMATE_DEFROSTER: '/climate/defroster',
  CLIMATE_STEERING: '/climate/steering_wheel',
  COMPASS: '/compass',
  ENGINE_OIL: '/engine/oil',
  FUEL: '/fuel',
  LOCATION: '/location',
  ODOMETER: '/odometer',
  SECURITY: '/security',
  SPEEDOMETER: '/speedometer',
  THERMOMETER_EXTERIOR: '/thermometer/exterior',
  THERMOMETER_INTERIOR: '/thermometer/interior',
  TIRES: '/tires/pressure',
  VEHICLE_EXTENDED_INFO: '/attributes',
  VEHICLE_INFO: '/',
  VEHICLE_STATUS: '/status',
  VIN: '/vin',
};

/**
 * Used for determining the labels and icons for each endpoint and
 * endpoint signal that is displayed in the simulator,
 * including what units are used for each signal.
 *
 * The labels here should more or less match how endpoints are labeled
 * in our doc center for consistency/easy reference.
 */
const endpointDisplayMap = {
  [endpointTypes.BATTERY]: {
    label: 'EV battery level',
    icon: batteryCapacityIcon,
    data: {
      percentRemaining: {
        label: 'Percent remaining',
        unitType: 'percentage',
      },
      range: {
        label: 'Range',
        unitType: 'distance',
      },
    },
  },
  [endpointTypes.BATTERY_CAPACITY]: {
    label: 'EV battery capacity',
    icon: batteryLevelIcon,
    data: {
      capacity: {
        label: 'Capacity',
        unitType: 'power',
      },
    },
  },
  [endpointTypes.CHARGE]: {
    label: 'EV charging status',
    icon: chargeIcon,
    data: {
      isPluggedIn: {
        label: 'Plugged in',
        unitType: false,
      },
      state: {
        label: 'Charge state',
        unitType: false,
      },
    },
  },
  [endpointTypes.CHARGE_AMPERAGE]: {
    label: 'EV charging amperage',
    icon: chargeIcon,
  },
  [endpointTypes.CHARGE_LIMIT]: {
    label: 'EV charge limit',
    icon: chargeLimitIcon,
    data: {
      limit: {
        label: 'Limit',
        unitType: 'percentage',
      },
    },
  },
  [endpointTypes.CHARGE_VOLTAGE]: {
    label: 'EV charging voltage',
    icon: chargeIcon,
  },
  [endpointTypes.CHARGE_WATTAGE]: {
    label: 'EV charging wattage',
    icon: chargeIcon,
  },
  [endpointTypes.CLIMATE_CABIN]: {
    label: 'Climate cabin',
    icon: temperatureIcon,
  },
  [endpointTypes.CLIMATE_DEFROSTER]: {
    label: 'Climate defroster',
    icon: temperatureIcon,
  },
  [endpointTypes.CLIMATE_STEERING]: {
    label: 'Steering heater',
    icon: temperatureIcon,
  },
  [endpointTypes.COMPASS]: {
    label: 'Compass',
    icon: compassIcon,
  },
  [endpointTypes.ENGINE_OIL]: {
    label: 'Engine oil life',
    icon: engineOilIcon,
    data: {
      lifeRemaining: {
        label: 'Engine oil life',
        unitType: 'percentage',
      },
    },
  },
  [endpointTypes.FUEL]: {
    label: 'Fuel tank',
    icon: fuelIcon,
    data: {
      amountRemaining: {
        label: 'Amount remaining',
        unitType: 'volume',
      },
      percentRemaining: {
        label: 'Percent remaining',
        unitType: 'percentage',
      },
      range: {
        label: 'Range',
        unitType: 'distance',
      },
    },
  },
  [endpointTypes.LOCATION]: {
    label: 'Location',
    icon: locationIcon,
    data: {
      latitude: {
        label: 'Latitude',
        unitType: false,
      },
      longitude: {
        label: 'Longitude',
        unitType: false,
      },
    },
  },
  [endpointTypes.ODOMETER]: {
    label: 'Odometer',
    icon: odometerIcon,
    data: {
      distance: {
        label: 'Distance',
        unitType: 'distance',
      },
    },
  },
  [endpointTypes.SECURITY]: {
    label: 'Lock / unlock',
    icon: lockUnlockIcon,
  },
  [endpointTypes.SPEEDOMETER]: {
    label: 'Speedometer',
    icon: speedometerIcon,
  },
  [endpointTypes.THERMOMETER_EXTERIOR]: {
    label: 'Exterior thermometer',
    icon: temperatureIcon,
  },
  [endpointTypes.THERMOMETER_INTERIOR]: {
    label: 'Interior thermometer',
    icon: temperatureIcon,
  },
  [endpointTypes.TIRES]: {
    label: 'Tire pressure',
    icon: tirePressureIcon,
    data: {
      backLeft: {
        label: 'Back left',
        unitType: 'pressure',
      },
      backRight: {
        label: 'Back right',
        unitType: 'pressure',
      },
      frontLeft: {
        label: 'Front left',
        unitType: 'pressure',
      },
      frontRight: {
        label: 'Front right',
        unitType: 'pressure',
      },
    },
  },
  [endpointTypes.VEHICLE_EXTENDED_INFO]: {
    label: 'Extended vehicle attributes',
    icon: vehicleInfoIcon,
  },
  [endpointTypes.VEHICLE_INFO]: {
    label: 'Vehicle attributes',
    icon: vehicleInfoIcon,
  },
  [endpointTypes.VEHICLE_STATUS]: {
    label: 'Vehicle status',
    icon: vehicleInfoIcon,
  },
  [endpointTypes.VIN]: {
    label: 'Vin',
    icon: vinIcon,
  },
};

const removeMakeFromPath = (path) => {
  const bseMakes = ['tesla', 'cadillac', 'chevrolet'];
  const subpaths = path.split('/');
  if (bseMakes.includes(subpaths[1])) {
    return `/${subpaths.slice(2).join('/')}`;
  }
  return path;
};

const getEndpointLabel = (fullPath) => {
  const path = removeMakeFromPath(fullPath);
  return endpointDisplayMap[path] &&
    endpointDisplayMap[path].label;
};

const getEndpointIcon = (fullPath) => {
  const path = removeMakeFromPath(fullPath);
  return endpointDisplayMap[path] &&
    endpointDisplayMap[path].icon;
};

const getEndpointSignalLabel = (endpoint, key) => {
  return endpointDisplayMap[endpoint] &&
  endpointDisplayMap[endpoint].data[key] &&
  endpointDisplayMap[endpoint].data[key].label;
};

const getEndpointSignalType = (endpoint, key) => {
  return endpointDisplayMap[endpoint] &&
  endpointDisplayMap[endpoint].data[key] &&
  endpointDisplayMap[endpoint].data[key].unitType;
};

export {
  endpointTypes,
  removeMakeFromPath,
  getEndpointLabel,
  getEndpointIcon,
  getEndpointSignalLabel,
  getEndpointSignalType,
};
