import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { func, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Divider } from '../AppSelector/components/AppSelectorList/styles';
import { AccountButton, Menu, MenuItem } from './styles';

import { ArrowLink } from '../../../../../../components';
import { accountMenu as staticText } from '../../../../../../localization/Application/nav';
import useToggleMenu from '../../useToggleMenu';

const AccountMenu = ({
  userDisplayName,
  userEmail,
  logoutRequest,
}) => {
  const theme = useTheme();

  const {
    menuOpen,
    toggleMenu,
    menuRef,
  } = useToggleMenu({
    label: 'account menu',
    text: '[account menu]',
  });

  const handleUserSettingsClick = (e, path, text) => {
    toggleMenu(false);
    reportToSegment(types.TRACK, eventNames.linkClicked, { text, path });
  };

  const handleCookieSettingsClick = () => {
    if (window.Osano) {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: staticText.cookieSettings,
      text: staticText.cookieSettings,
    });
    toggleMenu(false);
  };
  const handleLogoutClick = (text) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text,
    });
    logoutRequest(true);
  };

  return (
    <Box sx={{ position: 'relative' }} ref={menuRef}>
      <AccountButton menuOpen={menuOpen}>
        <button type="button" onClick={toggleMenu} id="account-menu-btn">
          <img src="/assets/icons/nav/profile.svg" alt="Account menu" />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="bold">{userDisplayName}</Typography>
            <Typography variant="caption">{userEmail}</Typography>
          </Box>
          <ExpandMoreRoundedIcon htmlColor={theme.palette.primary.main} />
        </button>
      </AccountButton>
      {menuOpen && (
        <Menu>
          <MenuItem>
            <Link
              to={staticText.userProfile.link}
              onClick={e => handleUserSettingsClick(
                e, staticText.userProfile.link, staticText.userProfile.linkText,
              )}
            >
              {staticText.userProfile.linkText}
            </Link>
          </MenuItem>
          <MenuItem>
            <Button onClick={handleCookieSettingsClick}>{staticText.cookieSettings}</Button>
          </MenuItem>
          <MenuItem>
            <Button onClick={handleLogoutClick}>{staticText.logout}</Button>
          </MenuItem>
          <Divider />
          <MenuItem>
            <ArrowLink
              toggleMenu={toggleMenu}
              link={staticText.privacy.link}
              text={staticText.privacy.linkText}
              variant="bold"
              icon="launch"
            />
          </MenuItem>
          <MenuItem>
            <ArrowLink
              toggleMenu={toggleMenu}
              link={staticText.terms.link}
              text={staticText.terms.linkText}
              variant="bold"
              icon="launch"
            />
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default AccountMenu;

AccountMenu.propTypes = {
  userDisplayName: string.isRequired,
  userEmail: string.isRequired,
  logoutRequest: func.isRequired,
};
