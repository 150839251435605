import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SimulateVehicle from './SimulateVehicle';
import { actions } from '../../reducers';
import { actions as tripActions } from './reducers';

function mapStateToProps(state) {
  return {
    ...state.simulator,
    ...state.simulatorTrip,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    deleteVehicle,
    setSelectedVehicle,
    checkConnectedVehicle,
  } = actions;
  const {
    fetchVehicleTripFrame,
    createVehicleTrip,
    updateVehicleTrip,
    completeVehicleTrip,
    resetVehicleTrip,
  } = tripActions;

  return {
    actions: bindActionCreators(
      {
        checkConnectedVehicle,
        setSelectedVehicle,
        fetchVehicleTripFrame,
        deleteVehicle,
        createVehicleTrip,
        updateVehicleTrip,
        completeVehicleTrip,
        resetVehicleTrip,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SimulateVehicle);
