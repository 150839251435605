/* istanbul ignore file */

export const formatTripClock = (h, m) => {
  let hours = h;
  let minutes = m;
  if (minutes > 59) {
    hours += 1;
    minutes = 0;
  }
  if (hours > 23) hours = 0;
  return { hours, minutes };
};

export const incrementTripClock = (clock, setTripClock, frame) => {
  if (frame === 0) {
    setTripClock({ hours: 0, minutes: 0 });
  } else {
    const { hours, minutes } = clock;
    const m = Number(minutes) + 1;
    const newClock = formatTripClock(Number(hours), m);
    setTripClock(newClock);
  }
};

export const startTripClock = (frameIndex, setTripClock) => {
  const hoursElapsed = Math.floor(frameIndex / 60);
  const minsElapsed = frameIndex - (hoursElapsed * 60);

  setTripClock(formatTripClock(hoursElapsed, minsElapsed));
};

// 'stageData' structure reference:
// src/localization/Application/Simulator/trips.js
export const incrementTripDistance = (
  stageData,
  currentFrameIndex,
  setTripDistance,
) => {
  let distanceDriven = 0;

  if (currentFrameIndex > 0) {
    const stagesWithMileage = stageData.filter(stage => stage.distance > 0);

    stagesWithMileage.forEach((stage) => {
      const milesPerFrame = stage.distance / stage.duration;
      let totalFrames = 0;

      if (currentFrameIndex > stage.startFrameIndex) {
        if (currentFrameIndex > (stage.startFrameIndex + stage.duration)) {
          totalFrames += stage.duration;
        } else {
          totalFrames += (currentFrameIndex - stage.startFrameIndex);
        }
        distanceDriven += (milesPerFrame * totalFrames);
      }
    });
  }

  setTripDistance(distanceDriven.toFixed(1));
};
