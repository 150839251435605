const staticText = {
  title: 'Team up for speed',
  subtitle: 'Integrate with Smartcar faster by collaborating with others. Additional users are free, so invite as many as you want!',
  subtitle2: 'Invite team members by adding their email address below.',
  buttons: {
    sendInvite: 'Send invite',
    skip: 'Skip',
  },
};

export default staticText;
