import _ from 'lodash';
import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { InputField, Spinner, Toast, VerifyPasswordModal } from '../../../../components';
import staticText from '../../../../localization/Authentication/updateUser';
import authFormState from '../../../Authentication/components/authFormUtils';
import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';
import {
  EmailVerificationButton,
  EmailVerificationContainer,
  EmailVerificationError,
  UpdateUserForm,
} from './styles';

const UserProfile = ({
  actions: {
    resetErrors,
    sendVerificationEmailRequest,
    updateUserRequest,
    verifyPasswordToChangeAccountInfoRequest,
  },
  selectedApp,
  inProgress,
  verifiedPassword,
  isSendingVerificationEmail,
  sendVerificationEmailError,
  verifyPasswordError,
  session: {
    userContext,
  },
  updateUserFormErrors,
  updateUserSuccess,
  verificationEmailSent,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const closeModal = () => setModalOpen(false);

  const {
    setErroredFields,
    erroredFields,
    handleBlur,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState({
    firstName: userContext.firstName,
    lastName: userContext.lastName,
    username: userContext.email,
  });

  useEffect(() => {
    resetErrors();
    reportToSegment(types.PAGE, 'Update User Modal');
  }, []);

  useEffect(() => {
    if (_.isEqual(
      {
        firstName: userContext.firstName,
        lastName: userContext.lastName,
        username: userContext.email,
      },
      values,
    )) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [values, userContext]);

  useEffect(() => {
    if (updateUserSuccess && formSubmitted) {
      Toast('Your update has been saved!', 'success');
      setFormSubmitted(false);
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields(updateUserFormErrors);
  }, [inProgress, updateUserFormErrors]);


  // Once an user verifies their password, we update their email address
  useEffect(() => {
    if (verifiedPassword) {
      closeModal();
      updateUserRequest(values);
    }
    setFormSubmitted(true);
  }, [verifiedPassword]);

  const handleSubmit = (event) => {
    event.preventDefault();

    /*
      When the form is submitted, we check to see what has changed. If they try to change
      their email address, we open up a modal and make them re-enter their password before
      updating the email. However, if they only want to change their name, we don't show the
      verify password modal
    */
    if (
      values.username !== userContext.email
    ) {
      setModalOpen(true);
    } else {
      updateUserRequest(values);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'update user attempt',
        form_content: { values },
      });
      setFormSubmitted(true);
    }
  };

  const getGeneralError = () => {
    if (Array.isArray(erroredFields)) {
      const error = erroredFields.find(err => !err.field || err.field === 'required_fields');
      return error ? error.message : '';
    } else if (erroredFields) {
      return erroredFields;
    }

    return '';
  };

  const handleEmailVerificationSend = (event) => {
    sendVerificationEmailRequest();
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: event.target.innerText,
    });
  };

  const getEmailVerificationContent = () => {
    let content = (
      <React.Fragment>
        {sendVerificationEmailError && (
          <EmailVerificationError
            id="verify-email-error-container"
            color="error"
            variant="caption"
          >
            {sendVerificationEmailError}
          </EmailVerificationError>
        )}
        <div>
          {verificationEmailSent
            ? staticText.emailVerificationSent
            : staticText.emailVerificationRequired
          }
        </div>
        <EmailVerificationButton
          id="verify-email-button"
          variant="text"
          onClick={handleEmailVerificationSend}
        >
          {verificationEmailSent
            ? staticText.resendVerificationButton
            : staticText.sendVerificationButton
          }
        </EmailVerificationButton>
      </React.Fragment>
    );
    if (isSendingVerificationEmail) {
      content = <Spinner size="small" />;
    }

    return (
      <EmailVerificationContainer>
        {content}
      </EmailVerificationContainer>
    );
  };

  return (
    <Box sx={{ maxWidth: '900px', minWidth: '650px' }}>
      <ApplicationHeader
        heading={staticText.title}
        backLink={selectedApp && selectedApp.id ? ({
          path: `/apps/${selectedApp.id}`,
          text: `Back to ${selectedApp.name} application`,
        }) : {}}
      />
      {!userContext.emailVerifiedAt && getEmailVerificationContent()}
      { modalOpen &&
        <VerifyPasswordModal
          verifyPasswordToChangeAccountInfoRequest={verifyPasswordToChangeAccountInfoRequest}
          closeModal={closeModal}
          verifyPasswordError={verifyPasswordError}
          resetErrors={resetErrors}
        />
      }
      <Section>
        <UpdateUserForm onSubmit={handleSubmit}>
          <Typography id="error-container" color="error" variant="caption">{getGeneralError()}</Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
            {staticText.fields.map((field, index) => (
              <Box id={`input-${index}`} key={field.name} width={field.width || '100%'}>
                <InputField
                  inputName={field.name}
                  inputType={field.type}
                  inputValues={values[field.name]}
                  inputPlaceholder={field.placeholder}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  inputDisabled={
                    disableFields || (field.type === 'email' && userContext.loginProvider !== 'EmailPassword')
                  }
                  errorMessage={getErrorMessage(field)}
                />
              </Box>
            ))}
          </Box>
          <Box mt={4} mb={2} display="flex" justifyContent="space-between">
            <Button
              id="btnSubmit"
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={disableFields || disabledButton}
            >
              {staticText.btnSubmit}
            </Button>
          </Box>
        </UpdateUserForm>
      </Section>
    </Box>
  );
};

export default UserProfile;


UserProfile.propTypes = {
  actions: shape({
    updateUserRequest: func.isRequired,
  }).isRequired,
  isSendingVerificationEmail: bool.isRequired,
  inProgress: bool,
  verifiedPassword: bool.isRequired,
  verifyPasswordError: string.isRequired,
  sendVerificationEmailError: string.isRequired,
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
  session: shape({
    userContext: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
      email: string.isRequired,
      emailVerifiedAt: string,
    }).isRequired,
  }).isRequired,
  updateUserFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
  updateUserSuccess: bool.isRequired,
  verificationEmailSent: bool.isRequired,
};

UserProfile.defaultProps = {
  updateUserFormErrors: [],
  inProgress: false,
};

