import { styled } from '@mui/material';

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: '80px',
  width: '80px',
  position: 'relative',

  '& img': {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    position: 'relative',
    objectFit: 'contain',
  },
}));

export const HoverOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  textAlign: 'center',
  opacity: 0,
}));

export const RemoveButton = styled('button', {
  shouldForwardProp: prop => prop !== 'removeText',
})(({ removeText, theme }) => ({
  backgroundColor: 'transparent',
  margin: '0',
  padding: '0',
  border: 'none',
  borderRadius: '50%',
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  height: '20px',
  width: '20px',
  cursor: 'pointer',
  display: 'none',
  zIndex: 2,

  '& img': {
    height: '100%',
    width: '100%',
  },

  '&::after': {
    content: `"${removeText}"`,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '-80%',
    left: '50%',
    padding: '4px 6px',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: theme.typography.body1.fontFamily,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    borderRadius: theme.border.radius,
    display: 'none',
  },

  '&:hover': {
    '& + #replace-text': {
      opacity: '0',
    },
    '&::after': {
      display: 'block',
    },
  },
}));

export const UploadButton = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})(({ disabled, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  cursor: 'pointer',
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: theme.border.radius,

  '&:hover': {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,

    '& svg path': {
      fill: theme.palette.secondary.main,
    },

    '& #replace-text': {
      opacity: '1',
    },

    '& #delete-button': {
      display: 'block',
    },
  },

  // hiding the native html file input element
  '& #image-upload-input': {
    opacity: '0',
    width: '0.1px',
    height: '0.1px',
    position: 'absolute',
  },

  '& ul': {
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(1),

    '& li::marker': {
      color: theme.palette.text.primary,
    },
  },

  ...(disabled && {
    color: theme.palette.text.disabled,
    border: `1px solid ${theme.palette.text.disabled}`,

    '& svg path': {
      fill: theme.palette.text.disabled,
    },

    '&:hover': {
      cursor: 'default',
      color: theme.palette.text.disabled,
      border: `1px solid ${theme.palette.text.disabled}`,
    },

  }),
}));
