import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import WebhooksForm from './WebhooksForm';
import { actions as webhookActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state) => {
  return {
    ...state.webhooks,
    webhook: state.webhooks.webhooks[state.webhooks.currentWebhook],
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    createWebhookRequest, createWebhookInterestRequest, updateWebhookRequest, toggleWebhookModal,
  } = webhookActions;

  return {
    actions: bindActionCreators({
      createWebhookRequest,
      createWebhookInterestRequest,
      updateWebhookRequest,
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksForm);
