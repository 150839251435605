const staticText = {
  cannotEditOwner: 'Original owners cannot be edited',
  deleteTeamInvite: 'Error deleting team invitation',
  deleteTeamMember: 'Error deleting team member',
  emailFormat: 'Please enter email(s) in the proper email format.',
  fetchingOrganizations: 'Error fetching organizations',
  fetchingRoles: 'Error fetching dashboard role options. Please try again later.',
  fetchingTeamInvites: 'Error fetching team invitations',
  fetchingTeamMembers: 'Error fetching team members',
  selectAppAccess: 'Please select the access level for your member.',
  selectRole: 'Please select a role from the options list.',
  sendingTeamInvite: 'Error sending team invites',
  updateTeamInfo: 'Error updating team settings',
  updateTeamMember: 'Error updating team member',
  verifyingTeamInvite: 'Error verifying team invitation',
  noPermissionToInviteOwner: 'Only owners may grant owner access',
  noPermissionToRegisterApplication: 'Only owners and admins can register new applications',
};

export default staticText;
