const activeActions = [
  {
    text: 'View',
    modalName: 'Information',
    icon: '/assets/icons/info.svg',
  },
  {
    text: 'Edit',
    modalName: 'Edit',
    icon: '/assets/icons/edit.svg',
  },
  {
    text: 'Deactivate',
    modalName: 'Deactivate',
    icon: '/assets/icons/deactivate.svg',
  },
  {
    text: 'Delete',
    modalName: 'Delete',
    icon: '/assets/icons/delete.svg',
  },
];

const inactiveActions = [
  {
    text: 'View',
    modalName: 'Information',
    icon: '/assets/icons/info.svg',
  },
  {
    text: 'Edit',
    modalName: 'Edit',
    icon: '/assets/icons/edit.svg',
  },
  {
    text: 'Delete',
    modalName: 'Delete',
    icon: '/assets/icons/delete.svg',
  },
];

export default class WebhookConfiguration {
  constructor(status) {
    this.status = status.toUpperCase();
    this.statusMap = {
      ACTIVE: {
        text: 'Active',
        iconColor: '#23C39D',
        primaryAction: 'Test',
        secondaryActions: activeActions,
      },
      UNVERIFIED: {
        text: 'Unverified',
        iconColor: '#DB2700',
        primaryAction: 'Verify',
        secondaryActions: inactiveActions,
      },
      INACTIVE: {
        text: 'Inactive',
        iconColor: '#828282',
        primaryAction: 'Activate',
        secondaryActions: inactiveActions,
      },
    };
  }

  getStatusText() {
    return this.statusMap[this.status].text;
  }

  getIconColor() {
    return this.statusMap[this.status].iconColor;
  }

  getPrimaryAction() {
    return this.statusMap[this.status].primaryAction;
  }

  getSecondaryActions() {
    return this.statusMap[this.status].secondaryActions;
  }
}
