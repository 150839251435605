import { styled } from '@mui/material';

export const LoginMethodContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

export const LoginMethod = styled('button')(({ theme }) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: theme.typography.fontSizes.sm,
  height: '46px',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',

  '&:hover': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
}));
