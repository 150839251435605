import React from 'react';
import { node, number, string } from 'prop-types';
import Box from '@mui/material/Box';

const TabPanel = (props) => {
  const {
    children, value, index, pageName, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${pageName}-tabpanel-${index}`}
      aria-labelledby={`${pageName}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box mt={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: node.isRequired,
  value: number.isRequired,
  index: number.isRequired,
  pageName: string.isRequired,
};
