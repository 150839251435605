import React from 'react';
import { func, arrayOf, string } from 'prop-types';
import { Divider } from '@mui/material';

import { EngineOptions, Form } from './styles';
import { Checkbox } from '../../../../../../../../../../components';

import { engineTypeOptions } from '../EngineFilter/utils';

const EngineFilterForm = ({
  selectedBrandManagementFilters,
  updateCustomizations,
}) => {
  const onChange = (event) => {
    const { name, checked } = event.target;

    if (name === 'all') {
      if (checked) {
        updateCustomizations({ brandManagementFilters: engineTypeOptions.map(({ type }) => type) });
      } else {
        updateCustomizations({ brandManagementFilters: [] });
      }
      return;
    }

    const clonedSelectedBrandManagementFilters = [...selectedBrandManagementFilters];

    if (checked) {
      if (!clonedSelectedBrandManagementFilters.includes(name)) {
        clonedSelectedBrandManagementFilters.push(name);
      }
    } else {
      const index = clonedSelectedBrandManagementFilters.indexOf(name);
      if (index !== -1) {
        clonedSelectedBrandManagementFilters.splice(index, 1);
      }
    }

    updateCustomizations({ brandManagementFilters: clonedSelectedBrandManagementFilters });
  };

  const renderEngineTypeLabel = (type) => {
    const { label, icon } = engineTypeOptions.find(option => option.type === type);
    return (
      <React.Fragment>
        {icon}{label}
      </React.Fragment>
    );
  };

  return (
    <Form>
      <Checkbox
        id="all-engine-types"
        inputName="all"
        text="All types"
        checked={selectedBrandManagementFilters.length === 4}
        handleChange={onChange}
        wrapperClassName="m-l-xs"
      />
      <Divider sx={{ mt: 2 }} />
      <EngineOptions>
        {engineTypeOptions.map(({ type }) => {
          const isChecked =
            selectedBrandManagementFilters.includes(type);
          return (
            <Checkbox
              key={type}
              id={type}
              inputName={type}
              labelText={renderEngineTypeLabel(type)}
              checked={isChecked}
              handleChange={onChange}
              wrapperClassName="flex-vertical-center compact"
            />
          );
        })}
      </EngineOptions>
    </Form>
  );
};

export default EngineFilterForm;


EngineFilterForm.propTypes = {
  selectedBrandManagementFilters: arrayOf(string).isRequired,
  updateCustomizations: func.isRequired,
};
