import { Box, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const TripWrapper = styled(Box, {
  shouldForwardProp: prop => (prop !== 'active'),
})(({ theme, active }) => ({
  width: '33%',
  padding: theme.spacing(3),
  boxSizing: 'border-box',
  cursor: 'pointer',

  ...(active && {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.border.radius,
  }),
}));
export const RadioButtonLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => (prop !== 'active'),
})(({ active, theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(2),

  '& > span:first-of-type': {
    paddingLeft: 0,
  },

  ...(active && {
    '& > span:last-of-type': {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.bold.fontFamily,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(1),
  width: '60px',
}));

export const StagesList = styled('ul')(({ theme }) => ({
  margin: '0',
  paddingLeft: '0',

  li: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),

    div: {
      height: '18px',
      width: '18px',
      marginRight: theme.spacing(1),

      img: {
        height: '100%',
        width: '100%',
      },
    },
  },
}));
