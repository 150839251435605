const simulatorHomeStaticText = {
  heading: 'Vehicle Simulator',
  newVehicle: 'Add vehicle',
  vehiclesList: {
    heading: 'Vehicles',
    tableHeaders: {
      vehicle: 'Vehicle',
      tripType: 'Trip type',
      tripState: 'Trip state',
    },
    tripNotStarted: 'Not selected',
    tripInactive: 'Not started',
    addVehicle: '+ Add vehicle',
    createFirstVehicle: 'Create a vehicle',
  },
  emptyState: {
    heading: 'Test your app with simulated data',
    details: [
      {
        key: 'create-vehicle',
        text: 'Create/connect a vehicle with simulated data',
      },
      {
        key: 'different-scenarios',
        text: 'Use different scenarios to control what data you get back',
      },
      {
        key: 'monitor-and-test',
        text: 'Monitor and test vehicles as you see live updates',
      },
      {
        key: 'debug',
        text: 'Debug with ease',
      },
      {
        key: 'develop-faster',
        text: 'Develop faster',
      },
    ],
  },
  featureGated: {
    description: 'Upgrade to test with simulated vehicles & more',
    contactOwnersToUpgrade: 'Contact a team owner to upgrade to test with simulated vehicles & more',
    contactOwnersToRegainAccess: 'Contact a team owner to upgrade to regain access to your simulated vehicles',
    buttonText: 'Upgrade to use this vehicle',
    regainAccessDescription: '|+|Upgrade your plan@@/team/billing|+| to regain access to your simulated vehicles',
    unlockMoreFeatures: '|+|Upgrade@@/team/billing|+| to enable more features and create additional simulated vehicles',
    contactUnlockMoreFeatures: 'Contact a team owner to upgrade to enable more features and create additional simulated vehicles',
  },
  resourceCards: [
    {
      title: 'Simulator Documentation',
      text: 'Learn about when and how to use the Vehicle Simulator feature while testing your application.',
      linkText: 'Read the docs',
      link: 'https://smartcar.com/docs/dashboard/simulator',
    },
    {
      title: 'API Reference',
      text: "Everything you need to get started using Smartcar's APIs, from endpoints and SDKs to error handling guides.",
      linkText: 'Check it out',
      link: 'https://smartcar.com/docs/api/',
    },
  ],
  tutorialLaunchButton: 'Launch tutorial',
};

export default simulatorHomeStaticText;
