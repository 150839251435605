import { Box, Typography, styled } from '@mui/material';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';

import { defaultEndpoints } from '../../localization/Components/endpoints';

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'numCheckboxes' && prop !== 'columns',
})(({ theme, columns, numCheckboxes }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridColumnGap: theme.spacing(6),
  gridRowGap: theme.spacing(3),
  gridTemplateRows: `repeat(${Math.ceil(numCheckboxes / columns)}, 1fr)`,
}));

const Endpoints = ({ endpoints }) => {
  return (
    <Container numCheckboxes={endpoints.length} columns={2}>
      {endpoints.map(({ label, iconSrc }) => {
        return (
          <Box key={label} display="flex" sx={{ opacity: 0.6 }} alignItems="center">
            <img src={iconSrc} alt={label} />
            <Typography marginLeft={2} >{label}</Typography>
          </Box>
        );
      })}
    </Container>
  );
};

export default Endpoints;

Endpoints.propTypes = {
  endpoints: arrayOf(shape({
    path: string.isRequired,
    method: string,
  })),
};

Endpoints.defaultProps = {
  endpoints: defaultEndpoints,
};
