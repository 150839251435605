import PropTypes from 'prop-types';
import React from 'react';
import AuthenticatedRoute from '../AuthenticatedRoute';

const propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    mfaSetRecoveryToken: PropTypes.func.isRequired,
  }).isRequired,

};

const PublicRoute = (props) => {
  React.useEffect(() => {
    if (props.location.pathname === '/mfa-enroll') {
      if (props.location.search) {
        const mfaRecoveryToken = new URLSearchParams(props.location.search).get('recover_id');
        if (mfaRecoveryToken) {
          props.actions.mfaSetRecoveryToken(mfaRecoveryToken);
        }
      }
    }
  }, []);
  return <AuthenticatedRoute {...props} isPrivate={false} />;
};

PublicRoute.propTypes = propTypes;

export default PublicRoute;
