import React from 'react';
import { eventNames, reportToSegment } from '@smartcar/morse';

import { types } from '../../services/applications/reducers';

import { HeaderContainer, HeaderContent } from './styles';

const navLogo = (
  <a
    id="homeLink"
    href="https://smartcar.com/"
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => reportToSegment(types.TRACK, eventNames.linkClicked, {
        section: 'topbar', style: 'image', path: 'https://smartcar.com/', text: 'smartcar logo',
      })}
  >
    <img src="/assets/images/smartcar-logo-black.svg" alt="smartcar logo" />
  </a>
);


const Header = () => {
  return (
    <HeaderContainer>
      <HeaderContent>{navLogo}</HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
