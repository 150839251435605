import React from 'react';

import { BevEngineIcon, HevEngineIcon, IceEngineIcon, PhevEngineIcon } from '../../../../../../../../../../assets/icons/engineType';

const engineTypeOptions = [
  {
    type: 'ICE',
    label: 'Internal combustion engine (ICE)',
    icon: <IceEngineIcon />,
  },
  {
    type: 'PHEV',
    label: 'Plug-in hybrid vehicle (PHEV)',
    icon: <PhevEngineIcon />,
  },
  {
    type: 'BEV',
    label: 'Battery electric vehicle (BEV)',
    icon: <BevEngineIcon />,
  },
  {
    type: 'HEV',
    label: 'Hybrid electric vehicle (HEV)',
    icon: <HevEngineIcon />,
  },
];

const getEngineTypeFilterStatusAndTitle = (selectedOptions = []) => {
  // if no options are selected
  if (selectedOptions.length === 0) {
    return ({
      isFilterActive: true,
      menuItemWrapperTitle: 'Select an engine type',
    });
  }
  // if all options are selected, fallback to "All types"
  if (selectedOptions.length === engineTypeOptions.length) {
    return ({
      isFilterActive: true,
      menuItemWrapperTitle: 'All types',
    });
  }

  let selectedEngineTypes = engineTypeOptions.filter(({ type }) => selectedOptions.includes(type));

  selectedEngineTypes = selectedEngineTypes.map(({ icon, type }, index) => (
    <React.Fragment key={type}>
      {icon} {type}
      {index < selectedEngineTypes.length - 1 && ', '}
    </React.Fragment>
  ));

  return {
    isFilterActive: true,
    menuItemWrapperTitle: selectedEngineTypes,
  };
};

export {
  engineTypeOptions,
  getEngineTypeFilterStatusAndTitle,
};
