/* istanbul ignore file */
import React from 'react';

const BevEngineIcon = () => {
  return (
    <svg
      className="engine-type"
      width="15"
      height="19"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.85374 0.933748L0.788737 9.05375C0.228737 9.56125 0.543737 10.4975 1.29624 10.5675L8.37499 11.25L4.13124 17.165C3.93874 17.4362 3.96499 17.8125 4.20124 18.0487C4.46374 18.3112 4.87499 18.32 5.14624 18.0662L14.2112 9.94625C14.7712 9.43875 14.4562 8.5025 13.7037 8.4325L6.62499 7.75L10.8687 1.835C11.0612 1.56375 11.035 1.1875 10.7987 0.951248C10.5362 0.688748 10.125 0.679998 9.85374 0.933748Z" fill="#6D6D6D" />
    </svg>
  );
};

export default BevEngineIcon;
