import { Box, Button, TextField, Typography, styled } from '@mui/material';
import { bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { eventNames, reportToSegment, types } from '@smartcar/morse';
import staticText from '../../../../../../localization/Authentication/userSecurity';

const Input = styled(TextField)(() => ({
  '& .MuiInputLabel-asterisk': {
    display: 'none',
  },
}));

const MfaChallengeInput = ({
  verifyCallback,
  mfaInProgress,
  mfaObj,
  mfaError,
  mfaSaveFactorId,
}) => {
  const [challengeSubmitted, setChallengeSubmitted] = useState(false);
  const [mfaCode, setMfaCode] = useState('');

  const handleChange = (e) => {
    setChallengeSubmitted(false);
    setMfaCode(e.target.value.replace(/\D/g, ''));
  };

  const verifyChallengeCode = (e) => {
    e.preventDefault();
    const verifyObj = {
      challengeId: mfaObj.challengeId,
      code: mfaCode,
      factorId: mfaObj.factorId,
      mfaSaveFactorId,
    };

    setChallengeSubmitted(true);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Verifying MFA passcode' });

    verifyCallback(verifyObj);
  };

  return (
    <form onSubmit={verifyChallengeCode}>
      <Box
        sx={{ mt: 4 }}
      >
        <Input
          id="code"
          name="code"
          type="text"
          label={staticText.mfaSetup.enterCode}
          inputMode="numeric"
          disabled={mfaInProgress}
          value={mfaCode}
          onChange={handleChange}
          autoComplete="one-time-code"
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          required
        />
        {!mfaInProgress && challengeSubmitted && mfaError && <Typography variant="caption" color="error">{mfaError}</Typography>}
        <Box>
          <Button
            id="confirmChallengeMFA"
            variant="contained"
            type="submit"
            size="large"
            sx={{ mt: 2 }}
            disabled={mfaInProgress}
          >
            {staticText.mfaSetup.verify}
          </Button>

        </Box>
      </Box>
    </form>
  );
};

export default MfaChallengeInput;

MfaChallengeInput.propTypes = {
  verifyCallback: func.isRequired,
  mfaObj: shape({
    challengeId: string,
    factorId: string,
    qrCode: string,
    secretKey: string,
  }),
  mfaInProgress: bool.isRequired,
  mfaError: string,
  mfaSaveFactorId: bool,
};

MfaChallengeInput.defaultProps = {
  mfaError: '',
  mfaObj: {
    challengeId: null,
    factorId: null,
    qrCode: null,
    secretKey: null,
  },
  mfaSaveFactorId: false,
};

