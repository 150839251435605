import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toggleModalDecorator } from './utils/webhookModalHelpers';
import Webhooks from './Webhooks';
import { actions as webhooksActions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);

  return {
    featureSetId: currentOrganization.featureSetId,
    rolePermissions: currentOrganization.rolePermissions,
    ...state.webhooks,
  };
};

function mapDispatchToProps(dispatch) {
  const {
    fetchWebhooksRequest,
    toggleWebhookModal,
    selectWebhook,
  } = webhooksActions;

  return {
    actions: bindActionCreators({
      fetchWebhooksRequest,
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
      selectWebhook,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
