import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WebhooksStatusRow from './WebhooksStatusRow';
import { actions as webhooksActions } from '../../../../reducers';
import { toggleModalDecorator } from '../../../../utils/webhookModalHelpers';

function mapDispatchToProps(dispatch) {
  const { selectWebhook, toggleWebhookModal } = webhooksActions;

  return {
    actions: bindActionCreators({
      selectWebhook,
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(WebhooksStatusRow);
