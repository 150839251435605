/* istanbul ignore file */
export default function googleAnalyticsClientId() {
  let gaClientId;
  if (document.cookie) {
    const gaCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_ga'));
    if (gaCookie) {
      // trim "_ga=GA1.2.1637580771.1598980847" to "1637580771.1598980847"
      const gaCookieParts = gaCookie.split('.');
      gaCookieParts.shift();
      gaCookieParts.shift();
      gaClientId = gaCookieParts.join('.');
    }
  }

  return gaClientId;
}
