import React, { useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { TextField, Typography } from '@mui/material';

import { ImageUpload } from '../../../../../../components/Form/useForm';
import staticText from '../../../../../../localization/Application/connect-config';
import {
  CustomizationGroupDescription,
  CustomizationGroupHeading,
  CustomizationOption,
  CustomizationOptionLabel,
  FeatureGatedBanner,
} from '../../styles';
import { ThemeOption } from './styles';
import { ContrastIcon, DarkModeIcon, LightModeIcon } from '../../../../../../assets/icons';
import { InlineLinks, PermissionGate } from '../../../../../../components';

const iconMap = {
  light: <LightModeIcon />,
  dark: <DarkModeIcon />,
  system: <ContrastIcon />,
};

const Appearance = ({
  actions: {
    updateAppLogoRequest,
    clearErrors,
  },
  customizations: {
    appName,
    theme,
    logoUrl,
  },
  uploadedLogoUrl,
  updateCustomizations,
  fetchingConnectConfigErrors,
  isUpdatingAppLogo,
  themeIsGated,
  rolePermissions,
}) => {
  const handleChange = (key, value) => {
    updateCustomizations({ [key]: value });
  };

  const removeLogo = () => {
    updateCustomizations({ logoUrl: '' });
  };

  // The logo is submitted to the backend as soon as the image is uploaded.
  // But instead of updating the customizations data immediately when the input changes,
  // we need to wait until we've received the logo's URL back from server,
  // then we can update `customizations.logoUrl` manually.
  useEffect(() => {
    if (uploadedLogoUrl) {
      updateCustomizations({ logoUrl: uploadedLogoUrl });
    }
  }, [uploadedLogoUrl]);

  const { appNameInput, themeInput, logoInput } = staticText.appearance.inputs;

  const canWriteBilling = rolePermissions.includes('write_billing');

  const renderThemeOption = ({ label, value }) => {
    // default to 'system' being selected when theme input is feature gated
    let isSelected = value === theme;
    if (themeIsGated) {
      isSelected = value === 'system';
    }
    return (
      <PermissionGate dashboardPermission="write_connect_configuration" disabled>
        <ThemeOption
          variant="outlined"
          onClick={() => handleChange('theme', value)}
          selected={isSelected}
          className={isSelected ? 'selected' : ''}
          disabled={themeIsGated}
        >
          {iconMap[value]}
          {label}
        </ThemeOption>
      </PermissionGate>
    );
  };

  return (
    <div>
      <CustomizationGroupHeading variant="h2">
        {staticText.appearance.heading}
      </CustomizationGroupHeading>
      <CustomizationGroupDescription>
        {staticText.appearance.description}
      </CustomizationGroupDescription>
      <CustomizationOption>
        <CustomizationOptionLabel id="logo-label">{logoInput.displayName}</CustomizationOptionLabel>
        <Typography marginBottom={1}>{logoInput.description}</Typography>
        <PermissionGate dashboardPermission="write_connect_configuration" disabled>
          <ImageUpload
            previousImageUrl={logoUrl}
            specs={logoInput.requirements}
            acceptedTypes={logoInput.acceptedTypes}
            onUpload={updateAppLogoRequest}
            onDelete={removeLogo}
            uploadText={logoInput.uploadLogo}
            replaceText={logoInput.replaceText}
            removeText={logoInput.removeText}
            imageAltText={logoInput.imageAltText}
            updating={isUpdatingAppLogo}
            uploadError={fetchingConnectConfigErrors.updatingAppLogo}
            clearUploadError={clearErrors}
            disabled={false}
          />
        </PermissionGate>
      </CustomizationOption>
      <CustomizationOption>
        <CustomizationOptionLabel id="app-name-label">{appNameInput.displayName}</CustomizationOptionLabel>
        <PermissionGate dashboardPermission="write_connect_configuration" disabled>
          <TextField
            aria-labelledby="app-name-label"
            id="appName"
            name="appName"
            data-testid="app-name"
            defaultValue={appName}
            onChange={e => handleChange('appName', e.target.value)}
          />
        </PermissionGate>
      </CustomizationOption>
      <CustomizationOption>
        <CustomizationOptionLabel id="theme-label">{themeInput.displayName}</CustomizationOptionLabel>
        {renderThemeOption(themeInput.options[0])}
        {themeIsGated && (
          <FeatureGatedBanner>
            <img src="/assets/icons/lock.svg" alt="lock icon" />
            <Typography component="p">
              <InlineLinks
                text={canWriteBilling ?
                  staticText.appearance.featureGated.upgrade :
                  staticText.appearance.featureGated.contactOwnersToUpgrade}
              />
            </Typography>
          </FeatureGatedBanner>
        )}
        {renderThemeOption(themeInput.options[1])}
        {renderThemeOption(themeInput.options[2])}
      </CustomizationOption>
    </div>
  );
};

export default Appearance;

Appearance.propTypes = {
  actions: shape({
    updateAppLogoRequest: func,
    clearErrors: func,
  }).isRequired,
  customizations: shape({
    appName: string.isRequired,
    theme: string.isRequired,
    logoUrl: string,
  }).isRequired,
  uploadedLogoUrl: string,
  updateCustomizations: func.isRequired,
  isUpdatingAppLogo: bool.isRequired,
  fetchingConnectConfigErrors: shape({
    updatingAppLogo: string,
  }).isRequired,
  themeIsGated: bool,
  rolePermissions: arrayOf(string).isRequired,
};

Appearance.defaultProps = {
  uploadedLogoUrl: null,
  themeIsGated: false,
};
