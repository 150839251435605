import React, { useState, useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, Typography, FormControl, RadioGroup, Radio } from '@mui/material';

import { TripWrapper, RadioButtonLabel, Label, StagesList } from './styles';
import { Wrapper } from '../shared/styles';
import tripOptions from '../SimulateVehicle/utils/tripOptions';
import staticText from '../../../../../../localization/Application/Simulator/trips';
import { chargeIcon, fuelIcon, locationIcon, vehicleInfoIcon } from '../../../../../../assets/icons';
import { SimulatorHeader } from '../shared';

const SelectTrip = ({
  applicationId,
  selectedVehicle,
  selectedRegion,
  actions: {
    setSelectedVehicle,
  },
  setIsFetching,
  history,
}) => {
  const trips = staticText.trips[selectedRegion];
  const tripKeys = Object.keys(staticText.trips[selectedRegion]);
  const [selectedTrip, setSelectedTrip] = useState('day_commute');

  /* istanbul ignore next */
  const handleTripChange = (event) => {
    setSelectedTrip(event.target.value);
  };

  const handleTripSelect = () => {
    setSelectedVehicle({
      ...selectedVehicle,
      simulatedVehicleTripStatus: '',
      simulatedVehicleTripType: selectedTrip,
      simulatedVehicleTripId: '',
    });
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      { label: 'backend action', text: `[simulator] select trip ${selectedTrip}` },
    );
    history.push(`/apps/${applicationId}/simulator/${selectedVehicle.vin}`);
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Simulator - Select Trip');
  }, []);

  const { make, model, year } = selectedVehicle;
  // ICE and HEVs will use the same descriptions here for now
  const vehicleType = selectedVehicle.vehicleType === 'BEV' ? 'BEV' : 'ICE';

  return (
    <React.Fragment>
      <SimulatorHeader
        heading={staticText.heading}
        applicationId={applicationId}
        setSelectedVehicle={setSelectedVehicle}
        setIsFetching={setIsFetching}
      />
      <Wrapper>
        <Box marginBottom={3} width="700px">
          <Typography component="span" marginRight="4px">{staticText.description}</Typography>
          <Typography component="span" variant="bold">{`${year} ${make} ${model}.`}</Typography>
          <Typography component="span" lineHeight={1.6}>{staticText.description2}</Typography>
        </Box>
        <Box display="flex">
          <FormControl sx={{ display: 'flex' }}>
            <RadioGroup
              aria-labelledby="trip-radio-buttons-group-label"
              name="trip-radio-buttons-group"
              value={selectedTrip}
              onChange={handleTripChange}
              sx={{ flexDirection: 'row' }}
            >
              {tripKeys.map((key) => {
              const tripName = trips[key].name;
              const trip = trips[key];

              if (key !== 'short_trip') {
                return (
                  <TripWrapper
                    active={selectedTrip === key}
                    onClick={() => handleTripChange({ target: { value: key } })}
                  >
                    <RadioButtonLabel
                      value={key}
                      control={<Radio />}
                      label={tripName}
                      key={key}
                      active={selectedTrip === key}
                    />
                    <Box>
                      <Box height="70px">
                        <Typography variant="caption" color="text.primary">
                          {trip.description[vehicleType]}
                        </Typography>
                      </Box>
                      <Box display="flex" marginTop={2}>
                        <Label variant="caption">Duration</Label>
                        <Typography variant="caption">{trip.duration}</Typography>
                      </Box>
                      <Box display="flex" marginTop={2}>
                        <Label variant="caption">Distance</Label>
                        <Typography variant="caption">{trip.distance}</Typography>
                      </Box>
                      {trip.stages && (
                        <Box display="flex" marginTop={2}>
                          <Label variant="caption">Stages</Label>
                          <StagesList>
                            {trip.stages.map((stage, index) => {
                            let icon = index % 2 === 0 ? locationIcon : vehicleInfoIcon;
                            if (stage.fuel && vehicleType !== 'BEV') icon = fuelIcon;
                            if (stage.charge && vehicleType === 'BEV') icon = chargeIcon;
                            if (stage.description) {
                              return (
                                <li key={stage.startFrameIndex}>
                                  <div><img src={icon} alt="" /></div>
                                  <Typography variant="caption">{stage.description[vehicleType]}</Typography>
                                </li>
                              );
                            }
                            return null;
                          })}
                          </StagesList>
                        </Box>
                      )}
                    </Box>
                  </TripWrapper>
                );
              }
              return null;
            })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box marginTop={4} marginBottom={2}>
          <Button
            variant="contained"
            size="large"
            onClick={handleTripSelect}
            id="simulator-select-trip-button"
          >
            {`Select ${tripOptions.getTripTypeName(selectedTrip)}`}
          </Button>
        </Box>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(SelectTrip);

SelectTrip.propTypes = {
  applicationId: string.isRequired,
  selectedVehicle: shape({
    make: string.isRequired,
  }).isRequired,
  selectedRegion: string.isRequired,
  actions: shape({
    setSelectedVehicle: func.isRequired,
  }).isRequired,
  setIsFetching: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
