import { styled } from '@mui/material/styles';

import loadingShimmerStyles from '../../../global-styles/animations/loadingShimmer';

export const Container = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const LoadingContainer = styled('div')(({ theme }) => ({
  width: '65%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',

  [theme.breakpoints.down('iPadPro')]: {
    width: '80%',
  },
}));

export const LoadingDoughnut = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  height: '200px',
  width: '200px',
  backgroundColor: theme.palette.grey[200],

  '&:after': {
    display: 'inline-block',
    position: 'relative',
    height: '136px',
    width: '136px',
    content: '""',
    borderRadius: '50%',
    top: '32px',
    left: '32px',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Legend = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  marginBottom: theme.spacing(2),
  width: '60%',
  maxWidth: '150px',
  height: '18px',
  borderRadius: '2px',
  animationDelay: '2s',
  ...loadingShimmerStyles,

  '&:last-of-type': {
    marginBottom: '0px',
  },
}));
