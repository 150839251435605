import React, { cloneElement } from 'react';
import { arrayOf, string, node, bool, element } from 'prop-types';

/* istanbul ignore next */
const PermissionGate = ({
  rolePermissions,
  dashboardPermission,
  disabled,
  children,
  alternateComponent,
}) => {
  if (rolePermissions && rolePermissions.includes(dashboardPermission)) {
    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    );
  }

  // This conditional checks if a permission is not allowed
  // and if a button should be disabled based on rolePermissions.
  // Use case and props added specifically for timeline,
  // checkboxes, and radio buttons for viewers*
  if (disabled) {
    return (
      <React.Fragment>
        {cloneElement(children, {
            disabled,
          })
        }
      </React.Fragment>
    );
  }

  if (dashboardPermission && alternateComponent) return alternateComponent;

  return (
    null
  );
};

export default PermissionGate;

PermissionGate.propTypes = {
  rolePermissions: arrayOf(string).isRequired,
  dashboardPermission: string.isRequired,
  children: node.isRequired,
  disabled: bool,
  alternateComponent: element,
};

PermissionGate.defaultProps = {
  disabled: false,
  alternateComponent: null,
};
