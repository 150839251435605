import React from 'react';
import { Box, Tooltip, Typography, styled, useTheme } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

import staticText from '../../localization/Application/VehicleManagement/vehicleDetails';

const IconContainer = styled('div')(({ theme }) => ({
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const InfoSection = ({
  header,
  iconSrc,
  items,
  tooltip,
}) => {
  const theme = useTheme();
  const handleTooltipOpen = text => () => {
    reportToSegment(types.TRACK, eventNames.tooltipOpened, {
      label: 'InfoOutlined icon',
      text,
    });
  };

  return (
    <Box display="flex">
      <IconContainer>
        <img src={iconSrc} alt={`${header}-icon`} />
      </IconContainer>
      <Box width="100%">
        <Box display="flex">
          <Typography variant="bold">{header}</Typography>
          {tooltip && (
            <Tooltip
              title={tooltip}
              arrow
              placement="top"
              sx={{ marginLeft: theme.spacing(0.5) }}
              onOpen={handleTooltipOpen(tooltip)}
            >
              <InfoOutlined
                htmlColor={theme.palette.grey[600]}
                fontSize="inherit"
              />
            </Tooltip>
          )}
        </Box>
        {items.map((item) => {
          return (
            <Box
              key={item.field}
              display="flex"
              justifyContent="space-between"
              marginTop={theme.spacing(1)}
              borderBottom={`1px solid ${theme.palette.grey[100]}`}
            >
              {item.title && <Typography noWrap>{item.title}</Typography>}
              <Typography noWrap>
                {(item.value === undefined || item.value === null) ?
                  staticText.unavailable : item.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default InfoSection;

InfoSection.propTypes = {
  header: string.isRequired,
  iconSrc: string.isRequired,
  tooltip: string,
  items: arrayOf(shape({
    field: string.isRequired,
    title: string.isRequired,
    value: oneOfType([string, number]),
  })).isRequired,
};

InfoSection.defaultProps = {
  tooltip: null,
};
