import { number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Typography, Box, Icon } from '@mui/material';

import { Card, CardContent, PercentBar } from './styles';
import { usersIcon } from '../../../../../../../../assets/icons';
import theme from '../../../../../../../../global-styles/theme';

const StatsCard = ({ event, delay = 100 }) => {
  const [percentage, setPercentage] = useState(0);
  const { percent, displayName, count } = event;
  useEffect(() => {
    setTimeout(() => {
      setPercentage(percent);
    }, delay);
  }, [percent]);

  return (
    <Card>
      <PercentBar percentage={percentage} />
      <CardContent>
        <Typography variant="h3" fontSize="24px">
          {Math.round(percent * 100)}%
        </Typography>
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="body1" color={theme.palette.grey[600]}>
            {displayName}
          </Typography>
        </Box>
        <Typography variant="h3" mt={1} display="flex" alignItems="center" gap={1}>
          <Icon>
            <img src={usersIcon} alt="people" />
          </Icon>
          {Number(count).toLocaleString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatsCard;

StatsCard.propTypes = {
  event: shape({
    count: string.isRequired,
    displayName: string.isRequired,
    percent: number.isRequired,
  }).isRequired,
  delay: number.isRequired,
};
