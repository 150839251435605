const tripOptions = {
  regions: [
    { region: 'United States', regionCode: 'US' },
    { region: 'Canada', regionCode: 'CA' },
    { region: 'Europe', regionCode: 'EU' },
  ],
  tripTypes: [
    { name: 'Day Commute', code: 'day_commute', duration: '24 hours' },
    { name: 'Long Trip', code: 'long_trip', duration: '8 hours' },
    { name: 'Short Trip', code: 'short_trip', duration: '4 hours' },
    { name: 'Parked', code: 'parked', duration: '8 hours' },
  ],
  tripStatus: [
    { name: 'In progress', code: 'playing' },
    { name: 'Paused', code: 'paused' },
    { name: 'Complete', code: 'complete' },
    { name: 'In progress', code: 'auto' },
  ],
  unitSystems: [
    'Imperial',
    'Metric',
  ],
  tripLatencies: [
    { name: 'Realistic', code: 'realistic' },
    { name: 'Immediate', code: 'ideal' },
  ],
  tripSpeeds: [1, 2, 8, 16],
  getTripTypeName: (tripTypeCode) => {
    return tripOptions.tripTypes.filter(trip => trip.code === tripTypeCode)[0].name;
  },
  getTripDuration: (tripTypeCode) => {
    return tripOptions.tripTypes.filter(trip => trip.code === tripTypeCode)[0].duration;
  },
  getTripStatus: (tripStatusCode) => {
    return tripOptions.tripStatus.filter(status => status.code === tripStatusCode)[0].name || '';
  },
};

export default tripOptions;
