// Config for storing multi-select component options (Vehicles and Logs)
const statusCodes = {
  200: true,
  201: true,
  202: true,
  204: true,
  302: true,
  304: true,
  400: true,
  401: true,
  403: true,
  404: true,
  409: true,
  429: true,
  430: true,
  500: true,
  501: true,
  502: true,
};

export default statusCodes;
