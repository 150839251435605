import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WebhookErrorModal from './WebhookErrorModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: ownProps.errorMessage || state.webhooks.webhooksErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { toggleWebhookModal } = webhooksActions;

  return {
    actions: bindActionCreators({
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookErrorModal);
