/* istanbul ignore next */
export const menuItems = [
  // shared by Logs and VehicleDetails
  {
    displayName: 'View log details',
    action: 'viewLogs',
  },
];

export const staticText = {
  responseStatusCode: {
    success: 'OK',
    failure: 'ERROR',
  },
};
