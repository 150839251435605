const staticText = {
  logsHeader: 'Logs',
};

export const errors = {
  requestLogs: 'Error fetching request logs',
  makes: 'Sorry, we encountered an error fetching the list of compatible vehicles for the makes and models filter. You may still use the other filters or try refreshing the page.',
  statusCodes: 'Error fetching status codes',
  exportData: 'Error exporting data',
  vehicleNotFound: 'VEHICLE_DISCONNECTED',
  logDrawerVehicleDetails: 'Unable to retrieve car make, model, and year details at this time.',
  outOfRange: date => `You have selected ${date === 'start' ? 'a start' : 'an end'} date outside the allowed date range.`,
};

export default staticText;
