import React, { useEffect } from 'react';
import { func, shape, string } from 'prop-types';

import { Spinner } from '../../components';

const Join = ({
  actions: {
    verifyOrgInvite,
    setOauthFlowInvitationId,
  }, history, location,
}) => {
  useEffect(() => {
    const invitationId = new URLSearchParams(location.search).get('id');

    if (!invitationId) {
      history.push('/');
    } else {
      // This setOauthFlowInvitationId is need to grab the invitationID
      // so we can accept the invite for an user if they chose to go through
      // the Google Oauth flow. This happens by triggering an extra verifyOrgInvite
      // which takes place in the auth saga `getDeveloper()` function.
      setOauthFlowInvitationId(invitationId);
      verifyOrgInvite(invitationId);
    }
  }, []);

  return <Spinner />;
};

export default Join;

Join.propTypes = {
  actions: shape({
    verifyAppInviteRequest: func.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
};
