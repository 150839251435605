export const types = {
  REGENERATE_SECRET: 'developer-client/Secret/REGENERATE_SECRET',
  REGENERATE_SECRET_SUCCESS: 'developer-client/Secret/REGENERATE_SECRET_SUCCESS',
  REGENERATE_SECRET_FAILURE: 'developer-client/Secret/REGENERATE_SECRET_FAILURE',
  HIDE_SECRET: 'developer-client/Secret/HIDE_SECRET',
};

export const initialState = {
  isRegenerating: false,
  secret: null,
  secretErrorMessage: '',
};

export const actions = {
  regenerateSecret: applicationId => ({
    type: types.REGENERATE_SECRET,
    payload: null,
    meta: applicationId,
  }),
  regenerateSecretSuccess: secret => ({
    type: types.REGENERATE_SECRET_SUCCESS,
    payload: secret,
    meta: null,
  }),
  regenerateSecretFailure: error => ({
    type: types.REGENERATE_SECRET_FAILURE,
    payload: error,
    meta: null,
  }),
  hideSecret: () => ({
    type: types.HIDE_SECRET,
    payload: null,
    meta: null,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.REGENERATE_SECRET: {
      return {
        ...state,
        isRegenerating: true,
        secretErrorMessage: '',
      };
    }
    case types.REGENERATE_SECRET_SUCCESS: {
      return {
        ...state,
        isRegenerating: false,
        secret: action.payload,
      };
    }
    case types.REGENERATE_SECRET_FAILURE: {
      return {
        ...state,
        isRegenerating: false,
        secretErrorMessage: (action.payload && action.payload.message) || 'Error regenerating secret',
      };
    }
    case types.HIDE_SECRET: {
      return {
        ...state,
        secret: null,
      };
    }
    default:
      return state;
  }
}
