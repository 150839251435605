import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

/* istanbul ignore next */
function formatChartOptions(data, options) {
  const chartOptions = cloneDeep(options);

  // keeping this function here bc might need to customize options
  // after iterating on chart designs
  // // allow autofit of y-axis if data isn't all 0s
  // if (max(data.datasets[0].data) > 0) {
  //   set(chartOptions, 'scales.y.suggestedMax', undefined);
  // }

  return chartOptions;
}

/* istanbul ignore next */
const getFilterStatusAndTitle = (filter, key, title) => {
  const keys = Object.keys(filter[key]);
  const numSelected = keys.reduce((acc, text) => {
    let currentTotal = acc;
    if (filter[key][text]) {
      currentTotal += 1;
    }
    return currentTotal;
  }, 0);

  const isFilterActive = keys.length !== numSelected && numSelected !== 0;

  return {
    isFilterActive,
    menuItemWrapperTitle: `${isFilterActive ? `(${numSelected}) ` : ''}${title}`,
  };
};

/* istanbul ignore next */
const getMenuItemWrapperDate = ({ start, interval }) => {
  if (start) {
    const format = interval === 'day' ? 'MMM YYYY' : 'YYYY';
    return moment(start).utc().format(format);
  }

  return 'Date';
};

/* istanbul ignore next */
const clearOrResetFilters = (filter, action, setFilter, buttonText, filterKey) => {
  // filter object looks like { makes: { TESLA: true, BMW: true }, statusCodes: { 200: true } }
  const clonedFilter = Object.keys(filter).reduce((acc, filterType) => {
    // if filterKey is present, skip modifying other filters
    if (filterKey && filterType !== filterKey) {
      acc[filterType] = { ...filter[filterType] };
    } else {
      // go through and change all the values for the particular filter that we are
      // iterating through (filterType = makes or statusCodes, etc.)
      acc[filterType] = Object.keys(filter[filterType]).reduce((newIndFilter, key) => {
        // eslint-disable-next-line no-param-reassign
        newIndFilter[key] = action === 'reset';
        return newIndFilter;
      }, {});
    }
    return acc;
  }, {});

  setFilter(clonedFilter, 'logs');
  reportToSegment(types.TRACK, eventNames.buttonClicked, {
    label: 'reset',
    text: buttonText,
  });
};

export {
  clearOrResetFilters,
  formatChartOptions,
  getFilterStatusAndTitle,
  getMenuItemWrapperDate,
};
