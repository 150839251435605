export const types = {
  SEND_SUPPORT_REQUEST: 'developer-client/nav/SEND_SUPPORT_REQUEST',
  SUPPORT_REQUEST_SUCCESS: 'developer-client/nav/SUPPORT_REQUEST_SUCCESS',
  SUPPORT_REQUEST_FAILURE: 'developer-client/nav/SUPPORT_REQUEST_FAILURE',

  RESET_SUPPORT_FORM: 'developer-client/nav/RESET_SUPPORT_FORM',
};

export const actions = {
  sendSupportRequest: fields => ({
    type: types.SEND_SUPPORT_REQUEST,
    payload: fields,
  }),
  supportRequestSuccess: () => ({
    type: types.SUPPORT_REQUEST_SUCCESS,
  }),
  supportRequestFailure: err => ({
    type: types.SUPPORT_REQUEST_FAILURE,
    payload: err,
  }),

  resetSupportForm: () => ({
    type: types.RESET_SUPPORT_FORM,
  }),
};

export const initialState = {
  sendingSupportRequest: false,
  supportRequestSuccess: false,
  supportRequestError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SEND_SUPPORT_REQUEST:
      return {
        ...state,
        sendingSupportRequest: true,
        supportRequestSuccess: false,
        supportRequestError: false,
      };
    case types.SUPPORT_REQUEST_SUCCESS:
      return {
        ...state,
        sendingSupportRequest: false,
        supportRequestSuccess: true,
        supportRequestError: false,
      };
    case types.SUPPORT_REQUEST_FAILURE:
      return {
        ...state,
        sendingSupportRequest: false,
        supportRequestSuccess: false,
        supportRequestError: true,
      };

    case types.RESET_SUPPORT_FORM:
      return initialState;

    default:
      return state;
  }
}
