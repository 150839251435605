import gatedFeatures from './gatedFeatures';

// Returns gated feature data based on a user's current billing plan and desired feature
export const gatedFeatureData = (feature, featureSetId) => {
  const gatedFeaturesFiltered = featureSetId
    ? gatedFeatures.plans.find(plan => plan.featureSetId === featureSetId)
    : null;
  return gatedFeaturesFiltered
    ? gatedFeaturesFiltered.features[feature]
    : false;
};

export const featureSetName = (featureSetId) => {
  const gatedFeaturesFiltered = featureSetId
    ? gatedFeatures.plans.find(plan => plan.featureSetId === featureSetId)
    : null;

  return gatedFeaturesFiltered
    ? gatedFeaturesFiltered.planName
    : '';
};

// Returns the feature set id of the latest (non-legacy) plan based on plan name
export const getActiveFeatureSetId = (planName) => {
  const features = gatedFeatures.plans.find(plan => plan.planName === planName.toLowerCase()
    && !plan.legacy);
  return features ? features.featureSetId : gatedFeatures.plans[0].featureSetId;
};

export const isLegacy = (featureSetId) => {
  return Boolean(gatedFeatures.plans.find(plan => plan.featureSetId === featureSetId
    && plan.legacy));
};

export const isSelfServe = (featureSetId) => {
  return Boolean(gatedFeatures.plans.find(plan => plan.featureSetId === featureSetId
    && plan.selfServe));
};
