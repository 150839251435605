import React, { useState, useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import _ from 'lodash';

import staticText from '../../../../../../localization/Application/Members/updateOrg';
import errors from '../../../../../../localization/Application/Members/errors';
import { CopyButton, InputField, PermissionGate, Toast } from '../../../../../../components';
import { UpdateOrgForm } from './styles';
import { status } from '../../../../../../services/organizations/reducers';

const UpdateOrg = ({
  actions: {
    updateOrgSettings,
    updateUpdateOrgSettingsStatus,
  },
  organization,
  updateOrgSettingsStatus,
}) => {
  const previousValues = {
    name: organization.name,
  };
  const [values, setValues] = useState(previousValues);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.key]: event.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!_.isEqual(values, previousValues)) {
      const updates = {
        // match shape for server
        companyName: values.name,
      };
      updateOrgSettings(updates);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'update team settings attempt',
        form_content: { updates },
      });
      reportToSegment(types.GROUP, organization.id, {
        name: values.name,
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      // prevents triggering onClick in CopyButton
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const nameInput = staticText.fields.name;

  useEffect(() => {
    if (updateOrgSettingsStatus === status.SUCCESS) {
      Toast(staticText.updateSuccess, 'success');
      updateUpdateOrgSettingsStatus(status.INACTIVE);
    }
    if (updateOrgSettingsStatus === status.FAILURE) {
      Toast(errors.updateTeamInfo, 'warn');
      updateUpdateOrgSettingsStatus(status.INACTIVE);
    }
  }, [updateOrgSettingsStatus]);

  return (
    <Box>
      <UpdateOrgForm onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
          <Box id={nameInput.key} key={nameInput.key} width="100%">
            <InputField
              inputName={nameInput.key}
              inputType={nameInput.type}
              inputValues={values[nameInput.key]}
              inputPlaceholder={nameInput.placeholder}
              handleChange={handleChange}
              inputDisabled={updateOrgSettingsStatus === status.PENDING || !organization.rolePermissions.includes('write_company')}
              required
            />
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h3">{staticText.teamId}</Typography>
          <Box display="flex" alignItems="center" mb={1} mt={2}>
            <Typography variant="copyable">{organization.id}</Typography>
            <CopyButton content={organization.id} />
          </Box>
          <Typography variant="caption">{staticText.supportNote}</Typography>
        </Box>
        <Box mt={4} mb={2} display="flex" justifyContent="flex-end">
          <PermissionGate rolePermissions={organization.rolePermissions} dashboardPermission="write_company" disabled>
            <Button
              id="btnSubmit"
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={updateOrgSettingsStatus === status.PENDING}
            >
              {staticText.btnSubmit}
            </Button>
          </PermissionGate>
        </Box>
      </UpdateOrgForm>
    </Box>
  );
};

export default UpdateOrg;


UpdateOrg.propTypes = {
  actions: shape({
    updateOrgSettings: func.isRequired,
    updateUpdateOrgSettingsStatus: func.isRequired,
  }).isRequired,
  organization: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  updateOrgSettingsStatus: string.isRequired,
};
