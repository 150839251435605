import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { bool, func, number, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { Modal } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/VehicleManagement/disconnectModal';

const InterpolatedText = ({ template, placeholder, placeholderValue }) => {
  return (
    template.split(new RegExp(`(${placeholder})`, 'g')).map((text, i) => {
      if (text === '{{selectedVehicle}}') {
        // eslint-disable-next-line react/no-array-index-key
        return <span key={`text-${i}`}>{placeholderValue}</span>;
      }
      // eslint-disable-next-line react/no-array-index-key
      return <span key={`text-${i}`}>{text}</span>;
    })
  );
};

const DisconnectModal = ({
  closeModal,
  disabled,
  disconnectVehicle,
  vehicle,
}) => {
  const {
    make, model, year, vehicleId,
  } = vehicle;

  const handleDisconnect = () => {
    disconnectVehicle(vehicleId);
    closeModal();
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'backend action',
        text: `[vehicles] ${staticText.disconnectVehicle}`,
      },
    );
  };

  const content = (
    <div>
      <Typography sx={{ mt: 1 }}>
        {disabled ? staticText.noPermissionBody1 : staticText.body1}
      </Typography>
      <Typography sx={{ mb: 3 }}>
        {disabled ?
        staticText.noPermissionBody2
        :
        <InterpolatedText
          template={staticText.body2}
          placeholder="{{selectedVehicle}}"
          placeholderValue={<strong>{`${year} ${make} ${model}`}</strong>}
        />}
      </Typography>
      <Box sx={{ display: 'flex', gap: '24px', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          color="error"
          disabled={disabled}
          size="large"
          onClick={handleDisconnect}
          sx={{ minWidth: '250px' }}
          id="confirm-delete"
        >
          {staticText.disconnectVehicle}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          sx={{ minWidth: '250px' }}
          onClick={closeModal}
          id="cancel-delete"
        >
          {staticText.cancel}
        </Button>
      </Box>
    </div>
  );
  return (
    <Modal
      title={staticText.disconnectVehicle}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default DisconnectModal;

DisconnectModal.propTypes = {
  closeModal: func.isRequired,
  disabled: bool,
  disconnectVehicle: func.isRequired,
  vehicle: shape({
    vehicleId: string.isRequired,
    make: string.isRequired,
    model: string.isRequired,
    year: number.isRequired,
  }).isRequired,
};

DisconnectModal.defaultProps = {
  disabled: false,
};
