import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SupportForm from './SupportForm';
import { actions } from '../../../../reducers';

const mapStateToProps = (state) => {
  return {
    ...state.nav,
    userContext: state.auth.session.userContext,
    applicationId: state.applications.selectedApplication,
    selectedOrganization: state.organizations.selectedOrganization,
  };
};

function mapDispatchToProps(dispatch) {
  const {
    sendSupportRequest,
    resetSupportForm,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        sendSupportRequest,
        resetSupportForm,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportForm);
