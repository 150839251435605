import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Share } from 'react-feather';
import { func, bool, shape, string } from 'prop-types';
import moment from 'moment-timezone';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { styled, useTheme } from '@mui/material/styles';

import { Spinner, Toast, UpsellModal } from '../../../../../../../../../../components';
import { staticText } from '../../../../../../../../../../localization/Application/Logs/filtersBar';
import FEATURES from '../../../../../../../../../../services/featureGate/features';
import { gatedFeatureData } from '../../../../../../../../../../services/featureGate';

const ExportBtn = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  border: `1px solid ${theme.palette.grey[200]}`,
  color: theme.palette.text.primary,
  minWidth: '106.5px',
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

/* istanbul ignore file */
const ExportButton = ({
  actions: {
    fetchExportData,
    setLogExportTriggered,
  },
  logExportTriggered,
  exportData,
  featureSetId,
  isFetchingExportData,
}) => {
  const [modalOpen, setModalOpen] = useState();
  const theme = useTheme();

  const isGated = gatedFeatureData(FEATURES.LOG_EXPORT, featureSetId);

  const toggleModal = () => {
    reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], staticText.upgradeModal);
    setModalOpen(!modalOpen);
  };

  const handleExport = () => {
    if (isGated) {
      toggleModal();
    } else {
      reportToSegment(types.TRACK, eventNames.buttonClicked, {
        label: 'download',
        text: staticText.export,
      });
      fetchExportData();
      Toast(staticText.exportStarted, 'info');
    }
  };

  const downloadFile = () => {
    if (logExportTriggered) {
      const url = window.URL.createObjectURL(new Blob([exportData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${moment().format('YYYY-MM-DD')} requests logs.csv`);
      link.click();
      window.URL.revokeObjectURL(url);
      setLogExportTriggered(false);
    }
  };

  useEffect(() => {
    downloadFile();
  }, [exportData]);

  return (
    <Box marginLeft={2}>
      {
        modalOpen &&
          <UpsellModal
            toggleModal={toggleModal}
            feature={FEATURES.LOG_EXPORT}
          />
      }
      <ExportBtn
        onClick={handleExport}
        variant="outlined"
        id="data-export"
        startIcon={isFetchingExportData
          ? <Spinner size="xs" spinnerColor={theme.palette.grey[200]} additionalClassNames="export-button" />
          : <Share color={theme.palette.primary.main} size={20} strokeWidth={2} />}
        disabled={isGated ? null : isFetchingExportData}
      >
        {staticText.export}
      </ExportBtn>
    </Box>
  );
};


export default ExportButton;

ExportButton.propTypes = {
  actions: shape({
    fetchExportData: func.isRequired,
    setLogExportTriggered: func.isRequired,
  }).isRequired,
  featureSetId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  logExportTriggered: bool.isRequired,
  exportData: string.isRequired,
  isFetchingExportData: bool.isRequired,
};
