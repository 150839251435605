import { ChevronDown } from 'react-feather';

export const filtersConfig = [
  {
    filterKey: 'makes',
    icon: ChevronDown,
    formTitle: 'Makes',
    id: 'logs-filter-makes',
    columns: 6,
  },
  {
    filterKey: 'statusCodes',
    icon: ChevronDown,
    formTitle: 'Status codes',
    id: 'logs-filter-status-codes',
    minWidth: '181px',
  },
];

export const staticText = {
  done: 'Done',
  resetAll: 'Reset all',
  conDate: 'Date range',
  export: 'Export',
  exportStarted: 'Your export has been started.',
  makesClearAll: 'Clear all',
  apply: 'Apply',
  datePlaceholder: 'Date Range',
  selectAllCheckboxes: 'Select all',
  clearAllCheckboxes: 'Clear all',
  logDaysAllowance: logDays => `Your current plan provides access to the most recent ${logDays} day${logDays > 1 ? 's' : ''} of log data.`,
  exceededSmartcarStartLimit: 'To see logs prior to Nov 29, 2023, please |+|contact support@@mailto:support@smartcar.com|+|.',

  upgradeModal: {
    label: 'provide link to billing',
    title: 'Upgrade your plan',
  },
};
