const staticText = {
  title: 'Team settings',
  teamId: 'Team ID',
  supportNote: 'Please use this Team ID when contacting support.',
  btnSubmit: 'Save',
  updateSuccess: 'Your update has been saved!',
  fields: {
    name: {
      key: 'name',
      placeholder: 'Team name',
      type: 'text',
    },
  },
};

export default staticText;

