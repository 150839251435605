import { connect } from 'react-redux';

import UpsellModal from './UpsellModal';
import { selectors as orgSelectors } from '../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    featureSetId: orgSelectors.getCurrentOrganization(state).featureSetId,
  };
}

export default connect(mapStateToProps, null)(UpsellModal);

