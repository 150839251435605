import React from 'react';
import { func, shape, string, bool } from 'prop-types';

import { Modal } from '../../../../../../components';
import Content from './components/Content';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'verify';

const VerifyWebhookModal = ({
  actions: { verifyWebhookRequest, toggleWebhookModal },
  webhook,
  isVerifyingWebhook,
}) => {
  const toggleModal = () =>
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent: true });

  const content = (
    <div>
      <Content
        toggleModal={toggleModal}
        verifyWebhookRequest={verifyWebhookRequest}
        isVerifyingWebhook={isVerifyingWebhook}
        webhook={webhook}
        challengeToVerify="nsQsLU7wY9WKt2IU"
      />
    </div>
  );

  return (
    <div>
      <Modal title={modalConfig[modalName].title} content={content} toggleModal={toggleModal} />
    </div>
  );
};

export default VerifyWebhookModal;

VerifyWebhookModal.propTypes = {
  actions: shape({
    verifyWebhookRequest: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
  webhook: shape({
    id: string.isRequired,
    status: string.isRequired,
  }).isRequired,
  isVerifyingWebhook: bool.isRequired,
};
