import React from 'react';
import { eventNames, reportToSegment } from '@smartcar/morse';

import { Link } from '@mui/material';
import { types } from '../../services/applications/reducers';

import {
  FooterContainer,
  FooterContent,
  FooterLink,
} from './styles';

const footerLinks = [
  {
    id: 'privacyPolicy',
    text: 'Privacy policy',
    url: 'https://smartcar.com/privacy/',
  },
  {
    id: 'support',
    text: 'Contact support',
    url: 'mailto:support@smartcar.com',
  },
];

const processClick = (link) => {
  reportToSegment(types.TRACK, eventNames.linkClicked, {
    text: link.text,
    path: link.url,
    style: 'inline',
    section: 'article',
  });
};

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLink variant="subtitle2">
          &copy; Smartcar, Inc. {new Date().getFullYear()}
          {footerLinks.map(link => (
            <Link
              key={link.id}
              id={link.id}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={processClick(link)}
            >
              {link.text}
            </Link>
          ))}
        </FooterLink>

      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
