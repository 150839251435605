// Replicates vinny utility from @smartcar/common
// https://github.com/smartcar/common/blob/60f4117f7c1fe1583436dba5af8192f9f9f690c2/modules/vinny/index.js

const vinny = {
  /**
   * A regular expression that matches strings that meet the requirements for being a VIN:
   *
   * - 17 characters
   * - only contains alphanumeric characters (other than I, O and Q)
   *
   * @see [ISO-3779 - VIN Section 4.5]{@link https://drive.google.com/file/d/1de1MtEapJFvLPdNLQpbbGCRZk2a9AYud/view}
   * @see [ISO-3779 - WMI Section 4.1]{@link https://drive.google.com/file/d/1bl5lOfiLXXISE4F0_i_V3nEssi7Umdd1/view}
   */
  VIN_REGEX: /^[A-HJ-NPR-Z0-9]{17}$/iu,
};

/**
 * Checks if the input matches the [VIN_REGEX]{@link module:vinny.VIN_REGEX}
 *
 * @throws {TypeError} if the input is not a string
 *
 * @param {string} vin
 * @returns {boolean}
 */
// eslint-disable-next-line func-names
vinny.isValid = function (vin) {
  const type = typeof vin;
  if (type !== 'string') {
    // eslint-disable-next-line no-console
    console.log(`vinny.isValid - vin must be a string, got "${type}"`);
    return false;
  }
  return vinny.VIN_REGEX.test(vin);
};

export default vinny;
