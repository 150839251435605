import React from 'react';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';

import { Card } from '../../../../../../components';
import { Border, Container } from './styles';

const CardSet = ({ cards }) => {
  return (
    <Container>
      {cards.reduce((acc, card, i) => {
        acc.push(<Card key={card.header} {...card} />);
        if (i !== cards.length - 1) {
          acc.push(
            <div
              key={`billing-card-spacer-${card.header}`}
            >
              <Border />
            </div>,
          );
        }
        return acc;
      }, [])}
    </Container>
  );
};

export default CardSet;

CardSet.propTypes = {
  cards: arrayOf(
    shape({
      header: string.isRequired,
      title: oneOfType([number, string]).isRequired,
      subtitle: string,
    }).isRequired,
  ).isRequired,
};
