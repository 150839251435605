const staticText = {
  heading: 'Applications',
  add: 'Create new application',
  table: {
    headers: {
      name: 'Application',
      id: 'ID',
      createdAt: 'Created at',
    },
  },
};

export default staticText;
