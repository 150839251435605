import React from 'react';
import { arrayOf, bool, func, string, shape } from 'prop-types';

import { Select } from '../../../../../../../components';
import './Dropdown.scss';

const { Option } = Select;

const Dropdown = ({
  inputName, inputValue, handleChange, children, disabled,
}) => {
  return (
    <div className="webhooks-dropdown">
      <Select
        placeholder={children[0].text}
        name={inputName}
        defaultValue={inputValue}
        onChange={handleChange}
        disabled={disabled}
      >
        {children.slice(1).map(el => (
          <Option key={el.name} {...el} />
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  inputName: string.isRequired,
  handleChange: func.isRequired,
  children: arrayOf(
    shape({
      text: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
  inputValue: string,
  disabled: bool.isRequired,
};

Dropdown.defaultProps = {
  inputValue: '',
};
