import { Button as MuiButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BillingDetails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const AddressDetailTitle = styled(Typography)(() => ({
  fontFamily: '"ibm-plex-mono-medium", "Lucida Console", Monaco, monospace',
  fontSize: '0.875rem',
  color: '#6D6D6D',
  textTransform: 'uppercase',
}));

export const Button = styled(MuiButton)(({
  alignSelf: 'flex-end',
}));

export const Detail = styled(Typography)(() => ({
  variant: 'copyable',
  fontSize: '0.875rem',
  padding: '8px 8px',
}));

