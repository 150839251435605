import { styled } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

export const StyledSummaryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.grey['100'],
  padding: '8px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey['200']}`,
  margin: '10px 0',
  alignItems: 'center',
}));

export const Image = styled('img')(({ theme }) => ({
  height: '24px',
  width: '24px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '50%',
  padding: '8px',
  marginRight: '8px',
  overflow: 'inherit',

  '&.keyboardArrowRight': {
    backgroundColor: 'transparent',
    position: 'relative',
    left: '270px',
    height: '32px',
    width: '32px',
  },
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',

  '&:focus, &:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
}));

