import React from 'react';
import { func, string, shape } from 'prop-types';

import { Modal } from '../../../../../../components';
import ValidateName from '../../../../../../components/ValidateNameModal/components/ValidateName';
import { modalConfig } from '../../utils/webhookModalHelpers';

const modalName = 'delete';

const staticText = {
  description1: 'Would like to permanently delete the webhook ',
  description2: '? This action cannot be undone and all information related to this webhook will be lost.',
};

const DeleteWebhookModal = (props) => {
  const {
    webhook: {
      name,
      id,
    },
    actions: {
      deleteWebhookRequest,
      toggleWebhookModal,
    },
  } = props;

  const toggleModal = (trackCloseEvent = true) =>
    toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent });

  const submitAction = () => deleteWebhookRequest(id);

  const description = (
    <div>
      <span>{staticText.description1}</span>
      <span className="emphasized-content">{name}</span>
      <span>{staticText.description2}</span>
    </div>
  );


  const content = (
    <ValidateName
      nameToMatch={name}
      nameType="webhook"
      submitAction={submitAction}
      toggleModal={toggleModal}
      title={modalConfig[modalName].title}
      description={description}
    />
  );

  return <Modal title={modalConfig[modalName].title} content={content} toggleModal={toggleModal} />;
};

export default DeleteWebhookModal;

DeleteWebhookModal.propTypes = {
  webhook: shape({
    name: string.isRequired,
    id: string.isRequired,
  }).isRequired,
  actions: shape({
    deleteWebhookRequest: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
};
