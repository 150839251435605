import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Simulator from './Simulator';
import { actions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);

  return {
    ...state.simulator,
    ...state.simulatorTrip,
    rolePermissions: currentOrganization.rolePermissions,
    appFeatureSetId:
      state.applications.applications[state.applications.selectedApplication].featureSetId,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchAllSimulatedVehicles,
    fetchRegionCompatibleVehicles,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchAllSimulatedVehicles,
        fetchRegionCompatibleVehicles,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Simulator);

