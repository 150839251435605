import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as updateUserActions } from '../../../../services/auth/reducers';
import UserProfile from './UserProfile';

const mapStateToProps = (state) => {
  const selectedApp = state.applications.selectedApplication ?
    state.applications.applications[state.applications.selectedApplication] : {};
  return {
    ...state.auth,
    selectedApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    resetErrors,
    sendVerificationEmailRequest,
    updateUserRequest,
    verifyPasswordToChangeAccountInfoRequest,
  } = updateUserActions;

  return {
    actions: bindActionCreators({
      resetErrors,
      sendVerificationEmailRequest,
      updateUserRequest,
      verifyPasswordToChangeAccountInfoRequest,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
