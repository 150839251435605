import { connect } from 'react-redux';

import ConnectedVehiclesTable from './ConnectedVehiclesTable';
import { selectors } from '../../../../../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  return {
    organization: selectors.getCurrentOrganization(state),
  };
};

export default connect(mapStateToProps)(ConnectedVehiclesTable);
