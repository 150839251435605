
const staticText = {
  requirements: [
    {
      text: 'Needs to be between 8-64 characters long',
      regex: '^(?=.*[a-zA-z0-9@$!%*#?&]).{8,64}$',
      name: 'min',
    },
    {
      text: 'Needs to contain at least one lowercase letter',
      regex: '^(?=.*[a-z]).+$',
      name: 'lowercase',
    },
    {
      text: 'Needs to contain at least one uppercase letter',
      regex: '^(?=.*[A-Z]).+$',
      name: 'uppercase',
    },
    {
      text: 'Needs to contain at least one number',
      regex: '^(?=.*[0-9]).+$',
      name: 'number',
    },
    {
      text: 'Needs to contain at least one symbol',
      regex: '^(?=.*[@$!%*#?&]).+$',
      name: 'symbol',
    },
  ],
};

export default staticText;
