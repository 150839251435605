import React, { useEffect } from 'react';
import { string } from 'prop-types';

import './CodeBlock.scss';

export const getNumberOfLines = snippet => snippet.split(/\r\n|\r|\n/).length;

export const generateLineNumbers = (numberOfLines) => {
  return Array(numberOfLines).fill(null).map((_, i) => <li key={`line-${i + 1}`}>{i + 1}</li>);
};

const CodeBlock = ({ code }) => {
  useEffect(() => {
    const { PR } = window;
    if (PR) {
      PR.prettyPrint();
    }
  }, [code]);

  return code ? (
    <div className="code-block">
      <ul className="code-block-line-numbers">
        {generateLineNumbers(getNumberOfLines(code))}
      </ul>
      <div className="p-l-med">
        <pre className="prettyprint">
          <code role="code">{code}</code>
        </pre>
      </div>
    </div>
  ) : null;
};

export default CodeBlock;

CodeBlock.propTypes = {
  code: string.isRequired,
};
