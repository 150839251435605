import React from 'react';
import { func, shape, string } from 'prop-types';

import { Container, Message, Button } from './styles';

const AccountTrialCountdownTracker = (props) => {
  const {
    message, button: { text: buttonText, clickHandler },
  } = props;
  return (
    <Container>
      <Message color="surface">{message}</Message>
      {
        buttonText && typeof clickHandler === 'function' &&
        (
          <Button
            variant="contained"
            onClick={clickHandler}
          >
            {buttonText}
          </Button>
        )
      }
    </Container>
  );
};

AccountTrialCountdownTracker.propTypes = {
  message: string.isRequired,
  button: shape({
    text: string,
    clickHandler: func,
  }),
};

AccountTrialCountdownTracker.defaultProps = {
  button: {},
};

export default AccountTrialCountdownTracker;
