import validate from '@smartcar/validate-redirects';
import errorMessages from '@smartcar/validate-redirects/lib/error-messages.json';

/**
 * Validate redirect URIs
 *
 * @function redirectUriValidator
 * @throws error if uri is invalid or duplicate
 * @param {String} uri - the redirect URI to validate
 * @param {String} clientId - application's client id
 * @param {String[]} [redirectUris] - current redirect URIs
 */
export default function redirectUriValidator(uri, clientId, redirectUris = []) {
  // `validate` requires the presence of the URL constructor to function correctly,
  // if the URL constructor is not available we do not run any specific
  // validation and fall back to the db validation
  // the URL constructor is known to not be available in IE 11
  if (typeof URL !== 'function') {
    return;
  }

  const existingUris = redirectUris.map(redirectUri => validate(redirectUri, clientId).toString());

  const normalizedUri = validate(uri, clientId).toString();

  if (existingUris.includes(normalizedUri)) {
    throw new Error(errorMessages.duplicateURI);
  }
}
