import { styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const Cards = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: theme.spacing(6),

  '& > div': {
    padding: theme.spacing(4),
    boxShadow: theme.border.boxShadow,
    borderRadius: theme.border.radius,
    backgroundColor: theme.palette.common.white,

    '& > * + *': {
      paddingTop: theme.spacing(2),
    },

    '& a': {
      paddingTop: theme.spacing(2),
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
