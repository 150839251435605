import React, { useEffect, useRef } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

const ActionsList = ({ actions, openModal, setDropdownOpen }) => {
  const dropdown = useRef(null);

  /* istanbul ignore next */
  // still figuring out how to trigger this while using react hooks
  const handleClick = (e) => {
    if (dropdown.current && !dropdown.current.contains(e.target)) {
      setDropdownOpen(false);
      reportToSegment(types.TRACK, eventNames.dropdownClosed, {
        label: 'action creator',
        text: 'More',
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <ul
      ref={dropdown}
      className="actions-list"
      onClick={e => e.stopPropagation()}
    >
      {actions.map(({ text, modalName, icon }) => (
        <li key={text}>
          <button
            className="actions-list-option"
            onClick={() => {
              openModal(modalName);
              setDropdownOpen(false);
            }}
          >
            <img src={icon} alt={text} className="m-r-sm" />
            <span>{text}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ActionsList;

ActionsList.propTypes = {
  actions: arrayOf(shape({
    text: string.isRequired,
    modalName: string.isRequired,
    icon: string.isRequired,
  })).isRequired,
  openModal: func.isRequired,
  setDropdownOpen: func.isRequired,
};

