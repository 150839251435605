import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { string } from 'prop-types';

import staticText from '../../localization/Legal/disclaimer';

const Disclaimer = ({ text }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        margin: theme.spacing(1, 0),
        fontSize: '0.75rem',
        color: theme.palette.grey[600],
      }}
    >
      {text}
    </Typography>
  );
};

export default Disclaimer;

Disclaimer.propTypes = {
  text: string,
};

Disclaimer.defaultProps = {
  text: staticText.legalDisclaimer,
};
