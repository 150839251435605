import React from 'react';
import { string, object } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Typography } from '@mui/material';
import { withRouter } from 'react-router-dom';

import { Button, Card, Image, Title } from './styles';
import staticText from '../../localization/Components/upsellModal';
import image from '../../assets/images/free.png';

// TODO: refactor this to have two variants to support UpsellModal
const UpsellContent = ({
  feature, history,
}) => {
  const handleUpgradeClick = () => {
    const billingPath = '/team/billing';
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'article',
      style: 'cta primary',
      text: `upsell (${feature})`,
      path: billingPath,
    });
    history.push(billingPath);
  };

  return (
    <Card>
      <Image src={image} alt="shooting star" />
      <Box>
        <Title variant="h4" fontWeight={600}>{staticText.title2}</Title>
        <Typography mt={1}>{staticText[feature].body}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpgradeClick}
          id="upgrade-now"
          sx={{ mt: 2 }}
        >
          {staticText.upgrade}
        </Button>
      </Box>
    </Card>
  );
};

UpsellContent.propTypes = {
  feature: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: object.isRequired,
};

export default withRouter(UpsellContent);
