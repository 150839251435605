import { connect } from 'react-redux';
import { selectors as orgSelectors } from './services/organizations/reducers';

import App from './App';

function mapStateToProps(state) {
  const selectedAppId = state.applications.selectedApplication;
  return {
    ...state.auth,
    // appId and organizationId is not available before initialization is complete
    appId: selectedAppId,
    appOrgId:
      selectedAppId &&
      state.applications.applications &&
      state.applications.applications[selectedAppId] &&
      state.applications.applications[selectedAppId].organizationId,
    organization: orgSelectors.getCurrentOrganization(state),
  };
}

export default connect(mapStateToProps)(App);
