import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LogDetailsDrawer from './LogDetailsDrawer';
import { actions } from '../../../../reducers';

function mapStateToProps(state) {
  return {
    vehicleInfo: state.logs.logDrawerVehicleDetails,
    vehicleInfoError: state.logs.logErrors.logDrawerVehicleDetails,
    loading: state.logs.isFetchingLogDrawerVehicleDetails,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchLogDrawerVehicleDetails,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchLogDrawerVehicleDetails,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogDetailsDrawer);
