import React from 'react';
import { Link } from 'react-router-dom';
import { node, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import './Error.scss';

const defaultButton = (
  <Link
    to="/"
    onClick={() => {
      reportToSegment(types.TRACK, eventNames.linkClicked, {
        text: 'Refresh',
        path: '/',
        style: 'cta primary',
        section: 'article',
      });
    }}
  >
    <div className="button-wrapper m-t-xl">
      <button className="button">Refresh</button>
    </div>
  </Link>
);

const Error = props => (
  <div className="error med-9 p-t-xl p-b-lg">
    <div className="error card">
      <h2 className="technical-content bold m-b-lg">{props.title}</h2>
      <div className="m-auto m-t-lg m-b-lg">{props.description}</div>
      {props.children}
    </div>
  </div>
);

export default Error;

Error.propTypes = {
  title: string,
  description: string,
  children: node,
};

Error.defaultProps = {
  title: 'Oh No!',
  description: 'There seems to have been an error. It has been reported to Smartcar for speedy\n' +
    ' investigation. Please refresh the page.',
  children: defaultButton,
};
