import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Box } from '@mui/material';
import { Check, AlertTriangle } from 'react-feather';
import staticText from '../../../../localization/Authentication/passwordRequirements';
import { IconPadding, RequirementsList } from './styles';

const PasswordRequirements = ({
  inputString,
}) => {
  const getIcon = (regex) => {
    if (!inputString) {
      return { icon: <span>&bull;</span> };
    }
    const convertRegex = new RegExp(regex);
    const valid = convertRegex.test(inputString);
    const status = valid ? {
      icon: <Check size={13} />,
      class: 'success',
    } :
      {
        icon: <AlertTriangle size={13} />,
        class: 'danger',
      };
    return status;
  };

  const updateRequirements = () => {
    return staticText.requirements.map((req) => {
      return { ...req, status: getIcon(req.regex) };
    });
  };

  const [requirements, setRequirements] = useState(updateRequirements());

  useEffect(() => {
    setRequirements(updateRequirements());
  }, [inputString]);

  return (
    <Box>
      {requirements && requirements.map(req => (
        <RequirementsList
          id={`requirement_${req.name}`}
          key={`requirement_${req.name}`}
          variant="caption"
          statusClass={req.status.class}
        >
          <IconPadding>{req.status.icon}</IconPadding>
          {req.text}
        </RequirementsList>
        ))}
    </Box>
  );
};

export default PasswordRequirements;

PasswordRequirements.propTypes = {
  inputString: string,
};

PasswordRequirements.defaultProps = {
  inputString: '',
};

