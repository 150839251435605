const staticText = {
  title: 'Billing information',
  updatePaymentMethod: 'Update your billing information',
  billingDetails: {
    address: {
      // object order used for rendering/styling
      title: 'Billing Address',
      line1: 'Address Line 1',
      line2: 'Address Line 2',
      city: 'City',
      postal_code: 'Postal Code',
      state: 'State',
      country: 'Country',
    },
    email: 'Billing Email',
    name: 'Business Legal Name',
    phone: 'Phone Number',
    taxId: 'Tax ID',
    taxType: 'Tax Type',
  },
};

export default staticText;
