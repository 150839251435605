import React from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

import Modal from '../Modal';
import ValidateName from './components/ValidateName';

const ValidateNameModal = (props) => {
  const {
    applicationName,
    actionTitle,
    actionDescription,
    toggleModal,
    submitAction,
    isLoading,
    segmentProperties,
  } = props;

  // if we are using the redux loading state for the submit action,
  // don't control modal closing from ValidateName, control it from
  // the parent component of ValidateNameModal
  const modalCloser = isLoading === null ? toggleModal : () => {};

  const content = (
    <ValidateName
      nameToMatch={applicationName}
      title={actionTitle}
      description={actionDescription}
      isLoading={isLoading}
      toggleModal={modalCloser}
      submitAction={submitAction}
    />
  );

  const toggleModalWithTracking = () => {
    reportToSegment(types.TRACK, eventNames.modalClosed, segmentProperties);
    toggleModal();
  };

  if (segmentProperties) {
    reportToSegment(types.TRACK, eventNames.modalOpened, segmentProperties);
  }

  return (
    <Modal
      toggleModal={segmentProperties ? toggleModalWithTracking : toggleModal}
      content={content}
      title={actionTitle}
    />
  );
};

ValidateNameModal.propTypes = {
  actionTitle: string.isRequired,
  actionDescription: element.isRequired,
  applicationName: string.isRequired,
  isLoading: bool,
  submitAction: func.isRequired,
  toggleModal: func.isRequired,
  segmentProperties: shape({
    label: string.isRequired,
    title: string.isRequired,
  }),
};

// since `false` is a valid state for isLoading, we use `null` to denote
// that we are not using the isLoading prop in ValidateName
ValidateNameModal.defaultProps = {
  isLoading: null,
  segmentProperties: null,
};

export default ValidateNameModal;
