import React from 'react';
import { useTheme } from '@mui/material/styles';
import { CheckRounded, CloseRounded } from '@mui/icons-material';

// eslint-disable-next-line import/prefer-default-export
export const getRowIconAndStyle = (eventName) => {
  const theme = useTheme();

  if (eventName === 'smartcar_grant:allowed') {
    return {
      backgroundColor: theme.palette.success.transparent,
      Icon: <CheckRounded color="success" titleAccess="Green check" />,
    };
  }
  if (eventName === 'smartcar_grant:denied'
    || (eventName && eventName.split(':')[1] === 'returned_to_application')) {
    return {
      backgroundColor: theme.palette.error.transparent,
      Icon: <CloseRounded color="error" titleAccess="Red X" />,
    };
  }
  return {
    backgroundColor: theme.palette.grey[100],
    Icon: null,
  };
};
