import React from 'react';
import { arrayOf, element, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { PermissionGate, Endpoints } from '..';
import { Container, DetailsList } from './styles';

const EmptyList = ({
  heading,
  upgradeMessage,
  details,
  cta,
  dashboardPermission,
}) => {
  const theme = useTheme();

  const renderCta = () => {
    const { text: ctaText, path, onClick } = cta;
    if (cta.path) {
      return (
        <Link id="create-link" to={path} onClick={onClick}>
          {ctaText}
        </Link>
      );
    } else if (cta.onClick) {
      return (
        <Button
          variant="contained"
          size="large"
          id="create-btn"
          onClick={onClick}
          sx={{ marginTop: theme.spacing(3) }}
        >
          {ctaText}
        </Button>
      );
    }
    return null;
  };

  return (
    <Container>
      <Box width="50%" marginRight={4} marginBottom="auto">
        <Typography variant="h2">{heading}</Typography>
        {upgradeMessage}
        <DetailsList>
          {details.map(detail => <li key={detail.key}>{detail.text}</li>)}
        </DetailsList>
        <PermissionGate dashboardPermission={dashboardPermission}>
          {cta && cta.text && renderCta()}
        </PermissionGate>
      </Box>
      <Box width="50%" marginLeft={4}>
        <Endpoints />
      </Box>
    </Container>
  );
};

export default EmptyList;

EmptyList.propTypes = {
  heading: string.isRequired,
  upgradeMessage: element,
  cta: shape({
    text: string.isRequired,
    path: string,
    onClick: func,
  }),
  details: arrayOf(shape({
    key: string.isRequired,
    text: string.isRequired,
  })).isRequired,
  dashboardPermission: string.isRequired,
};

EmptyList.defaultProps = {
  cta: null,
  upgradeMessage: null,
};
