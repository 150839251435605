import { connect } from 'react-redux';

import OrgSettings from './OrgSettings';

function mapStateToProps(state) {
  const selectedApp = state.applications.selectedApplication ?
    state.applications.applications[state.applications.selectedApplication] : {};

  return {
    selectedApp,
  };
}

export default connect(mapStateToProps)(OrgSettings);
