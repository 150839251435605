import React from 'react';
import { arrayOf, shape, func, string, number } from 'prop-types';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';

import { CopyButton, Modal } from '../../../../../../components';

import endpointToPermissionConverter from '../../utils/endpointToPermissionConverter';
import { modalConfig } from '../../utils/webhookModalHelpers';

import './WebhookInformationModal.scss';
import { TYPES } from '../../utils/webhookTypes';
import formFields from '../WebhooksForm/fields';

const modalName = 'information';

const staticText = {
  subscription:
    'No vehicles are subscribed to this webhook. See the guide to learn how to subscribe vehicles to a webhook.',
};

const sectionFactory = (title, content) => {
  return (
    <div>
      <Typography variant="h3" mt={3} mb={1}>{title}</Typography>
      {content}
    </div>
  );
};

const subscribedVehiclesText = (vehicles) => {
  const text = vehicles > 0 ? `${vehicles} ${vehicles === 1 ? 'vehicle' : 'vehicles'}` : staticText.subscription;
  return sectionFactory('subscribed vehicles', <p>{text}</p>);
};

const formatPermissionsByType = (permissions, type, profile) => {
  if (type === TYPES.eventBased.name) return permissions;

  let permissionsByType = permissions;
  if (type === TYPES.dynamic.name) {
    if (!profile || !_.get(formFields, ['dynamicProfiles', profile, 'impliedValues', 'permissions'])) return [];
    permissionsByType = _.get(formFields, ['dynamicProfiles', profile, 'impliedValues', 'permissions']);
  }
  return permissionsByType.reduce((acc, endpoint) => {
    const permission = endpointToPermissionConverter(endpoint);
    return permission && !acc.includes(permission) ? acc.concat(permission) : acc;
  }, []);
};

const getPermissionsContent = (permissions) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {permissions.sort().map(permission => (
        <Typography component="p" variant="copyable" sx={{ display: 'inline-block' }} key={permission} mr={1} mb={1}>
          {permission}
        </Typography>
      ))}
      <CopyButton content={JSON.stringify(permissions)} />
    </Box>
  );
};

export const getWebhookInformation = ({
  id, callbackUri, permissions, subscribedVehicles, type, profile,
}) => (
  <div>
    {sectionFactory('callback uri',
      <Typography component="p" variant="copyable" sx={{ display: 'inline-block' }}>{callbackUri}</Typography>,
    )}
    {sectionFactory('id',
      <Typography component="p" variant="copyable" sx={{ display: 'inline-block' }}>{id}</Typography>,
    )}
    {sectionFactory('permissions', getPermissionsContent(formatPermissionsByType(permissions, type, profile)))}
    {type === TYPES.dynamic.name && sectionFactory('state change',
      <Typography>{formFields.stateChangeTypes.PLUGGED_IN.text}</Typography>,
    )}
    {subscribedVehiclesText(subscribedVehicles)}
  </div>
);

getWebhookInformation.propTypes = {
  id: string.isRequired,
  callbackUri: string.isRequired,
  permissions: arrayOf(string),
  subscribedVehicles: number.isRequired,
};

getWebhookInformation.defaultProps = {
  permissions: [],
};

const WebhookInformationModal = ({ actions: { toggleWebhookModal }, webhook }) => {
  const content = (
    <div>
      {getWebhookInformation(webhook)}
    </div>
  );
  return (
    <div>
      <Modal
        title={modalConfig[modalName][webhook.type]}
        content={content}
        toggleModal={() => toggleWebhookModal({
          nextModal: null, curModal: modalName, trackCloseEvent: true,
        })}
      />
    </div>
  );
};

export default WebhookInformationModal;

WebhookInformationModal.propTypes = {
  actions: shape({
    toggleWebhookModal: func.isRequired,
  }).isRequired,
  webhook: shape({
    id: string.isRequired,
    callbackUri: string.isRequired,
    permissions: arrayOf(string),
    subscribedVehicles: number,
  }).isRequired,
};
