/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material/styles';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

export const StyledDateRangePicker = styled(DateRangePicker, {
  shouldForwardProp: prop => prop !== 'darkerBorder',
})(({ darkerBorder, theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    height: '2.625rem',
    color: theme.palette.text.primary,
    border: 'none',
  },

  '& input': {
    padding: theme.spacing(1, 2),
  },
  '& label': {
    top: '-6px',
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
  '& fieldset': {
    borderColor: darkerBorder ? theme.palette.grey[500] : theme.palette.grey[200],
    transition: 'border 0.25s ease',
  },
}));
