import React from 'react';
import { arrayOf, object } from 'prop-types';
import {
  Link,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

import { Table, Footnote } from './styles';
import { Chip } from '../../../../../../../CreateVehicle/styles';
import staticText from '../../../../../../../../../../../../localization/Application/Simulator/simulator';

const LatencyTable = ({ endpointData }) => {
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{staticText.brandLatencies.method}</TableCell>
            <TableCell>{staticText.brandLatencies.endpoint}</TableCell>
            <TableCell>{staticText.brandLatencies.range}</TableCell>
            <TableCell>{staticText.brandLatencies.median}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {endpointData.map((endpoint) => {
            const latencyRange = `${endpoint.latencyLowerBound} - ${endpoint.latencyUpperBound}`;
            const latencyAverage =
              ((endpoint.latencyUpperBound - endpoint.latencyLowerBound) / 2) +
              endpoint.latencyLowerBound;
            if (latencyAverage === 0) return null;
            return (
              <TableRow key={`${endpoint.method}-${endpoint.path}`}>
                <TableCell><Typography variant="tableData"><Chip key={endpoint.method} component="div" method={endpoint.method}>{endpoint.method}</Chip></Typography></TableCell>
                <TableCell><Typography variant="snippet">{endpoint.path}</Typography></TableCell>
                <TableCell><Typography variant="tableData">{`${latencyRange} seconds`}</Typography></TableCell>
                <TableCell><Typography variant="tableData">{`${latencyAverage} seconds`}</Typography></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Footnote variant="caption" component="p">
        {staticText.brandLatencies.footnote.text}
        <Link
          href={staticText.brandLatencies.footnote.cta.link}
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          {staticText.brandLatencies.footnote.cta.text}
        </Link>
      </Footnote>
    </React.Fragment>
  );
};

export default LatencyTable;

LatencyTable.propTypes = {
  endpointData: arrayOf(object).isRequired,
};
