import { useCallback, useEffect, useRef, useState } from 'react';

const useDelay = (delay = 200) => {
  // delay to prevent rendering spinner for fast loads
  const [afterDelay, setAfterDelay] = useState(false);
  const timerRef = useRef(null);

  const startTimer = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setAfterDelay(true);
    }, delay);
  }, [delay]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setAfterDelay(false);
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    startTimer();

    return () => clearTimeout(timerRef.current);
  }, [startTimer]);


  return {
    afterDelay,
    resetTimer,
  };
};

export default useDelay;
