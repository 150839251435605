import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../services/api/api';
import { actions, types } from './reducers';
import { actions as tripActions, types as tripTypes } from './components/SimulateVehicle/reducers';
import { endpointTypes, removeMakeFromPath } from './utils/endpointDisplayMap';

export function* fetchRegionCompatibleVehicles(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.fetchCompatibleVehicles,
      applicationId,
      payload,
    );

    yield put(actions.fetchRegionCompatibleVehiclesSuccess(data));
  } catch (error) {
    yield put(actions.fetchRegionCompatibleVehiclesFailure(error));
  }
}

export function* fetchVinCompatibility(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.checkVinCompatibility,
      applicationId,
      payload,
    );

    yield put(actions.fetchVinCompatibilitySuccess(data));
  } catch (error) {
    yield put(actions.fetchVinCompatibilityFailure(error));
  }
}

export function* fetchVehicleCapabilities(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.fetchVehicleCapabilities,
      applicationId,
      payload,
    );

    // RETURNED DATA SHAPE:
    // data = {
    //   vehicleDefinitionId,
    //   model,
    //   year,
    //   vehicleType,
    //   make,
    //   endpointsWithLatency: {
    //     path,
    //     method,
    //     latencyLowerBound,
    //     latencyUpperBound,
    //   }
    // }

    // The returned result will include all capabilities for the vehicle.
    // We will filter them to only those currently supported in the Simulator.
    const simulatorSupportedEndpointPaths = Object.values(endpointTypes);
    const filteredVehicleCapabilities = data.endpointsWithLatency.filter(endpoint => (
      simulatorSupportedEndpointPaths.includes(removeMakeFromPath(endpoint.path))
    ));
    data.endpointsWithLatency = filteredVehicleCapabilities;

    yield put(actions.fetchVehicleCapabilitiesSuccess(data));
  } catch (error) {
    yield put(actions.fetchVehicleCapabilitiesFailure(error));
  }
}

export function* fetchAllSimulatedVehicles(action) {
  try {
    const {
      meta: applicationId,
      payload: { selectedVin = '' },
    } = action;

    const { data } = yield call(
      api.fetchAllSimulatedVehicles,
      applicationId,
    );

    let vehicle = {};
    if (selectedVin && data[selectedVin].id) {
      vehicle = data[selectedVin];
      if (vehicle.simulatedVehicleTripId) {
        yield put(
          tripActions.fetchVehicleTrip(applicationId, vehicle.simulatedVehicleTripId),
        );
      }
      yield put(actions.setSelectedVehicle(vehicle));
    }
    yield put(actions.fetchAllSimulatedVehiclesSuccess(data));
  } catch (error) {
    yield put(actions.fetchAllSimulatedVehiclesFailure(error));
  }
}

export function* checkConnectedVehicle(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.checkConnectedVehicle,
      applicationId,
      payload,
    );

    if (data.smartcarId) {
      yield put(actions.checkConnectedVehicleSuccess(data));
    }
  } catch (error) {
    yield put(actions.checkConnectedVehicleFailure(error));
  }
}

export function* createVehicle(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.createSimulatedVehicle,
      applicationId,
      payload,
    );
    // Refresh simulated vehicles list
    yield put(actions.fetchAllSimulatedVehicles(applicationId, data.vin));

    yield put(actions.createVehicleSuccess(data));
  } catch (error) {
    yield put(actions.createVehicleFailure(error));
  }
}

export function* deleteVehicle(action) {
  try {
    const { meta: applicationId, payload } = action;

    const response = yield call(
      api.deleteSimulatedVehicle,
      applicationId,
      payload,
    );
    yield put(actions.deleteVehicleSuccess(response));

    // Refresh simulated vehicles list
    yield put(actions.fetchAllSimulatedVehicles(applicationId));
  } catch (error) {
    yield put(actions.deleteVehicleFailure(error));
  }
}

export function* fetchVehicleTrip(action) {
  try {
    const { meta: applicationId, payload: { vehicleTripId } } = action;
    const { data } = yield call(
      api.getVehicleTrip,
      applicationId,
      vehicleTripId,
    );

    yield put(tripActions.fetchVehicleTripSuccess(data));
  } catch (error) {
    yield put(tripActions.fetchVehicleTripFailure(error));
  }
}

export function* fetchVehicleTripFrame(action) {
  try {
    const { meta: applicationId, payload: { tripValues, frameIndex } } = action;
    const { data } = yield call(
      api.getVehicleTripFrame,
      applicationId,
      tripValues,
      frameIndex,
    );

    yield put(tripActions.fetchVehicleTripFrameSuccess(data));
  } catch (error) {
    yield put(tripActions.fetchVehicleTripFrameFailure(error));
  }
}

export function* createVehicleTrip(action) {
  try {
    const { meta: applicationId, payload } = action;

    const { data } = yield call(
      api.createVehicleTrip,
      applicationId,
      payload,
    );

    yield put(tripActions.createVehicleTripSuccess(data));
  } catch (error) {
    yield put(tripActions.createVehicleTripFailure(error));
  }
}

export function* updateVehicleTrip(action) {
  const { meta: applicationId, payload } = action;
  const {
    vehicleId, updateAction, updatePayload,
  } = payload;
  try {
    const response = yield call(
      api.updateVehicleTrip,
      applicationId,
      vehicleId,
      updateAction,
      updatePayload,
    );

    yield put(tripActions.updateVehicleTripSuccess({
      updateAction,
      updatePayload,
      response: response.data,
    }));
  } catch (error) {
    yield put(tripActions.updateVehicleTripFailure({ updateAction, error }));
  }
}

export function* completeVehicleTrip(action) {
  const { meta: applicationId, payload } = action;
  const { vehicleId } = payload;
  const updateAction = 'COMPLETE';
  try {
    const response = yield call(
      api.updateVehicleTrip,
      applicationId,
      vehicleId,
      updateAction,
    );

    yield put(tripActions.completeVehicleTripSuccess({ updateAction, response }));
  } catch (error) {
    yield put(tripActions.completeVehicleTripFailure({ updateAction, error }));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_REGION_COMPATIBLE_VEHICLES, fetchRegionCompatibleVehicles);
  yield takeLatest(types.FETCH_VIN_COMPATIBILITY, fetchVinCompatibility);
  yield takeLatest(types.FETCH_VEHICLE_CAPABILITIES, fetchVehicleCapabilities);
  yield takeLatest(types.FETCH_VEHICLES, fetchAllSimulatedVehicles);
  yield takeLatest(types.CREATE_VEHICLE, createVehicle);
  yield takeLatest(types.DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(types.CHECK_CONNECTED_VEHICLE, checkConnectedVehicle);
  yield takeLatest(tripTypes.FETCH_VEHICLE_TRIP, fetchVehicleTrip);
  yield takeLatest(tripTypes.FETCH_VEHICLE_TRIP_FRAME, fetchVehicleTripFrame);
  yield takeLatest(tripTypes.CREATE_VEHICLE_TRIP, createVehicleTrip);
  yield takeLatest(tripTypes.UPDATE_VEHICLE_TRIP, updateVehicleTrip);
  yield takeLatest(tripTypes.COMPLETE_VEHICLE_TRIP, completeVehicleTrip);
}
