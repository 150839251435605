import { AppBar, styled } from '@mui/material';

const TabsNav = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  marginTop: theme.spacing(-1),
  zIndex: 1,

  '.MuiTabs-root': {
    minHeight: '0',
  },

  button: {
    marginLeft: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default TabsNav;
