import { differenceInDays, format } from 'date-fns';

import billingStaticText from '../../../../../localization/Application/Billing/billing';
import cardSetStaticText from '../../../../../localization/Application/Billing/cardSet';

export const dateFormatter = (date, isFullMonth = false) =>
  format(new Date(date), `${isFullMonth ? 'MMMM' : 'MMM'} d, Y`);

export const isInTrial = (planName) => {
  return planName === 'build_trial';
};

export const daysLeftInTrial = (trialEndsAt) => {
  return differenceInDays(
    // TODO: remove Date.now after updating jest
    new Date(trialEndsAt),
    new Date(Date.now()),
  );
};

export const cardSetFormatter = (billingInfo) => {
  let cardSet;

  if (isInTrial(billingInfo.planName)) {
    // For free trials, we display their # of vehicle connections and days left on trial
    cardSet = cardSetStaticText.freeTrialCards.map((card, i) => {
      const formattedCard = { ...card };
      if (i === 1) {
        formattedCard.title = billingInfo.numberOfActiveVehicles || 0;
      } else if (i === 2) {
        formattedCard.title = daysLeftInTrial(billingInfo.billingPeriodEndDate);
      }

      return formattedCard;
    });
  } else if (billingInfo.planName === 'free') {
    cardSet = cardSetStaticText.freePlanCards.map((card, i) => {
      const formattedCard = { ...card };
      if (i === 1) {
        formattedCard.title = billingInfo.numberOfActiveVehicles || 0;
        formattedCard.subtitle = formattedCard.subtitle(
          billingInfo.numberOfBaseVehicles,
        );

        formattedCard.titleError = billingInfo.numberOfVehiclesAboveBase > 0;
      } else if (i === 2) {
        formattedCard.title = billingInfo.numOfApiViolationVehicles;
        formattedCard.subtitle = formattedCard.subtitle(
          billingInfo.apiCallLimit,
        );
        formattedCard.titleError = billingInfo.numOfApiViolationVehicles > 0;
      }

      return formattedCard;
    });
  } else {
    cardSet = cardSetStaticText.defaultCards.map((card, i) => {
      const formattedCard = { ...card };
      // istanbul ignore else
      if (i === 0) {
        formattedCard.title = billingInfo.planName;

        // The line below can be removed when there are no more Business (legacy) plans
        if (billingInfo.planName === billingStaticText.businessPlanName) {
          formattedCard.titleAddendum = '(Legacy)';
        }

        if (
          billingInfo.billingPeriodStartDate &&
          billingInfo.billingPeriodEndDate
        ) {
          formattedCard.subtitle = `${dateFormatter(
            billingInfo.billingPeriodStartDate,
          )} - ${dateFormatter(billingInfo.billingPeriodEndDate)}`;
        }
      } else if (i === 1) {
        formattedCard.title = billingInfo.numberOfActiveVehicles || 0;

        // Only show "X vehicles included" for self-serve plans
        if (billingInfo.planName === billingStaticText.freePlanName ||
           billingInfo.planName === billingStaticText.buildPlanName ||
           billingInfo.planName === billingStaticText.businessPlanName) {
          formattedCard.subtitle = formattedCard.subtitle(
            billingInfo.numberOfBaseVehicles,
          );
        } else {
          formattedCard.subtitle = '';
        }

        formattedCard.titleError = billingInfo.numberOfVehiclesAboveBase > 0;
      } else if (i === 2) {
        formattedCard.title = billingInfo.numberOfApiCalls || 0;
        formattedCard.subtitle = formattedCard.subtitle(
          billingInfo.apiCallLimit,
        );
      }

      return formattedCard;
    });
  }

  return cardSet;
};

export const pluralFormatter = (num) => {
  return num !== 1
    ? `${num} ${billingStaticText.pluralVehicles}`
    : `${num} ${billingStaticText.singleVehicle}`;
};

export const usageErrorGenerator = (
  planName,
  numberOfVehiclesAboveBase,
  billedByVehicleConnections,
) => {
  const usageError = {
    message: '',
    type: 'warn',
  };

  if (planName === billingStaticText.enterprisePlanName) return usageError;

  if (numberOfVehiclesAboveBase > 0) {
    if (planName === billingStaticText.freePlanName) {
      usageError.message += `${pluralFormatter(numberOfVehiclesAboveBase)} ${
        billingStaticText.activeVehicleLimitExceededFree
      }`;
    } else {
      usageError.message += billingStaticText.activeVehicleLimitExceededPaid(
        numberOfVehiclesAboveBase,
      );
    }
    if (billedByVehicleConnections) {
      usageError.type = 'alert';
    }
  }

  return usageError;
};

export const displayNameFormatter = (planName) => {
  if (planName === billingStaticText.buildPlanTrialName) {
    return (
      billingStaticText.buildPlanName[0].toUpperCase() +
      billingStaticText.buildPlanName.slice(1)
    );
  }
  return planName[0].toUpperCase() + planName.slice(1);
};
