import { styled } from '@mui/material/styles';
import { Button as MuiButton, Typography } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.monetization.main,
  color: theme.palette.text.primary,
  height: '50px',
  padding: '20px',

  '&:hover, &:focus': {
    backgroundColor: theme.palette.monetization.light,
  },
}));

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '170px',
  maxWidth: '700px',
  alignItems: 'center',
  padding: theme.spacing(2, 3),
  gap: theme.spacing(2),
  border: theme.border.radius,
  boxShadow: theme.border.boxShadow3,
}));

export const Image = styled('img')(() => ({
  maxWidth: '150px',
  transform: 'scaleX(-1)',
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: theme.typography.fontFamily,
}));
