/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, number, object, objectOf, oneOfType, shape, string } from 'prop-types';
import { Typography, styled } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { PAGE_SIZE, initialState, status } from '../../reducers';
import { Disclaimer, Spinner } from '../../../../../../components';
import { ConnectedVehiclesTable, FiltersBar, NoResultsFound } from './components';
import staticText from '../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import { menuItems } from '../../../../../../localization/Application/VehicleManagement/vehiclesTable';
import formatFilterValues from '../../utils/formatFilterValues';
import { ErrorsList, FiltersContainer } from '../shared/styles';
import validateFilterValues from '../../utils/validateFilterValues';
import ApplicationHeader from '../../../ApplicationHeader';
import { useDelay } from '../../../../../../hooks';

const VehiclesContainer = styled('div')(() => ({
  maxWidth: '100% !important',
}));

const ConnectedVehicles = ({
  actions: {
    fetchAllCompatibleVehicles,
    fetchConnectedVehicles,
    updateDisconnectStatus,
    updateFilterValues,
    updateOverview,
  },
  applicationId,
  compatibleVehicles,
  countDifference,
  history,
  disconnectStatus,
  isFetchingInitialVehicles,
  isFetchingVehicles,
  filterValues,
  openDisconnectModal,
  pageInfo,
  pageToNextCursorMap,
  paginationModel,
  provideConnectCta,
  vehicleErrors,
}) => {
  const [formError, setFormError] = useState('');
  const { afterDelay: showLoading, resetTimer } = useDelay();

  const getPageInfo = (page, filters) => {
    // Allow page change if new page is 0 or we have a cursor for that page
    const cursor = pageToNextCursorMap[page - 1];
    if (page === 0 || cursor) {
      const formattedFilterValues = {
        ...formatFilterValues(filters),
        ...(cursor && { cursor }),
      };

      fetchConnectedVehicles({
        filterValues: formattedFilterValues,
        newPaginationModel: {
          page,
          pageSize: PAGE_SIZE,
        },
      });
    }
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    getPageInfo(newPaginationModel.page, filterValues);

    const [label, text] = newPaginationModel.page > paginationModel.page ?
      ['forward', '[vehicles - right arrow icon]'] : ['back', '[vehicles - left arrow icon]'];
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label, text });
    resetTimer();
  };

  const handleResetAll = () => {
    const defaultFilterValues = initialState.filterValues;
    getPageInfo(0, defaultFilterValues);
    updateFilterValues(defaultFilterValues);
    resetTimer();
  };

  const handleApplyFilters = (newFilterValues) => {
    const validated = validateFilterValues(newFilterValues);
    if (validated.error) {
      setFormError(validated.error);
      return;
    }
    getPageInfo(0, newFilterValues);
    updateFilterValues(newFilterValues);
    setFormError('');
    resetTimer();
  };

  const errorsToDisplay = Object.entries(vehicleErrors)
    .filter(([errorKey, message]) => {
      if (errorKey === 'disconnectVehicle') return false;
      if (!message) return false;
      return true;
    });
  if (formError) {
    errorsToDisplay.push(['formError', formError]);
  }

  // todo: update this logic to depend on the mode attribute of the vehicle instead
  // once the api response contains this mode attribute
  const filteredMenuItems = filterValues.mode === 'live' ?
    menuItems : menuItems.filter(item => item.action !== 'viewDetails');

  const moreActions = {
    menuItems: filteredMenuItems,
    actionMap: {
      disconnect: (rowItem) => {
        const vehicle = {
          ...rowItem.vehicle,
          vehicleId: rowItem.vehicleId,
        };
        openDisconnectModal(vehicle);
      },
      viewDetails: (rowItem) => {
        const { vehicle, ...info } = rowItem;
        const vehicleInfo = { ...vehicle, ...info };
        updateOverview(vehicleInfo);
        history.push(`/apps/${applicationId}/vehicles/${vehicleInfo.vehicleId}`);
      },
    },
  };

  useEffect(() => {
    fetchAllCompatibleVehicles();
    getPageInfo(paginationModel.page, filterValues);
  }, []);

  useEffect(() => {
    if (disconnectStatus === status.SUCCESS) {
      getPageInfo(paginationModel.page, filterValues);
      updateDisconnectStatus(status.INACTIVE);
    } else if (disconnectStatus === status.FAILURE) {
      updateDisconnectStatus(status.INACTIVE);
    }
  }, [disconnectStatus]);

  return (
    <React.Fragment>
      <ApplicationHeader
        heading={staticText.heading}
      />
      <VehiclesContainer>
        <FiltersContainer>
          {pageInfo.rows && pageInfo.rows.length > 0 &&
            <Typography>
              {staticText.totalVehicles(pageInfo.rowCount - countDifference)}
            </Typography>
          }
          <FiltersBar
            compatibleVehicles={compatibleVehicles}
            filterValues={filterValues}
            handleApplyFilters={handleApplyFilters}
            handleResetAll={handleResetAll}
            setFormError={setFormError}
          />
        </FiltersContainer>
        {errorsToDisplay.length > 0 &&
          <ErrorsList alignItems="flex-end">
            {errorsToDisplay.map(([errorKey, message]) => {
              return (
                <li key={errorKey}>
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                </li>
              );
            })}
          </ErrorsList>
        }
        {isFetchingInitialVehicles && <Spinner delay={200} />}
        {!isFetchingInitialVehicles && pageInfo.rows && pageInfo.rows.length > 0 &&
          <ConnectedVehiclesTable
            pageInfo={pageInfo}
            getRowId={row => `${row.vehicleId}+${row.userId}`}
            pageSizeOptions={[PAGE_SIZE]}
            paginationModel={paginationModel}
            handlePaginationModelChange={handlePaginationModelChange}
            loading={isFetchingVehicles && showLoading}
            moreActions={moreActions}
            rowCount={pageInfo.rowCount - countDifference}
            filterValues={filterValues}
          />
        }
        {(!isFetchingInitialVehicles && pageInfo.rows && pageInfo.rows.length === 0) &&
          <NoResultsFound
            provideConnectCta={provideConnectCta}
            resetFilters={handleResetAll}
          />
        }
        {!isFetchingInitialVehicles &&
          <div style={{ marginLeft: '2px' }}>
            <Disclaimer />
          </div>}
      </VehiclesContainer>
    </React.Fragment>
  );
};

export default ConnectedVehicles;

ConnectedVehicles.propTypes = {
  actions: shape({
    fetchAllCompatibleVehicles: func.isRequired,
    fetchConnectedVehicles: func.isRequired,
    updateDisconnectStatus: func.isRequired,
    updateFilterValues: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  compatibleVehicles: objectOf(
    shape({
      make: string.isRequired,
      models: arrayOf(string).isRequired,
    }),
  ).isRequired,
  countDifference: number.isRequired,
  history: object.isRequired,
  disconnectStatus: string.isRequired,
  isFetchingInitialVehicles: bool.isRequired,
  isFetchingVehicles: bool.isRequired,
  filterValues: shape({
    make: string.isRequired,
    model: string.isRequired,
    vehicleId: string.isRequired,
    conDateRange: array.isRequired,
  }).isRequired,
  openDisconnectModal: func.isRequired,
  pageInfo: oneOfType([
    shape({
      rows: array.isRequired,
      columns: array.isRequired,
      rowCount: number.isRequired,
    }),
    shape({}),
  ]).isRequired,
  pageToNextCursorMap: objectOf(string).isRequired,
  paginationModel: shape({
    page: number,
    pageSize: number,
  }).isRequired,
  provideConnectCta: bool.isRequired,
  vehicleErrors: shape({
    compatibleVehicles: string,
    connectedVehicles: string,
    disconnectVehicle: string,
  }).isRequired,
};
