import { connect } from 'react-redux';

import DowngradePlanModal from './DowngradePlanModal';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  return {
    userContext: state.auth.session.userContext,
    applications: state.applications.applications,
    organization: orgSelectors.getCurrentOrganization(state),
  };
};


export default connect(mapStateToProps)(DowngradePlanModal);
