import React from 'react';
import { string } from 'prop-types';
import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { InlineRoute } from '..';

const SuccessMessage = ({ message, routeInfo }) => {
  return (
    <Box id="success-message-container" mt={3} mb={3}>
      <Box mb={1}>
        <CheckCircleRounded fontSize="large" color="secondary" />
      </Box>
      <Typography variant="subtitle1">{ message }</Typography>
      <Box mt={3} mb={4} pr={6}>
        <InlineRoute text={routeInfo} />
      </Box>
    </Box>
  );
};

export default SuccessMessage;

SuccessMessage.propTypes = {
  message: string.isRequired,
  routeInfo: string.isRequired,
};
