import React from 'react';
import { ClipLoader } from 'react-spinners';
import { number, string } from 'prop-types';

import theme from '../../global-styles/theme';
import './Spinner.scss';
import { useDelay } from '../../hooks';

const DEFAULT_SIZES = {
  page: 50,
  button: 24,
  small: 30,
  xs: 16,
};

const Spinner = ({
  size,
  spinnerColor,
  additionalClassNames,
  delay,
}) => {
  const { afterDelay: showLoading } = useDelay(delay);
  return (showLoading || delay === 0) ? (
    <div
      data-testid="spinner"
      className={additionalClassNames
        ? `spinner ${additionalClassNames}`
        : `spinner ${size.toLowerCase() === 'page' && 'spinner-page'}`}
    >
      <ClipLoader color={spinnerColor} size={DEFAULT_SIZES[size.toLowerCase()]} loading />
    </div>
  ) : null;
};


export default Spinner;

Spinner.propTypes = {
  size: string,
  additionalClassNames: string,
  spinnerColor: string,
  delay: number,
};

Spinner.defaultProps = {
  size: 'PAGE',
  additionalClassNames: '',
  spinnerColor: theme.palette.primary.main,
  delay: 0,
};
