import React, { useState } from 'react';
import { Button, styled } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { func, string } from 'prop-types';
import BoltIcon from '@mui/icons-material/Bolt';

import UpsellModal from '../UpsellModal';

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.monetization.main,
  color: theme.palette.text.primary,
  height: '50px',
  padding: '20px',

  '&:hover, &:focus': {
    backgroundColor: theme.palette.monetization.light,
  },
}));

const UpgradeButton = ({ text, feature, handleClick }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(false);
  const handleButtonClick = () => {
    if (handleClick) {
      handleClick();
    } else {
      setModalOpen(true);
    }
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: `upsell (${feature})`,
      title: text,
    });
  };

  return (
    <React.Fragment>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        id="upgrade-button"
      >
        <BoltIcon sx={{ height: '22px', marginLeft: '-8px' }} />
        {text}
      </StyledButton>
      {modalOpen && <UpsellModal feature={feature} toggleModal={toggleModal} />}
    </React.Fragment>
  );
};

UpgradeButton.propTypes = {
  text: string.isRequired,
  feature: string.isRequired,
  handleClick: func,
};

UpgradeButton.defaultProps = {
  handleClick: null,
};

export default UpgradeButton;
