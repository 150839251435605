import { styled } from '@mui/material/styles';
import { Box, Button as MuiButton, Typography } from '@mui/material';

export const UpgradeTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: '2rem',
}));


export const Text = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.125rem',
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({ display: 'flex', justifyContent: 'center', gap: theme.spacing(3) }));

export const Button = styled(MuiButton)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  width: '144px',
  fontSize: '1.125rem',
}));

export const Image = styled('img')(({ theme }) => ({
  width: '288px',
  marginBottom: theme.spacing(6),
}));

export const StyledButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.monetization.main,
  color: theme.palette.text.primary,
  height: '50px',
  padding: '20px',
  fontSize: '1.125rem',

  '&:hover, &:focus': {
    backgroundColor: theme.palette.monetization.light,
  },
}));
