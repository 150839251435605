import _ from 'lodash';
import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Typography, useTheme } from '@mui/material';

import { RedirectUri, RedirectUriForm } from './components';
import PermissionGate from '../../../../../../components/PermissionGate';
import staticText from '../../../../../../localization/Application/configuration';

const RedirectUriList = ({
  redirectUris, updateUris, clientId, isUpdating,
}) => {
  const theme = useTheme();

  const handleCreate = (redirectUri) => {
    const updatedUris = _.concat(redirectUris, redirectUri);
    updateUris(updatedUris);
  };

  const handleRemove = (redirectUri) => {
    const updatedUris = _.filter(redirectUris, value =>
      redirectUri !== value,
    );
    updateUris(updatedUris);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: staticText.oauth.redirectUris.delete,
    });
  };

  const buildRedirectUris = _.map(redirectUris, (redirectUri, index) => (
    <RedirectUri
      key={index}
      index={index}
      redirectUri={redirectUri}
      removeRedirectUri={handleRemove}
    />
  ));

  return (
    <Box marginTop={3}>
      <Typography variant="h3" mb={2}>{staticText.oauth.redirectUris.heading}</Typography>
      <div>
        {redirectUris.length > 0
          ? buildRedirectUris
          : (
            <Typography mb={2} maxWidth={theme.width.paragraph}>
              {staticText.oauth.redirectUris.emptyState.text}
            </Typography>
          )
        }
        <PermissionGate dashboardPermission="write_configuration">
          <RedirectUriForm
            createRedirectUri={handleCreate}
            clientId={clientId}
            isUpdating={isUpdating}
            redirectUris={redirectUris}
          />
        </PermissionGate>
      </div>
    </Box>
  );
};

export default RedirectUriList;

RedirectUriList.propTypes = {
  updateUris: func.isRequired,
  redirectUris: arrayOf(string).isRequired,
  clientId: string.isRequired,
  isUpdating: bool.isRequired,
};
