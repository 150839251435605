import _ from 'lodash';

const matchNameOrEmail = (member, inputValue) => {
  const lowerCaseValue = inputValue.toLowerCase();
  return (member.name && member.name.toLowerCase().includes(lowerCaseValue))
      || member.email.toLowerCase().includes(lowerCaseValue);
};

/**
 * Filter Logic
 * search input: match substring, case insensitive
 * role: match if user's role is included in selected roles
 * app access: match if user has access to all selected apps
 */

/**
 * Returns a new array of filtered members
 * @param {Array} members - array of members
 * @param {Object} filters - current set of filters
 * @param {Object} defaultFilters - default filters
 * @returns {Array} array of filtered members
 */
const applyFilters = (members, filters, defaultFilters) => {
  let filteredMembers = [...members];
  if (_.isEqual(filters, defaultFilters)) {
    return filteredMembers;
  }

  const shouldFilterNameEmail = filters.search !== '';

  const availableRoles = Object.keys(filters.roles);
  const selectedRoles = availableRoles.filter(role => filters.roles[role]);
  // don't filter if user selected all or none
  const shouldFilterRoles = selectedRoles.length > 0
    && selectedRoles.length < availableRoles.length;

  const availableApps = Object.keys(filters.access);
  const selectedApps = availableApps.filter(app => filters.access[app]);
  const shouldFilterApps = selectedApps.length > 0 && selectedApps.length < availableApps.length;


  filteredMembers = members.filter((member) => {
    // reject member unless they can satisfy all applied filters
    if (shouldFilterNameEmail && !matchNameOrEmail(member, filters.search)) {
      return false;
    }
    if (shouldFilterRoles && !selectedRoles.includes(member.dashboardRole)) {
      return false;
    }
    if (shouldFilterApps) {
      const memberApps = member.applications.reduce((acc, app) => {
        acc.add(app.id);
        return acc;
      }, new Set());
      if (!selectedApps.every(app => memberApps.has(app))) {
        return false;
      }
    }
    return true;
  });
  return filteredMembers;
};

export default applyFilters;
