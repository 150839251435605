import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../../../../../services/auth/reducers';
import MfaSettings from './MfaSettings';


function mapStateToProps(state) {
  const {
    mfaObj,
    mfaEnabled,
    mfaInProgress,
    mfaError,
  } = state.auth;
  return {
    mfaObj,
    mfaEnabled,
    mfaInProgress,
    mfaError,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    mfaEnrollRequest, mfaVerificationRequest, mfaNewChallengeRequest,
  } = actions;

  return {
    actions: bindActionCreators({
      mfaEnrollRequest,
      mfaVerificationRequest,
      mfaNewChallengeRequest,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MfaSettings);
