import { styled } from '@mui/material';

const EmptyStateContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'condensed' && prop !== 'paperBackground',
})(({ theme, condensed, paperBackground }) => ({
  padding: theme.spacing(3),
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  ...(!condensed && { minHeight: 'calc(100vh - 236px)' }),
  ...(paperBackground && { background: theme.palette.background.paper }),
}));

export default EmptyStateContainer;
