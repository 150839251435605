import { call, put, takeEvery } from 'redux-saga/effects';

import api from '../../../../services/api/api';
import { actions as errorActions } from '../../../../services/errorReporter/reducers';
import { types, actions } from './reducers';

export function* regenerateSecret(action) {
  try {
    const newSecretResponse = yield call(
      api.regenerateSecret,
      action.meta,
    );
    yield put(actions.regenerateSecretSuccess(newSecretResponse.data.secret));
  } catch (error) {
    yield put(actions.regenerateSecretFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.REGENERATE_SECRET, regenerateSecret);
}
