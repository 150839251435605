import React from 'react';
import { shape, string } from 'prop-types';
import {
  ListItem,
  ListItemText as MuiListItemText,
  ListItemIcon as MuiListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material';
import { reportToSegment, eventNames, types } from '@smartcar/morse';

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const ListItemIcon = styled(MuiListItemIcon)({
  marginLeft: '4px',
});

export const handleTooltipOpen = text => () => {
  reportToSegment(types.TRACK, eventNames.tooltipOpened, {
    label: 'InfoOutlined icon',
    text,
  });
};

const InfoItem = ({ item }) => {
  const theme = useTheme();

  return (
    <ListItem disableGutters>
      <ListItemText>
        <Typography variant="bold">{item.text}</Typography>
        {item.tooltip && (
          <ListItemIcon>
            <Tooltip
              title={item.tooltip}
              arrow
              placement="top"
              onOpen={handleTooltipOpen(item.tooltip)}
            >
              <InfoOutlined
                htmlColor={theme.palette.primary.main}
                fontSize="inherit"
              />
            </Tooltip>
          </ListItemIcon>
        )}
      </ListItemText>
    </ListItem>
  );
};

export default InfoItem;

InfoItem.propTypes = {
  item: shape({
    text: string.isRequired,
    tooltip: string,
  }).isRequired,
};
