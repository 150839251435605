import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PrivateRoute from './PrivateRoute';
import { actions } from '../../../../services/auth/reducers';

function mapDispatchToProps(dispatch) {
  const { setEmailToken } = actions;

  return {
    actions: bindActionCreators({
      setEmailToken,
    }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PrivateRoute);
