const staticText = {
  heading: 'Select vehicle',
  subheading: 'Select the vehicle you\'d like to simulate by choosing the Country, Make, Model and Year. Or look up a vehicle by VIN number (only available for US-based VINs at this time).',
  alignmentFormLabel: 'Search by',
  regionForm: {
    label: 'Country',
  },
  mmyForm: {
    heading: 'Make, Model, Year',
    label: 'Make, Model and Year',
    button: 'Search by MMY',
    errorFetchingRegionData: 'Sorry, we encountered an error fetching data for this region. Please select a different region or try again later.',
  },
  vinForm: {
    heading: 'US-based VIN',
    label: 'VIN number',
    vinButton: 'Search',
    errors: {
      invalid: 'Please enter a valid VIN.',
      incorrectModel: 'The selected model does not appear to match the VIN provided. Please confirm the correct model.',
      alreadyInUse: 'A vehicle with this VIN has already been created. Please try another VIN.',
      incompatible: 'Looks like this VIN is not compatible with Smartcar. Please try another or use the Make, Model, Year search.',
      noMMY: 'Looks like this VIN is compatible with Smartcar, however we are unable to determine vehicle details at this time. Please create your vehicle using the Make, Model, Year search instead.',
      apiError: 'We experienced an error checking compatibility of this VIN. Please search by Make, Model, and Year.',
    },
  },
  latencyTable: {
    headers: ['API Capability', 'Method', 'Endpoint', 'Latency Range', 'Average Latency'],
    unavailable: 'Unavailable',
    seconds: 'seconds',
    footnote: {
      text: 'Learn more about request latencies ',
      link: 'https://smartcar.com/docs/help/oem-latency',
    },
  },
  useVehicleButton: 'Use this vehicle',
  maxVehiclesUpgrade: "You've reached the maximum number of simulated vehicles. Use or delete an existing vehicle, or upgrade your account to create more.",
  maxVehiclesEnterprise: "You've reached the maximum number of simulated vehicles. Please use or delete an existing vehicle before creating a new one.",
  emailNotVerified: 'Please verify your email address before creating a simulated vehicle.',
};

export default staticText;
