import { connect } from 'react-redux';

import PermissionGate from './PermissionGate';
import { selectors as orgSelectors } from '../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  const { rolePermissions } = currentOrganization;

  return {
    rolePermissions,
  };
}

export default connect(mapStateToProps, null)(PermissionGate);
