import { styled } from '@mui/material/styles';

export const HeaderContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '85px',
  padding: theme.spacing(0, 2),
}));

export const HeaderContent = styled('div')(({ theme }) => ({
  maxWidth: '1000px',
  margin: theme.spacing(0, 'auto'),

  '& a': {
    display: 'inline-block',
    height: '100%',
    padding: theme.spacing(3, 0),
  },
}));
