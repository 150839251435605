import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';

const propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
};

const AuthenticatedRoute = ({
  component: Component,
  isAuthenticated,
  isPrivate,
  redirect,
  ...rest
}) => {
  // Render Component if:
  //   1. The user is not authenticated, and the component is public. OR
  //   2. The user is authenticated, and the component is private.
  // Otherwise, render Redirect.
  const renderComponent = (isAuthenticated === isPrivate);
  const render = props => (
    renderComponent ? <Component {...props} /> : <Redirect to={redirect} />
  );

  return (<Route {...rest} render={render} />);
};

AuthenticatedRoute.propTypes = propTypes;

export default AuthenticatedRoute;
