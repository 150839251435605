import React from 'react';
import { func } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import SupportForm from './components/SupportForm/SupportFormContainer';
import { Modal } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/application';


const SupportModal = ({
  toggleModal,
}) => {
  const closeModal = (withTracking = true) => {
    toggleModal(null);
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames.modalClosed, { label: 'contact support', title: staticText.supportModal.title });
    }
  };

  const content = (
    <SupportForm />
  );

  return (
    <Modal toggleModal={closeModal} content={content} title={staticText.supportModal.title} />
  );
};

export default SupportModal;

SupportModal.propTypes = {
  toggleModal: func.isRequired,
};
