import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Join from './Join';
import { actions } from '../../services/organizations/reducers';
import { actions as authActions } from '../../services/auth/reducers';

function mapDispatchToProps(dispatch) {
  const { verifyOrgInvite } = actions;
  const { setOauthFlowInvitationId } = authActions;

  return {
    actions: bindActionCreators({
      verifyOrgInvite,
      setOauthFlowInvitationId,
    }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Join);
