/* istanbul ignore file */


const convertKmToMiles = (km) => {
  return km * 0.6214;
};
const convertKpaToPsi = (kpa) => {
  return kpa * 0.145;
};
const convertLitersToGallons = (liters) => {
  return liters * 0.2642;
};

/**
 * If using imperial units, convert the value and then return the new value
 * as a string with its applicable units.
 *
 * If using metric, keep the original value and return it as a string
 * with its applicable units.
 */
const getValueAndUnits = (metricValue, unitType, useImperial) => {
  if (metricValue) {
    switch (unitType) {
      case 'distance': {
        return useImperial ? `${convertKmToMiles(metricValue).toFixed(1)} mi` : `${metricValue.toFixed(1)} km`;
      }
      case 'volume': {
        return useImperial ? `${convertLitersToGallons(metricValue).toFixed(1)} gal` : `${metricValue.toFixed(1)} L`;
      }
      case 'pressure': {
        return useImperial ? `${convertKpaToPsi(metricValue).toFixed(1)} psi` : `${metricValue.toFixed(1)} kPa`;
      }
      case 'percentage': {
        return `${Math.round(metricValue * 100)}%`;
      }
      case 'power': {
        return `${metricValue.toFixed(1)} kWh`;
      }
      default: {
        return metricValue;
      }
    }
  }
  return 'NULL';
};

export default getValueAndUnits;
