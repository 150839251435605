import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { string } from 'prop-types';

import { eventProps } from '../../../../propTypes';
import staticText from '../../../../../../../../localization/Application/ConnectInsights/connectOptions';

import { ConnectOptionsContainer, StyledAlert, StyledLink } from './styles';
import { gatedFeatureData } from '../../../../../../../../services/featureGate';

const ConnectOptions = ({ newestEvent, featureSetId }) => {
  const theme = useTheme();
  const billingPath = '/team/billing';

  const handleUpgradeClick = ({ feature }) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'article',
      style: 'cta primary',
      text: `upsell (${feature})`,
      path: billingPath,
    });
  };

  const renderAlert = ({
    text, linkText, path, handleClick,
  }) => (
    <StyledAlert
      severity="info"
    >
      <Typography display="inline">{text}</Typography>
      <StyledLink
        href={path}
        onClick={handleClick}
      >
        {linkText}
      </StyledLink>
    </StyledAlert>
  );

  return (
    <div>
      {staticText.labels.map((label) => {
        const paramValue = newestEvent[label.key];
        const noCustomerVehicleOwnerId = label.key === 'customerVehicleOwnerId' && !paramValue;
        return (
          <ConnectOptionsContainer key={label.key} hideBorder={noCustomerVehicleOwnerId}>
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontFamily: theme.typography.bold.fontFamily }}>
                {label.displayName}
              </Typography>
              {
                paramValue
                  ? (
                    <Typography>
                      {label.formatter
                        ? label.formatter(paramValue)
                        : paramValue
                      }
                    </Typography>
                  ) : (
                    <Typography sx={{ color: theme.palette.grey[500] }}>
                      {staticText.notAvailable}
                    </Typography>
                  )
              }
            </Box>
            {noCustomerVehicleOwnerId
              && renderAlert({
                text: staticText.userParamInfo,
                linkText: staticText.learnMoreButton.text,
                path: staticText.learnMoreButton.link,
              })
            }
            {label.gatedFeature && paramValue && gatedFeatureData(label.gatedFeature, featureSetId)
              && renderAlert({
                text: staticText.gatedText[label.gatedFeature],
                linkText: staticText.upgradeNow,
                path: billingPath,
                handleClick: handleUpgradeClick,
              })
            }
          </ConnectOptionsContainer>
        );
      })}
    </div>
  );
};

export default ConnectOptions;

ConnectOptions.propTypes = {
  newestEvent: eventProps,
  featureSetId: string.isRequired,
};

ConnectOptions.defaultProps = {
  newestEvent: {},
};
