import React from 'react';
import { bool, string } from 'prop-types';
import { Box, Tooltip, Typography } from '@mui/material';

import { alertTriangleIcon } from '../../../../../../../../../../assets/icons';

const errorHint = {
  text: 'Inactive due to a repeated number of error responses from your callback URI.',
  children: { iconSrc: alertTriangleIcon, alt: 'error' },
};

const Status = ({ statusText, statusColor, hasError }) => (
  <Box display="flex" alignItems="center" zIndex={1}>
    {hasError ? (
      <Tooltip title={errorHint.text} placement="top" arrow>
        <img src={alertTriangleIcon} alt="error" />
      </Tooltip>
    ) : (
      <Box>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6" cy="6" r="6" fill={statusColor} />
        </svg>
      </Box>
    )}
    <Typography marginLeft={1}>{statusText}</Typography>
  </Box>
);

export default Status;

Status.propTypes = {
  statusText: string.isRequired,
  statusColor: string.isRequired,
  hasError: bool.isRequired,
};
