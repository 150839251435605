import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VehicleDetails from './VehicleDetails';
import { actions } from '../../reducers';
import { actions as vehicleActions } from './reducers';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    applicationId: state.applications.selectedApplication,
    featureSetId: orgSelectors.getCurrentOrganization(state).featureSetId,
    disconnectStatus: state.vehicles.disconnectStatus,
    ...state.vehicleDetails,
  };
}

function mapDispatchToProps(dispatch) {
  const { updateDisconnectStatus } = actions;
  const {
    fetchVehicleInfo,
    fetchVehicleLogs,
    fetchVehicleOverview,
    resetVehicleDetailsState,
    updateFilterValues,
    updateCurrentTab,
    fetchWebhooksInfoRequest,
  } = vehicleActions;

  return {
    actions: bindActionCreators(
      {
        fetchVehicleInfo,
        fetchVehicleLogs,
        fetchVehicleOverview,
        resetVehicleDetailsState,
        updateDisconnectStatus,
        updateFilterValues,
        updateCurrentTab,
        fetchWebhooksInfoRequest,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);
