import { node } from 'prop-types';
import React, { createContext, useState } from 'react';
import { resetOnboardingChecklist } from '../services/commandAi/commandAi';

const CommandAiContext = createContext();

const CommandAiProvider = ({ children }) => {
  const [isBooted, setIsBooted] = useState(false);
  const shutDownCommandAi = () => {
    resetOnboardingChecklist();
    window.CommandBar.shutdown();
    setIsBooted(false);
  };

  return (
    <CommandAiContext.Provider value={{ isBooted, setIsBooted, shutDownCommandAi }}>
      {children}
    </CommandAiContext.Provider>
  );
};

export { CommandAiProvider, CommandAiContext };

CommandAiProvider.propTypes = {
  children: node.isRequired,
};
