/* eslint-disable react/forbid-prop-types */
/* istanbul ignore file */
import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import { Grid, Divider, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import staticText from '../../../../../../../../localization/Application/connect-config';

import { Checkbox, PermissionGate } from '../../../../../../../../components';
import BrandOptIn from '../BrandOptIn/BrandOptIn';
import EngineFilter from './components/EngineFilter';

const BrandList = ({
  disabledBrands,
  defaultSupportedBrands,
  selectedMakes,
  updateSelectedMakes,
  selectedBrandManagementFilters,
  selectedNewBrandOptIn,
  updateCustomizations,
  engineFilterIsGated,
  selectBrandsIsGated,
  featureGatedMessage,
}) => {
  const theme = useTheme();

  const handleChange = (event) => {
    let updatedCheckboxList = [...selectedMakes];

    if (event.target.checked) {
      updatedCheckboxList = [...selectedMakes, event.target.name];
    } else {
      updatedCheckboxList.splice(selectedMakes.indexOf(event.target.name), 1);
    }

    updateSelectedMakes(updatedCheckboxList);
  };

  const selectOrClearAll = ({ target: { checked } }) => {
    if (checked) {
      const updatedMakes = defaultSupportedBrands
        .filter(make => !disabledBrands.includes(make.make))
        .map(make => make.make);
      updateSelectedMakes(updatedMakes);
    } else {
      updateSelectedMakes([]);
    }

    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: '[connect config - brand management] - Select all',
    });
  };

  // isAllSelected & isSomeSelected needed to determine Select All checkbox icon
  const updateSelectAllCheckbox = () => {
    const filteredApplicationMakes = defaultSupportedBrands
      .filter(make => !disabledBrands.includes(make.make))
      .map(make => make.make);

    const isAllSelected =
      filteredApplicationMakes.length === selectedMakes.length &&
      filteredApplicationMakes.every(make => selectedMakes.includes(make));
    const isSomeSelected = selectedMakes.length > 0;

    return {
      isAllSelected,
      isSomeSelected,
    };
  };

  const {
    isAllSelected,
    isSomeSelected,
  } = updateSelectAllCheckbox();

  return (
    <React.Fragment>
      <PermissionGate dashboardPermission="write_connect_configuration" disabled>
        <EngineFilter
          selectedBrandManagementFilters={selectedBrandManagementFilters}
          updateCustomizations={updateCustomizations}
          disabled={engineFilterIsGated}
        />
      </PermissionGate>
      {featureGatedMessage && featureGatedMessage}
      <PermissionGate dashboardPermission="write_connect_configuration" disabled>
        <BrandOptIn
          updateCustomizations={updateCustomizations}
          selectedNewBrandOptIn={selectedNewBrandOptIn}
          disabled={selectBrandsIsGated}
        />
      </PermissionGate>
      <div>
        <PermissionGate dashboardPermission="write_connect_configuration" disabled>
          <Checkbox
            inputName="selectAll"
            text={staticText.brandManagement.selectAll}
            handleChange={selectOrClearAll}
            checked={isAllSelected && isSomeSelected}
            partiallySelected={isSomeSelected}
            disabled={selectBrandsIsGated}
            labelVariant="bold"
          />
        </PermissionGate>
        <Divider sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }} />
        {defaultSupportedBrands.length > 0 &&
          <Grid
            container
            spacing={theme.spacing(2)}
            sx={{
              gridTemplateColumns: 'repeat(2, 1fr)',
              textAlign: 'left',
              textWrap: 'nowrap',
            }}
          >
            {defaultSupportedBrands.map(applicationMake => (
              <Grid
                key={applicationMake.make}
                item
                xs={6}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  fontSize: '14px',
                  marginTop: '10px',
                }}
              >
                <PermissionGate dashboardPermission="write_connect_configuration" disabled>
                  <Checkbox
                    inputName={applicationMake.make}
                    text={applicationMake.displayName}
                    handleChange={handleChange}
                    checked={selectedMakes.includes(applicationMake.make)}
                    disabled={selectBrandsIsGated || disabledBrands.includes(applicationMake.make)}
                    icon={InfoOutlined}
                    disabledIcon={disabledBrands.includes(applicationMake.make)}
                    disabledIconText={staticText.brandManagement.disabledIconText}
                  />
                </PermissionGate>
              </Grid>
            ))}
          </Grid>
        }
      </div>
    </React.Fragment>
  );
};

export default BrandList;

BrandList.propTypes = {
  defaultSupportedBrands: arrayOf(shape({
    make: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  selectedMakes: arrayOf(string),
  updateSelectedMakes: func.isRequired,
  updateCustomizations: func.isRequired,
  selectedNewBrandOptIn: bool.isRequired,
  disabledBrands: arrayOf(string).isRequired,
  selectedBrandManagementFilters: object.isRequired,
  engineFilterIsGated: bool.isRequired,
  selectBrandsIsGated: bool.isRequired,
  featureGatedMessage: node,
};

BrandList.defaultProps = {
  selectedMakes: [],
  featureGatedMessage: null,
};
