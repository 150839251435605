import { ChevronDown } from 'react-feather';

export const selectFilters = [
  {
    filterKey: 'roles',
    formTitle: 'Roles',
    Icon: ChevronDown,
    id: 'filter-roles',
    columns: 4,
    minWidth: '130px',
  },
  {
    filterKey: 'access',
    formTitle: 'Applications',
    Icon: ChevronDown,
    id: 'filter-access',
    columns: 2,
    minWidth: '140px',
  },
];

export const staticText = {
  resetAll: 'Reset all',
  searchLabel: 'Search',
};
