import React from 'react';
import { arrayOf, bool, func, string, object, element } from 'prop-types';
import { Button, styled } from '@mui/material';

import { PermissionGate } from '..';

export const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),

  '.MuiFormControl-root': {
    width: '100%',
  },
  '.MuiInput-root': {
    width: '100%',
    marginTop: theme.spacing(1.5),
  },

  '& .button-wrapper': {
    alignSelf: 'flex-end',
    width: 'auto',
    marginTop: theme.spacing(4),
  },
}));

/**
 * The `Form` should be used along with the `useForm` hook and can wrap any
 * combination of input types. It is recommended to wrap each input
 * within a `FormControlWrapper` component for additional label and
 * error handling logic.
 *
 * @param {object} props - Props for the Form component
 * @param {React.ReactNode} props.children - Child components within form, usually inputs
 * @param {function} props.handleSubmit - Function to call when the form is submitted
 * @param {boolean} [props.disableSubmit=false] - State managed and validated via useForm component
 * @param {string} [props.submitButtonLabel='Save'] - Label for the submit button
 * @param {string} [props.permission=''] - Dashboard role permission required to submit the form
 * @returns {React.ReactElement} The Form component
 */
const Form = ({
  children, handleSubmit, disableSubmit, submitButtonLabel, permission, upgradeCta,
}) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      {children}
      <div className="button-wrapper">
        {upgradeCta ||
          <PermissionGate dashboardPermission={permission} disabled>
            <Button
              id="settings-form-submit"
              type="submit"
              variant="contained"
              disabled={disableSubmit}
              onClick={handleSubmit}
            >
              {submitButtonLabel}
            </Button>
          </PermissionGate>
        }
      </div>
    </StyledForm>
  );
};

export default Form;

Form.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: arrayOf(object).isRequired,
  handleSubmit: func.isRequired,
  disableSubmit: bool,
  submitButtonLabel: string,
  permission: string,
  upgradeCta: element,
};

Form.defaultProps = {
  disableSubmit: false,
  submitButtonLabel: 'Save',
  permission: '',
  upgradeCta: null,
};
