import _ from 'lodash';
import errorMessages from './errorMessages.json';

export default function isValid(name, appNames = []) {
  const trimmedName = _.trim(name);

  if (trimmedName.length !== name.length) {
    throw new Error(errorMessages.spaceError);
  }

  if (trimmedName.length < 2 || trimmedName.length > 64) {
    throw new Error(errorMessages.lengthError);
  }

  const lowercaseNames = _.map(appNames, _.toLower);

  if (_.includes(lowercaseNames, _.toLower(name))) {
    throw new Error(errorMessages.duplicateName);
  }

  return true;
}
