import React from 'react';
import { func, string, arrayOf, shape } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { Modal, VerifyEmailModalContent, Feedback } from '../../../../../../components';
import RoleSelect from '../RoleSelect';
import useHandleRole from '../../utils/useHandleRole';

import {
  ButtonContainer,
  StyledButton,
} from '../shared/styles';

import staticText from '../../../../../../localization/Application/Members/inviteEditUsers';
import errors from '../../../../../../localization/Application/Members/errors';
import useIsEnterprise from '../../utils/useIsEnterprise';
import MultiAppSelect, { useMultiAppSelect } from '../MultiAppSelect';
import { getOrganizationRole, organizationRole as orgRoleConstants, dashboardRole as dashRoleConstants } from '../../utils/roles';
import verifyText from '../../../../../../localization/Application/Billing/verifyEmailModal';

const EditUserModal = ({
  apps,
  user,
  developerEmailVerifiedAt,
  closeModal,
  dashboardPermissionRoles,
  updateOrgMember,
  featureSetId,
  organizationRole,
  fetchRolesError,
}) => {
  const {
    roleError,
    setRoleError,
    selectedRole,
    handleRoleClick,
  } = useHandleRole(user.dashboardRole);

  const appIds = apps.map(app => app.id);
  const userAppIds = user.applications.map(app => app.id);

  const {
    allAppsSelected,
    appAccessError,
    selectedApps,
    handleAppChange,
    handleAppDelete,
    handleToggleAll,
    setAppAccessError,
  } = useMultiAppSelect(appIds, userAppIds);

  const { canAccessRoles } = useIsEnterprise(featureSetId, selectedRole);
  const sameAppSelection = (previousSelection, currentSelection) => {
    if (previousSelection.length !== currentSelection.length) return false;
    const previousAppIds = new Set(previousSelection);
    return currentSelection.every(appId => previousAppIds.has(appId));
  };

  /* istanbul ignore next */
  const handleSubmit = () => {
    if (user.originalOwner === true) {
      setRoleError(errors.cannotEditOwner);
    } else if (selectedRole === dashRoleConstants.OWNER &&
      organizationRole !== orgRoleConstants.FULL_ACCESS) {
      setRoleError(errors.noPermissionToInviteOwner);
    } else if (selectedApps.length === 0) {
      setAppAccessError(errors.selectAppAccess);
    } else {
      const orgRole = getOrganizationRole(selectedRole, allAppsSelected);
      const applicationIds = selectedApps;
      updateOrgMember({
        selectedRole, dashboardUserId: user.id, organizationRole: orgRole, applicationIds,
      });
      closeModal();
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'edit member',
        form_content: {
          dashboardUserId: user.id,
          selectedRole,
          organizationRole: orgRole,
          applicationIds,
        },
      });
    }
  };

  const content = developerEmailVerifiedAt ? (
    <div>
      <Typography marginBottom={2}>{staticText.role}</Typography>
      {fetchRolesError
        ?
          <Typography variant="caption" color="error">
            {fetchRolesError}
          </Typography>
        :
          <RoleSelect
            dashboardPermissionRoles={dashboardPermissionRoles}
            handleRole={handleRoleClick}
            selectedRole={selectedRole}
            error={roleError}
          />
      }
      {
        !canAccessRoles &&
          <Feedback
            type="alert"
            message={staticText.rolesGatedText}
          />
      }
      <Box marginY={3}>
        <Typography marginBottom={2}>
          {staticText.accessLevel}
        </Typography>
        <MultiAppSelect
          availableApps={apps}
          error={appAccessError}
          selectedApps={selectedApps}
          selectedRole={selectedRole}
          handleChange={handleAppChange}
          handleAppDelete={handleAppDelete}
          toggleAll={handleToggleAll}
        />
      </Box>
      <ButtonContainer>
        <StyledButton
          variant="outlined"
          size="large"
          onClick={closeModal}
        >
          {staticText.cancelButton}
        </StyledButton>
        <StyledButton
          id="update-user-button"
          variant="contained"
          size="large"
          /* istanbul ignore next */
          disabled={
            (selectedRole === user.dashboardRole
              && sameAppSelection(userAppIds, selectedApps))
            || Boolean(roleError)
            || !canAccessRoles
            || (selectedRole === 'editor' && allAppsSelected)
            || fetchRolesError
          }
          onClick={handleSubmit}
        >
          {staticText.updateButton}
        </StyledButton>
      </ButtonContainer>
    </div>
  ) : (
  /* istanbul ignore next */
    <VerifyEmailModalContent openingText={staticText.emailVerificationRequired} />
  );
  return (
    <Modal
      title={developerEmailVerifiedAt ? `${user.name} (${user.email})` : verifyText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default EditUserModal;

EditUserModal.propTypes = {
  apps: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  developerEmailVerifiedAt: string,
  closeModal: func.isRequired,
  updateOrgMember: func.isRequired,
  user: shape({
    id: string.isRequired,
    email: string.isRequired,
    name: string,
    dashboardRole: string.isRequired,
    organizationRole: string.isRequired,
    applications: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })),
  }).isRequired,
  dashboardPermissionRoles: arrayOf(
    shape({
      name: string,
      displayName: string,
      description: string,
    }),
  ).isRequired,
  featureSetId: string.isRequired,
  organizationRole: string.isRequired,
  fetchRolesError: string.isRequired,
};

EditUserModal.defaultProps = {
  developerEmailVerifiedAt: '',
};
