/* istanbul ignore file */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { object, func, bool } from 'prop-types';

import {
  FormControl,
  useTheme,
  Typography,
  Box,
} from '@mui/material';
import staticText from '../../../../../../../../../../localization/Application/connect-config';
import { MenuItemWrapper, SelectIcon } from '../../../../../../../../../../components';

import { getEngineTypeFilterStatusAndTitle } from './utils';

import EngineFilterForm from '../EngineFilterForm/EngineFilterForm';

const EngineFilter = ({
  selectedBrandManagementFilters,
  updateCustomizations,
  disabled,
}) => {
  const theme = useTheme();
  const {
    isFilterActive,
    menuItemWrapperTitle,
  } = getEngineTypeFilterStatusAndTitle(
    selectedBrandManagementFilters,
  );

  return (
    <Box marginBottom={2}>
      <Typography
        variant="bold"
        color={disabled ? theme.palette.text.disabled : theme.palette.text.primary}
      >
        {staticText.brandManagement.filterByEngineType}
      </Typography>
      <FormControl sx={{
        margin: theme.spacing(1, 0),
        width: '100%',
      }}
      >
        <MenuItemWrapper
          title={menuItemWrapperTitle}
          isFilterActive={isFilterActive}
          Icon={SelectIcon}
          id="engine-type-filter"
          disabled={disabled}
        >
          <EngineFilterForm
            selectedBrandManagementFilters={selectedBrandManagementFilters}
            updateCustomizations={updateCustomizations}
          />
        </MenuItemWrapper>
      </FormControl>
    </Box>
  );
};

export default EngineFilter;

EngineFilter.propTypes = {
  selectedBrandManagementFilters: object.isRequired,
  updateCustomizations: func.isRequired,
  disabled: bool.isRequired,
};
