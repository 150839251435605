import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ResetPassword from './ResetPassword';
import { actions as ResetPasswordActions } from '../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { resetPasswordRequest, logoutRequest, resetErrors } = ResetPasswordActions;

  return {
    actions: bindActionCreators({
      resetPasswordRequest,
      logoutRequest,
      resetErrors,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
