import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from '../../../../services/auth/reducers';
import MfaEnroll from './MfaEnroll';

function mapStateToProps(state) {
  const {
    mfaEnabled,
    mfaInProgress,
    mfaObj,
    mfaRecoveryToken,
    mfaError,
    mfaRequiredForLogin,
  } = state.auth;
  return {
    mfaEnabled,
    mfaInProgress,
    mfaObj,
    mfaRecoveryToken,
    mfaError,
    mfaRequiredForLogin,
  };
}

function mapDispatchToProps(dispatch) {
  const { mfaLoginVerificationRequest } = actions;

  return {
    actions: bindActionCreators({
      mfaLoginVerificationRequest,
    }, dispatch),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MfaEnroll);
