import dateUtils from '../../../../Overview/utils/dateUtils';
import { errors } from '../../../../../../../localization/Application/VehicleManagement/vehicleManagement';

const validateFilterValues = (filterValues, logRestrictions) => {
  const validated = {};

  try {
    if (filterValues.start === null || filterValues.end === null) {
      throw new Error(errors.datesRequired);
    }

    const { minDate, maxDate } = logRestrictions;
    if (dateUtils.isBefore(filterValues.start, minDate)) {
      throw new Error(errors.outOfRange('start'));
    }
    if (dateUtils.isAfter(filterValues.end, maxDate)) {
      throw new Error(errors.outOfRange('end'));
    }

    return validated;
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export default validateFilterValues;
