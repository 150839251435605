const OWNER = 'owner';
const ADMIN = 'admin';
const EDITOR = 'editor';
const VIEWER = 'viewer';

const FULL_ACCESS = 'full_access';
const ALL_APPS = 'all_apps';
const SPECIFIC_APPS = 'specific_apps';

export const dashboardRole = {
  OWNER, ADMIN, EDITOR, VIEWER,
};

export const gatedTeamRoles = [ADMIN, EDITOR, VIEWER];

/**
 * We'll temporarily hard code this until Teams v1 is deprecated
 * and Product has decided on final copy changes
 * Server will need to remove the filtering of owner on the dashboardRoles route
 */

export const dashboardRoles = [
  {
    name: OWNER,
    displayName: 'Owner',
  },
  {
    name: ADMIN,
    displayName: 'Admin',
  },
  {
    name: EDITOR,
    displayName: 'Editor',
  },
  {
    name: VIEWER,
    displayName: 'Viewer',
  },
];

export const organizationRole = { FULL_ACCESS, ALL_APPS, SPECIFIC_APPS };

export const canAccessAllApps = (role) => {
  return [OWNER, ADMIN].includes(role);
};

export const getRoleDisplayName = (role) => {
  const foundRole = dashboardRoles.find(r => r.name === role);
  return foundRole ? foundRole.displayName : role;
};

export const getOrganizationRole = (selectedRole, allAppsSelected) => {
  if (selectedRole === OWNER) {
    return FULL_ACCESS;
  } else if (selectedRole === ADMIN) {
    return ALL_APPS;
  } else if (selectedRole === EDITOR) {
    return SPECIFIC_APPS;
  }
  // viewer
  return allAppsSelected ? ALL_APPS : SPECIFIC_APPS;
};
