import _ from 'lodash';
import { errors } from '../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import { errorTypes, requestMethods } from '../../../../../../../config/requestLogs';

const initialErrorTypes = Object.fromEntries(errorTypes.sort().map(type => [type, true]));
const initialRequestMethods =
  Object.fromEntries(requestMethods.vehicleEndpoints.sort().map(method => [method, true]));

export const PAGE_SIZE = 10;

export const types = {
  FETCH_VEHICLE_LOGS_REQUEST: 'developer-client/vehicle-management/details/FETCH_VEHICLE_LOGS_REQUEST',
  FETCH_VEHICLE_LOGS_SUCCESS: 'developer-client/vehicle-management/details/FETCH_VEHICLE_LOGS_SUCCESS',
  FETCH_VEHICLE_LOGS_FAILURE: 'developer-client/vehicle-management/details/FETCH_VEHICLE_LOGS_FAILURE',

  FETCH_VEHICLE_INFO_REQUEST: 'developer-client/vehicle-management/details/FETCH_VEHICLE_INFO_REQUEST',
  FETCH_VEHICLE_INFO_SUCCESS: 'developer-client/vehicle-management/details/FETCH_VEHICLE_INFO_SUCCESS',
  FETCH_VEHICLE_INFO_FAILURE: 'developer-client/vehicle-management/details/FETCH_VEHICLE_INFO_FAILURE',

  FETCH_VEHICLE_OVERVIEW_REQUEST: 'developer-client/vehicle-management/details/FETCH_VEHICLE_OVERVIEW_REQUEST',
  FETCH_VEHICLE_OVERVIEW_SUCCESS: 'developer-client/vehicle-management/details/FETCH_VEHICLE_OVERVIEW_SUCCESS',
  FETCH_VEHICLE_OVERVIEW_FAILURE: 'developer-client/vehicle-management/details/FETCH_VEHICLE_OVERVIEW_FAILURE',

  RESET_VEHICLE_DETAILS_STATE: 'developer-client/vehicle-management/details/RESET_VEHICLE_DETAILS_STATE',
  UPDATE_FILTER_VALUES: 'developer-client/vehicle-management/details/UPDATE_FILTER_VALUES',
  UPDATE_PAGINATION_MODEL: 'developer-client/vehicle-management/details/UPDATE_PAGINATION_MODEL',
  UPDATE_OVERVIEW: 'developer-client/vehicle-management/details/UPDATE_OVERVIEW',

  FETCH_WEBHOOKS_INFO_REQUEST: 'developer-client/vehicle-management/details/FETCH_WEBHOOKS_INFO_REQUEST',
  FETCH_WEBHOOKS_INFO_SUCCESS: 'developer-client/vehicle-management/details/FETCH_WEBHOOKS_INFO_SUCCESS',
  FETCH_WEBHOOKS_INFO_FAILURE: 'developer-client/vehicle-management/details/FETCH_WEBHOOKS_INFO_FAILURE',

  UPDATE_CURRENT_TAB: 'developer-client/vehicle-management/details/UPDATE_CURRENT_TAB',
};

export const initialState = {
  isFetchingInitialLogs: true,
  isFetchingVehicleInfo: true,
  isFetchingVehicleLogs: false,
  isFetchingVehicleOverview: true,
  isFetchingWebhooksInfo: false,
  filterValues: {
    start: null,
    end: null,
    errorTypes: initialErrorTypes,
    requestMethods: initialRequestMethods,
  },
  overview: {},
  webhooksInfo: {},
  pageInfo: {},
  paginationModel: {
    page: 0,
    pageSize: PAGE_SIZE,
  },
  vehicleLogsErrors: {
    info: '',
    logs: '',
    overview: '',
    webhooks: '',
  },
  currentTab: 0,
};

export const actions = {
  fetchVehicleInfo: vehicleId => ({
    type: types.FETCH_VEHICLE_INFO_REQUEST,
    payload: vehicleId,
  }),
  fetchVehicleInfoSuccess: data => ({
    type: types.FETCH_VEHICLE_INFO_SUCCESS,
    payload: data,
  }),
  fetchVehicleInfoFailure: err => ({
    type: types.FETCH_VEHICLE_INFO_FAILURE,
    payload: err,
  }),

  fetchVehicleLogs: ({ vehicleId, filterValues, newPaginationModel }) => ({
    type: types.FETCH_VEHICLE_LOGS_REQUEST,
    payload: { filterValues, newPaginationModel },
    meta: vehicleId,
  }),
  fetchVehicleLogsSuccess: data => ({
    type: types.FETCH_VEHICLE_LOGS_SUCCESS,
    payload: data,
  }),
  fetchVehicleLogsFailure: err => ({
    type: types.FETCH_VEHICLE_LOGS_FAILURE,
    payload: err,
  }),

  fetchVehicleOverview: vehicleId => ({
    type: types.FETCH_VEHICLE_OVERVIEW_REQUEST,
    payload: vehicleId,
  }),
  fetchVehicleOverviewSuccess: data => ({
    type: types.FETCH_VEHICLE_OVERVIEW_SUCCESS,
    payload: data,
  }),
  fetchVehicleOverviewFailure: err => ({
    type: types.FETCH_VEHICLE_OVERVIEW_FAILURE,
    payload: err,
  }),

  resetVehicleDetailsState: () => ({
    type: types.RESET_VEHICLE_DETAILS_STATE,
  }),
  updateFilterValues: filterValues => ({
    type: types.UPDATE_FILTER_VALUES,
    payload: filterValues,
  }),
  updateOverview: overview => ({
    type: types.UPDATE_OVERVIEW,
    payload: overview,
  }),
  updatePaginationModel: model => ({
    type: types.UPDATE_PAGINATION_MODEL,
    payload: model,
  }),
  updateCurrentTab: data => ({
    type: types.UPDATE_CURRENT_TAB,
    payload: data,
  }),
  fetchWebhooksInfoRequest: vehicleId => ({
    type: types.FETCH_WEBHOOKS_INFO_REQUEST,
    payload: vehicleId,
  }),
  fetchWebhooksInfoSuccess: data => ({
    type: types.FETCH_WEBHOOKS_INFO_SUCCESS,
    payload: data,
  }),
  fetchWebhooksInfoFailure: error => ({
    type: types.FETCH_WEBHOOKS_INFO_FAILURE,
    payload: error,
  }),
};

export const selectors = {
  getRowCount: (state) => {
    return _.get(state, ['vehicleDetails', 'pageInfo', 'rowCount']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_VEHICLE_INFO_REQUEST:
      return {
        ...state,
        isFetchingVehicleInfo: true,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          info: '',
        },
      };
    case types.FETCH_VEHICLE_INFO_SUCCESS:
      return {
        ...state,
        isFetchingVehicleInfo: false,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    case types.FETCH_VEHICLE_INFO_FAILURE:
      return {
        ...state,
        isFetchingVehicleInfo: false,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          info: (action.payload && action.payload.message) || errors.vehicleInfo,
        },
      };

    case types.FETCH_VEHICLE_LOGS_REQUEST:
      return {
        ...state,
        isFetchingVehicleLogs: true,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          logs: '',
        },
      };
    case types.FETCH_VEHICLE_LOGS_SUCCESS:
      return {
        ...state,
        isFetchingInitialLogs: false,
        isFetchingVehicleLogs: false,
        pageInfo: action.payload,
      };
    case types.FETCH_VEHICLE_LOGS_FAILURE:
      return {
        ...state,
        isFetchingInitialLogs: false,
        isFetchingVehicleLogs: false,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          logs: (action.payload && action.payload.message) || errors.vehicleLogs,
        },
      };

    case types.FETCH_VEHICLE_OVERVIEW_REQUEST:
      return {
        ...state,
        isFetchingVehicleOverview: true,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          overview: '',
        },
      };
    case types.FETCH_VEHICLE_OVERVIEW_SUCCESS:
      return {
        ...state,
        isFetchingVehicleOverview: false,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    case types.FETCH_VEHICLE_OVERVIEW_FAILURE:
      return {
        ...state,
        isFetchingVehicleOverview: false,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          overview: (action.payload && action.payload.message) || errors.vehicleOverview,
        },
      };

    case types.RESET_VEHICLE_DETAILS_STATE:
      return initialState;
    case types.UPDATE_PAGINATION_MODEL:
      return {
        ...state,
        paginationModel: action.payload,
      };
    case types.UPDATE_OVERVIEW:
      return {
        ...state,
        overview: action.payload,
      };
    case types.UPDATE_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };
    case types.UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case types.FETCH_WEBHOOKS_INFO_REQUEST:
      return {
        ...state,
        isFetchingWebhooksInfo: true,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          webhooks: '',
        },
      };
    case types.FETCH_WEBHOOKS_INFO_SUCCESS:
      return {
        ...state,
        isFetchingWebhooksInfo: false,
        webhooksInfo: action.payload.webhooksInfo,
      };
    case types.FETCH_WEBHOOKS_INFO_FAILURE:
      return {
        ...state,
        isFetchingWebhooksInfo: false,
        vehicleLogsErrors: {
          ...state.vehicleLogsErrors,
          webhooks: errors.webhooksInfo,
        },
      };
    default:
      return state;
  }
}

