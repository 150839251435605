import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import {
  Divider,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  AnchorIcon,
  AppsIcon,
  BillingIcon,
  CubeIcon,
  CustomizationsIcon,
  HomeIcon,
  KeyIcon,
  LogsIcon,
  ProfileIcon,
  SecurityIcon,
  SettingsIcon,
  SmartphoneIcon,
  TeamIcon,
  VehicleIcon,
} from '../../../../assets/icons/nav';
import { Toast } from '../../../../components';
import { applicationNav, settingsNav } from '../../../../localization/Application/nav';
import { capitalizeFirstLetter } from '../../utils';
import { HoverText, Nav, NavHeading, NavItem, NavToggle, SubNavWrapper } from './styles';

const iconMap = {
  applications: <AppsIcon />,
  billing: <BillingIcon />,
  configuration: <KeyIcon />,
  connect: <SmartphoneIcon />,
  customizations: <CustomizationsIcon />,
  logs: <LogsIcon />,
  members: <TeamIcon />,
  overview: <HomeIcon />,
  profile: <ProfileIcon />,
  security: <SecurityIcon />,
  settings: <SettingsIcon />,
  simulator: <CubeIcon />,
  webhooks: <AnchorIcon />,
  vehicles: <VehicleIcon />,
};

const getSubNavLink = (
  billingWarning,
  expanded,
  getNavLink,
  handleNavLinkClick,
  parentText,
  path,
  pathParts,
  closeMenu,
) => (subNavItem) => {
  const Component = expanded ? NavItem : MenuItem;
  const subNavPath = getNavLink(undefined, `${parentText}/${subNavItem.text}`);
  const handleSubNavLinkClick = (e) => {
    handleNavLinkClick(e, subNavItem, path);
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <Component
      id={`nav-${subNavItem.text}`}
      key={subNavItem.text}
      className={`${expanded ? 'sub-nav ' : ''}text`}
      newfeature={subNavItem.newfeature}
      current={pathParts.has(subNavItem.text.toLocaleLowerCase())}
      error={billingWarning && subNavItem.text === 'billing'}
      {...(!expanded && { component: NavItem })}
    >
      <Link
        to={subNavPath}
        onClick={handleSubNavLinkClick}
      >
        <Typography>{capitalizeFirstLetter(subNavItem.text)}</Typography>
      </Link>
    </Component>
  );
};

const SidebarNav = ({
  applicationId,
  pathname,
  menuExpanded,
  toggleExpandedMenu,
  view,
  authErrorMessage,
  billingInfo,
}) => {
  const theme = useTheme();
  const [subNavAnchorEl, setSubNavAnchorEl] = useState(null);
  const pathParts = new Set(pathname.split('/'));
  const isApplicationNav = view === 'apps';

  const navItems = isApplicationNav ? applicationNav : settingsNav;

  const getNavLink = (heading, path) => {
    return isApplicationNav ? `/apps/${applicationId}/${path}` : `/${heading}/${path}`;
  };

  const handleNavLinkClick = (e, text, path) => {
    // we also have a click listener on the nav container itself to toggle
    // expanding the nav, so this prevents that from firing when a link is clicked
    e.stopPropagation();
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'sidebar', style: 'inline', path, text,
    });
  };

  const billingWarning = Boolean(billingInfo.vehiclesOverLimit) ||
    Boolean(billingInfo.numOfApiViolationVehicles) ||
    Boolean(billingInfo.delinquent);

  useEffect(() => {
    if (authErrorMessage) {
      Toast(authErrorMessage, 'warn');
    }
  }, [authErrorMessage]);

  return (
    <Nav aria-label="secondary" expanded={menuExpanded} onClick={toggleExpandedMenu}>
      <NavToggle
        type="button"
        tabIndex={0}
        onClick={toggleExpandedMenu}
        aria-expanded={menuExpanded}
        menuExpanded={menuExpanded}
      >
        <NavigateNextRoundedIcon htmlColor={theme.palette.grey[600]} />
      </NavToggle>
      {navItems && navItems.map(({
        heading, navLinks,
      }, index) => {
        return (
          <React.Fragment key={heading}>
            {(!isApplicationNav && menuExpanded) &&
              <NavHeading variant="h4">{heading}</NavHeading>
            }
            {!isApplicationNav && !menuExpanded && index !== 0 &&
              <Divider sx={{ margin: theme.spacing(-1.5, 0, 1.5) }} />
            }
            <ul>
              {navLinks && navLinks.map(({
                text, icon, newfeature, subNav,
              }) => {
                const path = getNavLink(heading, text);
                return (
                  <React.Fragment key={text}>
                    <NavItem
                      className={menuExpanded ? 'text' : 'icon'}
                      newfeature={newfeature}
                      current={pathParts.has(text.toLocaleLowerCase())}
                      error={billingWarning && text === 'billing'}
                      id={`nav-${text}`}
                    >
                      {(!subNav || menuExpanded) ? (
                        <Link to={path} onClick={e => handleNavLinkClick(e, text, path)}>
                          {iconMap[text] ? iconMap[text] : <img src={icon} alt="" />}
                          {menuExpanded && <Typography>{capitalizeFirstLetter(text)}</Typography>}
                          {!menuExpanded && (
                            <HoverText className="hover-text">
                              {capitalizeFirstLetter(text)}
                            </HoverText>
                          )}
                        </Link>
                      ) : (
                        <React.Fragment>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSubNavAnchorEl(e.currentTarget);
                            }}
                          >
                            {iconMap[text] ? iconMap[text] : <img src={icon} alt="" />}
                            <HoverText className="hover-text">
                              {capitalizeFirstLetter(text)}
                            </HoverText>
                          </button>
                          <Menu
                            anchorEl={subNavAnchorEl}
                            anchorOrigin={{
                              horizontal: 'right',
                            }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                            open={Boolean(subNavAnchorEl)}
                            onClose={(e) => {
                              e.stopPropagation();
                              setSubNavAnchorEl(null);
                            }}
                            sx={{
                              '.MuiList-root': {
                                padding: theme.spacing(0.5, 1.5),
                                width: '140px',
                              },
                              marginLeft: '11px',
                            }}
                            onClick={e => e.stopPropagation()}
                          >
                            {subNav.map(
                              getSubNavLink(
                                billingWarning,
                                menuExpanded,
                                getNavLink,
                                handleNavLinkClick,
                                text,
                                path,
                                pathParts,
                                () => setSubNavAnchorEl(null),
                              ),
                            )}
                          </Menu>
                        </React.Fragment>
                      )}
                    </NavItem>
                    {/* subnav list items when sidebar is expanded */}
                    {subNav && menuExpanded && (
                      <SubNavWrapper>
                        {subNav.map(
                          getSubNavLink(
                            billingWarning,
                            menuExpanded,
                            getNavLink,
                            handleNavLinkClick,
                            text,
                            path,
                            pathParts,
                          ),
                        )}
                      </SubNavWrapper>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </React.Fragment>
        );
      })}
    </Nav>
  );
};

export default SidebarNav;

SidebarNav.propTypes = {
  applicationId: string.isRequired,
  pathname: string.isRequired,
  menuExpanded: bool.isRequired,
  toggleExpandedMenu: func.isRequired,
  view: string.isRequired,
  authErrorMessage: string,
  billingInfo: shape({
    vehiclesOverLimit: number.isRequired,
    numOfApiViolationVehicles: number,
  }).isRequired,
};

SidebarNav.defaultProps = {
  authErrorMessage: '',
};
