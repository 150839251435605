import _ from 'lodash';

const generate12Hours = (type) => {
  const hours = _.times(12, (i) => {
    const hour = `${i + 1}:00${type}`;
    return { text: hour, name: hour };
  });
    // Pushes 12 to beginning of array.
  hours.unshift(hours.pop());
  return hours;
};

const generateHours = () => {
  const amHours = generate12Hours('AM');
  const pmHours = generate12Hours('PM');
  return amHours.concat(pmHours);
};

const generateHourIntervals = () => {
  return new Array(23).fill().map((item, index) => {
    const text = `Every ${index + 1} ${index === 0 ? 'Hour' : 'Hours'}`;
    return {
      text,
      name: text,
    };
  });
};

const generateDays = () => {
  return _.times(31, i => ({
    text: `${i + 1}`,
    name: `${i + 1}`,
  }));
};

export { generateHours, generateHourIntervals, generateDays };

