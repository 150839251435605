import React from 'react';
import { string, func, object, node, bool } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button } from '@mui/material';
import spinningButtonStyles from '../../../../global-styles/animations/spinningButton';
import InputField from '../../../InputField';

const staticText = {
  title: 'confirmation',
  button: 'Confirm',
  account: {
    input: 'Email',
    errorMessage: 'Email address does not match.',
    placeholder: 'Email',
  },
  application: {
    input: 'Application Name',
    errorMessage: 'Application name does not match.',
    placeholder: 'e.g. Radiator Springs Fuel Delivery',
  },
  webhook: {
    input: 'Name',
    errorMessage: 'Webhook name does not match.',
    placeholder: 'Enter webhook name',
  },
  billingDowngrade: {
    input: 'Email',
    errorMessage: 'Email address does not match.',
    placeholder: email => `${email}`,
  },
};

export const defaultToggleModal = () => {};
export default class ValidateName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: false,
      attemptedSubmit: false,
      value: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange({ value: val }) {
    const { nameToMatch } = this.props;

    let value = val;
    // if a curly apostrophe is used instead of the standard ASCII character, catch it and change
    // it to the ASCII apostrophe so it will pass validation. This fixes a (mobile Safari) bug.
    const apostrophe = value.match(/\u2019/);
    const apostropheIndex = apostrophe ? apostrophe.index : -1;
    if (apostropheIndex > -1) {
      value = `${value.substring(0, apostropheIndex)}'${value.substring(
        apostropheIndex + 1,
        value.length,
      )}`;
    }
    if (value === nameToMatch) {
      this.setState({ isValid: true, value });
    } else {
      this.setState({ isValid: false, value });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      title, submitAction, toggleModal, nameType, segmentEventInfo,
    } = this.props;
    const { isValid, value } = this.state;

    if (isValid) {
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: `validate name - ${title}`,
        form_content: { value, ...segmentEventInfo },
      });

      submitAction();
      toggleModal(false);
    } else {
      reportToSegment(types.TRACK, eventNames.formFailed, {
        label: `validate name - ${title}`,
        form_content: {
          value,
          errorMessage: staticText[nameType].errorMessage,
        },
      });
      this.setState({
        attemptedSubmit: true,
      });
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    const { attemptedSubmit, isValid, value } = this.state;
    const {
      description, isLoading, nameType, additionalFields,
    } = this.props;

    const showErrorMessage = attemptedSubmit && !isValid;

    return (
      <div>
        <Box mb={2}>{description}</Box>
        <form onSubmit={this.handleSubmit}>
          <Box display="flex" flexDirection="column">
            <InputField
              inputName="validate-name-input"
              inputValues={value}
              handleChange={this.handleChange}
              InputProps={{ onKeyPress: this.handleKeyPress }}
              inputPlaceholder={staticText[nameType].input}
              helperText={nameType === 'billingDowngrade' ? staticText[nameType].placeholder(this.props.nameToMatch) : staticText[nameType].placeholder}
              errorMessage={showErrorMessage && staticText[nameType].errorMessage}
              autoFocus
            />
            {additionalFields}
            <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                id="validate-name-submit"
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                onClick={this.handleSubmit}
                sx={isLoading && spinningButtonStyles}
                disabled={isLoading}
              >
                {staticText.button}
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    );
  }
}

ValidateName.propTypes = {
  nameToMatch: string.isRequired,
  nameType: string,
  title: string.isRequired,
  toggleModal: func,
  submitAction: func.isRequired,
  description: node.isRequired,
  isLoading: bool,
  additionalFields: node,
  segmentEventInfo: object,
};

ValidateName.defaultProps = {
  nameType: 'application',
  isLoading: false,
  toggleModal: defaultToggleModal,
  additionalFields: null,
  segmentEventInfo: null,
};
