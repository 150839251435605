import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { StageWrapper, StageLabel, StageButton, StageTime, FuelIndicator } from '../../styles';
import { chargeIcon, fuelIcon } from '../../../../../../../../../../assets/icons';
import { PermissionGate } from '../../../../../../../../../../components';

const Stage = ({
  stage,
  index,
  handleTripAction,
  tripStarted,
  active,
  tripDuration,
  vehicleType,
  autoplay,
}) => {
  const theme = useTheme();
  const {
    label, startHour, startFrameIndex, charge, fuel,
  } = stage;

  const showFuelIndicator = (vehicleType === 'ICE' || vehicleType === 'HEV') && fuel;
  const showChargeIndicator = vehicleType === 'BEV' && charge;

  const handleStageClick = (frameIndex) => {
    handleTripAction('CHANGE_FRAME', frameIndex);
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      { label: 'backend action', text: '[simulator] change trip stage' },
    );
  };

  return (
    <StageWrapper
      key={index}
      leftOffset={`${(startFrameIndex / tripDuration) * 100}%`}
    >
      <StageLabel variant="bold">
        <Box sx={{
          width: '80px',
          textAlign: 'center',
          // adding some space between the cramped day_commute 'driving' and 'plugged in' labels
          paddingLeft: label[vehicleType] === 'Plugged in' && index > 0 ? '30px' : '0',
        }}
        >{label[vehicleType]}
        </Box>
        {showFuelIndicator && (
          <Tooltip
            title="Refuel stage"
            arrow
            placement="top"
          >
            <FuelIndicator>
              <img src={fuelIcon} alt="this stage includes a fuel up" />
            </FuelIndicator>
          </Tooltip>
        )}
        {showChargeIndicator && (
          <Tooltip
            title="Charge stage"
            arrow
            placement="top"
          >
            <FuelIndicator>
              <img src={chargeIcon} alt="this stage includes a charge" />
            </FuelIndicator>
          </Tooltip>
        )}
      </StageLabel>
      <PermissionGate dashboardPermission="write_simulator" disabled>
        <StageButton
          active={active}
          disabled={autoplay || !tripStarted}
          onClick={() => handleStageClick(startFrameIndex)}
        ><p>{index + 1}</p>
        </StageButton>
      </PermissionGate>
      <StageTime variant="monospace" color={theme.palette.grey[600]}>{startHour}</StageTime>
    </StageWrapper>
  );
};

export default Stage;

Stage.propTypes = {
  stage: shape({
    duration: number.isRequired,
    label: string.isRequired,
    description: shape({
      ICE: string.isRequired,
      BEV: string.isRequired,
    }),
    startFrameIndex: number.isRequired,
    startHour: string.isRequired,
    fuel: bool.isRequired,
    charge: bool.isRequired,
  }).isRequired,
  index: number.isRequired,
  active: bool.isRequired,
  handleTripAction: func.isRequired,
  tripStarted: bool.isRequired,
  tripDuration: number.isRequired,
  vehicleType: string.isRequired,
  autoplay: bool.isRequired,
};
