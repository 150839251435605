import React, { useState } from 'react';
import { func, object, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, Typography } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Dialog, PermissionGate } from '../../../../../../../../components';
import {
  VehiclesTable,
  Headers,
  Rows,
  Vehicle,
  VehicleLabel,
  BrandLogo,
  InactiveLabel,
  RegionTag,
  TripStatusLabel,
} from './styles';
import getBrandLogo from '../../../../utils/brandLogoMap';
import {
  isAutoCreatedVehicle,
  removeVinCredentialsStorage,
} from '../../../../utils/utils';
import tripOptions from '../../../SimulateVehicle/utils/tripOptions';
import simulatorHomeStaticText from '../../../../../../../../localization/Application/Simulator/home';
import staticText from '../../../../../../../../localization/Application/Simulator/simulator';

/* istanbul ignore next */
const VehiclesList = ({
  applicationId,
  fetchVehicleTrip,
  setSelectedVehicle,
  setSelectedRegion,
  deleteVehicle,
  vehicles,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [focusedVehicle, setFocusedVehicle] = useState({});

  const handleVehicleClick = (vehicle) => {
    if (vehicle.simulatedVehicleTripId) {
      fetchVehicleTrip(applicationId, vehicle.simulatedVehicleTripId);
    }
    setSelectedRegion(vehicle.region);
    setSelectedVehicle(vehicles[vehicle.vin]);
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'nav',
      style: 'inline',
      path: `/apps/${applicationId}/simulator/${vehicle.vin}`,
      text: `${vehicle.make} ${vehicle.model}`,
    });
  };

  const handleDeleteClick = (vehicle) => {
    setFocusedVehicle(vehicle);
    setDeleteDialogOpen(true);
  };

  const handleDeleteVehicle = (vehicle) => {
    const vehicleId = vehicle.id;
    deleteVehicle(applicationId, vehicleId);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: `[simulator] delete vehicle ${vehicleId}`,
    });

    removeVinCredentialsStorage(vehicle.vin);
  };

  const vins = Object.keys(vehicles);

  return (
    <React.Fragment>
      <VehiclesTable>
        <Headers>
          <Typography variant="h3">
            {simulatorHomeStaticText.vehiclesList.tableHeaders.vehicle}
          </Typography>
          <Typography variant="h3">
            {simulatorHomeStaticText.vehiclesList.tableHeaders.tripType}
          </Typography>
          <Typography variant="h3">
            {simulatorHomeStaticText.vehiclesList.tableHeaders.tripState}
          </Typography>
        </Headers>
        <Rows>
          {vins.map((key) => {
            const vehicle = vehicles[key];
            const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
            const vehicleTripType = vehicle.simulatedVehicleTripType;
            const vehicleTripStatus = vehicle.simulatedVehicleTripStatus;
            const logo = getBrandLogo(vehicle.make);
            return (
              <li key={vehicle.vin} id={vehicle.vin}>
                <Link
                  to={`/apps/${applicationId}/simulator/${vehicle.vin}`}
                  onClick={() => handleVehicleClick(vehicle)}
                >
                  <Vehicle>
                    <React.Fragment>
                      <Box display="flex" alignItems="center">
                        <BrandLogo>
                          <img src={logo} alt={vehicle.make} />
                        </BrandLogo>
                        <VehicleLabel variant="bold">
                          {`${vehicleName}`}
                          <RegionTag variant="caption">
                            {vehicle.region}
                          </RegionTag>
                        </VehicleLabel>
                      </Box>
                      {vehicleTripType ? (
                        <Typography>
                          {tripOptions.getTripTypeName(vehicleTripType)}
                        </Typography>
                    ) : (
                      <InactiveLabel>
                        {simulatorHomeStaticText.vehiclesList.tripNotStarted}
                      </InactiveLabel>
                    )}
                      <div>
                        {vehicleTripStatus ? (
                          <TripStatusLabel
                            variant="caption"
                            status={vehicleTripStatus}
                          >
                            {tripOptions.getTripStatus(vehicleTripStatus)}
                          </TripStatusLabel>
                      ) : (
                        <TripStatusLabel variant="caption">
                          {simulatorHomeStaticText.vehiclesList.tripInactive}
                        </TripStatusLabel>
                      )}
                      </div>
                    </React.Fragment>
                  </Vehicle>
                </Link>
                {!isAutoCreatedVehicle(vehicle.vin) ? (
                  <PermissionGate dashboardPermission="write_simulator">
                    <div>
                      <Button
                        id="delete-dialog-btn"
                        variant="text"
                        onClick={() => handleDeleteClick(vehicle)}
                      >
                        <DeleteOutlineRoundedIcon />
                      </Button>
                    </div>
                  </PermissionGate>
                ) : (
                  <Box marginLeft={8} />
                )}
              </li>
            );
          })}
        </Rows>
        <Dialog
          open={deleteDialogOpen}
          setDialogOpen={setDeleteDialogOpen}
          heading={staticText.deleteVehicleDialog.heading}
          text={staticText.deleteVehicleDialog.text(focusedVehicle.make)}
          submitText={staticText.deleteVehicleDialog.submitButtonText}
          handleSubmit={handleDeleteVehicle}
          additionalProps={focusedVehicle}
          segmentLabel="[simulator]"
        />
      </VehiclesTable>
    </React.Fragment>
  );
};

export default VehiclesList;

VehiclesList.propTypes = {
  applicationId: string.isRequired,
  fetchVehicleTrip: func.isRequired,
  setSelectedVehicle: func.isRequired,
  setSelectedRegion: func.isRequired,
  deleteVehicle: func.isRequired,
  vehicles: object.isRequired,
};
