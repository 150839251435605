import { Box, Typography } from '@mui/material';
import { reportToSegment, types } from '@smartcar/morse';
import { bool, func, object, shape, string } from 'prop-types';
import React, { useEffect } from 'react';

import { AuthContentContainer, Header } from '../../../../components';
import staticText from '../../../../localization/Authentication/userSecurity';
import { MfaChallenge, MfaChallengeInput } from '../../../Application/components/UserSecurity/components';

const MfaEnroll = (props) => {
  const {
    actions: {
      mfaLoginVerificationRequest,
    },
    mfaRequiredForLogin,
    mfaInProgress,
    mfaObj,
    mfaRecoveryToken,
    mfaError,
    history,
  } = props;

  useEffect(() => {
    reportToSegment(types.PAGE, 'Enroll in MFA from Login');
  }, []);

  useEffect(() => {
    if (mfaRecoveryToken && (!mfaRequiredForLogin || !mfaObj.qrCode)) {
      history.push('/login');
    }
  }, [mfaRecoveryToken]);

  return (
    <div>
      <Header />
      <AuthContentContainer>
        <Typography variant="h1">{staticText.mfaEnroll.title}</Typography>
        <Box
          width="500px"
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
        >
          <MfaChallenge mfaObj={mfaObj} />
          <MfaChallengeInput
            verifyCallback={mfaLoginVerificationRequest}
            mfaInProgress={mfaInProgress}
            mfaObj={mfaObj}
            mfaError={mfaError}
            mfaSaveFactorId
          />
        </Box>
      </AuthContentContainer>
    </div>
  );
};

export default MfaEnroll;

MfaEnroll.propTypes = {
  actions: shape({
    mfaLoginVerificationRequest: func.isRequired,
  }).isRequired,
  mfaInProgress: bool.isRequired,
  mfaRecoveryToken: string.isRequired,
  history: object.isRequired,
  mfaRequiredForLogin: bool.isRequired,
  mfaError: string,
  mfaObj: shape({
    challengeId: string,
    factorId: string,
    qrCode: string,
    secretKey: string,
  }),
};

MfaEnroll.defaultProps = {
  mfaError: '',
  mfaObj: {
    challengeId: null,
    factorId: null,
    qrCode: null,
    secretKey: null,
  },
};
