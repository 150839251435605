/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { arrayOf, func, object, objectOf, shape, string } from 'prop-types';
import { MoreHoriz } from '@mui/icons-material';
import { Menu } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import {
  IconButton,
  MenuItem,
  MoreActionsCell,
} from './styles';

const MoreActions = ({
  actionMap,
  menuItems,
  row,
  rowId,
}) => {
  const [menuOpen, setMenuOpen] = useState(null);

  const handleClick = (e) => {
    setMenuOpen(e.currentTarget);
  };
  const handleClose = () => {
    setMenuOpen(null);
  };

  const theme = useTheme();
  return (
    <MoreActionsCell >
      <IconButton id={rowId} isOpen={menuOpen} onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={menuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menuOpen)}
        onClose={handleClose}
        sx={{ mt: 1 }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: theme.border.boxShadow2,
              pl: 1,
              pr: 1,
            },
          },
        }}
      >
        {menuItems.map((menuItem) => {
          const menuItemClick = async () => {
            // wait for Menu to close to prevent layout shift
            await handleClose();
            if (menuItem.action && actionMap[menuItem.action]) {
              actionMap[menuItem.action](row);
            }
            reportToSegment(
              types.TRACK,
              eventNames.buttonClicked,
              { label: 'action', text: menuItem.displayName },
            );
          };
          return (
            <MenuItem
              key={menuItem.action}
              onClick={menuItemClick}
              className="more-actions-menu-item"
            >
              {menuItem.displayName}
            </MenuItem>
          );
        })}
      </Menu>
    </MoreActionsCell>
  );
};

export default MoreActions;

MoreActions.propTypes = {
  actionMap: objectOf(func).isRequired,
  menuItems: arrayOf(shape({
    action: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  row: object.isRequired,
  rowId: string.isRequired,
};
