const nouns = require('./config/nouns.json');
const adjectives = require('./config/adjectives.json');

export default () => {
  const adjectiveIndex = Math.floor(Math.random() * adjectives.length);
  const nounIndex = Math.floor(Math.random() * nouns.length);

  const suffix = Math.random().toString(36).slice(2, 6);

  return `${adjectives[adjectiveIndex]}-${nouns[nounIndex]}-${suffix}`;
};
