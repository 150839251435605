import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'customHeight',
})(({ theme, customHeight }) => ({
  width: theme.width.content,
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.border.boxShadow,
  borderRadius: theme.border.radius,
  padding: theme.spacing(5),
  ...(customHeight && {
    minHeight: customHeight,
  }),
}));

export const Disclaimer = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(5),
  backgroundColor: theme.palette.common.white,
}));
