import { connect } from 'react-redux';

import Setup from './Setup';

function mapStateToProps(state) {
  return {
    developerCreatedAt: state.auth.session.userContext.createdAt,
    developerEmailVerifiedAt: state.auth.session.userContext.emailVerifiedAt,
  };
}

export default connect(mapStateToProps, null)(Setup);
