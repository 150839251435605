import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Secret from './Secret';
import { actions } from './reducers';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';

function mapStateToProps(state) {
  return {
    applicationSecretGenerated: state.applications.applications[
      state.applications.selectedApplication
    ].secret,
    ...state.secret,
    credentialsEvent: state.onboarding.onboardingEvents.liveModeEvents.find((event) => {
      return event.eventName === 'generate_client_credentials';
    }),
  };
}

function mapDispatchToProps(dispatch) {
  const { updateOnboardingEvent } = onboardingActions;
  return {
    actions: bindActionCreators({
      ...actions,
      updateOnboardingEvent,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Secret);
