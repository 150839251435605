import React from 'react';
import { func, shape, string, bool, node } from 'prop-types';

const staticText = {
  inactiveStatusButton: 'Verify and re-activate webhook',
  inactiveStatus:
    'This webhook is currently inactive. Please complete the following verification steps to re-activate it.',
  inactiveStatusError:
    'This webhook is inactive because we detected a repeated number of error responses from your callback URI. Please complete the following verification steps to re-activate it.',
};

const Inactive = ({
  handleWebhookVerify,
  isVerifyingWebhook,
  verifyAttempted,
  webhook: { disabledReason },
  feedback,
  sharedTitle,
  sharedLink,
}) => {
  return (
    <div>
      {(verifyAttempted && !isVerifyingWebhook) && feedback}
      <div>
        <p className="bold">
          {disabledReason === 'error' ? staticText.inactiveStatusError : staticText.inactiveStatus}
        </p>
      </div>
      {sharedTitle}
      {sharedLink}
      <div className="right m-t-auto">
        <button
          className={`button button-med-general ${isVerifyingWebhook && 'spin'}`}
          onClick={() => handleWebhookVerify(staticText.inactiveStatusButton)}
        >
          {staticText.inactiveStatusButton}
        </button>
      </div>
    </div>
  );
};

export default Inactive;

Inactive.propTypes = {
  handleWebhookVerify: func.isRequired,
  isVerifyingWebhook: bool.isRequired,
  verifyAttempted: bool.isRequired,
  feedback: node.isRequired,
  sharedTitle: node.isRequired,
  sharedLink: node.isRequired,
  webhook: shape({
    disabledReason: string,
  }).isRequired,
};
