import { styled } from '@mui/material/styles';
import {
  IconButton as MuiIconButton,
  MenuItem as MuiMenuItem,
} from '@mui/material';

export const IconButton = styled(MuiIconButton, {
  shouldForwardProp: prop => prop !== 'isOpen',
})(({ isOpen, theme }) => ({
  padding: '0px',
  borderRadius: '50%',
  border: '1px solid transparent',

  ...(isOpen && {
    backgroundColor: theme.palette.grey[200],
  }),

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minWidth: '200px',
  fontFamily: theme.typography.bold.fontFamily,
  borderRadius: theme.border.radius,
  padding: theme.spacing(1, 2),

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const MoreActionsCell = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));
