/* eslint-disable react/require-default-props,react/no-unused-prop-types, react/forbid-prop-types */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

import {
  handleGetElementAtEvent,
  handleGetDatasetAtEvent,
  handleElementClick,
} from '../utils';
import { Container, LoadingBar, LoadingContainer } from './styles';

const loadingConfig = [
  '154px',
  '207px',
  '143px',
  '189px',
  '159px',
  '211px',
  '148px',
  '189px',
];

const defaultChartOptions = {
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    x: {},
    y: {
      ticks: {
        beginAtZero: true,
      },
    },
  },
};

/* istanbul ignore next */
const BarChart = (props) => {
  const { data, options, isLoading } = props;

  const chartOptions = {
    ...defaultChartOptions,
    ...options,
  };

  const loading = (
    <LoadingContainer>
      {loadingConfig.map((height, index) => {
        return <LoadingBar key={`bar-${index + 1}`} height={height} delay={index} />;
      })}
    </LoadingContainer>
  );

  return (
    <Container>
      {data && options && !isLoading ? (
        <Bar
          data={data}
          options={chartOptions}
          onElementsClick={handleElementClick}
          getElementAtEvent={handleGetElementAtEvent}
          getDatasetAtEvent={handleGetDatasetAtEvent}
        />
      ) : loading}
    </Container>
  );
};

BarChart.propTypes = {
  data: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  height: PropTypes.number,
  plugins: PropTypes.array,
  onElementsClick: PropTypes.func,
  getElementAtEvent: PropTypes.func,
  getDatasetAtEvent: PropTypes.func,
  optionalClasses: PropTypes.string,
  isLoading: PropTypes.bool,
};

BarChart.defaultProps = {
  height: 400,
};

export default BarChart;
