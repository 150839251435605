import React from 'react';
import { arrayOf, element, func, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { Error } from '@mui/icons-material';

import { alertTriangleIconYellow, checkCircleIcon, infoIcon } from '../../assets/icons';
import './Feedback.scss';

const generateIcon = (type) => {
  switch (type) {
    case 'warn':
      return <Error sx={{ color: 'error.main', fontSize: 18 }} alt="error" />;
    case 'success':
      return <img src={checkCircleIcon} alt="success" />;
    case 'alert':
      return <img src={alertTriangleIconYellow} alt="alert" />;
    case 'info':
    default:
      return <img src={infoIcon} alt="info" />;
  }
};

const Feedback = ({
  message, type, additionalClassNames, buttonText, buttonOnClick,
}) => {
  return (
    <div className={clsx('feedback', type, additionalClassNames)}>
      <div className="feedback-icon">
        {generateIcon(type)}
      </div>
      <div className="feedback-message">{message}</div>
      {buttonText &&
        <Button
          onClick={() => buttonOnClick()}
          variant="containedError"
          sx={{ padding: '1, 2' }}
        >
          {buttonText}
        </Button>}
    </div>
  );
};

Feedback.propTypes = {
  message: oneOfType([element, string]).isRequired,
  type: string,
  additionalClassNames: oneOfType([string, arrayOf(string)]),
  buttonText: string,
  buttonOnClick: func,
};

Feedback.defaultProps = {
  type: '',
  additionalClassNames: '',
  buttonText: '',
  buttonOnClick: null,
};

export default Feedback;
