import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Billing from './Billing';
import { actions } from '../../../../services/billing/reducers';
import { actions as authActions } from '../../../../services/auth/reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

const mapStateToProps = (state) => {
  const selectedApp = state.applications.selectedApplication ?
    state.applications.applications[state.applications.selectedApplication] : {};

  return {
    ...state.billing,
    ...state.auth,
    organization: orgSelectors.getCurrentOrganization(state),
    selectedApp,
  };
};

function mapDispatchToProps(dispatch) {
  const {
    closeFreeTrialModal, updateBillingInfo, setHasHandledStripeRedirect,
  } = actions;
  const { setPlanRedirect } = authActions;
  return {
    actions: bindActionCreators({
      closeFreeTrialModal,
      updateBillingInfo,
      setPlanRedirect,
      setHasHandledStripeRedirect,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
