import React from 'react';
import { arrayOf, string } from 'prop-types';
import { List, ListItem as MuiListItem, ListItemIcon, ListItemText as MuiListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckRounded } from '@mui/icons-material';

const ListItem = styled(MuiListItem)({
  alignItems: 'flex-start',
});

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  marginBottom: '0px',
  marginTop: '2px',
  marginLeft: theme.spacing(2),

  '.listItemTextPrimary': {
    fontFamily: theme.typography.bold.fontFamily,
  },
}));

const PlanHighlights = ({ planHighlights }) => {
  return (
    <List disablePadding>
      {planHighlights.map(highlight => (
        <ListItem
          key={highlight}
          disableGutters
        >
          <ListItemIcon>
            <CheckRounded color="success" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={highlight}
            classes={{
              primary: 'listItemTextPrimary',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default PlanHighlights;

PlanHighlights.propTypes = {
  planHighlights: arrayOf(string).isRequired,
};

