import React, { useState } from 'react';
import { bool, func, objectOf, shape, string } from 'prop-types';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Typography } from '@mui/material';

import ApplicationLink from './components/ApplicationLink/index';
import { SelectorList, NewAppButton, Divider, StickyButtonContainer } from './styles';
import FEATURES from '../../../../../../../../services/featureGate/features';
import { dashboardRole as constants } from '../../../../../Members/utils/roles';
import staticText from '../../../../../../../../localization/Application/application';
import { setScrollAbility } from '../../../../../../../../services/styleUtils';
import { UpsellModal } from '../../../../../../../../components';

const AppSelectorList = ({
  applications,
  applicationId,
  applicationName,
  applicationLogoUrl,
  closeList,
  pathname,
  toggleModal,
  getAppLogo,
  showHeader,
  dashboardRole,
}) => {
  // For locked applications, toggle upsell modal
  const [lockedAppClicked, setLockedAppClicked] = useState(false);

  /* istanbul ignore next */
  const toggleUpsellModal = (withTracking = true) => {
    const segmentReport = staticText.upgradeModal;
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames[`modal${lockedAppClicked ? 'Closed' : 'Opened'}`],
        segmentReport);
    }

    if (closeList) {
      closeList(false);
    }
    setScrollAbility(lockedAppClicked);
    setLockedAppClicked(!lockedAppClicked);
  };

  const userCanRegisterNewApp =
    [constants.OWNER, constants.ADMIN].includes(dashboardRole);

  return (
    <SelectorList>
      {lockedAppClicked &&
        <UpsellModal
          toggleModal={toggleUpsellModal}
          feature={`${FEATURES.APPLICATIONS}-locked`}
        />
      }
      {showHeader &&
        <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>Application</Typography>
      }
      {applicationName &&
        <React.Fragment>
          <ApplicationLink
            id={applicationId}
            name={applicationName}
            logoUrl={applicationLogoUrl}
            pathname={pathname}
            current
            closeList={closeList}
            setLockedAppClicked={setLockedAppClicked}
            getAppLogo={getAppLogo}
          />
          {toggleModal && <Divider />}
        </React.Fragment>
      }
      {Object.keys(applications).map((appId) => {
        const { id, name, logoUrl } = applications[appId];
        // return app link if it's not the currently selected app
        const appNotSelected = (id !== applicationId);
        return appNotSelected ?
           (
             <ApplicationLink
               key={`app-${id}`}
               id={id}
               name={name}
               logoUrl={logoUrl}
               closeList={closeList}
               pathname={pathname}
               setLockedAppClicked={setLockedAppClicked}
               getAppLogo={getAppLogo}
             />
          ) : null;
        },
      )}
      {userCanRegisterNewApp && toggleModal && (
        <StickyButtonContainer>
          {Object.keys(applications).length > 1 && <Divider />}
          <NewAppButton onClick={toggleModal} id="register-button">
            <AddRoundedIcon fontSize="small" />
            {staticText.registerApplication}
          </NewAppButton>
        </StickyButtonContainer>
      )}
    </SelectorList>
  );
};

export default AppSelectorList;

AppSelectorList.propTypes = {
  applications: objectOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  applicationId: string.isRequired,
  applicationName: string,
  applicationLogoUrl: string,
  closeList: func,
  pathname: string.isRequired,
  toggleModal: func.isRequired,
  getAppLogo: func.isRequired,
  showHeader: bool,
  dashboardRole: string.isRequired,
};

AppSelectorList.defaultProps = {
  applicationName: '',
  applicationLogoUrl: '',
  closeList: null,
  showHeader: false,
};
