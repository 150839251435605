import { connect } from 'react-redux';

import Credentials from './Credentials';

function mapStateToProps(state) {
  return {
    ...state.secret,
    ...state.applications,
  };
}

export default connect(mapStateToProps, null)(Credentials);

