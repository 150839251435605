import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Header = styled(Typography)({
  textTransform: 'uppercase',
});

export const HeaderPill = styled('span')(({ theme }) => ({
  padding: '4px 8px 2px 8px',
  marginLeft: '8px',
  fontFamily: theme.typography.bold.fontFamily,
  fontSize: '.75rem',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
}));

export const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'titleError',
})(({ theme, titleError }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'baseline',
  ...(titleError && {
    color: theme.palette.error.main,
  }),
}));

export const Subtitle = styled(Typography, {
  shouldForwardProp: prop => prop !== 'subtitle',
})(({ subtitle }) => ({
  fontSize: '1rem',
  minWidth: subtitle ? 'auto' : '209px',
}));

export const Addendum = styled(Typography)({
  fontSize: '1rem',
  marginLeft: '4px',
});
