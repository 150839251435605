import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CommandAi from './CommandAi';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';
import { actions as orgActions, selectors as orgSelectors } from '../../../../services/organizations/reducers';
import { actions as customizationsActions } from '../ConnectCustomizations/reducers';
import { actions as errorActions } from '../../../../services/errorReporter/reducers';


function mapStateToProps(state) {
  return {
    userContext: state.auth.session.userContext,
    selectedApplication: state.applications.selectedApplication,
    selectedOrganization: state.organizations.selectedOrganization,
    organization: orgSelectors.getCurrentOrganization(state),
    liveOnboardingEvents: state.onboarding.onboardingEvents.liveModeEvents,
    connectConfig: state.connectCustomizations.connectConfig,
    isFetchingConnectConfig: state.connectCustomizations.isFetchingConnectConfig,
    activeOrgMembers: state.organizations.activeOrgMembers,
    invitedOrgMembers: state.organizations.invitedOrgMembers,
  };
}

function mapDispatchToProps(dispatch) {
  const { startOnboardingPolling } = onboardingActions;
  const { fetchConnectConfigRequest } = customizationsActions;
  const { fetchActiveOrgMembers, fetchInvitedOrgMembers } = orgActions;
  const { reportError } = errorActions;

  return {
    actions: bindActionCreators({
      startOnboardingPolling,
      fetchConnectConfigRequest,
      fetchActiveOrgMembers,
      fetchInvitedOrgMembers,
      reportError,
    },
    dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommandAi);
