import { call, put, takeEvery } from 'redux-saga/effects';

import captureException from './utils/captureException';
import { types, actions } from './reducers';

export function* reportError(action) {
  try {
    const { error } = action.payload;
    yield call(captureException, error);
    yield put(actions.reportErrorSuccess());
  } catch (error) {
    yield put(actions.reportErrorFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.REPORT_ERROR, reportError);
}
