/*
  if an applications featureSetId is not enterprise, then block all other
  role submissions except for 'admin'.

  enterprise featureSetId -> 01be4b83-49bb-4aab-8760-cc362e455b7a
*/
import FEATURES from '../../../../../services/featureGate/features';
import { gatedFeatureData } from '../../../../../services/featureGate';

/* istanbul ignore next */
const useIsEnterprise = (featureSetId, selectedRole) => {
  const teamsRoles = gatedFeatureData(FEATURES.TEAMS_ROLES, featureSetId);
  const canAccessRoles = !Array.from(teamsRoles).includes(selectedRole);

  return {
    canAccessRoles,
  };
};

export default useIsEnterprise;
