import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import staticText from '../../../../../../localization/Application/Billing/billing';
import spinningButtonStyles from '../../../../../../global-styles/animations/spinningButton';
import { openChat } from '../../../../../../services/front/front';
import api from '../../../../../../../src/services/api/api';

const Button = styled(MuiButton)`
  ${({ theme, downgrade, current }) => {
    let color;
    let backgroundColor;
    let hoverBackground;
    if (downgrade) {
      const grey = [100, 200, 500, 600];
      color = theme.palette.grey[grey[3]];
      backgroundColor = theme.palette.grey[grey[0]];
      hoverBackground = theme.palette.grey[grey[2]];
    } else if (current) {
      color = theme.palette.primary.main;
      backgroundColor = theme.palette.primary.light;
      hoverBackground = theme.palette.blueButton.hover;
    } else {
      color = theme.palette.primary.secondary;
      backgroundColor = theme.palette.primary.main;
      hoverBackground = theme.palette.secondary.main;
    }
    return `
      font-size: 1.125rem;
      padding-top: 12px;
      padding-bottom: 12px;
      color: ${color};
      background-color: ${backgroundColor};
      transition: ${theme.transitions.create(['background-color', 'transform'], { duration: theme.transitions.duration.standard })};
      &:enabled:hover {
          background-color: ${hoverBackground};
      }
      &:disabled {
      opacity: 1;
      color: ${current ? theme.palette.primary.main : theme.palette.grey[600]};
      background-color: ${current ? theme.palette.primary.light : theme.palette.grey[100]};
      }
    `;
  }}
`;

const PlanButton = ({
  buttonTexts,
  columnPlanName,
  emailVerifiedAt,
  setCurrentModal,
  userPlanName,
  trialActive,
  organizationId,
  setHasHandledStripeRedirect,
  isCurrentPlanLegacy,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const buttonProps = {
    color: 'primary',
    variant: 'contained',
    fullWidth: true,
  };

  let buttonText = 'Current plan';

  const userPlan = trialActive ? staticText.buildPlanName : userPlanName.toLowerCase();
  const columnPlan = columnPlanName.toLowerCase();

  // disable button if user is on the same plan
  if (userPlan === columnPlan && !isCurrentPlanLegacy) {
    buttonProps.disabled = true;
    buttonProps.current = 'true';

    if (trialActive && columnPlan === staticText.buildPlanName) {
      buttonText = buttonTexts.inTrial;
    } else {
      buttonText = buttonTexts.current;
    }

  // if user's current plan is not free, the free plan button should show downgrade
  } else if (columnPlan === staticText.freePlanName) {
    buttonProps.onClick = () => setCurrentModal('downgradeFree');
    buttonProps.downgrade = 'true';

    buttonText = buttonTexts.downgrade;
  } else if (columnPlan === staticText.buildPlanName) {
    // We can remove business logic when there are no more Business (legacy) plans
    if (
      userPlan === staticText.scalePlanName ||
      userPlan === staticText.businessPlanName
    ) {
      buttonProps.onClick = () => setCurrentModal('downgradeBuild');
      buttonProps.downgrade = 'true';

      buttonText = buttonTexts.downgrade;
    } else if (!emailVerifiedAt) {
      buttonProps.onClick = () => setCurrentModal('verifyEmail');
      buttonText = buttonTexts.upgrade;

      // Obtain URL for Stripe Checkout session and redirect
    } else {
      const getStripeCheckoutURL = async () => {
        const URL = (await api.fetchStripeUrl(organizationId, 'checkout', columnPlan));
        window.location.href = URL;
      };
      buttonProps.onClick = () => {
        setHasHandledStripeRedirect(false);
        setIsButtonLoading(true);
        getStripeCheckoutURL();
      };
      buttonText = buttonTexts.upgrade;
    }
  } else {
    buttonProps.onClick = openChat;

    buttonText = buttonTexts.contactUs;
  }

  return (
    <div>
      <Button
        {...buttonProps}
        sx={isButtonLoading && spinningButtonStyles}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default PlanButton;

PlanButton.propTypes = {
  buttonTexts: shape({
    contactUs: string.isRequired,
    downgrade: string.isRequired,
    upgrade: string.isRequired,
    current: string.isRequired,
  }).isRequired,
  columnPlanName: string.isRequired,
  emailVerifiedAt: string,
  setCurrentModal: func.isRequired,
  userPlanName: string.isRequired,
  trialActive: bool.isRequired,
  organizationId: string.isRequired,
  setHasHandledStripeRedirect: func.isRequired,
  isCurrentPlanLegacy: bool.isRequired,
};

PlanButton.defaultProps = {
  emailVerifiedAt: null,
};
