import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import image from '../../../../assets/images/platform-features.png';
import { Content, Image, Button, ButtonContainer } from '../../styles';
import { Modal, Spinner } from '../../../../components';
import ValidateName from '../../../../components/ValidateNameModal/components/ValidateName';
import staticText from '../../../../localization/Setup/account';

const Account = ({
  actions: {
    createOrganization,
    deleteDashboardUser,
  },
  createOrgError,
  deleteUserError,
  isCreatingOrg,
  isDeletingUser,
  userContext: {
    email,
    firstName,
    company,
  },
  organizations,
  history,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCreateTeam = () => {
    const orgName = company.company || staticText.defaultTeamName(firstName);
    createOrganization(orgName);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: staticText.createTeam });
  };
  const handleDeleteClick = () => setModalOpen(true);

  const description = [];
  const texts = staticText.confirmDelete.split('{{email}}');
  texts.forEach((text, i) => {
    description.push(text);
    if (i < texts.length - 1) {
      description.push(<Typography variant="bold" component="span"> {email} </Typography>);
    }
  });

  const modalContent = (
    <ValidateName
      nameToMatch={email}
      nameType="account"
      title={staticText.deleteAccountTitle}
      toggleModal={() => setModalOpen(false)}
      submitAction={deleteDashboardUser}
      description={description}
    />
  );

  const title = staticText.title.replace('{{firstName}}', firstName);

  const errors = [
    ...(createOrgError ? [['org-creation-error', createOrgError]] : []),
    ...(deleteUserError ? [['delete-user-error', deleteUserError]] : []),
  ];

  const hasOrganizations = Object.keys(organizations).length > 0;

  useEffect(() => {
    reportToSegment(types.PAGE, 'Setup - Account');

    if (hasOrganizations) {
      // for now, this page is only accessible for org-less users
      history.push('/');
    }
  }, []);

  if (hasOrganizations) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        {title}
      </Typography>
      <Content>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Image src={image} alt="astronaut checking his phone next to a car" />
          <Typography>
            {staticText.description}
          </Typography>
          <ButtonContainer>
            <Button variant="contained" onClick={handleCreateTeam} disabled={isCreatingOrg}>
              {isCreatingOrg ? <Spinner size="button" /> : staticText.createTeam}
            </Button>
            <Button variant="outlined" onClick={handleDeleteClick} disabled={isDeletingUser}>
              {isDeletingUser ? <Spinner size="button" /> : staticText.deleteAccount}
            </Button>
          </ButtonContainer>
          {errors.map(([errorKey, message]) => {
            return (
              <Typography key={errorKey} variant="caption" color="error">
                {message}
              </Typography>
            );
          })}
        </Box>
      </Content>
      {modalOpen && <Modal title="Delete Smartcar account" content={modalContent} toggleModal={() => setModalOpen(false)} />}
    </React.Fragment>
  );
};

export default Account;

Account.propTypes = {
  actions: shape({
    createOrganization: func.isRequired,
    deleteDashboardUser: func.isRequired,
  }).isRequired,
  createOrgError: string.isRequired,
  deleteUserError: string.isRequired,
  isCreatingOrg: bool.isRequired,
  isDeletingUser: bool.isRequired,
  userContext: shape({
    company: shape({
      company: string,
    }).isRequired,
    email: string.isRequired,
    firstName: string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
