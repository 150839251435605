import { styled } from '@mui/material/styles';

export const Dashboard = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  minHeight: '500px',
}));

export const TimelineBackground = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 0, 6, 0),
}));

export const TripStatusCardsBackground = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-6.5),
}));
