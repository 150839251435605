const apiErrors = {
  AUTHENTICATION: {
    errorText: 'Thrown when there is an issue with your authorization header.',
    href: 'https://smartcar.com/docs/errors/api-errors/authentication-errors',
  },
  BILLING: {
    errorText: 'Thrown when limits have been reached based on your plan, or if the feature is not avaible.',
    href: 'https://smartcar.com/docs/errors/api-errors/billing-errors',
  },
  COMPATIBILITY: {
    errorText: 'Thrown when Smartcar does not support a make, or feature for a vehicle.',
    href: 'https://smartcar.com/docs/errors/api-errors/compatibility-errors',
  },
  CONNECTED_SERVICES_ACCOUNT: {
    errorText: 'Thrown when there are issues with the user\'s connected service account.',
    href: 'https://smartcar.com/docs/errors/api-errors/connected-services-account-errors',
  },
  PERMISSION: {
    errorText: 'Thrown when Smartcar does not support a make or feature for a vehicle.',
    href: 'https://smartcar.com/docs/errors/api-errors/permission-errors',
  },
  RATE_LIMIT: {
    errorText: 'Thrown when there is an issue with the frequency of your requests.',
    href: 'https://smartcar.com/docs/errors/api-errors/rate-limit-errors',
  },
  RESOURCE_NOT_FOUND: {
    errorText: 'Thrown when the incorrect API version is used or when the endpoint URL is incorrect.',
    href: 'https://smartcar.com/docs/errors/api-errors/resource-not-found-errors',
  },
  SERVER: {
    errorText: 'Thrown when Smartcar runs into an unexpected issue and was unable to process the request.',
    href: 'https://smartcar.com/docs/errors/api-errors/server-errors',
  },
  UPSTREAM: {
    errorText: 'Thrown when the OEM servers or vehicle failed to process the request.',
    href: 'https://smartcar.com/docs/errors/api-errors/upstream-errors',
  },
  VALIDATION: {
    errorText: 'Thrown if there is an issue with the format of the request or body.',
    href: 'https://smartcar.com/docs/errors/api-errors/validation-errors',
  },
  VEHICLE_STATE: {
    errorText: 'Thrown when a request fails due to the state of a vehicle or logically cannot be completed e.g. you can\'t start a vehicle charging if it\'s not plugged in.',
    href: 'https://smartcar.com/docs/errors/api-errors/vehicle-state-errors',
  },
};

export default apiErrors;
