import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Autocomplete, Box } from '@mui/material';

import { StyledChip, StyledTextField } from './styles';

import staticText from '../../../../../../localization/Components/multiEmailInput';

const EmailInput = ({
  activeMembers,
  emails,
  handleChange,
  handleInputChange,
  handleKeyDown,
  inputError,
  inputValue,
}) => {
  const emailToMembers = activeMembers.reduce((acc, member) => {
    acc[member.email] = member;
    return acc;
  }, {});

  const renderOption = (optionProps, email) => (
    <Box
      {...optionProps}
    >
      {`${email} (${emailToMembers[email].name})`}
    </Box>
  );

  return (
    <Autocomplete
      id="email-input"
      open={inputValue.length > 0}
      clearText=""
      freeSolo
      multiple
      disablePortal
      options={activeMembers.map(member => member.email)}
      value={emails}
      inputValue={inputValue}
      onChange={(_, newEmails) => handleChange(newEmails)}
      onInputChange={(_, newInput) => handleInputChange(newInput)}
      renderOption={renderOption}
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <StyledChip variant="outlined" label={option} {...getTagProps({ index })} />
      ))}
      renderInput={(params) => {
        return (
          <StyledTextField
            {...params}
            onKeyDown={handleKeyDown}
            error={Boolean(inputError)}
            helperText={inputError}
            InputLabelProps={{ shrink: true }}
            label={staticText.inputLabel}
            placeholder={staticText.inputPlaceholder}
            variant="outlined"
          />
        );
      }}
    />
  );
};

export default EmailInput;

EmailInput.propTypes = {
  activeMembers: arrayOf(shape({
    id: string,
    name: string,
    email: string,
  })),
  emails: arrayOf(string).isRequired,
  handleChange: func.isRequired,
  handleInputChange: func.isRequired,
  handleKeyDown: func.isRequired,
  inputError: string.isRequired,
  inputValue: string.isRequired,
};

EmailInput.defaultProps = {
  activeMembers: [],
};
