/* istanbul ignore file */

import React from 'react';
import { func, number, shape, string } from 'prop-types';
import { StaticDatePicker } from '@mui/x-date-pickers-pro';
import { styled } from '@mui/material/styles';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { isAfter } from 'date-fns';
import moment from 'moment-timezone';

import getFilterConfiguration from '../../../../utils/getFilterConfiguration';
import dateUtils from '../../../../utils/dateUtils';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.border.borderRadius,
  boxShadow: theme.border.boxShadow,
  '& > .MuiTypography-h3': {
    marginBottom: theme.spacing(3),
  },
  minWidth: '310px',

  '& .MuiPickersYear-yearButton:hover, & .MuiPickersMonth-root:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiPickersYear-yearButton.Mui-selected, & .MuiPickersMonth-root.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',

    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
}));

const DateDropdown = (props) => {
  const {
    createdAt,
    refreshChartData,
    setFilter,
    setOpen,
    timeframe,
  } = props;
  const views = ['year'];
  let minChartDate;
  if (timeframe.interval === 'day') {
    views.push('month');
    minChartDate = isAfter(new Date(createdAt), new Date('Apr 1, 2020')) ? createdAt : 'Apr 1, 2020';
  } else {
    minChartDate = isAfter(new Date(createdAt), new Date('Apr 1, 2020')) ? dateUtils.getStartOfYearUtc(createdAt) : 'Apr 1, 2020';
  }

  const handleDateChange = pickerStage => (momentDate) => {
    const date = new Date(momentDate);
    const filter = getFilterConfiguration(timeframe.interval, date);
    setFilter(filter, 'timeframe');
    refreshChartData();

    if (pickerStage === 'month' || (pickerStage === 'year' && timeframe.interval === 'month')) {
      setOpen(false);
      reportToSegment(types.TRACK, eventNames.buttonClicked, {
        label: 'filter',
        text: date,
      });
    }
  };

  return (
    <Container id="overview-date-dropdown">
      <StaticDatePicker
        views={views}
        // If usage tab, get all available data, if not - get limited timeframe
        minDate={moment(minChartDate).utc()}
        // minDate={new Date('August 1, 2020')}
        maxDate={moment().utc()}
        value={moment(timeframe.start).utc()}
        onMonthChange={handleDateChange('month')}
        onYearChange={handleDateChange('year')}
        slots={{
          actionBar: () => null,
          toolbar: () => null,
        }}
        // we are using onMonthChange/onYearChange to handle clicks
        onChange={() => {}}
        timezone="UTC"
      />
    </Container>
  );
};

export default DateDropdown;

DateDropdown.propTypes = {
  createdAt: string.isRequired,
  refreshChartData: func.isRequired,
  setFilter: func.isRequired,
  setOpen: func,
  timeframe: shape({
    start: number,
    end: number,
    interval: string,
    timezone: string,
  }).isRequired,
};

DateDropdown.defaultProps = {
  setOpen: () => {},
};
