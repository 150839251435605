const isInfinity = num => num === null || Number(num) === Infinity;
const pluralFormatter = num => `${num} vehicle${num === 1 ? '' : 's'}`;


const staticText = {
  defaultCards: [
    {
      header: 'Plan',
    },
    {
      header: 'Vehicles',
      subtitle: (numberOfBaseVehicles) => {
        if (isInfinity(numberOfBaseVehicles)) {
          return '';
        }
        return `${pluralFormatter(numberOfBaseVehicles)} included`;
      },
    },
    {
      header: 'API Calls',
      subtitle: (numApiCalls) => {
        if (isInfinity(numApiCalls)) {
          return '';
        }
        return `${numApiCalls.toLocaleString()} API calls/month`;
      },
    },
  ],
  freePlanCards: [
    {
      header: 'Plan',
      title: 'Free',
    },
    {
      header: 'Vehicles',
      subtitle: (numberOfBaseVehicles) => {
        return `Up to ${pluralFormatter(numberOfBaseVehicles)}`;
      },
    },
    {
      header: 'Vehicles over API call limit',
      subtitle: (numApiCalls) => {
        if (isInfinity(numApiCalls)) {
          return '';
        }
        return `${numApiCalls} API calls/vehicle/month`;
      },
    },
  ],
  freeTrialCards: [
    {
      header: 'Plan',
      title: 'Free trial',
      subtitle: 'Build',
    },
    {
      header: 'Vehicles',
    },
    {
      header: 'Trial duration',
      subtitle: 'Days remaining',
    },
  ],
};

export default staticText;
