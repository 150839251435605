/* istanbul ignore next */
const alphabetizeMakes = (a, b) => {
  const makeA = a.displayName.toLowerCase();
  const makeB = b.displayName.toLowerCase();
  if (makeA < makeB) {
    return -1;
  }
  if (makeA > makeB) {
    return 1;
  }
  return 0;
};

export default alphabetizeMakes;
