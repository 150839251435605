/* istanbul ignore file */
import React from 'react';

const AnchorIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_88_18001)">
        <path d="M10 6.66663C11.3807 6.66663 12.5 5.54734 12.5 4.16663C12.5 2.78591 11.3807 1.66663 10 1.66663C8.61929 1.66663 7.5 2.78591 7.5 4.16663C7.5 5.54734 8.61929 6.66663 10 6.66663Z" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 18.3333V6.66663" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.16663 10H1.66663C1.66663 12.2101 2.5446 14.3298 4.1074 15.8926C5.67021 17.4554 7.78982 18.3333 9.99996 18.3333C12.2101 18.3333 14.3297 17.4554 15.8925 15.8926C17.4553 14.3298 18.3333 12.2101 18.3333 10H15.8333" stroke="#4F4F4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_88_18001">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnchorIcon;
