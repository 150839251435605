const checkChangeToCustomizations = (
  previousState,
  updatedFields,
  storedConnectConfig,
  appName,
) => {
  let changeDetected = false;
  if (!storedConnectConfig) {
    // If there is no stored connect config and the user makes a change, return true
    if (Object.keys(updatedFields).length > 0) {
      return true;
    }
    return changeDetected;
  }
  const current = { ...previousState, ...updatedFields };

  if (
    // appearance
    (current.appName !== appName) ||
    (current.theme !== storedConnectConfig.theme) ||
    (current.logoUrl !== storedConnectConfig.logoUrl) ||
    // brand management
    (current.supportedMakesSortOrder !== storedConnectConfig.supportedMakesSortOrder) ||
    (current.brandManagementFilters !== storedConnectConfig.brandManagementFilters) ||
    (current.newBrandOptIn !== storedConnectConfig.newBrandOptIn)
  ) {
    changeDetected = true;
  }
  // supported makes
  if (storedConnectConfig.supportedMakes) {
    if (storedConnectConfig.supportedMakes.length !== current.supportedMakes.length) {
      changeDetected = true;
    } else {
      const previous = new Set(storedConnectConfig.supportedMakes);
      if (!current.supportedMakes.every(make => previous.has(make))) {
        changeDetected = true;
      }
    }
  }
  if (current.supportedMakes.length === 0) {
    changeDetected = false;
  }
  // supported countries
  if (storedConnectConfig.supportedCountries) {
    if (current.supportedCountries.length !== storedConnectConfig.supportedCountries.length) {
      changeDetected = true;
    } else {
      const previous = new Set(storedConnectConfig.supportedCountries);
      if (!current.supportedCountries.every(country => previous.has(country))) {
        changeDetected = true;
      }
    }
  }
  if (current.supportedCountries.length === 0) {
    changeDetected = false;
  }

  return changeDetected;
};

export default checkChangeToCustomizations;
