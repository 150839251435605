import applicationNameValidator from '../../../../../../services/validators/applicationNameValidator';
import privacyPolicyValidator from '../../../../../../services/validators/privacyPolicyUrlValidator';

const inputs = [
  {
    displayName: 'Application name',
    pathOnData: 'name',
    placeholderText: 'Your application name, e.g. Sky Insurance',
    validator: applicationNameValidator,
  },
  {
    displayName: 'Privacy policy URL',
    tooltip: 'Your application’s privacy policy, which will be linked in Smartcar Connect.',
    pathOnData: 'serviceLinks.privacyPolicy',
    placeholderText: 'https://your-app.com/privacy',
    validator: privacyPolicyValidator,
  },
];

export default inputs;
