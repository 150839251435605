import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Members from './Members';
import { actions, selectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const selectedApp = state.applications.selectedApplication ?
    state.applications.applications[state.applications.selectedApplication] : {};

  return {
    deleteOrgInviteStatus: state.organizations.deleteOrgInviteStatus,
    deleteOrgInviteError: state.organizations.deleteOrgInviteError,
    deleteOrgMemberStatus: state.organizations.deleteOrgMemberStatus,
    deleteOrgMemberError: state.organizations.deleteOrgMemberError,
    updateOrgMemberStatus: state.organizations.updateOrgMemberStatus,
    updateOrgMemberError: state.organizations.updateOrgMemberError,
    fetchRolesError: state.organizations.fetchRolesError,
    isFetching: state.organizations.isFetchingActiveOrgMembers
      || state.organizations.isFetchingInvitedOrgMembers,
    isFetchRoles: state.organizations.isFetchRoles,
    members: {
      activeMembers: state.organizations.activeOrgMembers,
      invitedMembers: state.organizations.invitedOrgMembers,
    },
    userContext: state.auth.session.userContext,
    organization: selectors.getCurrentOrganization(state),
    dashboardRoles: state.organizations.dashboardPermissionRoles,
    selectedApp,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchActiveOrgMembers,
    fetchInvitedOrgMembers,
    fetchDashboardPermissionRoles,
    sendOrgInvites,
    deleteOrgInvite,
    updateOrgMember,
    deleteOrgMember,
    updateDeleteOrgInviteStatus,
    updateDeleteOrgMemberStatus,
    updateUpdateOrgMemberStatus,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchActiveOrgMembers,
        fetchInvitedOrgMembers,
        fetchDashboardPermissionRoles,
        sendOrgInvites,
        deleteOrgInvite,
        updateOrgMember,
        deleteOrgMember,
        updateDeleteOrgInviteStatus,
        updateDeleteOrgMemberStatus,
        updateUpdateOrgMemberStatus,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
