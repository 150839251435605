import React from 'react';
import { string, arrayOf, shape, func } from 'prop-types';
import { Typography, FormControl, MenuItem } from '@mui/material';
import { ChevronDown } from 'react-feather';

import { StyledSelect, MenuProps } from './styles';

const RoleSelect = ({
  dashboardPermissionRoles,
  handleRole,
  selectedRole,
  error,
}) => {
  const decorateSelect = (roleName) => {
    const role = dashboardPermissionRoles.find(item => item.name === roleName);
    return (
      <React.Fragment>
        <Typography
          variant="bold"
          position="relative"
        >
          {role.displayName}
        </Typography>
        <Typography>
          {role.description}
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <FormControl key="role-select" sx={{ width: '100%' }}>
      <StyledSelect
        IconComponent={ChevronDown}
        id="role-select"
        value={selectedRole}
        onChange={handleRole}
        renderValue={decorateSelect}
        MenuProps={MenuProps}
        error={Boolean(error)}
      >
        {dashboardPermissionRoles.map(({ name, displayName }) => (
          <MenuItem
            key={name}
            value={name}
            sx={{ height: '50px' }}
          >
            {displayName}
          </MenuItem>
        ))}
      </StyledSelect>
      {error && (
        <Typography variant="caption" color="error" m="3px 14px">
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

export default RoleSelect;


RoleSelect.propTypes = {
  dashboardPermissionRoles: arrayOf(
    shape({
      name: string,
      displayName: string,
      description: string,
    }),
  ).isRequired,
  handleRole: func.isRequired,
  selectedRole: string.isRequired,
  error: string.isRequired,
};
