const staticText = {
  heading: 'Vehicles',
  integrateWithConnectLink: 'https://smartcar.com/docs/connect/what-is-connect',
  noVehicles: 'No vehicles connected yet',
  noVehiclesWithFilters: 'No vehicle data available for the given criteria.',
  integrateWithConnect: 'Integrate with Connect',
  resetFilters: 'Reset filters',
  navigation: {
    allVehicles: 'All vehicles',
  },
  vehicleNotFoundHeader: 'Vehicle not found',
  totalVehicles: count => `Showing ${count} vehicle${count > 1 ? 's' : ''}`,
};

export const messages = {
  disconnectVehicleSuccess: 'Vehicle connection deleted!',
  disconnectVehicleFailure: 'Failed to disconnect vehicle!',
};

export const errors = {
  compatibleVehicles: 'Sorry, we encountered an error fetching the list of compatible vehicles for the makes and models filter. You may still use the other filters or try refreshing the page.',
  connectedVehicles: 'Sorry, we encountered an error fetching the list of connected vehicles. Please try again later.',
  disconnectVehicle: 'Sorry, we encountered an error attempting to disconnect this vehicle. Please try again later.',
  vehicleInfo: 'Sorry, we encountered an error fetching information about this vehicle. Please try again later.',
  vehicleLogs: 'Sorry, we encountered an error fetching the logs for this vehicle. Please try again later.',
  vehicleOverview: 'Sorry, we encountered an error fetching the summary information for this vehicle. Please try again later.',
  webhooksInfo: 'Sorry, we encountered an error rendering the table of webhooks subscribed to this vehicle. Please try again later.',
  datesRequired: 'Please select a start and end date.',
  outOfRange: date => `You have selected ${date === 'start' ? 'a start' : 'an end'} date outside the allowed date range.`,
  vehicleNotFound: 'The provided Vehicle ID does not match any vehicle connected to this application.',
  invalidVehicleId: 'Invalid Vehicle ID',
};

export const header = {
  allVehicles: 'All vehicles',
};

export default staticText;
