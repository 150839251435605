import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { styled, TableCell, TableRow, Typography, Button, useTheme } from '@mui/material';

import { ActionButton, MoreActions, Status } from './components';
import PermissionGate from '../../../../../../../../components/PermissionGate';

import WebhookConfiguration from '../../../../utils/WebhookConfiguration';
import { TYPES } from '../../../../utils/webhookTypes';

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.common.white,
  fontFamily: theme.typography.bold.fontFamily,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.border.radius,
  textDecoration: 'none',
  transition: '.25s',

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.secondary.main,
  },
}));

const TypeTag = styled(Typography)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: theme.border.radius,
  padding: '4px 4px 1px 4px',
  marginLeft: theme.spacing(1),
  marginTop: '-2px',
}));

/* istanbul ignore next */
const WebhooksStatusRow = ({ webhook, actions, disabled }) => {
  const {
    disabledReason, name, id, status, type,
  } = webhook;

  const { selectWebhook, toggleWebhookModal } = actions;

  const openModal = (modalName) => {
    selectWebhook(id);
    toggleWebhookModal({ nextModal: modalName, curModal: null });
  };

  const webhookConfiguration = new WebhookConfiguration(status);
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell sx={{ color: disabled && theme.palette.grey[500] }}>
        <Status
          statusText={webhookConfiguration.getStatusText()}
          statusColor={webhookConfiguration.getIconColor()}
          hasError={disabledReason === 'error'}
        />
      </TableCell>
      <TableCell sx={{ color: disabled && theme.palette.grey[500] }}>
        <Typography>{name}<TypeTag variant="caption">{TYPES[type].text}</TypeTag></Typography>
      </TableCell>
      <TableCell sx={{ display: 'flex' }}>
        <PermissionGate
          dashboardPermission="write_webhooks"
          alternateComponent={
            <ActionButton
              action="View"
              openModal={() => openModal('Information')}
              disabled={disabled}
            />
          }
        >
          <ActionButton
            action={webhookConfiguration.getPrimaryAction()}
            openModal={openModal}
            disabled={disabled}
          />
          <MoreActions
            actions={webhookConfiguration.getSecondaryActions()}
            openModal={openModal}
            disabled={disabled}
          />
        </PermissionGate>
      </TableCell>
    </TableRow>
  );
};

export default WebhooksStatusRow;

WebhooksStatusRow.propTypes = {
  webhook: shape({
    disabledReason: string,
    name: string.isRequired,
    id: string.isRequired,
    status: string.isRequired,
  }).isRequired,
  actions: shape({
    selectWebhook: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
  disabled: bool,
};

WebhooksStatusRow.defaultProps = {
  disabled: false,
};
