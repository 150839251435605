import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line import/prefer-default-export
export const ThemeOption = styled(Button, {
  shouldForwardProp: prop => prop !== 'selected',
})(({ theme, selected }) => ({
  borderColor: theme.palette.grey[300],
  color: theme.palette.common.black,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1.5, 2),
  justifyContent: 'flex-start',
  width: '100%',
  transition: 'none',
  backgroundColor: 'transparent',

  '&:last-of-type': {
    marginBottom: 0,
  },

  '& svg': {
    marginRight: theme.spacing(1.5),
    marginTop: '-2px',
  },

  '&:hover, &:focus, &:active': {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,

    '& svg path': {
      fill: theme.palette.secondary.main,
    },
  },

  '&:disabled': {
    '& svg path': {
      fill: theme.palette.grey[400],
    },

    ...selected && {
      opacity: 0.5,
    },
  },

  ...(selected && {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,

    '&:hover, &:focus, &:active, &:disabled': {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,

      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },

    '& svg path': {
      fill: theme.palette.primary.main,
    },
  }),
}));
