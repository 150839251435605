import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Vehicles from './Vehicles';
import { actions } from './reducers';

function mapStateToProps(state) {
  return {
    applicationId: state.applications.selectedApplication,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    disconnectVehicle,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        disconnectVehicle,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
