import React, { useState, useEffect } from 'react';
import { func, string, arrayOf, shape } from 'prop-types';
import { Box, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import {
  Feedback,
  Modal,
} from '../../../../../../components';
import RoleSelect from '../RoleSelect';

import {
  ButtonContainer,
  StyledButton,
} from '../shared/styles';

import useHandleRole from '../../utils/useHandleRole';
import useIsEnterprise from '../../utils/useIsEnterprise';

import staticText from '../../../../../../localization/Application/Members/inviteEditUsers';
import errors from '../../../../../../localization/Application/Members/errors';
import MultiAppSelect, { useMultiAppSelect } from '../MultiAppSelect';
import EmailInput, { useEmailInput } from '../EmailInput';
import { getOrganizationRole, organizationRole as orgRoleConstants, dashboardRole as dashRoleConstants } from '../../utils/roles';

const InviteUserModal = ({
  apps,
  activeMembers,
  dashboardPermissionRoles,
  fetchRolesError,
  sendOrgInvites,
  openModal,
  closeModal,
  featureSetId,
  organizationRole,
}) => {
  const [temporarilyDisableInviteButton, setTemporarilyDisableInviteButton] = useState(false);
  const {
    roleError,
    setRoleError,
    selectedRole,
    handleRoleClick,
  } = useHandleRole('owner');

  const { canAccessRoles } = useIsEnterprise(featureSetId, selectedRole);

  const {
    emails,
    getEmailsToSend,
    handleEmailsChange,
    handleInputChange,
    handleKeyDown,
    inputError,
    inputValue,
    setInputError,
  } = useEmailInput();

  const appIds = apps.map(app => app.id);

  const {
    allAppsSelected,
    appAccessError,
    selectedApps,
    handleAppChange,
    handleAppDelete,
    handleToggleAll,
    setAppAccessError,
    // default role is admin, so default to selecting all apps
  } = useMultiAppSelect(appIds, appIds);

  const handleInvites = () => {
    if (!selectedRole) {
      setRoleError(errors.selectRole);
    } else if (selectedRole === dashRoleConstants.OWNER &&
      organizationRole !== orgRoleConstants.FULL_ACCESS) {
      setRoleError(errors.noPermissionToInviteOwner);
    } else if (selectedApps.length === 0) {
      setAppAccessError(errors.selectAppAccess);
    } else {
      const emailsToSend = getEmailsToSend();
      if (emailsToSend) {
        const orgRole = getOrganizationRole(selectedRole, allAppsSelected);
        const applicationIds = selectedApps;
        sendOrgInvites({
          emailsToSend, selectedRole, organizationRole: orgRole, applicationIds,
        });
        setTemporarilyDisableInviteButton(true);
        closeModal();
        reportToSegment(types.TRACK, eventNames.formSubmitted, {
          label: 'invite members',
          form_content: {
            emailsToSend,
            selectedRole,
            organizationRole: orgRole,
            applicationIds,
          },
        });
      }
    }
  };

  const content = (
    <div>
      <Typography variant="body1" sx={{ mb: 4, mt: 1 }}>
        {staticText.instructions}
      </Typography>
      <EmailInput
        activeMembers={activeMembers}
        emails={emails}
        handleChange={handleEmailsChange}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        inputError={inputError}
        inputValue={inputValue}
      />
      <Box marginY={3}>
        <Typography marginBottom={2}>{staticText.selectRole}</Typography>
        {fetchRolesError
          ?
            <Typography variant="caption" color="error">
              {fetchRolesError}
            </Typography>
          :
            <RoleSelect
              selectedRole={selectedRole}
              handleRole={handleRoleClick}
              dashboardPermissionRoles={dashboardPermissionRoles}
              error={roleError}
            />}
      </Box>
      {
        !canAccessRoles &&
          /* istanbul ignore next */
          <Feedback
            type="alert"
            message={staticText.rolesGatedText}
          />
      }
      <Box marginY={3}>
        <Typography marginBottom={2}>
          {staticText.appAccess.selectAccess}
        </Typography>
        <MultiAppSelect
          availableApps={apps}
          error={appAccessError}
          selectedApps={selectedApps}
          selectedRole={selectedRole}
          handleChange={handleAppChange}
          handleAppDelete={handleAppDelete}
          toggleAll={handleToggleAll}
        />
      </Box>
      <ButtonContainer>
        <StyledButton
          variant="outlined"
          size="large"
          onClick={closeModal}
        >
          {staticText.cancelButton}
        </StyledButton>
        <StyledButton
          id="submit-button"
          variant="contained"
          size="large"
          /* istanbul ignore next */
          disabled={
            (emails.length === 0 && inputValue.length === 0)
            || Boolean(inputError)
            || !canAccessRoles
            || selectedApps.length === 0
            || (selectedRole === 'editor' && allAppsSelected)
            || temporarilyDisableInviteButton
            || fetchRolesError
          }
          onClick={() => {
            handleInvites();

            // temp disables button so an user cannot submit multiple
            // invites in quick succession of each other
            setTimeout(() => {
              setTemporarilyDisableInviteButton(false);
            }, 3000);
          }}
        >
          {staticText.inviteButton}
        </StyledButton>
      </ButtonContainer>
    </div>
  );

  useEffect(() => {
    // Redirect to Edit User flow when the only email is an existing member
    if (emails.length === 1) {
      const existingMember = activeMembers.find(member => member.email === emails[0]);
      if (existingMember) {
        openModal('editUser', { user: existingMember });
      }
    } else if (emails.length > 1) {
      // Prevent attempts to invite existing members
      const activeMemberEmails = new Set(activeMembers.map(member => member.email));
      const emailsToRemove = emails.filter(email => activeMemberEmails.has(email));
      if (emailsToRemove.length > 0) {
        setInputError(`Please remove existing member(s): ${emailsToRemove.join(', ')}`);
      }
    }
  }, [emails]);

  return (
    <Modal
      title={staticText.title}
      content={content}
      toggleModal={closeModal}
    />
  );
};

export default InviteUserModal;

InviteUserModal.propTypes = {
  apps: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  activeMembers: arrayOf(shape({
    email: string.isRequired,
  })).isRequired,
  sendOrgInvites: func.isRequired,
  closeModal: func.isRequired,
  openModal: func.isRequired,
  featureSetId: string.isRequired,
  dashboardPermissionRoles: arrayOf(
    shape({
      name: string,
      displayName: string,
      description: string,
    }),
  ).isRequired,
  organizationRole: string.isRequired,
  fetchRolesError: string.isRequired,
};
