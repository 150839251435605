import React from 'react';
import { string } from 'prop-types';
import { styled, Typography } from '@mui/material';

const Error = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '200px',

  img: {
    width: '40px',
  },
}));

const ErrorState = ({ message }) => {
  return (
    <Error>
      <img src="/assets/icons/alert-triangle-yellow.svg" alt="" />
      <Typography variant="caption" m={2}>{message}</Typography>
    </Error>
  );
};

export default ErrorState;

ErrorState.propTypes = {
  message: string.isRequired,
};
