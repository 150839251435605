import React, { useEffect, useState, useRef } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { Input, styled } from '@mui/material';

const StyledInput = styled(Input)(({ theme }) => ({
  '&.MuiInput-root.Mui-disabled': {
    ':before': {
      borderBottomStyle: 'solid',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  '&:hover:before': {
    borderBottom: `1px solid ${theme.palette.grey[200]} !important`,
  },
}));

const TextInput = ({
  displayName,
  placeholderText,
  handleBlur,
  handleChange,
  currentInputState,
  pathOnData,
  debounceDelay,
}) => {
  // When a text input receives a debounceDelay value, the `input` field
  // below will update on each change while the `debounceInput` will only
  // update after the delay. `handleChange` will be called only when
  // the `debounceInput` value changes, reducing the number of change calls.
  // If no delay is provided, everything below will happen instantly.
  const [input, setInput] = useState(currentInputState.value);
  const [debouncedInput, setDebouncedInput] = useState(input);

  useEffect(() => {
    handleChange({
      target: {
        name: pathOnData,
        value: debouncedInput,
      },
    });
  }, [debouncedInput]);

  const ref = useRef();
  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => {
      setDebouncedInput(input);
    }, debounceDelay);
  }, [input, debounceDelay]);

  return (
    <StyledInput
      id={displayName.toLowerCase().split(' ').join('-')}
      variant="standard"
      placeholder={placeholderText}
      onChange={e => setInput(e.target.value)}
      onBlur={handleBlur}
      value={input}
      name={pathOnData}
    />
  );
};

export default TextInput;

TextInput.propTypes = {
  displayName: string.isRequired,
  pathOnData: string.isRequired,
  placeholderText: string,
  currentInputState: shape({
    isValid: bool,
    value: string,
    visited: bool,
    errorMessage: string,
  }).isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  debounceDelay: number,
};

TextInput.defaultProps = {
  placeholderText: '',
  debounceDelay: 0,
};
