// list of models with known duplicates, varied by upper/lower casing

import { alphabetize } from '../../../utils';

// check the lower case version of the model for desired display names if applicable
const getModelDisplayName = (model) => {
  const knownDuplicates = {
    'i-pace': 'I-Pace',
    leaf: 'LEAF',
    'mach-e': 'Mach-E',
    // temporary solution to add the hyphen in these model names
    // otherwise, filtering by 'I Pace' won't match to 'I-Pace' vehicles
    'e pace': 'E-Pace',
    'i pace': 'I-Pace',
    'f pace': 'F-Pace',
    'f type': 'F-Type',
    'mach e': 'Mach-E',
    'c max energi': 'C-Max Energi',
    'e transit': 'E-Transit',
    'f 150': 'F-150',
    'f 150 lightning': 'F-150 Lightning',
    's max': 'S-Max',
  };
  return knownDuplicates[model.toLowerCase()] || model;
};

// deduplicates model names based on variation in upper/lower casing
const formatVehicles = (vehicles) => {
  const vehiclesCopy = { ...vehicles };
  Object.keys(vehiclesCopy).forEach((make) => {
    const modelsMap = {};
    vehiclesCopy[make].models.forEach((model) => {
      modelsMap[model.toLowerCase()] = getModelDisplayName(model);
    });
    vehiclesCopy[make].models = Object.values(modelsMap).sort(alphabetize);
  });

  return vehiclesCopy;
};

export default formatVehicles;
