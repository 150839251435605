import formFields from '../fields';

const { fields, dynamicProfiles } = formFields;

export const formatFrequency = (value) => {
  switch (value.frequency) {
    case 'hourly':
      return value.hourly;
    case 'daily':
      return `Daily at ${value.daily}`;
    case 'weekly':
      return `Weekly on ${value.weekly}`;
    case 'monthly':
      return `Monthly on day ${value.monthly}`;
    default:
      return JSON.stringify(value);
  }
};

const additionalFieldsByProfile = (profileName) => {
  const profile = dynamicProfiles[profileName];
  const additionalFields = [];
  if (!profile || !profile.impliedValues) return additionalFields;

  // implied values was not configurable but is displayed in Review
  Object.entries(profile.impliedValues).forEach(([fieldName, value]) => {
    const field = fields.find(f => f.name === fieldName);
    if (field) {
      additionalFields.push({
        ...field,
        value,
      });
    }
  });

  return additionalFields;
};

export const filterAndFormatReviewFields = (filteredFields, formValues) => {
  let fieldsToReview = filteredFields.reduce((acc, field) => {
    if (formValues[field.name]) {
      acc.push({
        ...field,
        value: formValues[field.name],
      });
    }
    return acc;
  }, []);

  if (formValues.profile) {
    fieldsToReview = [...fieldsToReview, ...additionalFieldsByProfile(formValues.profile)];
  }

  return fieldsToReview;
};
