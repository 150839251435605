import React, { useEffect, useState } from 'react';

import { bool, func, shape, string } from 'prop-types';

import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { AuthContentContainer, Header } from '../../../../components';
import staticText from '../../../../localization/Authentication/userSecurity';
import { MfaChallengeInput } from '../../../Application/components/UserSecurity/components';

const MfaLogin = ({
  actions: {
    mfaLoginVerificationRequest,
    mfaRecoveryRequest,
    mfaResetLogin,
  },
  mfaInProgress,
  mfaObj,
  mfaError,
  mfaRecoveryEmailSent,
  history,
}) => {
  const [showMfaRecoverySuccess, setShowMfaRecoverySuccess] = useState(false);

  useEffect(() => {
    if (mfaRecoveryEmailSent) {
      setShowMfaRecoverySuccess(true);
    }
  }, [mfaRecoveryEmailSent]);

  const handleBackToLogin = (e) => {
    e.preventDefault();
    mfaResetLogin();
    history.push('/login');
  };

  const handleMfaRecoveryRequest = (e) => {
    e.preventDefault();
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Triggered MFA Recovery' });
    mfaRecoveryRequest();
  };

  return (
    <Box >
      <Header />
      <AuthContentContainer>
        <Box
          display="flex"
          justifyContent="center"
        >
          {showMfaRecoverySuccess ? (
            <Box width="500px">
              <Box sx={{ mb: 4 }}>
                <img
                  src={staticText.mfaRecoveryRequest.image.url}
                  alt={staticText.mfaRecoveryRequest.image.alt}
                  width="120"
                />
              </Box>

              <Typography variant="h1" sx={{ mt: 2, mb: 2 }}>{staticText.mfaRecoveryRequest.title}</Typography>
              <Typography variant="p">{staticText.mfaRecoveryRequest.message}</Typography>

              <Button
                id="backToLogin"
                variant="contained"
                type="button"
                size="large"
                sx={{ mt: 4 }}
                disabled={mfaInProgress}
                onClick={handleBackToLogin}
              >{staticText.mfaRecoveryRequest.button}
              </Button>
            </Box>
          ) : (
            <Box width="500px">
              <Typography variant="h1">{staticText.mfaChallenge.title}</Typography>
              <MfaChallengeInput
                verifyCallback={mfaLoginVerificationRequest}
                mfaInProgress={mfaInProgress}
                mfaObj={mfaObj}
                mfaError={mfaError}
                mfaSaveFactorId={false}
              />
              <Box mt={2}>
                <Button
                  id="triggerMfaRecovery"
                  variant="text"
                  type="button"
                  sx={{ mt: 2 }}
                  disabled={mfaInProgress}
                  onClick={handleMfaRecoveryRequest}
                >{staticText.mfaChallenge.noAccess}
                </Button>
              </Box>
            </Box>
          )
          }
        </Box>
      </AuthContentContainer>
    </Box>
  );
};

export default MfaLogin;

MfaLogin.propTypes = {
  actions: shape({
    mfaLoginVerificationRequest: func.isRequired,
    mfaRecoveryRequest: func.isRequired,
    mfaResetLogin: func.isRequired,
  }).isRequired,
  mfaObj: shape({
    factorId: string,
    challengeId: string,
  }),
  mfaInProgress: bool.isRequired,
  mfaError: string,
  mfaRecoveryEmailSent: bool.isRequired,
  history: shape({
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
  }).isRequired,
};

MfaLogin.defaultProps = {
  mfaError: '',
  mfaObj: {
    factorId: null,
    challengeId: null,
  },

};
