import { useState } from 'react';

/* istanbul ignore next */
const useHandleRole = (role) => {
  const [roleError, setRoleError] = useState('');
  const [selectedRole, setSelectedRole] = useState(role);

  const handleRoleClick = (e) => {
    setRoleError('');
    setSelectedRole(e.target.value);
  };

  return {
    roleError, setRoleError, selectedRole, setSelectedRole, handleRoleClick,
  };
};

export default useHandleRole;
