import React, { useEffect, useState } from 'react';
import { bool, func, number, object, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Header, Timeline, TripData, TripStatusCards } from './components';
import { SimulatorHeader } from '../shared';
import { Wrapper } from '../shared/styles';
import { Dashboard, TimelineBackground, TripStatusCardsBackground } from './styles';
import tripOptions from './utils/tripOptions';
import { Spinner } from '../../../../../../components';
import { startTripClock, incrementTripClock, incrementTripDistance } from './utils/tripClock';
import useTripInterval from './utils/useTripInterval';
import { removeVinCredentialsStorage } from '../../utils/utils';
import staticText from '../../../../../../localization/Application/Simulator/trips';

/* istanbul ignore next */
const SimulateVehicle = ({
  actions: {
    checkConnectedVehicle,
    deleteVehicle,
    fetchVehicleTripFrame,
    createVehicleTrip,
    updateVehicleTrip,
    completeVehicleTrip,
    setSelectedVehicle,
    resetVehicleTrip,
  },
  applicationId,
  selectedVehicle,
  selectedRegion,
  tripType,
  vehicleTrip: {
    tripId,
    autoplay,
    tripActive,
    tripPaused,
    tripSpeed,
    tripLatency,
    tripDurationInMins,
    tripFrameData,
    tripFrameIndex,
  },
  history,
}) => {
  const theme = useTheme();
  const { unitSystems } = tripOptions;
  const [unitSystem, setUnitSystem] = useState(unitSystems[1]);

  const [selectedTripSpeed, setSelectedTripSpeed] = useState(tripSpeed);
  const [selectedTripLatency, setSelectedTripLatency] = useState(tripLatency);

  const [frameIndex, setFrameIndex] = useState(0);
  const [tripIntervalId, setTripIntervalId] = useState('');
  const [tripClock, setTripClock] = useState({ hours: 0, minutes: 0 });
  const [tripDistance, setTripDistance] = useState('0.0');

  const stageData = staticText.trips[selectedRegion][tripType].stages;

  const clearTripInterval = () => {
    clearInterval(tripIntervalId);
  };

  const handleCreateTrip = () => {
    setTripClock({ hours: 0, minutes: 0 });
    setTripDistance('0.0');
    setFrameIndex(0);
    const vehicleInfo = {
      id: selectedVehicle.id,
      vin: selectedVehicle.vin,
      type: selectedVehicle.vehicleType,
      make: selectedVehicle.make,
    };
    createVehicleTrip(
      applicationId,
      vehicleInfo,
      selectedRegion,
      {
        tripType,
        tripLatency: selectedTripLatency,
        tripSpeed: selectedTripSpeed,
      },
    );
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      { label: 'backend action', text: `[simulator] create trip for ${selectedVehicle.id}` },
    );
  };

  const handleCompleteTrip = () => {
    clearTripInterval();
    completeVehicleTrip(applicationId, selectedVehicle.id);
  };

  const handleDeleteVehicle = (vehicle) => {
    const vehicleId = vehicle.id;
    deleteVehicle(applicationId, vehicleId);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: `[simulator] delete vehicle ${vehicleId}` });

    removeVinCredentialsStorage(vehicle.vin);

    // redirect to home page
    history.push(`/apps/${applicationId}/simulator`);
  };

  // Speed and latency changes should only trigger an API call
  // to update the database after the trip has started.
  // The trip interval is listening to the speed state and will
  // therefore be refreshed automatically.
  const handleSpeedChange = (e) => {
    const speed = e.target.value;
    setSelectedTripSpeed(speed);
    if (tripActive) {
      updateVehicleTrip(
        applicationId,
        selectedVehicle.id,
        selectedVehicle.vin,
        'CHANGE_SPEED',
        speed,
      );
    }
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[simulator] change trip speed to ${speed}`,
    });
  };
  const handleLatencyChange = (e) => {
    const latency = e.target.value;
    setSelectedTripLatency(latency);
    if (tripActive) {
      updateVehicleTrip(
        applicationId,
        selectedVehicle.id,
        selectedVehicle.vin,
        'CHANGE_LATENCY',
        latency);
    }
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[simulator] change trip latency to ${latency}`,
    });
  };

  // Adding vehicle info to the fetchVehicleTripFrame call
  const fetchFrame = (appId, tripValues, index) => {
    fetchVehicleTripFrame(
      appId,
      {
        ...tripValues,
        vehicle: {
          id: selectedVehicle.id,
          make: selectedVehicle.make,
          model: selectedVehicle.model,
          year: selectedVehicle.year,
        },
      },
      index,
    );
  };

  const handleTripAction = (action, payload) => {
    if (action === 'RESUME') {
      updateVehicleTrip(applicationId, selectedVehicle.id, selectedVehicle.vin, action);
    }
    if (action === 'PAUSE') {
      clearTripInterval(tripIntervalId);
      updateVehicleTrip(applicationId, selectedVehicle.id, selectedVehicle.vin, action);
    }
    if (action === 'COMPLETE') {
      clearTripInterval(tripIntervalId);
      setTripClock({ hours: 0, minutes: 0 });
      setTripDistance('0.0');
      setFrameIndex(0);
      completeVehicleTrip(applicationId, selectedVehicle.id);
      fetchFrame(
        applicationId,
        {
          tripType,
          region: selectedRegion,
          vehicleType: selectedVehicle.vehicleType,
        },
        0,
      );
    }
    if (action === 'CHANGE_FRAME') {
      const newIndex = payload;
      updateVehicleTrip(applicationId, selectedVehicle.id, selectedVehicle.vin, action, newIndex);
      fetchFrame(
        applicationId,
        { tripId },
        newIndex,
      );
      setFrameIndex(newIndex);
      startTripClock(newIndex, setTripClock);
      incrementTripDistance(
        stageData,
        newIndex,
        setTripDistance,
      );
    }
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      { label: 'backend action', text: `[simulator] ${action} vehicle ${selectedVehicle.id}` },
    );
  };

  // If no active trip for selected vehicle, or the last active trip
  // does not match the newly selected trip type, reset the trip state and
  // fetch the first frame of the selected trip type.
  useEffect(() => {
    if (!selectedVehicle.simulatedVehicleTripId ||
        tripType !== selectedVehicle.simulatedVehicleTripType) {
      resetVehicleTrip();
      fetchFrame(
        applicationId,
        {
          tripType,
          region: selectedRegion,
          vehicleType: selectedVehicle.vehicleType,
        },
        0,
      );
    }
  }, [
    selectedVehicle.simulatedVehicleTripId,
    tripType,
    selectedRegion,
    selectedVehicle.VehicleType,
  ]);

  // Update selected trip speed and latency to match current vehicle trip.
  useEffect(() => {
    setSelectedTripSpeed(tripSpeed);
    setSelectedTripLatency(tripLatency);
  }, [tripSpeed, tripLatency]);

  // Automatically end the trip fetching if we've reached the end of the trip,
  // except for autoplay trips
  useEffect(() => {
    if (
      !autoplay &&
      selectedVehicle.simulatedVehicleTripId &&
      tripDurationInMins > 0 &&
      (tripFrameIndex >= tripDurationInMins - 1)
    ) {
      handleCompleteTrip();
    }
  }, [selectedVehicle.simulatedVehicleTripId, tripDurationInMins, tripFrameIndex]);

  // Update the trip clock, trip distance, starting frame index to match current trip.
  useEffect(() => {
    if (selectedVehicle.simulatedVehicleTripId) {
      setFrameIndex(tripFrameIndex);
      startTripClock(tripFrameIndex, setTripClock);
      incrementTripDistance(
        stageData,
        tripFrameIndex,
        setTripDistance,
      );
    } else {
      setFrameIndex(0);
      setTripClock({ hours: 0, minutes: 0 });
      setTripDistance('0.0');
    }
  }, [selectedVehicle.simulatedVehicleTripId, tripFrameIndex]);

  // If a vehicle is not yet connected to Smartcar, set an interval to check for the
  // connection every minute until confirmed.
  useEffect(() => {
    function tick() {
      checkConnectedVehicle(applicationId, selectedVehicle.vin);
    }
    if (!selectedVehicle.smartcarId) {
      const id = setInterval(tick, 60000);
      return () => clearInterval(id);
    }
    return undefined;
  }, [selectedVehicle.vin]);

  // The callback provided to this custom hook contains all the trip logic
  // that needs to run on an interval, and includes:
  // - updating the frame index
  // - fetching the next frame's data
  // - incrementing the trip clock
  // - incrementing the distance counter
  // const speed = (tripActive && !tripPaused) ? selectedTripSpeed : null;
  useTripInterval(() => {
    let nextFrame = frameIndex + 1;
    if (frameIndex >= tripDurationInMins - 1) {
      if (autoplay) {
        // For autoplay trips, go back to first frame when we've reached the end.
        nextFrame = 0;
      } else {
        // For non-autoplay trips, complete the trip when we've reached the end.
        handleCompleteTrip();
        return undefined;
      }
    }
    fetchFrame(
      applicationId,
      { tripId },
      nextFrame,
    );
    setFrameIndex(nextFrame);
    incrementTripClock(tripClock, setTripClock, nextFrame);
    incrementTripDistance(
      stageData,
      nextFrame,
      setTripDistance,
    );
    return undefined;

    // The interval will only start if a speed value is passed,
    // and will be automatically cleared and restarted any time
    // tripActive, tripPaused or selectedTripSpeed change values
  }, (tripActive && !tripPaused) ? selectedTripSpeed : null, setTripIntervalId);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Simulator - Simulate Vehicle');
  }, []);

  const vehicleState = (tripFrameData && tripFrameData.vehicleState) ?
    tripFrameData.vehicleState : '';

  return (
    <React.Fragment>
      <SimulatorHeader
        heading={staticText.simulatorHeading}
        applicationId={applicationId}
        setSelectedVehicle={setSelectedVehicle}
      />
      <Wrapper>
        <Header
          vehicle={selectedVehicle}
          deleteVehicle={handleDeleteVehicle}
          autoplay={autoplay}
        />
        <Dashboard>
          {tripFrameData && tripFrameData.endpoints ? (
            <React.Fragment>
              <TimelineBackground>
                <Timeline
                  selectedVehicle={selectedVehicle}
                  stages={staticText.trips[selectedRegion][tripType].stages}
                  frameIndex={frameIndex}
                  tripActive={tripActive}
                  tripPaused={tripPaused}
                  handleCreateTrip={handleCreateTrip}
                  handleTripAction={handleTripAction}
                  handleSpeedChange={handleSpeedChange}
                  selectedTripSpeed={selectedTripSpeed}
                  autoplay={autoplay}
                />
              </TimelineBackground>
              <TripStatusCardsBackground>
                <TripStatusCards
                  selectedTripType={tripType}
                  vehicleState={vehicleState}
                  tripActive={tripActive}
                  tripClock={tripClock}
                  tripDistance={tripDistance}
                  unitSystem={unitSystem}
                  handleTripAction={handleTripAction}
                  vehicle={selectedVehicle}
                  setVehicle={setSelectedVehicle}
                  autoplay={autoplay}
                />
              </TripStatusCardsBackground>
              <TripData
                selectedVehicle={selectedVehicle}
                unitSystem={unitSystem}
                tripData={tripFrameData}
                latency={selectedTripLatency}
                handleLatencyChange={handleLatencyChange}
                setUnitSystem={setUnitSystem}
              />
            </React.Fragment>
        ) : (
          <Box>
            <Spinner spinnerColor={theme.palette.grey[200]} additionalClassNames="simulator" />
          </Box>
        )}
        </Dashboard>
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(SimulateVehicle);

SimulateVehicle.propTypes = {
  actions: shape({
    checkConnectedVehicle: func.isRequired,
    fetchVehicleTripFrame: func.isRequired,
    createVehicleTrip: func.isRequired,
    updateVehicleTrip: func.isRequired,
    completeVehicleTrip: func.isRequired,
    deleteVehicle: func.isRequired,
    resetVehicleTrip: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  selectedVehicle: shape({
    make: string.isRequired,
    model: string.isRequired,
    year: number.isRequired,
    vin: string.isRequired,
    simulatedVehicleTripType: string.isRequired,
  }).isRequired,
  selectedRegion: string.isRequired,
  tripType: string.isRequired,
  vehicleTrip: shape({
    tripId: string,
    tripActive: bool,
    tripPaused: bool,
    tripLatency: string,
    tripSpeed: number,
    tripDurationInMins: number,
    tripFrameData: shape({
      stage: number,
      vehicleState: string,
      endpoints: object,
    }),
    tripFrameIndex: number,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
