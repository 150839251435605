import { string } from 'prop-types';

import { getRowIconAndStyle } from '../../util';

const TimelineEventIcon = ({ eventName }) => {
  return getRowIconAndStyle(eventName).Icon;
};

export default TimelineEventIcon;

TimelineEventIcon.propTypes = {
  eventName: string.isRequired,
};
