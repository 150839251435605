import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { reportToSegment, types } from '@smartcar/morse';
import {
  Footer,
  Header,
} from '../../components';
import {
  Login,
  SignUp,
  ForgotPassword,
  ResetPassword,
} from './components';


export default class Authentication extends React.Component {
  componentDidMount() {
    reportToSegment(types.PAGE, 'Authentication');
  }

  render() {
    return (
      <div className="sign-up">
        <Header />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Redirect to="/login" />
        </Switch>
        <Footer />
      </div>
    );
  }
}
