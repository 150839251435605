const previewConfig = {
  appearance: {
    pagePath: '/preamble',
    showHeader: false,
  },
  brands: {
    pagePath: '/brand-selector',
    showHeader: true,
  },
  countries: {
    pagePath: '/preamble',
    showHeader: false,
  },
};

export default previewConfig;
