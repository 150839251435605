import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SimulatorHome from './SimulatorHome';
import { actions } from '../../reducers';
import { actions as tripActions } from '../SimulateVehicle/reducers';

function mapStateToProps(state) {
  return {
    ...state.simulator,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setSelectedVehicle,
    setSelectedRegion,
    deleteVehicle,
  } = actions;
  const {
    fetchVehicleTrip,
  } = tripActions;

  return {
    actions: bindActionCreators(
      {
        setSelectedVehicle,
        setSelectedRegion,
        fetchVehicleTrip,
        deleteVehicle,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SimulatorHome);

