import { TextField as MuiTextField, styled } from '@mui/material';

const TextField = styled(MuiTextField, {
  shouldForwardProp: prop => prop !== 'invalid',
})(({ theme, invalid }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    height: '2.625rem',
    color: theme.palette.text.primary,
    border: 'none',
  },

  '& input': {
    padding: theme.spacing(1, 2),
  },
  '& label': {
    top: '-6px',
    ...(invalid && { color: theme.palette.error.main }),
    '&.MuiInputLabel-shrink': {
      top: 0,
    },
  },
  '& fieldset': {
    borderColor: invalid ? theme.palette.error.main : theme.palette.grey[200],
    transition: 'border 0.25s ease',
  },
  '.MuiOutlinedInput-root:not(.Mui-focused):hover > fieldset': {
    ...(invalid && { borderColor: theme.palette.error.main }),
  },
}));

export default TextField;
