import React from 'react';
import { func } from 'prop-types';

import { NoResults } from '../../../../../../../../components';
import staticText from '../../../../../../../../localization/Application/Logs/noResultsFound';

// eslint-disable-next-line no-unused-vars
const NoResultsFound = ({ resetFilters }) => {
  const ctaProps = {
    button: {
      handleClick: resetFilters,
      text: staticText.buttonText,
      trackingContext: 'Logs',
    },
  };


  return (
    <NoResults
      text={staticText.text}
      {...ctaProps}
    />
  );
};

export default NoResultsFound;

NoResultsFound.propTypes = {
  resetFilters: func.isRequired,
};
