import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WebhooksModal from './WebhooksModal';
import { actions as webhooksActions } from '../../reducers';
import { toggleModalDecorator } from '../../utils/webhookModalHelpers';

const mapDispatchToProps = (dispatch) => {
  const { toggleWebhookModal } = webhooksActions;

  return {
    actions: bindActionCreators({
      toggleWebhookModal: toggleModalDecorator(toggleWebhookModal),
    }, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(WebhooksModal);
