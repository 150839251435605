import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import api from '../api/api';
import { types, actions, selectors } from './reducers';
import { selectors as authSelectors } from '../auth/reducers';
import { actions as errorActions } from '../errorReporter/reducers';
import { fetchApplications } from '../applications/sagas';

import { Toast } from '../../components';

export function* fetchOrganizations() {
  try {
    const { data } = yield call(api.fetchOrganizations);
    const formattedOrganizations = data.reduce((acc, org) => {
      acc[org.id] = org;
      return acc;
    }, {});
    yield put(actions.fetchOrganizationsSuccess(formattedOrganizations));
    return { organizations: formattedOrganizations };
  } catch (error) {
    yield put(actions.fetchOrganizationsFailure(error));
    yield put(errorActions.reportError(error));
    return { error };
  }
}

export function* fetchActiveOrgMembers(action) {
  const organizationId = action.payload;
  try {
    const { data } = yield call(
      api.fetchActiveOrgMembers,
      organizationId,
    );
    yield put(actions.fetchActiveOrgMembersSuccess(data));
  } catch (error) {
    yield put(actions.fetchActiveOrgMembersFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchInvitedOrgMembers(action) {
  const organizationId = action.payload;
  try {
    const { data } = yield call(
      api.fetchInvitedOrganizationMembers,
      organizationId,
    );
    yield put(actions.fetchInvitedOrgMembersSuccess(data));
  } catch (error) {
    yield put(actions.fetchInvitedOrgMembersFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchDashboardPermissionRoles() {
  try {
    const { data } = yield call(
      api.fetchDashboardPermissionRoles,
    );

    yield put(actions.fetchDashboardPermissionRolesSuccess(data));
  } catch (error) {
    yield put(actions.fetchDashboardPermissionRolesFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* updateOrgSettings(action) {
  const organizationId = yield select(selectors.getSelectedOrganization);
  try {
    const { data } = yield call(
      api.updateOrgSettings,
      organizationId,
      action.payload,
    );
    // refresh organizations
    yield call(fetchOrganizations);
    yield put(actions.updateOrgSettingsSuccess(data));
  } catch (error) {
    yield put(actions.updateOrgSettingsFailure(error));
  }
}

export function* updateOrgMember(action) {
  const organizationId = yield select(selectors.getSelectedOrganization);
  const {
    dashboardUserId,
    selectedRole,
    organizationRole,
    applicationIds,
  } = action.payload;
  try {
    yield call(
      api.updateOrgMember,
      organizationId,
      dashboardUserId,
      selectedRole,
      organizationRole,
      applicationIds,
    );
    const { dashboardUserId: currentUserId } = yield select(authSelectors.getUserContext);
    const userEditedThemselves = currentUserId === dashboardUserId;
    if (userEditedThemselves) {
      // refresh roles and permissions attached to organizations and applications
      yield call(fetchApplications);
    }
    yield put(actions.updateOrgMemberSuccess());
  } catch (error) {
    yield put(actions.updateOrgMemberFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* sendOrgInvite(
  organizationId,
  email,
  selectedRole,
  organizationRole,
  applicationIds,
) {
  try {
    yield call(
      api.sendOrganizationInvite,
      organizationId,
      email,
      selectedRole,
      organizationRole,
      applicationIds,
    );
    return { status: 'success' };
  } catch (error) {
    // don't send validation errors to sentry
    if (error.response && error.response.status !== 403) {
      yield put(errorActions.reportError(error));
    }
    const errorMessage = error
      && error.response
      && error.response.data
      && error.response.data.message;

    // attach error to return result
    return { status: 'failure', errorMessage, email };
  }
}

export function* sendOrgInvites(action) {
  const organizationId = yield select(selectors.getSelectedOrganization);

  const {
    emailsToSend, selectedRole, organizationRole, applicationIds,
  } = action.payload;

  const results = yield all((emailsToSend.map(email =>
    call(
      sendOrgInvite,
      organizationId,
      email,
      selectedRole,
      organizationRole,
      applicationIds,
    ),
  )));

  const errors = results.reduce((acc, result) => {
    if (result.status !== 'success') {
      // errors pushed to this array will be displayed to the user in a Toast
      acc.push({ errorMessage: result.errorMessage, email: result.email });
    }
    return acc;
  }, []);
  if (errors.length === 0) {
    yield put(actions.sendOrgInvitesSuccess());
    yield call(Toast, `Member${results.length === 1 ? '' : 's'} invited successfully`, 'success');
  } else {
    yield put(actions.sendOrgInvitesFailure(errors));
    yield all(errors.map(error => call(Toast, error.errorMessage, 'warn')));
  }

  // update list of accepted and invited members
  const refreshAction = {
    payload: organizationId,
  };
  yield all([
    call(fetchActiveOrgMembers, refreshAction),
    call(fetchInvitedOrgMembers, refreshAction),
  ]);
}

export function* verifyOrgInvite(action) {
  try {
    const { data } = yield call(api.verifyOrganizationInvite, action.payload);

    if (data.redirect) {
      yield put(push(data.redirect));
    } else if (data.organizationId) {
      yield put(actions.selectOrganization(data.organizationId));
      yield put(push('/'));
    } else {
      yield put(push('/'));
    }
    yield put(actions.verifyOrgInviteSuccess());
  } catch (error) {
    yield put(actions.verifyOrgInviteFailure(error));
    yield put(errorActions.reportError(error));
    yield put(push('/'));
    const formattedError = yield select(selectors.getVerifyOrgInviteError);
    yield call(Toast, formattedError, 'warn');
    yield put(actions.resetVerifyOrgInviteError());
  }
}

export function* deleteOrgInvite(action) {
  const invitationId = action.payload;
  const organizationId = yield select(selectors.getSelectedOrganization);

  try {
    yield call(
      api.deleteOrganizationInvite,
      organizationId,
      invitationId,
    );

    yield put(actions.deleteOrgInviteSuccess());
  } catch (error) {
    yield put(actions.deleteOrgInviteFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* deleteOrgMember(action) {
  const organizationId = yield select(selectors.getSelectedOrganization);
  const dashboardUser = action.payload;

  try {
    yield call(
      api.deleteOrganizationMember,
      organizationId,
      dashboardUser,
    );

    yield put(actions.deleteOrgMemberSuccess());
    const { dashboardUserId } = yield select(authSelectors.getUserContext);
    const userDeletedThemselves = dashboardUser.id === dashboardUserId;
    if (userDeletedThemselves) {
      // re-initializing onboarding to refresh organizations, applications, billing
      yield put(push('/'));
    }
  } catch (error) {
    yield put(actions.deleteOrgMemberFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.FETCH_ORGANIZATIONS_REQUEST, fetchOrganizations);
  yield takeEvery(types.FETCH_ACTIVE_ORG_MEMBERS_REQUEST, fetchActiveOrgMembers);
  yield takeEvery(types.FETCH_INVITED_ORG_MEMBERS_REQUEST, fetchInvitedOrgMembers);
  yield takeEvery(types.FETCH_DASHBOARD_PERMISSION_ROLES_REQUEST,
    fetchDashboardPermissionRoles);
  yield takeEvery(types.UPDATE_ORG_SETTINGS_REQUEST, updateOrgSettings);
  yield takeEvery(types.UPDATE_ORG_MEMBER_REQUEST, updateOrgMember);
  yield takeEvery(types.SEND_ORG_INVITES_REQUEST, sendOrgInvites);
  yield takeEvery(types.VERIFY_ORG_INVITE_REQUEST, verifyOrgInvite);
  yield takeEvery(types.DELETE_ORG_INVITE_REQUEST, deleteOrgInvite);
  yield takeEvery(types.DELETE_ORG_MEMBER_REQUEST, deleteOrgMember);
}
