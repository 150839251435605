import React from 'react';
import { string } from 'prop-types';

import './Chip.scss';

const Chip = ({ text, additionalStyle }) => <span className={`chip chip-${additionalStyle}`}>{text}</span>;

export default Chip;

Chip.propTypes = {
  text: string.isRequired,
  additionalStyle: string,
};

Chip.defaultProps = {
  additionalStyle: 'default',
};
