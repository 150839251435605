import _ from 'lodash';

import staticText from '../../../../localization/Application/connect-config';

export const types = {
  FETCH_SUPPORTED_COUNTRIES_REQUEST: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_REQUEST',
  FETCH_SUPPORTED_COUNTRIES_SUCCESS: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_SUCCESS',
  FETCH_SUPPORTED_COUNTRIES_FAILURE: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_FAILURE',

  FETCH_CONNECT_CONFIG_REQUEST: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_REQUEST',
  FETCH_CONNECT_CONFIG_SUCCESS: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_SUCCESS',
  FETCH_CONNECT_CONFIG_FAILURE: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_FAILURE',

  FETCH_MAKES_BY_COUNTRY_REQUEST: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_REQUEST',
  FETCH_MAKES_BY_COUNTRY_SUCCESS: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_SUCCESS',
  FETCH_MAKES_BY_COUNTRY_FAILURE: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_FAILURE',

  FETCH_APPLICATION_MAKES_REQUEST: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_REQUEST',
  FETCH_APPLICATION_MAKES_SUCCESS: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_SUCCESS',
  FETCH_APPLICATION_MAKES_FAILURE: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_FAILURE',

  UPDATE_CONNECT_CONFIG_REQUEST: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_REQUEST',
  UPDATE_CONNECT_CONFIG_SUCCESS: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_SUCCESS',
  UPDATE_CONNECT_CONFIG_FAILURE: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_FAILURE',

  UPDATE_APP_LOGO_REQUEST: 'developer-client/connect-config/UPDATE_APP_LOGO_REQUEST',
  UPDATE_APP_LOGO_SUCCESS: 'developer-client/connect-config/UPDATE_APP_LOGO_SUCCESS',
  UPDATE_APP_LOGO_FAILURE: 'developer-client/connect-config/UPDATE_APP_LOGO_FAILURE',

  FETCH_BRAND_MANAGEMENT_CAPABILITIES_REQUEST: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_CAPABILITIES_REQUEST',
  FETCH_BRAND_MANAGEMENT_CAPABILITIES_SUCCESS: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_CAPABILITIES_SUCCESS',
  FETCH_BRAND_MANAGEMENT_CAPABILITIES_FAILURE: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_CAPABILITIES_FAILURE',

  DELETE_APP_LOGO: 'developer-client/connect-config/DELETE_APP_LOGO',

  CLEAR_ERRORS: 'developer-client/connect-config/CLEAR_ERRORS',
};

export const initialState = {
  connectConfig: {},
  defaultSupportedBrands: [], // makes app has access to
  makesByCountry: [],
  brandManagementCapabilities: {},
  defaultSupportedCountries: [], // countries app has access to
  uploadedLogoUrl: null,

  // fetching state
  isFetchingConnectConfig: false,
  isFetchingDefaultSupportedBrands: false,
  isFetchingDefaultSupportedCountries: false,
  isFetchingMakesByCountry: false,
  isFetchingBrandManagementCapabilities: false,

  // updating state
  isUpdatingAppLogo: false,
  isUpdatingConnectConfig: false,

  // fetching errors
  fetchingConnectConfigErrors: {
    defaultSupportedBrands: false,
    connectConfig: '',
    makesByCountry: false,
    updatingConnectConfig: false,
    updatingAppLogo: '',
    brandManagementCapabilities: false,
    defaultSupportedCountries: false,
  },
};

export const actions = {
  fetchSupportedCountriesRequest: () => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_REQUEST,
  }),
  fetchSupportedCountriesSuccess: countries => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_SUCCESS,
    payload: countries,
  }),
  fetchSupportedCountriesFailure: err => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_FAILURE,
    payload: err,
  }),
  fetchConnectConfigRequest: () => ({
    type: types.FETCH_CONNECT_CONFIG_REQUEST,
  }),
  fetchConnectConfigSuccess: data => ({
    type: types.FETCH_CONNECT_CONFIG_SUCCESS,
    payload: data,
  }),
  fetchConnectConfigFailure: err => ({
    type: types.FETCH_CONNECT_CONFIG_FAILURE,
    payload: err,
  }),
  fetchMakesByCountryRequest: country => ({
    type: types.FETCH_MAKES_BY_COUNTRY_REQUEST,
    payload: country,
  }),
  fetchMakesByCountrySuccess: data => ({
    type: types.FETCH_MAKES_BY_COUNTRY_SUCCESS,
    payload: data,
  }),
  fetchMakesByCountryFailure: err => ({
    type: types.FETCH_MAKES_BY_COUNTRY_FAILURE,
    payload: err,
  }),
  fetchApplicationMakesRequest: () => ({
    type: types.FETCH_APPLICATION_MAKES_REQUEST,
  }),
  fetchApplicationMakesSuccess: data => ({
    type: types.FETCH_APPLICATION_MAKES_SUCCESS,
    payload: data,
  }),
  fetchApplicationMakesFailure: err => ({
    type: types.FETCH_APPLICATION_MAKES_FAILURE,
    payload: err,
  }),
  updateConnectConfigRequest: data => ({
    type: types.UPDATE_CONNECT_CONFIG_REQUEST,
    payload: data,
  }),
  updateConnectConfigSuccess: data => ({
    type: types.UPDATE_CONNECT_CONFIG_SUCCESS,
    payload: data,
  }),
  updateConnectConfigFailure: err => ({
    type: types.UPDATE_CONNECT_CONFIG_FAILURE,
    payload: err,
  }),
  updateAppLogoRequest: (data, logoUrlToDelete) => ({
    type: types.UPDATE_APP_LOGO_REQUEST,
    payload: { data, logoUrlToDelete },
  }),
  updateAppLogoSuccess: data => ({
    type: types.UPDATE_APP_LOGO_SUCCESS,
    payload: data,
  }),
  updateAppLogoFailure: err => ({
    type: types.UPDATE_APP_LOGO_FAILURE,
    payload: err,
  }),
  fetchBrandManagementCapabilitiesRequest: () => ({
    type: types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_REQUEST,
  }),
  fetchBrandManagementCapabilitiesSuccess: data => ({
    type: types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_SUCCESS,
    payload: data,
  }),
  fetchBrandManagementCapabilitiesFailure: err => ({
    type: types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_FAILURE,
    payload: err,
  }),
  deleteAppLogo: url => ({
    type: types.DELETE_APP_LOGO,
    payload: url,
  }),
  clearErrors: () => ({
    type: types.CLEAR_ERRORS,
  }),
};

export const selectors = {
  getConnectConfig: (state) => {
    return _.get(state, ['connectCustomizations', 'connectConfig']);
  },
  getUploadedLogoUrl: (state) => {
    return _.get(state, ['connectCustomizations', 'uploadedLogoUrl']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUPPORTED_COUNTRIES_REQUEST:
      return {
        ...state,
        isFetchingDefaultSupportedCountries: true,
      };
    case types.FETCH_SUPPORTED_COUNTRIES_SUCCESS:
      return {
        ...state,
        defaultSupportedCountries: action.payload,
        isFetchingDefaultSupportedCountries: false,
      };
    case types.FETCH_SUPPORTED_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingDefaultSupportedCountries: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          defaultSupportedCountries: true,
        },
      };
    case types.FETCH_CONNECT_CONFIG_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          connectConfig: '',
        },
        isFetchingConnectConfig: true,
      };
    case types.FETCH_CONNECT_CONFIG_SUCCESS:
      return {
        ...state,
        connectConfig: action.payload,
        isFetchingConnectConfig: false,
      };
    case types.FETCH_CONNECT_CONFIG_FAILURE:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          connectConfig:
            (action.payload && action.payload.message) || staticText.errors.connectConfig,
        },
        isFetchingConnectConfig: false,
      };
    case types.FETCH_APPLICATION_MAKES_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          defaultSupportedBrands: false,
        },
        isFetchingDefaultSupportedBrands: true,
      };
    case types.FETCH_APPLICATION_MAKES_SUCCESS:
      return {
        ...state,
        isFetchingDefaultSupportedBrands: false,
        defaultSupportedBrands: action.payload,
      };
    case types.FETCH_APPLICATION_MAKES_FAILURE:
      return {
        ...state,
        isFetchingDefaultSupportedBrands: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          defaultSupportedBrands: true,
        },
      };
    case types.UPDATE_CONNECT_CONFIG_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingConnectConfig: false,
        },
        isUpdatingConnectConfig: true,
      };
    case types.UPDATE_CONNECT_CONFIG_SUCCESS:
      return {
        ...state,
        connectConfig: {
          ...state.connectConfig,
          ...action.payload,
        },
        uploadedLogoUrl: action.payload.logoUrl,
        isUpdatingConnectConfig: false,
      };
    case types.UPDATE_CONNECT_CONFIG_FAILURE:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingConnectConfig: true,
        },
        isUpdatingConnectConfig: false,
      };
    case types.UPDATE_APP_LOGO_REQUEST:
      return {
        ...state,
        isUpdatingAppLogo: !!action.payload.data,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingAppLogo: '',
        },
      };
    case types.UPDATE_APP_LOGO_SUCCESS:
      return {
        ...state,
        isUpdatingAppLogo: false,
        uploadedLogoUrl: action.payload,
      };
    case types.UPDATE_APP_LOGO_FAILURE:
      return {
        ...state,
        isUpdatingAppLogo: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingAppLogo:
            staticText.errors.updatingAppLogo,
        },
      };
    case types.DELETE_APP_LOGO:
      return {
        ...state,
        uploadedLogoUrl: null,
      };
    case types.FETCH_MAKES_BY_COUNTRY_REQUEST:
      return {
        ...state,
        isFetchingMakesByCountry: true,
        previewSelectedCountry: action.payload,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          makesByCountry: false,
        },
      };
    case types.FETCH_MAKES_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetchingMakesByCountry: false,
        makesByCountry: action.payload,
      };
    case types.FETCH_MAKES_BY_COUNTRY_FAILURE:
      return {
        ...state,
        isFetchingMakesByCountry: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          makesByCountry: true,
        },
      };
    case types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          brandManagementCapabilities: false,
        },
        isFetchingBrandManagementCapabilities: true,
      };
    case types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_SUCCESS:
      return {
        ...state,
        isFetchingBrandManagementCapabilities: false,
        brandManagementCapabilities: action.payload,
      };
    case types.FETCH_BRAND_MANAGEMENT_CAPABILITIES_FAILURE:
      return {
        ...state,
        isFetchingBrandManagementCapabilities: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          brandManagementCapabilities: true,
        },
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...initialState.fetchingConnectConfigErrors,
        },
      };
    default:
      return state;
  }
}
