import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Account from './Account';
import { actions as onboardingActions } from '../../../../services/onboarding/reducers';
import { actions as authActions } from '../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  const { createOrgError, isCreatingOrg } = state.onboarding;
  const { isDeletingUser, deleteUserError, session: { userContext } } = state.auth;
  return {
    userContext,
    createOrgError,
    deleteUserError,
    isCreatingOrg,
    isDeletingUser,
    organizations: state.organizations.organizations,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { createOrganization } = onboardingActions;
  const { deleteDashboardUser } = authActions;

  return {
    actions: bindActionCreators({
      createOrganization,
      deleteDashboardUser,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
