const staticText = {
  title: 'Select a plan',
  plans: [
    {
      planName: 'Free',
      price: {
        cost: '$0',
      },
      planSubtitle: 'No credit card required',
      planHighlights: [
        '1 vehicle included',
        '500 API calls per vehicle',
        'Up to 5 simulated vehicles',
        '1 application',
        '3 days of log data',
      ],
      planFeatures: {
        title: 'Get started with:',
        features: [
          {
            text: 'Standard APIs',
            tooltip:
              'Retrieve vehicle data and trigger actions through simple API requests',
          },
          {
            text: 'Standard connect flow',
            tooltip: 'Access to consent management and password reset flows',
          },
          {
            text: '1-day request logs',
            tooltip: 'Access to all vehicle data requests for a 24 hour period',
          },
          {
            text: 'List of vehicles',
            tooltip:
              'Access to all vehicles by VIN that have onboarded to your app',
          },
          {
            text: '1 dashboard application',
            tooltip:
              'One application workspace to use within the Smartcar dashboard',
          },
          {
            text: 'Email support',
          },
        ],
      },
    },
    {
      planName: 'Build',
      price: {
        cost: '$2.99',
        addendum: '/vehicle/month',
      },
      planSubtitle: 'Pay as you go',
      planHighlights: [
        'Up to 100 vehicles included',
        '1,000 API calls per vehicle',
        'Up to 10 simulated vehicles',
        '2 applications',
        '7 days of log data',
      ],
      planFeatures: {
        title: 'Everything in Free, plus:',
        features: [
          {
            text: 'Make-specific APIs',
            tooltip: 'Retrieve vehicle data for specific makes/OEMs',
          },
          {
            text: 'Vehicle simulator',
            tooltip: 'Simulate trips and vehicle behavior within the dashboard',
          },
          {
            text: '7-day request logs',
            tooltip: 'Access to all vehicle data requests for a 7 day period',
          },
          {
            text: '2 dashboard applications',
          },
          {
            text: 'Batch requests',
            tooltip: 'Retrieve large amounts of vehicle data by HTTP requests',
          },
        ],
      },
    },
    {
      planName: 'Enterprise',
      price: {
        cost: 'Custom',
      },
      planSubtitle: 'Volume discounts',
      planHighlights: [
        'Custom API call limit',
        'SLAs/Certifications',
        'Custom # of applications',
        'Full access to simulated vehicles',
        '365 days of log data',
        'Log exports',
        'Smartcar Connect customization',
        'Dedicated Slack channel',
        'Managed onboarding',
      ],
      planFeatures: {
        title: 'Everything in Build, plus:',
        features: [
          {
            text: 'Managed onboarding',
          },
          {
            text: 'Dedicated support',
            tooltip: 'Dedicated customer success manager for your account',
          },
          {
            text: '365-day request logs',
            tooltip: 'Access to all vehicle data requests for a 365-day period',
          },
          {
            text: 'SLAs (optional)',
            tooltip: 'Only available with minimum spend commitments',
          },
          {
            text: '50 dashboard apps',
          },
          {
            text: 'Access to test fleet',
            tooltip: 'Access to the Smartcar test fleet to test your vehicles',
          },
          {
            text: 'Priority response',
            tooltip: 'Response times to support tickets within a 1-hour window',
          },
          {
            text: 'Security audit',
            tooltip:
              'Smartcar will answer compliance questionnaires when asked',
          },
          {
            text: 'Event-based webhooks',
            tooltip: 'Webhooks which are triggered on a specific action',
          },
          {
            text: 'Phone support',
          },
        ],
      },
    },
  ],
  current: 'Current',
  buttonTexts: {
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    contactUs: 'Contact us',
    selected: 'Selected',
    current: 'Current',
    inTrial: 'In trial',
    addPaymentInfo: 'Add payment info',
  },
};

export default staticText;
