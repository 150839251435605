export const setFetchingState = (state, type, key) => {
  return {
    ...state,
    isFetchingOverview: {
      ...state.isFetchingOverview,
      [key]: true,
    },
    [type]: {
      ...state[type],
      [key]: [],
    },
    errors: {
      ...state.errors,
      [key]: '',
    },
  };
};

export const setSuccessState = (state, type, key, action) => {
  return {
    ...state,
    isFetchingOverview: {
      ...state.isFetchingOverview,
      [key]: false,
    },
    [type]: {
      ...state[type],
      [key]: action.payload,
    },
  };
};

export const setFailureState = (state, key, action) => {
  return {
    ...state,
    isFetchingOverview: {
      ...state.isFetchingOverview,
      [key]: false,
    },
    errors: {
      ...state.errors,
      [key]: (action.payload && action.payload.message) || `Something went wrong fetching ${key}`,
    },
  };
};
