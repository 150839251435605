import { useState } from 'react';
import fields from './fields';
import fieldValidations from './validators';

const { defaultFields, dynamicProfiles } = fields;

const useFormState = (webhook = {}) => {
  const [values, setValues] = useState(webhook);
  const [erroredFields, setErroredFields] = useState({});

  const handleChange = ({ key, value }) => {
    if (key === 'type') {
      // if we change the type we need to reset the form values
      const resetFields = Object.keys(values)
        .filter(k => defaultFields[value].includes(k))
        .reduce((acc, k) => {
          acc[k] = values[k];
          return acc;
        }, {});
      if (value === 'dynamic') {
        // dynamic profile currently has only one option so we'll automatically select it
        resetFields.profile = dynamicProfiles.managedCharging.name;
      }
      setValues({ ...resetFields, [key]: value });
    } else {
      setValues(prev => ({ ...prev, [key]: value }));
    }
  };

  const clearErroredField = (name) => {
    setErroredFields((prevErroredFields) => {
      const newErroredFields = { ...prevErroredFields };
      delete newErroredFields[name];
      return newErroredFields;
    });
  };

  const handleInputBlur = (inputName) => {
    if (!fieldValidations.validators[inputName](values[inputName])) {
      setErroredFields(prevErroredFields => ({
        ...prevErroredFields,
        [inputName]: true,
      }));
    } else {
      clearErroredField(inputName);
    }
  };

  return {
    clearErroredField,
    erroredFields,
    handleInputBlur,
    handleChange,
    values,
  };
};

export default useFormState;
