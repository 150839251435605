import React, { useState } from 'react';
import { arrayOf, objectOf, string } from 'prop-types';
import { startCase } from 'lodash';
import { List, ListItemAvatar, ListItemText, Typography } from '@mui/material';

import { ViewAllButton, StyledListItem } from '../../styles';

import sharedText from '../../../../../../../../localization/Application/ConnectInsights/sessionDetailsDrawer';
import { scopes } from '../../../../../../../../localization/Components/endpoints';

const staticText = { ...sharedText, scopes };

const DISPLAY_LIMIT = 15;
const NUM_COLUMNS = 3;

const getListItem = (showViewAllButton, numItems) => (scope) => {
  let scopeDetails = staticText.scopes[scope];

  if (!scopeDetails) {
    const scopeNameArray = scope.split('_');
    // capitalize first letter in first word
    scopeNameArray[0] = startCase(scopeNameArray[0]);
    const label = scopeNameArray.join(' ');
    scopeDetails = {
      label,
      iconSrc: scopes.default.iconSrc,
    };
  }

  return (
    <StyledListItem
      key={scope}
      showViewAllButton={showViewAllButton}
      numColumns={NUM_COLUMNS}
      numItems={numItems}
    >
      <ListItemAvatar sx={{ display: 'flex', minWidth: 'auto', marginRight: '16px' }}>
        <img src={scopeDetails.iconSrc} alt={`Icon representing ${scopeDetails.label}`} />
      </ListItemAvatar>
      <ListItemText
        primary={<Typography>{scopeDetails.label}</Typography>}
      />
    </StyledListItem>
  );
};


const ScopesList = ({ scopesAuthorized }) => {
  const [fullListOpen, setFullListOpen] = useState(false);

  const allScopes = Array.from(Object.keys(scopesAuthorized).reduce((acc, vehId) => {
    const scopesForVehicleId = scopesAuthorized[vehId];
    // control_* permissions can have a subset permission that has a suffix making it more specific
    // ex: control_security:lock --> we want to only get the parent permission name
    scopesForVehicleId.forEach(scope => acc.add(scope.split(':')[0]));
    return acc;
  }, new Set()));

  const showViewAllButton = allScopes.length > DISPLAY_LIMIT;

  return (
    <div>
      <List
        disablePadding
        sx={{
          display: 'grid',
          gridTemplateColumns:
          `repeat(${NUM_COLUMNS}, 1fr)`,
          columnGap: '16px',
        }}
      >
        {allScopes.slice(0, DISPLAY_LIMIT).map(getListItem(showViewAllButton, allScopes.length))}
        {fullListOpen
          && allScopes.slice(DISPLAY_LIMIT).map(getListItem(showViewAllButton, allScopes.length))}
      </List>
      {showViewAllButton && (
        <ViewAllButton
          onClick={() => setFullListOpen(!fullListOpen)}
          type="button"
          variant="text"
          size="small"
        >
          {fullListOpen ? staticText.viewLess : `${staticText.viewAll} (${allScopes.length})`}
        </ViewAllButton>
      )}
    </div>
  );
};

export default ScopesList;

ScopesList.propTypes = {
  scopesAuthorized: objectOf(arrayOf(string)).isRequired,
};
